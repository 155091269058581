import React from "react";
import { ShortCode, TextFieldLabel, ValueTypography } from "..";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import AlternativeButton from "components/Buttons/AlternativeButton";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "utils/mixpanel";
import { PAGES, BUTTONS } from "constants/mixPanelEvents";

export default function Overview({name, targetAudience, message, smsCount, fileName, cid}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const navigate = useNavigate();
	const handleEdit = function(){
		Mixpanel.track_button_action(PAGES.REVIEW_CAMPAIGN, BUTTONS.CAMPAIGN_EDIT);
		navigate("/campaigns/create-campaigns?cid="+cid);
	}

	return (
		<>
			<ChildContainer>
				<InnerChild>
					<Box>
						<TextFieldLabel mb={1}>Campaign Name</TextFieldLabel>
					</Box>
					<Box>
						<AlternativeButton  onClick={() => handleEdit()}>Edit</AlternativeButton>
					</Box>
				</InnerChild>
				<ValueTypography>{name}</ValueTypography>
			</ChildContainer>
			<Divider />
			<ChildContainer>
				<InnerChild>
					<Box>
						<TextFieldLabel mb={1}>Target Audience / Segment</TextFieldLabel>
					</Box>
					<Box>
						<AlternativeButton onClick={() => handleEdit()}>Edit</AlternativeButton>
					</Box>
				</InnerChild>
				<ValueTypography>{targetAudience} </ValueTypography>
			</ChildContainer>
			<Divider />
			{/* <ChildContainer>
				<InnerChild>
					<Box>
						<TextFieldLabel mb={1}>A/B testing</TextFieldLabel>
					</Box>
					<Box>
						<AlternativeButton>Edit</AlternativeButton>
					</Box>
				</InnerChild>
				<ValueTypography>Enabled</ValueTypography>
			</ChildContainer>
			<Divider /> */}
			<ChildContainer>
				<InnerChild>
					<Box>
						<TextFieldLabel mb={1}>Text Message</TextFieldLabel>
					</Box>
					<Box mb={"8px"}>
						<AlternativeButton onClick={() => handleEdit()}>Edit</AlternativeButton>
					</Box>
				</InnerChild>

				<Typography
					fontSize={14}
					fontWeight={500}
					lineHeight={"26px"}
					color={mode == "light" ? "#808698" : "#FFF"}
				>
					{message} 
					<br/><br/>
					Reply STOP to opt-out
				</Typography>
			</ChildContainer>
			<ChildContainer>
				<Box mb={'29px'}>
					<TextFieldLabel mb={1}>SMS Segments</TextFieldLabel>
					<ValueTypography >{smsCount}</ValueTypography>
				</Box >
			{/* </ChildContainer>
			<ChildContainer> */}
				<Box>
					<TextFieldLabel mb={1}>Characters</TextFieldLabel>
					<ValueTypography>{message.length} / 1600</ValueTypography>
				</Box>
			</ChildContainer>
			<Divider />
			<ChildContainer>
				<InnerChild>
					<Box>
						<TextFieldLabel mb={1}>Attach Image or GIF</TextFieldLabel>
					</Box>
					<Box>
						<AlternativeButton onClick={() => handleEdit()}>Edit</AlternativeButton>
					</Box>
				</InnerChild>
				<ValueTypography>{fileName}</ValueTypography>
			</ChildContainer>
		</>
	);
}
export const ChildContainer = ({ children, ...props }) => {
	return (
		<Box
			p={"20px"}
			// display={"flex"}
			// justifyContent={"space-between"}
			// alignItems={"center"}
			{...props}
		>
			{children}
		</Box>
	);
};

const InnerChild = ({ children }) => {
	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			alignItems={"center"}
		>
			{children}
		</Box>
	);
};
