import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AnalyticsTemplate from 'templates/Analytics'
import { DataContext } from "context/DataContext";
import { txtCartFetch } from "utilifyFunctions";
import AnalyticsLockedTemplate from '../templates/LockedPages/Analytics.js'
import { Mixpanel } from "../utils/mixpanel";
import { PAGES, EVENT_CATEGORIES } from "../constants/mixPanelEvents";

class Analytics extends Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);
    this.state = {
      verified: true
    };
  }
    componentDidMount() {
      Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
        page: PAGES.ANALYTICS,
      });        
      this.load();
    }

    load = async function(){        
        const { setShowLoading } = this.context;
        setShowLoading(true);

        const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/verify?id=Business");
        this.setState({verified: response});
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));

            setShowLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setShowLoading(false);
        }
    }

  render() {
      const { verified } = this.state;
      if(verified){
        return (
          <div>
            <Helmet>
              <title>TxtCart® | Analytics</title>
            </Helmet>
            <AnalyticsTemplate />
          </div>
        );
      } else{
        return (
          <div>
            <Helmet>
              <title>TxtCart® | Analytics</title>
            </Helmet>
            <AnalyticsLockedTemplate />
          </div>
        );
      }
  }
}

export default Analytics;
