import {  Grid, Typography, useTheme } from "@mui/material";

import React from "react";
import CampaignsSection from "./components/CampaignsSection";

export default function CampaignPlanner() {
	const theme = useTheme();

	return (
		<Grid container alignItems={"center"}>
			<Grid item xs={12}>
				<Typography
					lineHeight={"normal"}
					fontSize={"24px"}
					fontWeight={600}
					color={theme.palette?.card?.color}
					mb={"8px"}
				>
					Campaign Planner & Templates
				</Typography>
				<Typography
					lineHeight={"20px"}
					fontSize={"14px"}
					fontWeight={500}
					color={theme.palette?.card?.color}
					mb={"24px"}
					sx={{
						opacity: "0.5",
					}}
				>
					Use the upcoming events to engage with your subscribers and send
					high-converting SMS campaigns
				</Typography>
				<CampaignsSection />
			</Grid>
		</Grid>
	);
}
