import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IntercomProvider } from 'react-use-intercom';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		 <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID} autoBoot>
		 	<App />
		 </IntercomProvider>
	</React.StrictMode>
);

reportWebVitals();
