import React, { Component } from "react";
import { Helmet } from "react-helmet";
import DashboardTemplate from "../templates/Dashboard";
import { DataContext } from "context/DataContext";

class Dashboard extends Component {
	static contextType = DataContext;

	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		const { setShowLoading } = this.context;

		// Show the loader
		setShowLoading(true);

		try {
			await new Promise((resolve) => setTimeout(resolve, 2000));

			setShowLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setShowLoading(false);
		}
	};

	render() {
		return (
			<div>
				<Helmet>
					<title>TxtCart® | Dashboard</title>
				</Helmet>
				<DashboardTemplate />
			</div>
		);
	}
}

export default Dashboard;
