/* eslint-disable react/no-unknown-property */
import React from "react";

export default function Filter() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M6 12H18M3 6H21M9 18H15"
				stroke="#0081FF"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
