import React from "react";
import { Helmet } from "react-helmet";
import ReportsComingSoonTemplate from "templates/ComingSoon/Reports";

export default function FlowAnalytics() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | Reports</title>
			</Helmet>
			<ReportsComingSoonTemplate />
		</div>
	);
}