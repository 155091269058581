/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Typography } from "@mui/material";
import { Popover, createTheme, useMediaQuery } from "@mui/material";

import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { DataContext } from "context/DataContext";

export default function CartRecovery({ handleOpen, topText, bottomText }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const {setShowBillingModal}=useContext(DataContext)

	return (
		<Box
			bgcolor={"#0C84FE"}
			sx={{ display: "flex" }}
			py={"12px"}
			alignItems={"center"}
			width={"100%"}
		>
			{!isSmall && (
				<Box width={"250px"} sx={{ display: { sm: "none", md: "flex" } }}></Box>
			)}
			{!isSmall && (
				<Box px={2} mt={"5px"}>
					<img src="/icons/lock.svg" alt="Lock Icon" width={"75%"} />
				</Box>
			)}
			<Box
				justifyContent={"center"}
				display={"flex"}
				flexDirection={"column"}
				textAlign={isSmall ? "center" : "start"}
				padding={isSmall ? "5px 35px" : "0px"}
			>
				<Typography fontSize={"12px"} fontWeight={"600"} mb={"4px"}>
					{topText}
				</Typography>

				<Typography fontSize={"12px"} fontWeight={"400"} color={"#AAD4FF"}>
					{bottomText}
				</Typography>
				{isSmall && (
					<Box mt={2} display={"flex"} justifyContent={"space-evenly"}>
						<Button
							onClick={()=>setShowBillingModal(true)}
							sx={{
								padding: "5px 25px",
								background: "#EBECF2",
								width: "91px",
								fontSize: "12px",
								fontWeight: "600",
								color: "#0C84FE",
								marginRight: "10px",
								":focus": {
									backgroundColor: "#EBECF2",
								},
								":hover": {
									backgroundColor: "#d6d6d9",
								},
							}}
						>
							Upgrade
						</Button>
					</Box>
				)}
			</Box>
			{!isSmall && ( //button for desktop
				<Box>
					<Button
						onClick={()=>setShowBillingModal(true)}
						sx={{
							padding: "5px 25px",
							background: "#EBECF2",
							width: "91px",
							fontSize: "12px",
							fontWeight: "600",
							color: "#0C84FE",
							marginRight: "10px",
							position: "absolute",
							right: "10px",
							bottom: "16px",
							":focus": {
								backgroundColor: "#EBECF2",
							},
							":hover": {
								backgroundColor: "#d6d6d9",
							},
						}}
					>
						Upgrade
					</Button>
				</Box>
			)}
		</Box>
	);
}
