import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import FlowsAnalyticsCard from "components/Card/FlowsAnalytics";

import Skeleton from "components/Skeleton/CartRecovery";
import {
	CTRSVG,
	ClickSVG,
	FlowSVG,
	OrderSVG,
	RevenueSVG,
} from "resources/Icons/Analytics";

export default function Analytics() {
	const [showSkeleton, setShowSkeleton] = useState(false);
	const cardMockfirstRow = [
		{
			title: "Sent Flows",
			value: "152,984",
			valueType: "number",
			desc: "",
			icon: <FlowSVG />,
		},
		{
			title: "Clicks",
			value: "1,235,753",
			valueType: "number",
			desc: "",
			icon: <ClickSVG />,
		},
		{
			title: "CTR",
			value: "17.34",
			valueType: "percentage",
			desc: "",
			icon: <CTRSVG />,
		},
	];
	const cardMockSecondRow = [
		{
			title: "Orders",
			value: "10,891",
			valueType: "number",
			desc: "",
			icon: <OrderSVG />,
		},
		{
			title: "Revenue",
			value: "142,499.25",
			valueType: "amount",
			desc: "",
			icon: <RevenueSVG />,
		},
	];
	const getMode = useTheme();
	const theme = useTheme();
	const mode = getMode?.palette?.mode;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	useEffect(() => {
		setTimeout(() => {
			setShowSkeleton(false);
		}, 3000);
	}, []);
	return (
		<Box
			sx={{
				borderRadius: "12px",
			}}
			mt={"8px"}
		>
			<Box
				display="flex"
				flexDirection={belowTablet ? "column" : "row"}
				justifyContent="space-between"
				gap={"24px"}
			>
				{!showSkeleton
					? cardMockfirstRow?.map((item, index) => {
							return <FlowsAnalyticsCard cardData={item} key={index} />;
					})
					: [1, 2, 3].map((item, index) => {
							return <Skeleton mode={mode} key={index} />;
					})}
			</Box>
			<Box
				display="flex"
				flexDirection={belowTablet ? "column" : "row"}
				justifyContent="space-between"
				gap={"24px"}
				mt={"20px"}
			>
				{!showSkeleton
					? cardMockSecondRow?.map((item, index) => {
							return <FlowsAnalyticsCard cardData={item} key={index} />;
					})
					: [1, 2, 3].map((item, index) => {
							return <Skeleton mode={mode} key={index} />;
					})}
			</Box>
		</Box>
	);
}
