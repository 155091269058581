import { Box, useTheme, Grid } from "@mui/material";
import React from "react";
import FeaturedAppsCard from "../../../components/Card/Integration/FeaturedAppsCard";
import PageHeading from "components/Typography/PageHeading";

export default function FeaturedApps() {
	const getMode = useTheme();
	const mode = getMode?.palette?.mode;
	const featureAppMock = getThreeRandomApp(featuredApps);

	return (
		<Box>
			<PageHeading mb={2} mt={2}>
				Featured Apps
			</PageHeading>
			<Box>
				<Grid
					mb={2.5}
					container
					gap={{ xs: 1.5, lg: 0 }}
					flexWrap={"wrap"}
					justifyContent={"space-between"}
				>
					{featureAppMock?.map((item, index) => {
						return (
							<Grid
								key={index}
								container
								item
								xs={12}
								sm={12}
								lg={3.9}
								flexDirection={"row"}
								sx={{
									padding: "16px",
									borderRadius: "12px",
									border:
										mode === "light"
											? "1px solid #E8EBF3"
											: "1px solid #1E202D",
									background: getMode.palette?.card?.background,
								}}
							>
								<FeaturedAppsCard
									name={item?.name}
									description={item?.description}
									icon={item?.icon}
									action={item?.action}
									mode={mode}
									isConnected={item?.isConnected}
									url={item?.url}
								/>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</Box>
	);
}

function getThreeRandomApp(array) {
	if (array.length < 3) {
		console.error("Array must contain at least 3 objects");
		return [];
	}

	const shuffledArray = array.slice().sort(() => Math.random() - 0.5);
	return shuffledArray.slice(0, 3);
}

const featuredApps = [
	{
		name: "TurboBoost: Pagespeed Booster",
		description: "Page Speed, SEO and Image optimizer to boost traffic & sales",
		icon: "/img/integration/turbo.png",
		action: "Add App",
		isConnected: false,
		url:"https://apps.shopify.com/turboboost"
	},

	{
		name: "Chargeflow Dispute Chargebacks",
		description:
			"Automate Chargebacks, Fight Fraud Prevention, Order Protection",
		icon: "/img/integration/chargeflow.webp",
		action: "Add App",
		isConnected: false,
		url:"https://apps.shopify.com/chargeflow"
	},
	{
		name: "Appstle℠ Subscriptions App",
		description:
			"App for recurring payments, subscriptions, build a box, bundle",
		icon: "/img/integration/appstyle.svg",
		isConnected: false,
		action: "Un Add",
		url:"https://apps.shopify.com/subscriptions-by-appstle"
	},

];
