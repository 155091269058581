
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ConversationsTemplate from "../templates/Cart-Recovery/Conversations";
import { DataContext } from "context/DataContext";
import { Mixpanel } from "../utils/mixpanel";
import { PAGES, EVENT_CATEGORIES } from "../constants/mixPanelEvents";

class Conversation extends Component {
  static contextType = DataContext;

	componentDidMount() {
		Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
			page: PAGES.CONVERSATIONS,
		});
    this.fetchData();
  }

  fetchData = async () => {
    const { setShowLoading } = this.context;

    // Show the loader
    setShowLoading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setShowLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowLoading(false);
    }
  };

  render() {
    return (
		<div>
		<Helmet>
			<title>TxtCart® | Conversations</title>
		</Helmet>
		<ConversationsTemplate />
	</div>
    );
  }
}

export default Conversation;
