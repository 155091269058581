import { Grid } from "@mui/material";
import PageHeading from "components/Typography/PageHeading";
import React from "react";
import Analytics from "./components/Analytics";
import TableSection from "./components/TableSection";

export default function index() {
	return (
		<Grid container spacing={2} alignItems={"center"}>
			<Grid item xs={12}>
				<PageHeading>Flow Analytics</PageHeading>
			</Grid>
			<Grid item xs={12}>
				<Analytics />
			</Grid>
			<Grid item xs={12} mt={'8px'}>
				<TableSection />
			</Grid>
		</Grid>
	);
}
