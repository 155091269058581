/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Banner from "./components/Banner";
import { MenuItem, MenuList, ListItemText } from "@mui/material";
import Templates from "./components/Templates";
import GraphSection from "./components/GraphSection";
import Analytics from "./components/Analytics";
import { MoreOutlined } from "@ant-design/icons";
import Antd from "components/Table/Antd";
import TableHeading from "components/Typography/TableHeading";
import { Dropdown } from "antd";
import TableHeader from "./components/TableHeader";
import PageHeading from "components/Typography/PageHeading";
import EmptyTableData from "templates/Analytics/Campaigns/components/EmptyComponentTable";
import { txtCartFetch } from "utilifyFunctions";
import { useNavigate } from "react-router-dom";
import { useIntercom } from 'react-use-intercom';
import {  compareDateStrings } from "resources/utils/sorter";

export default function Campaigns() {
	const theme = useTheme();
	const [dateRange, setDateRange] = useState("last_days_30");
	const mode = theme?.palette?.mode;
	const [tableData, setTableData] = useState(null);
	const lightMode = mode == "light" ? true : false;
	const [mainData, setMainData] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [showBanner, setShowBanner] = useState(true);
	const [allCount, setAllCount] = useState("");
	const [customCount, setCustomCount] = useState("");
	const [templateCount, setTemplateCount] = useState("");
	const [activeTab, setActiveTab] = useState("All");

	const iconStyle = {
		transform: `rotate(${90}deg)`,
		transition: "transform 0.3s ease-in-out",
	};
	
	const handleDateChange = (label, value) => {
		setDateRange(value);
	}

	const handleRemoveBenner = function(){
		setShowBanner(false);
	}

	const sortSent = function(a,b){
		if(a.sent === b.sent){
			return 0;
		}
		if(a.sent === '--'){
			return 1
		}
		if(b.sent === '--'){
			return -1;
		}
		return a.sent - b.sent; 
	}

	const sortReplyRate = function(a,b){
		if(a.replyRate === b.replyRate){
			return 0;
		}
		if(a.replyRate === '--'){
			return 1
		}
		if(b.replyRate === '--'){
			return -1;
		}

		let tempA= parseFloat(a.replyRate.replace('%',''));
		let tempB = parseFloat(b.replyRate.replace('%',''));
		return tempA - tempB; 
	}

	const sortRevenue = function(a,b){
		if(a.revenue === b.revenue){
			return 0;
		}
		if(a.revenue === '--'){
			return 1
		}
		if(b.revenue === '--'){
			return -1;
		}

		let tempA= parseFloat(a.revenue.replace('%',''));
		let tempB = parseFloat(b.revenue.replace('%',''));
		return tempA - tempB; 
	}
	
	const sortROI = function(a,b){
		if(a.roi === b.roi){
			return 0;
		}
		if(a.roi === '--'){
			return 1
		}
		if(b.roi === '--'){
			return -1;
		}

		let tempA= parseFloat(a.roi.replace('X',''));
		let tempB = parseFloat(b.roi.replace('X',''));
		return tempA - tempB; 
	}
	
	const columns = [
		{
			dataIndex: "name",
			key: "name",
			title: <TableHeading minWidth={"220px"}>Campaign Name</TableHeading>,
			sorter: (a, b) =>a.name?.localeCompare(b.name),
			showSorterTooltip: false,
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "formattedDate",
			key: "formattedDate",
			title: <TableHeading minWidth={"150px"}>Date</TableHeading>,
			sortable: false,
			sorter: (a, b) => compareDateStrings(a.formattedDate, b.formattedDate),
			showSorterTooltip: false,
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					sx={{
						maxWidth: "110px",
					}}
				>
					{params}
				</Typography>
			),
		},
		{
			key: "status",
			dataIndex: "status",
			title: <TableHeading minWidth={"100px"}>Status</TableHeading>,
			// width: "50px",
			render: (params) => showStatus(params),
		},
		{
			dataIndex: "sent",
			key: "sent",
			title: <TableHeading minWidth={"100px"}>Sent</TableHeading>,
			sorter: (a, b) => sortSent(a,b),
			showSorterTooltip: false,
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					maxWidth={"70px"}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "replyRate",
			key: "replyRate",
			title: <TableHeading minWidth={"110px"}>Reply Rate</TableHeading>,
			sortable: false,
			showSorterTooltip: false,
			sorter: (a, b) => sortReplyRate(a,b),
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					maxWidth={"70px"}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		// {
		// 	dataIndex: "ctr",
		// 	key: "ctr",
		// 	title: <TableHeading minWidth={"110px"}>CTR</TableHeading>,
		// 	sortable: false,
		// 	render: (params) => (
		// 		<Typography
		// 			fontSize={14}
		// 			fontWeight={500}
		// 			color={lightMode ? "#000D31" : "#FFF"}
		// 		>
		// 			{params}
		// 		</Typography>
		// 	),
		// },
		{
			dataIndex: "revenue",
			key: "revenue",
			title: <TableHeading minWidth={"110px"}>Revenue</TableHeading>,
			sorter: (a, b) => sortRevenue(a,b),
			showSorterTooltip: false,
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "roi",
			key: "roi",
			title: <TableHeading minWidth={"70px"}>ROI</TableHeading>,
			sorter: (a, b) => sortROI(a,b),
			render: (params, record) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					float={"right"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "none",
			key: "none",
			title: "",
			sortable: false,
			// width: "10%",
			render: (params, record) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					float={"right"}
				>
					 &nbsp;&nbsp;&nbsp;
					<Dropdown
						overlay={<ActionMenu record={record} 
						mainData={mainData} 
						setMainData={setMainData} 
						tableData={tableData} 
						setTableData={setTableData} 
						setCustomCount={setCustomCount} 
						setTemplateCount={setTemplateCount}
						setAllCount={setAllCount}
						/>}
						placement="bottomRight"
						trigger={["click"]}
					>
						<MoreOutlined style={iconStyle} />
					</Dropdown>
				</Typography>
			),
		},
	];

	const showStatus = (value) => {
		return mapStatus[value];
	};

	const mapStatus = {
		sent: (
			<span className={`campaigns_status ${lightMode ? "Sent" : "Sent_Dark"}`}>
				Sent
			</span>
		),
		draft: (
			<span
				className={`campaigns_status ${lightMode ? "Draft" : "Draft_Dark"}`}
			>
				Draft
			</span>
		),
		scheduled: (
			<span
				className={`campaigns_status ${
					lightMode ? "Scheduled" : "Scheduled_Dark"
				}`}
			>
				Scheduled
			</span>
		),
		sending: (
			<span
				className={`campaigns_status ${lightMode ? "Sending" : "Sending_Dark"}`}
			>
				Sending
			</span>
		)
	};

	const filterStatus = function(value){
		setActiveTab("All");
		setSearchValue("");
		if(value === "all"){
			setTableData(mainData);
			return;
		}
		const filteredData = mainData?.filter((campaign) => {
			return value
				? campaign?.status?.toLowerCase().includes(value)
				: true;
		});
		setTableData(filteredData);
	}

	const handleSearch = function(value){
		setSearchValue(value);
		setActiveTab("All");
		let lowerCaseValue = value.toLowerCase();
		const filteredData = mainData?.filter((campaign) => {
			return searchValue
				? campaign?.name?.toLowerCase().includes(lowerCaseValue)
				: true;
		});
		setTableData(filteredData);
	}

	const handleFilterTableData = (type) => {

		let result;
		let tempTableData = mainData;
		if (type === "Template") {
			result = tempTableData?.filter((item) => {
				if(item?.templateId && item?.templateId !== "null") {
					return true;
				} else{
					return false; 
				}
			});
		} else if (type === "Custom") {
			result = tempTableData?.filter((item) => {
				if(!item?.templateId && item?.templateId !== "null") {
					return true;
				} else{
					return false; 
				}
			});
		} else {
			result = [...tempTableData];
		}
		setSearchValue('');
		setTableData([...result]);
	};
	
	const loadAllCampaigns = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/campaign/all");
		let campaigns = Object.values(response);
		setMainData(campaigns);
		setTableData(campaigns);	
		setAllCount(campaigns.length);
		let custom = 0; 
		let template = 0; 
		for(let i = 0; i < campaigns.length; i++){
			if(!campaigns[i].templateId || campaigns[i].templateId === "null"){
				custom++;
			} else{
				template++; 
			}
		}
		setCustomCount(custom);
		setTemplateCount(template);
		console.log(campaigns);
	}

	useEffect(() => {
		loadAllCampaigns();
	}, []);

	return (
		<Grid container spacing={2} alignItems={"center"}>
			<Grid item xs={12}>
				{
					showBanner ? (<Banner mode={mode} handleRemove={handleRemoveBenner}/>)
					: (<></>)	
				}
			</Grid>
			<Grid item xs={12}>
				<PageHeading mb={"24px"} mt={"8px"}>
					Recommended Templates
				</PageHeading>

				<Templates />
			</Grid>
			<Grid item xs={12}>
				<GraphSection handleDateChange={handleDateChange} filter={dateRange}/>
			</Grid>
			<Grid item xs={12}>
				<Analytics dateRange={dateRange}/>
			</Grid>
			<Grid item xs={12}>
				<Box
					sx={{
						borderBottom: `1px solid ${theme.palette?.card?.border}`,
						borderBottomLeftRadius: "12px",
						borderBottomRightRadius: "12px",
					}}
				>
					<Antd
						mode={mode}
						rows={tableData}
						columns={columns}
						EmptyComponentTable={<EmptyTableData />}
						loading={mainData ? false : true}
						TableHead={
							<TableHeader
								mode={mode}
								theme={theme}
								handleFilterTableData={handleFilterTableData}
								searchValue={searchValue}
								setSearchValue={handleSearch}
								allCount={allCount}
								customCount={customCount}
								templateCount={templateCount}
								activeTableTab={activeTab}
								setActiveTableTab={setActiveTab}
								filterStatus={filterStatus}
							/>
						}
						// page="cart-recovery"
					/>
				</Box>
			</Grid>
		</Grid>
	);
}


const ActionMenu = ({ record, mainData, setMainData, tableData, setTableData, setCustomCount, setTemplateCount, setAllCount}) => {
	const navigate= useNavigate();
	const { showMessages } = useIntercom();
	const { showNewMessage } = useIntercom();

	const handleEdit = function(record){
		navigate('/campaigns/create-campaigns?cid='+record._id);
	}

	const cloneCampaign = async function(record){
		let body = {
			id:record._id
		}
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/cloneCampaign", body);
		navigate('/campaigns/create-campaigns?cid='+response.id);
	}

	const cancel = function(record){
		showMessages();
		showNewMessage("I would like to cancel a scheduled campaign I have. The campaign name is '" + record.name + "'");
	}

	const deleteScheduledCampaign = function(record){
		showMessages();
		showNewMessage("I would like to delete a campaign I have scheduled. The campaign name is '" + record.name + "'");
	}

	const deleteCampaign = async function(record){
		let body = {
			id:record._id
		}
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/deleteExistingCampaign", body);
		alert("Campaign Deleted.");
		console.log(tableData);
		const filteredTable = tableData?.filter((campaign) => {
			return campaign?._id !== record._id
		});

		const filteredData = mainData?.filter((campaign) => {
			return campaign?._id !== record._id
		});
		setMainData(filteredData);
		setTableData(filteredTable);

		let custom = 0; 
		let template = 0; 
		for(let i = 0; i < filteredData.length; i++){
			if(!filteredData[i].templateId || filteredData[i].templateId === "null"){
				custom++;
			} else{
				template++; 
			}
		}
		console.log(custom);
		console.log(template);
		setCustomCount(custom);
		setTemplateCount(template);
		setAllCount(filteredData.length);
	}

	const theme = useTheme();
	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={`1px solid ${theme.palette?.card?.border}`}
			borderRadius={"8px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				{record.status !== "sent" && record.status !== "scheduled" && record.status !== "sending" && (
					<MenuItem key={"1"} sx={{ py: 1 }} onClick={()=>{handleEdit(record)}}>
						<ListItemText>
							<MenuTypography theme={theme}>Edit</MenuTypography>
						</ListItemText>
					</MenuItem>
				)}
				<MenuItem key={"2"} sx={{ py: 1 }} onClick={()=>{cloneCampaign(record)}}>
					<ListItemText>
						<MenuTypography theme={theme}>Duplicate</MenuTypography>
					</ListItemText>
				</MenuItem>
				{record.status === "scheduled" && (
					<MenuItem key={"4"} sx={{ py: 1 }} onClick={()=>{cancel(record)}}>
						<ListItemText>
							<MenuTypography theme={theme} >Cancel</MenuTypography>
						</ListItemText>
					</MenuItem>
				)}
				{record.status !== "scheduled" && record.status !== "sent" && record.status !== "sending" && (
					<MenuItem key={"3"} sx={{ py: 1 }} onClick={() =>{deleteCampaign(record)}}>
						<ListItemText>
							<MenuTypography theme={theme} color="#FF465C">
								Delete
							</MenuTypography>
						</ListItemText>
					</MenuItem>
				)}
				{record.status === "scheduled" && (
					<MenuItem key={"3"} sx={{ py: 1 }} onClick={() =>{deleteScheduledCampaign(record)}}>
						<ListItemText>
							<MenuTypography theme={theme} color="#FF465C">
								Delete
							</MenuTypography>
						</ListItemText>
					</MenuItem>
				)}
			</MenuList>
		</Box>
	);
};
const MenuTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			fontSize={"14px"}
			color={theme.palette?.mode == "light" ? "#0A0A18" : "#FFF"}
			lineHeight={"normal"}
			fontWeight={500}
			{...props}
		>
			{children}
		</Typography>
	);
};