import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import Analytics from "templates/Cart-Recovery/components/Analytics";
import Tutorial from "templates/Cart-Recovery/components/Tutorial";
import OnboardingModal from "components/Onboarding";
import FunnelSection from "templates/Cart-Recovery/components/FunnelSection";
import ChartSection from "templates/Cart-Recovery/components/ChartSection";

export default function Onboarding() {
	const getMode = useTheme();
	const mode = getMode?.palette?.mode;
    const lightMode=mode=='light'?true:false
	return (
		<React.Fragment>
			<Box>
				<Box
					sx={{
						position: "relative",
						height: "100vh",
						WebkitFilter: "blur(4px)",
					}}
					className="disableSection"
				>
					<Grid item xs={12}>
						<Tutorial mode={mode} closeTab={() => {}} />
					</Grid>
					<Grid item xs={12} mb={3}>
						<Analytics />
					</Grid>
					<Grid item xs={12} mb={3}>
						<Box
							p={0}
							sx={{
								width: "100%",
								borderRadius: "12px",
								background: lightMode ? "#FFF" : "#0C0D17",
								border: lightMode ? "1px solid #E8EBF3" : "1px solid #1E202D",
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Typography
									p={"21px 0px 14px 20px"}
									fontWeight={"600"}
									fontSize={"20px"}
									lineHeight={"normal"}
								>
									Funnel
								</Typography>
								<Box padding={"14px 14px 20px 0"}>
									<Box
										display={"flex"}
										height={"40px"}
										width={"145px"}
										id="pointerCursor"
										sx={{
											borderRadius: "5px",
											padding: "10px",
											border:
												mode === "light"
													? "1px solid #E8EBF3"
													: "1px solid #1E202D",
											background: getMode.palette?.card?.background,
										}}
										// onClick={handleClick}
									>
										<Box paddingRight={"5px"}>
											<img
												src={`/icons/${
													lightMode ? "calender" : "dark/calender"
												}.svg`}
												width={"80%"}
											/>
										</Box>
										<Typography
											fontWeight={"500"}
											fontSize={"12px"}
											paddingRight={"10px"}
										>
											This Week
										</Typography>
										<Box display={"flex"} justifyContent={"end"}>
											<img src="/topbar/down.svg" width={"50%"} />
										</Box>
									</Box>
									{/* <Popover
										id={id}
										open={open}
										anchorEl={anchorEl}
										onClose={handleClosepop}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
									>
										<Box
											width={"145px"}
											sx={{
												borderRadius: "5px",
												border:
													mode === "light"
														? "1px solid #E8EBF3"
														: "1px solid #1E202D",
												background: getMode.palette?.card?.background,
												color: "#808698",
												fontSize: "12px",
											}}
										>
											{TimeSelection?.map((item, index) => {
												return (
													<Typography
														key={index}
														sx={{
															padding: "8px 15px",
															":hover": {
																background: "#cccaca3b",
															},
														}}
														fontSize={"14px"}
														id="pointerCursor"
													>
														{item?.label}
													</Typography>
												);
											})}
										</Box>
									</Popover> */}
								</Box>
							</Box>

							<FunnelSection mode={mode} />
						</Box>
					</Grid>
					<Grid item xs={12} mb={3}>
						<Box
							py={"25px"}
							sx={{
								width: "100%",
								borderRadius: "12px",
								background: lightMode ? "#FFF" : "#0C0D17",
								border: lightMode ? "1px solid #E8EBF3" : "1px solid #1E202D",
							}}
						>
							<ChartSection mode={mode} />
						</Box>
					</Grid>
					{/* <Grid item xs={12} mb={3}>
						<Box
							sx={{
								borderBottomLeftRadius: "12px",
								borderBottomRightRadius: "12px",
							}}
						>
							<Antd
								mode={mode}
								rows={DataMock}
								columns={TableColumns}
								TableHead={<TableHeader mode={mode} />}
								page="cart-recovery"
							/>
						</Box>
					</Grid> */}
				</Box>
                <OnboardingModal />
			</Box>
		</React.Fragment>
	);
}
