export const checkEmptyState=(link)=>{
    let breakDown = link.split("/");
		let lastLink = breakDown[breakDown?.length - 1];
        if(lastLink=='empty'){
            return true
        }
        return false
}


export function updateDataIfEmpty(arr) {
    if (arr.length === 0) {
        return [];
    }
    return arr.map(dataItem => ({ date: dataItem.date }));
}
export function updateRedarDataIfEmpty(arr) {
    if (arr.length === 0) {
        return [];
    }
    return arr.map(dataItem => ({ 
        subject: dataItem.subject ,
		// smsSent: 0,
		// smsReceived: 0,
		// fullMark: 0,
    }));
}