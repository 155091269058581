import { styled } from "@mui/material";
import Radio from '@mui/material/Radio';

const CustomRadioButton = ({ ...props }) => {
	return (
		<CustomRadio
			{...props}
			size="small"
			sx={{
				padding: "0px 0px 0px 0px",
				marginRight:'8px'
			}}
		/>
	);
};
const CustomRadio = styled(Radio)(() => ({
	// height: "18px",
	// width: "18px%",
}));

export default CustomRadioButton;
