import React from "react";
import { Helmet } from "react-helmet";
import KnowledgeBaseComingSoonTemplate from "templates/ComingSoon/KnowledgeBaseComingSoonTemplate";

export default function Flow() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | KB</title>
			</Helmet>
			<KnowledgeBaseComingSoonTemplate />
		</div>
	);
}
