import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { DataContext } from "context/DataContext";
import TrustHubTemplate from 'templates/TrustHub'
import { Mixpanel } from "../utils/mixpanel";
import { PAGES, EVENT_CATEGORIES } from "../constants/mixPanelEvents";

class TrustHub extends Component {
  static contextType = DataContext;

  componentDidMount() {
		Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
			page: PAGES.TRUST_HUB,
		});
    this.fetchData();
  }

  fetchData = async () => {
    const { setShowLoading } = this.context;

    // Show the loader
    setShowLoading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setShowLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowLoading(false);
    }
  };

  render() {
    return (
		<div>
			<Helmet>
				<title>TxtCart® | Trust Hub</title>
			</Helmet>
			<TrustHubTemplate />
		</div>
	);
  }
}

export default TrustHub;
