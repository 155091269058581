const EVENT_CATEGORIES = {
    PAGE_VIEW: 'page_view',
    BUTTON_CLICK: 'button_clicked',
};

const PAGES = {
    GLOBAL: 'Global',
    HOME: 'Home Page',
    ONBOARDING: 'Onboarding',
    CART_RECOVERY: 'Cart Recovery',
    INBOX: 'Inbox',
    ORDERS: 'Orders',
    CONVERSATIONS: 'Conversations',
    KNOWLEDGE_BASE: 'Knowledge Base',
    CAMPAIGNS: 'Campaigns',
    CREATE_SMS_CAMPAIGN: 'Create SMS Campaign',
    CAMPAIGN_REVIEW: 'Campaign Review',
    LISTS: 'Lists',
    SEGMENTS: 'Segments',
    TAGS: 'Tags',
    CAMPAIGN_PLANNER: 'Campaign Planner',
    POPUPS: 'Popups',
    FLOWS: 'Flows',
    AUTOMATIONS: 'Automations',
    FLOW_BUILDER: 'Flow Builder',
    ANALYTICS: 'Analytics',
    INSIGHTS: 'Insights',
    SUBSCRIBERS: 'Subscribers',
    REPORTS: 'Reports',
    TRUST_HUB: 'Trust Hub',
    INTEGRATIONS: 'Integrations',
    SETTINGS: 'Settings',
    ACCOUNT: 'Account',
    COMPANY: 'Company',
    MESSAGING: 'Messaging',
    DISCOUNTS: 'Discounts',
    BILLING: 'Billing',
    TUTORIALS: 'Tutorials',
    CAMPAIGN_ANALYTICS: 'Campaign Analytics',
    AFFILATE: 'Affilate',
};

const BUTTONS = {
    NOTIFICATION_ICON: 'Notification Icon',
    CREATE_SMS_CAMPAIGN: 'Create SMS Campaign',
    // HELP
    PRODUCT_UPDATES: 'Help/Product Updates',
    FEATURE_REQUESTS: 'Help/Feature Requests',
    HELP_CENTRE: 'Help/Help Center',
    TUTORIALS: 'Help/Tutorials',
    CONTACT_SUPPORT: 'Help/Contact Support',
    // PROFILE
    ACCOUNT_SETTINGS: 'Profile/Account Settings',
    BILLING: 'Profile/Billing',
    GO_TO_SHOPIFY: 'Profile/Go to Shopify',
    GO_TO_STORE: 'Profile/Go to Store',
    AFFILATES: 'Profile/Affiliates',
    LOGOUT: 'Profile/Logout',
    // International Phone Steps
    IPS_ENABLE: 'International Phone Step/Enable',
    IPS_SKIP: 'International Phone Step/Skip',
    // Subscriber Upload Steps
    SUS_UPLOAD: 'Subscriber Upload Step/Upload',
    SUS_SKIP: 'Subscriber Upload Step/Skip',
    // Persona Steps
    PERSONA_ADD: 'Persona Step/Add',
    PERSONA_SKIP: 'Persona Step/Skip',
    // Others
    EXPORT: 'Export',
    MENU_LOGOUT:  'Menu/Logout',
    CUSTOMIZE_AND_SCHEDULE: 'Campaign Planner/Customize & Schedule',
    UNLOCK_ALL_FEATURES: 'Unlock All Features',
    RETRY_TFN: 'Retry Tfn',
    // Recommended
    CREATE_CAMPAIGN: 'Recommended/Create Campaign',
    CONNECT: 'Recommended/Connect',
    VIEW_PLANNER: 'Recommended/View Planner',
    VIEW_ANALYTICS: 'Recommended/View Analytics',
    VIEW_COMPLIANCE: 'Recommended/View Compliance',
    VIEW_SETTINGS: 'Recommended/View Settings',
    UPGRADE_10DLC: 'Recommended/Upgrade 10DLC',
    UPGRADE_PLAN: 'Recommended/Upgrade Plan',
    REFER_AND_EARN: 'Recommended/Refer and Earn',
    // How it works campaigns
    CAMPAIGNS_HOW_IT_WORKS: 'Campaigns/How it works',
    // How it works cart recovery
    CART_RECOVERY_HOW_IT_WORKS: 'Cart Recovery/How it works',
    // How it works analytics
    ANALYTICS_HOW_IT_WORKS: 'Analytics/How it works',
    // Campaign
    SAVE_DRAFT: 'Save Draft',
    SEND_NOW: 'Send Now',
    EDIT: 'Edit',
    SEND_TEST: 'Send Test',
    REVIEW_CAMPAIGN: 'Review Campaign',
    SCHEDULE_NOW: 'Schedule Now',
    SELECT_TEMPLATE: 'Select Template',
    BROWSE_TEMPLATES: 'Browse Templates',
    CAMPAIGN_EDIT: 'Campaign Edit',
    // 10DLC UK
    UK_SUBMIT: 'Messaging/Enable UK',
    AU_SUBMIT: 'Messaging/Enable AU',
    BUY_10DLC: 'Messaging/Buy 10-DLC',
    TEXTING_STATUS: 'Messaging/Texting Status',
    // Settings
    MANAGE_PLANS: 'Billing/Manage Plans',
    CANCEL_PLAN: 'Billing/Cancel Plan',
    VIEW_MESSAGE_RATES: 'Billing/View Message Rates',
    KEEP_MY_SUBSCRIPTION: 'Billing/Cancel/Keep My Subscription',
    CANCEL_MY_SUBSCRIPTION: 'Billing/Cancel/Cancel My Subscription',
    SAVE_SETTINGS: 'Save Settings',
    CREATE_NEW_CODE :'Discounts/Create New Code'
};

const TxtCartMajorVersion = {
    V3: 3
}

module.exports = {
    EVENT_CATEGORIES,
    PAGES,
    BUTTONS,
    TxtCartMajorVersion
};
