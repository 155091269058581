import {
	Backdrop,
	Box,
	Fade,
	LinearProgress,
	Modal,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect } from "react";

export default function ProgressBar() {
	const theme = useTheme();
	const [progress, setProgress] = React.useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					clearInterval(timer);
					return 100;
				}

				const diff = (100 / 2) * 0.5;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);
	return (
		<Modal
			className="Onboarding-modal"
			open={true}
			BackdropComponent={Backdrop}
			BackdropProps={{
				sx: {
					backdropFilter: "blur(4px)",
					backgroundColor: "rgba(0, 0, 0, 0.2)",
					// backgroundColor: "transparent",
					"::-webkit-scrollbar": {
						display: "none",
					},
				},
			}}
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Fade in={true} timeout={500}>
				<Box
					sx={{
						width: "600px",
						maxWidth: "90%",
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						background: theme?.palette?.card?.background,
						padding: "20px",
						borderRadius: "12px",
						outline: "none",
						border: `1px solid ${theme?.palette?.card?.border}`,
						":focus-visible": {
							border: "none",
						},
					}}
				>
					<Box>
						<Typography
							fontSize={20}
							fontWeight={500}
							lineHeight={"normal"}
							color={theme?.palette?.pageHeading}
							mb="18px"
							mt={"6px"}
						>
							Finding the best plan for your store
						</Typography>

						<LinearProgress
							value={progress}
							variant="determinate"
							sx={{
								height: "6px",
								background: theme?.palette?.card?.border,
								"& .MuiLinearProgress-bar": {
									background: "#0CD16A !important",
								},
							}}
						/>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
}
