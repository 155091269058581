import { Grid, Box, Typography, useTheme, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableHeading from "components/Typography/TableHeading";
import Antd from "components/Table/Antd";
import PageHeading from "components/Typography/PageHeading";
import {  compareDateStrings } from "resources/utils/sorter";
import EmptyTableData from "./Components/EmptyComponentTable";
import { txtCartFetch } from "utilifyFunctions";

export default function Orders() {
	const getMode = useTheme();
	const mode = getMode?.palette?.mode;
	const lightMode = mode == "light" ? true : false;

	const [orders, setOrders] = useState(null);

	const getOrders = async function(){
		let orders = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/cart/sold");

		if(orders){
			for(let i = 0; i < orders.length; i++){
				orders[i].AppliedDiscount = orders[i].totalDiscounts ? orders[i].totalDiscounts?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
				orders[i].ItemCount = orders[i].lineItems ? orders[i].lineItems.length : "-";
				orders[i].OrderTotal = orders[i].totalPrice ? orders[i].totalPrice?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
				if(orders[i].soldAt){
					let messageDate = new Date(orders[i].soldAt);
					let day = messageDate.toLocaleDateString("default",{ weekday: 'short'});
					let month =  messageDate.toLocaleDateString("default",{month: "short"});
					orders[i].OrderDate = day + ", " + month + " " + messageDate.getDate() + " " + messageDate.getFullYear();
				} else{
					orders[i].OrderDate = "";
				}
			}
			setOrders(orders);
		}
	}

	useEffect(() => {
		console.log('ORDERS TABLE USE EFFECT');
		getOrders();
		return;
	}, []);


	const columns = [
		{
			dataIndex: "orderId",
			key: "orderId",
			title: <TableHeading minWidth={"150px"}>Order ID</TableHeading>,
			sortable: true,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.orderId)-parseInt(b.orderId),
			width: "20%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "OrderDate",
			key: "OrderDate",
			title: <TableHeading minWidth={"150px"}>Order Date</TableHeading>,
			sortable: true,
			showSorterTooltip: false,
			sorter: (a, b) => compareDateStrings(a.OrderDate, b.OrderDate),
			flex: 1,
			minWidth: 150,
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "ItemCount",
			key: "ItemCount",
			title: <TableHeading minWidth={"100px"}>Item Count</TableHeading>,
			// sortable: true,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.ItemCount, 10) - parseInt(b.ItemCount, 10),
			flex: 1,
			minWidth: 150,
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "AppliedDiscount",
			key: "AppliedDiscount",
			title: <TableHeading minWidth={"100px"}>Applied Discount</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseFloat(a.AppliedDiscount.replace('$','').replace(',','')) - parseFloat(b.AppliedDiscount.replace('$','').replace(',','')),
			flex: 1,
			minWidth: 150,
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					${params}
				</Typography>
			),
		},
		{
			dataIndex: "OrderTotal",
			key: "OrderTotal",
			title: <TableHeading minWidth={"100px"}>Order Total</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseFloat(a.OrderTotal.replace('$','').replace(',','')) - parseFloat(b.OrderTotal.replace('$','').replace(',','')),
			flex: 1,
			minWidth: 150,
			render: (params) => (
				<CustomTypographyDesc lightMode={lightMode}>
					${params}
				</CustomTypographyDesc>
			),
		},
		{
			dataIndex: "vendor",
			key: "vendor",
			title: <TableHeading minWidth={"170px"}>View Order</TableHeading>,
			// flex: 1,
			width: 170,
			// minWidth: '200px',
			sortable: false,
			render: (params) => (
				<Button
					fontSize={14}
					fontWeight={600}
					sx={{
						borderRadius: "4px",
						border: lightMode ? "1px solid #e8ebf3" : "1px solid #1E202D",
						padding: "5px 20px",
						background: lightMode ? "#e8ebf3" : "#1E202D",
					}}	
					onClick={()=> {window.open(params, '_blank')}}
				>
					<Typography
						fontSize={14}
						fontWeight={550}
						color={lightMode ? "#18113C" : "#FFF"}
						// fontFamily={"SF Pro Display"}
					>
						View on Shopify
					</Typography>
				</Button>
			),
		},
	];

	return (
		<Grid container spacing={4} alignItems={"center"}>
			<Grid item container xs={12}>
				<PageHeading mb={2}>All Orders Recovered</PageHeading>
				<Grid item xs={12}>
					<Box
						sx={{
							borderBottom: `1px solid ${getMode.palette?.card?.border}`,
							borderBottomLeftRadius: "12px",
							borderBottomRightRadius: "12px",
						}}
					>
						<Antd columns={columns} rows={orders} mode={mode} EmptyComponentTable={<EmptyTableData/>} />
					</Box>
				</Grid>
			</Grid>
		</Grid>
	);
}
const CustomTypographyDesc = ({ children, lightMode, ...props }) => {
	return (
		<Typography
			fontSize={14}
			fontWeight={500}
			color={lightMode ? "#000D31" : "#FFF"}
			lineHeight={"normal"}
			{...props}
		>
			{children}
		</Typography>
	);
};

// import React from "react";
// import { Table } from "antd";

// const MyTable = () => {
// 	const data = [
// 		{ key: "1", name: "John", age: 25 },
// 		{ key: "2", name: "Jane", age: 30 },
// 		{ key: "3", name: "Doe", age: 22 },
// 		// Add more data as needed
// 	];

// 	const columns = [
// 		{
// 			title: "Name",
// 			dataIndex: "name",
// 			key: "name",
// 			sorter: (a, b) => a.name.localeCompare(b.name),
// 		},
// 		{
// 			title: "Age",
// 			dataIndex: "age",
// 			key: "age",
// 			sorter: (a, b) => a.age - b.age,
// 		},
// 	];

// 	return <Table dataSource={data} columns={columns} />;
// };

// export default MyTable;
