import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { AICopyAssistantButton, CustomHeading } from "..";
// eslint-disable-next-line no-unused-vars
import TextMessage from "./Text-message-1";
import { Mixpanel } from "utils/mixpanel";
import { PAGES, BUTTONS } from "constants/mixPanelEvents";

export default function TextEditor({ 
	setBrowseCampaignModal, 
	heading,
	value,setValue,
	setImage,
	image,
	setTextValue, 
	setFile, 
	setSMSCount, 
	smsCount, 
	setIsGSM, 
	setAddDiscountModal,
	addDiscountModal,
	priceRules,
	setPriceRule,
	codeType,
	setCodeType,
	discountType,
	setDiscountType,
	discountValue,
	setDiscountValue,
	discountUrl,
	setDiscountUrl,
	discountPath,
	setDiscountPath,
	isEmbedLink,
	setIsEmbedLink,
	priceRule,
	setCodeConfirmed
}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const belowMedium = useMediaQuery(theme.breakpoints.down("md"));
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [showNameWarning, setShowNameWarning] = useState(false);

	useEffect(() => {
		if (value && value.includes("{StoreName}")) {
			setShowNameWarning(false); // Hide warning if {StoreName} is present
		} else {
			setShowNameWarning(true);  // Show warning if it's missing
		}
	}, [value]);

	return (
		<React.Fragment>	
			{ showNameWarning === true ? (<Box bgcolor={"#FF004C"} borderRadius={"12px"} paddingTop={"5px"} paddingBottom={"5px"} mb={"10px"}>
				<Typography
						fontSize={"12px"}
						fontWeight={"600"}
						paddingLeft={"10px"}
						color={"#FFF"}
					>
						Warning: Identify your brand. Failure to do so may result in increased Carrier Filtering. Include StoreName.
					</Typography>
				</Box>) : (<></>)}
			<Box
				display={"flex"}
				flexDirection={belowMedium ? "column" : "row"}
				justifyContent={"space-between"}
				alignItems={isSmall ? "baseline" : "center"}
				mb={"9px"}
			>
				<Box>
					<CustomHeading theme={theme} mb={isSmall ? "6px" : "0px"}>
						{heading}
					</CustomHeading>
				</Box>
				<Box display={"flex"} alignItems={"center"}>
					{/* <AICopyAssistantButton>
						<img src="/img/star.svg" style={{ marginRight: "6px" }} />
						<Typography
							fontSize={14}
							fontWeight={600}
							color={"#0081FF"}
							lineHeight={"normal"}
						>
							AI Copy Assistant
						</Typography>
					</AICopyAssistantButton> */}
					<Button
						size={"small"}
						sx={{
							height: "40px",
							backgroundColor: "#0081FF",
							padding: isSmall ? "11px 19px" : "11px 31px",
							color: mode == "light" ? "#FFF" : "#FFF",
							":hover": {
								color: mode == "light" ? "#0C0D17" : "#FFF",
							},
							fontSize: "14px",
							fontWeight: "600",
							ml: isSmall ? "8px" : "24px",
						}}
						variant="outlined"
						onClick={() => {
							setBrowseCampaignModal(true);
							Mixpanel.track_button_action(PAGES.CREATE_SMS_CAMPAIGN, BUTTONS.BROWSE_TEMPLATES);
						}
					}
					>
						Browse templates
					</Button>
				</Box>
			</Box>
			<TextMessage 
				value={value} 
				setValue={setValue} 
				setImage={setImage} 
				image={image} 
				setShowNameWarning={setShowNameWarning} 
				setTextValue={setTextValue} 
				setFile={setFile} 
				setSMSCount={setSMSCount} 
				smsCount={smsCount} 
				setIsGSM={setIsGSM}
				setAddDiscountModal={setAddDiscountModal}
				priceRules={priceRules}
				setPriceRule={setPriceRule}
				codeType={codeType}
				setCodeType={setCodeType}
				discountType={discountType}
				setDiscountType={setDiscountType}
				discountValue={discountValue}
				setDiscountValue={setDiscountValue}
				discountUrl={discountUrl}
				setDiscountUrl={setDiscountUrl}
				setDiscountPath={setDiscountPath}
				discountPath={discountPath}
				setIsEmbedLink={setIsEmbedLink}
				isEmbedLink={isEmbedLink}
				priceRule={priceRule}
				addDiscountModal={addDiscountModal}
				setCodeConfirmed={setCodeConfirmed}
				/>
		</React.Fragment>
	);
}