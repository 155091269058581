import SecondaryButton from "components/Buttons/SecondaryButton";
import EmptyComponentTable from "components/Table/EmptyComponentTable";
import React from "react";
import { CartSVG } from "resources/Icons/Table";

export default function EmptyTableData({handleOpenModal}) {
	return (
		<EmptyComponentTable
			icon={<CartSVG />}
			title="No segments yet"
			desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
            button={<SecondaryButton
            onClick={handleOpenModal}
            >
                Create Segment
            </SecondaryButton>}
		/>
	);
}
