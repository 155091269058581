import {
	Box,
	ListItemText,
	MenuItem,
	MenuList,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Dropdown, Input } from "antd";
import React, { useState } from "react";
import { FilterSVG, SearchSVG } from "resources/Icons";
import Currency from "resources/Icons/Currency";

export default function Contacts({ activeTab }) {
	const theme = useTheme();
	const [isActive, setIsActive] = useState(1);
	const [tab, setTab] = useState("Opened");
	const [filterOption, setfilterOption] = useState("Unread (32)");
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	// console.log("checktab", activeTab);
	return (
		<Box
			width={isMobile ? "100%" : "25%"}
			borderRight={`1px solid ${theme?.palette?.card?.border}`}
			// position={isMobile ? "fixed" : "relative"}
			// zIndex={"9"}
			display={isMobile ? (activeTab == "Info" ? "block" : "none") : "block"}
			// marginLeft={activeTab == "Info" ? "0" : "-50%"}
			bgcolor={theme?.palette?.card?.background}
		>
			<Box p={"20px  0px 0px 0px"}>
				<TabSelection
					tab={tab}
					setTab={setTab}
					filterOption={filterOption}
					setfilterOption={setfilterOption}
				/>

				<Box mb={"31px"} p={"0px 13px"}>
					<Input
						placeholder="Search by Name"
						prefix={<SearchSVG />}
						style={
							{
								// backgroundColor:theme?.palette?.card?.background
							}
						}
					/>
				</Box>
				<Box
					sx={{
						overflow: "hidden",
						overflowY: "scroll",
						maxHeight: `calc(100vh - ${isMobile ? "235px" : "176px"})`,
						minHeight: `calc(100vh - ${isMobile ? "235px" : "176px"})`,
					}}
				>
					{messagesMock?.map((item, index) => {
						return (
							<ChatCard
								key={index}
								name={item?.name}
								count={item?.count}
								number={item?.number}
								time={item?.time}
								message={item?.message}
								isActive={isActive == index}
								setIsActive={setIsActive}
								index={index}
								isRecovered={item?.isRecovered}
								onClick={() => setIsActive(index)}
							/>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
}

const ChatCard = ({
	number,
	count,
	name,
	message,
	time,
	isActive,
	isRecovered,
	...props
}) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	return (
		<Box
			display={"flex"}
			width={"100%"}
			sx={{
				background: isActive
					? lightMode
						? "#E7F3FF"
						: "rgba(12, 132, 254, 0.10)"
					: "transparent",
				":hover": {
					background: lightMode
						? "rgba(0, 0, 0, 0.04)"
						: "rgba(255, 255, 255, 0.08)",
				},
			}}
		>
			<Box
				sx={{
					visibility: isActive ? "visible" : "hidden",
				}}
			>
				<div className="card-bar">&nbsp;</div>
			</Box>
			<Box
				className="contact-card"
				width={"100%"}
				// display={"flex"}
				p={"13px 0px"}
				id="pointerCursor"
				borderBottom={`1px solid ${theme?.palette?.card?.border}`}
				{...props}
			>
				<Box p={"0 13px"}>
					<Box
						display={"flex"}
						justifyContent={"space-between"}
						mb={"7px"}
						sx={{}}
					>
						<Box>
							<Typography
								color={"#808698"}
								fontSize={12}
								fontWeight={600}
								lineHeight={"16px"}
							>
								{number}
							</Typography>
						</Box>
					</Box>
					<Box
						display={"flex"}
						alignItems={"flex-start"}
						gap={isRecovered ? "7px" : "0px"}
					>
						{isRecovered && <Currency isActive={isActive} />}
						<Box
							display={"flex"}
							justifyContent={"space-between"}
							width={"100%"}
						>
							<Typography
								color={theme?.palette?.pageHeading}
								fontSize={16}
								fontWeight={600}
								lineHeight={"normal"}
								sx={{
									opacity: isActive ? 1 : 0.5,
								}}
							>
								{name}
							</Typography>
							{count && (
								<Box
									sx={{
										width: "24px",
										height: "21px",
										background: "#FF004C",
										borderRadius: "100px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										padding: "2px 6px",
									}}
								>
									<Typography
										color={"#FFF"}
										fontSize={12}
										fontWeight={700}
										lineHeight={"normal"}
									>
										{count}
									</Typography>
								</Box>
							)}
						</Box>
					</Box>
					<Box display={"flex"} justifyContent={"space-between"} mt={"8px"}>
						<Typography
							color={"#808698"}
							fontSize={14}
							fontWeight={400}
							lineHeight={"16px"}
						>
							{message}
						</Typography>
						<Typography
							color={"#808698"}
							fontSize={14}
							fontWeight={400}
							lineHeight={"16px"}
						>
							{time}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const TabSelection = ({ tab, setTab, setfilterOption, filterOption }) => {
	const theme = useTheme();
	return (
		<Box p={"0px 13px"} display={"flex"} justifyContent={"space-between"}>
			<Box display={"flex"} gap={"12px"} mb={"16px"}>
				<Typography
					id="pointerCursor"
					color={tab == "Opened" ? theme?.palette?.pageHeading : "#808698"}
					fontSize={16}
					fontWeight={tab == "Opened" ? 600 : 500}
					lineHeight={"normal"}
					onClick={() => setTab("Opened")}
					pb={"7px"}
					sx={{
						borderBottom:
							tab == "Opened"
								? `2px solid #0C84FE`
								: `2px solid ${theme?.palette?.card?.background}`,
					}}
				>
					Opened
				</Typography>
				<Typography
					id="pointerCursor"
					color={tab == "Closed" ? theme?.palette?.pageHeading : "#808698"}
					fontSize={16}
					fontWeight={tab == "Closed" ? 600 : 500}
					lineHeight={"normal"}
					pb={"7px"}
					onClick={() => setTab("Closed")}
					sx={{
						borderBottom:
							tab == "Closed"
								? `2px solid #0C84FE`
								: `2px solid ${theme?.palette?.card?.background}`,
					}}
				>
					Closed
				</Typography>
			</Box>
			<Box>
				<Dropdown
					overlay={
						<ActionMenu
							setfilterOption={setfilterOption}
							filterOption={filterOption}
						/>
					}
					placement="bottomRight"
					trigger={["click"]}
				>
					<Box id="pointerCursor">
						<FilterSVG />
					</Box>
				</Dropdown>
			</Box>
		</Box>
	);
};

const messagesMock = [
	{
		number: "+1 636 697 3988",
		name: "Bob Anderson",
		count: "4",
		message: "I’m not sure where my...",
		isRecovered: false,
		time: "37m",
	},
	{
		number: "+1 636 697 3988",
		name: "Luis Flores",
		count: false,
		message: "I’m not sure where my order we...",
		isRecovered: true,
		time: "37m",
	},
	{
		number: "+1 636 697 3988",
		name: "Sammie Paxton",
		count: "9",
		message: "I’m not sure where my order we...",
		isRecovered: true,
		time: "37m",
	},
	{
		number: "+1 636 697 3988",
		name: "Glenda Meskin",
		count: false,
		message: "I’m not sure where my order we...",
		isRecovered: false,
		time: "1mo",
	},
	{
		number: "+1 636 697 3988",
		name: "Bob Anderson",
		count: false,
		message: "I’m not sure where my order we...",
		isRecovered: false,
		time: "3d",
	},
	{
		number: "+1 636 697 3988",
		name: "Luis Flores",
		count: false,
		message: "I’m not sure where my...",
		isRecovered: false,
		time: "2w",
	},
	{
		number: "+1 636 697 3988",
		name: "Glenda Meskin",
		count: false,
		message: "I’m not sure where my...",
		isRecovered: true,
		time: "2mo",
	},
	{
		number: "+1 636 697 3988",
		name: "Christian McDaniel",
		count: false,
		message: "I’m not sure where my...",
		isRecovered: false,
		time: "1y",
	},
	{
		number: "+1 636 697 3988",
		name: "Christian McDaniel",
		count: false,
		message: "I’m not sure where my...",
		isRecovered: false,
		time: "37m",
	},
	{
		number: "+1 636 697 3988",
		name: "Glenda Meskin",
		count: false,
		message: "I’m not sure where my order we...",
		isRecovered: false,
		time: "1mo",
	},
	{
		number: "+1 636 697 3988",
		name: "Bob Anderson",
		count: false,
		message: "I’m not sure where my order we...",
		isRecovered: false,
		time: "3d",
	},
	{
		number: "+1 636 697 3988",
		name: "Luis Flores",
		count: false,
		message: "I’m not sure where my...",
		isRecovered: false,
		time: "2w",
	},
	{
		number: "+1 636 697 3988",
		name: "Glenda Meskin",
		count: false,
		message: "I’m not sure where my...",
		isRecovered: true,
		time: "2mo",
	},
	{
		number: "+1 636 697 3988",
		name: "Christian McDaniel",
		count: false,
		message: "I’m not sure where my...",
		isRecovered: false,
		time: "1y",
	},
	{
		number: "+1 636 697 3988",
		name: "Christian McDaniel",
		count: false,
		message: "I’m not sure where my...",
		isRecovered: false,
		time: "37m",
	},
];
const ActionMenu = ({ setfilterOption, filterOption }) => {
	const theme = useTheme();
	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={`1px solid ${theme.palette?.card?.border}`}
			borderRadius={"4px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				<MenuItem
					key={"1"}
					sx={{
						padding: "20px 50px 15px 10px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
						textAlign: "left",
					}}
					onClick={() => {
						setfilterOption("Unread (32)");
					}}
				>
					<div
						style={{
							visibility: filterOption == "Unread (32)" ? "visible" : "hidden",
						}}
					>
						<img src="/arrow/tick.svg" />
					</div>
					<ListItemText>
						<MenuTypography
							theme={theme}
							color={filterOption == "Unread (32)" && "#0081FF"}
						>
							Unread (32)
						</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem
					key={"2"}
					sx={{
						padding: "20px 50px 15px 10px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
						textAlign: "left",
					}}
					onClick={() => {
						setfilterOption("Open (129)");
					}}
				>
					<div
						style={{
							visibility: filterOption == "Open (129)" ? "visible" : "hidden",
						}}
					>
						<img src="/arrow/tick.svg" />
					</div>
					<ListItemText>
						<MenuTypography
							theme={theme}
							color={filterOption == "Open (129)" && "#0081FF"}
						>
							Open (129)
						</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem
					key={"3"}
					sx={{
						padding: "20px 50px 15px 10px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
						textAlign: "left",
					}}
					onClick={() => {
						setfilterOption("Closed (3,284)");
					}}
				>
					<div
						style={{
							visibility:
								filterOption == "Closed (3,284)" ? "visible" : "hidden",
						}}
					>
						<img src="/arrow/tick.svg" />
					</div>
					<ListItemText>
						<MenuTypography
							theme={theme}
							color={filterOption == "Closed (3,284)" && "#0081FF"}
						>
							Closed (3,284)
						</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem
					key={"4"}
					sx={{
						padding: "20px 50px 15px 10px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
						textAlign: "left",
					}}
					onClick={() => {
						setfilterOption("Recovered (98)");
					}}
				>
					<div
						style={{
							visibility:
								filterOption == "Recovered (98)" ? "visible" : "hidden",
						}}
					>
						<img src="/arrow/tick.svg" />
					</div>
					<ListItemText>
						<MenuTypography
							theme={theme}
							color={filterOption == "Recovered (98)" && "#0081FF"}
						>
							Recovered (98)
						</MenuTypography>
					</ListItemText>
				</MenuItem>
			</MenuList>
		</Box>
	);
};
const MenuTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			fontSize={"14px"}
			color={theme.palette?.mode == "light" ? "#0A0A18" : "#FFF"}
			lineHeight={"normal"}
			fontWeight={500}
			{...props}
			ml={"8px"}
		>
			{children}
		</Typography>
	);
};
