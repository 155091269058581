/* eslint-disable no-unused-vars */
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import RedarChart from "components/Chart/RedarChart";
import Map from "components/Chart/SalesMap1";
import React, { useEffect, useState } from "react";
import { checkEmptyState } from "resources/helpers/checkEmptyState";
import DottedMap from "dotted-map";

export default function TraficSection({salesByCountry, messageByDate}) {
	const [emptyState, setEmptyState] = useState(false)
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			setEmptyState(true);
		}
	}, []);
	return (
		<Box
			display={"flex"}
			gap={"31px"}
			flexDirection={isMobile ? "column" : "row"}
		>
			<Box
				width={isMobile ? "100%" : "65%"}
				p={"20px"}
				bgcolor={theme?.palette?.card?.background}
				borderRadius={"12px"}
				border={`1px solid ${theme?.palette?.card?.border}`}
			>
				<Typography
					color={theme?.palette?.pageHeading}
					fontSize={20}
					fontWeight={600}
					lineHeight={"normal"}
					// mb={'44px'}
				>
					Sales by Country
				</Typography>
				<Box display={"flex"} justifyContent={"center"} p={"44px"}>
				<Map salesByCountry={salesByCountry}/>
				</Box>
			</Box>
			<Box
				width={isMobile ? "100%" : "35%"}
				p={"20px"}
				bgcolor={theme?.palette?.card?.background}
				borderRadius={"12px"}
				border={`1px solid ${theme?.palette?.card?.border}`}
			>
				<Box display={"flex"} justifyContent={"space-between"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={20}
						fontWeight={600}
						lineHeight={"normal"}
					>
						SMS Traffic
					</Typography>
					<Box
						display={"flex"}
						className="analytics-ellipse "
						gap={isMobile ? "12px" : "16px"}
					>
						<Box
							display={"flex"}
							alignItems={"center"}
							gap={isMobile ? "4px" : "7px"}
						>
							<Box className="analytics-ellipse cart-Recovery-ellipse">
								&nbsp;
							</Box>
							<Typography
								color={theme?.palette?.pageHeading}
								fontSize={isMobile ? 12 : 14}
								fontWeight={500}
								lineHeight={"normal"}
							>
								SMS Sent
							</Typography>
						</Box>
						<Box
							display={"flex"}
							alignItems={"center"}
							gap={isMobile ? "4px" : "7px"}
						>
							<Box className="analytics-ellipse campaigns-ellipse"> &nbsp;</Box>
							<Typography
								color={theme?.palette?.pageHeading}
								fontSize={isMobile ? 12 : 14}
								fontWeight={500}
								lineHeight={"normal"}
							>
								SMS Received
							</Typography>
						</Box>
					</Box>
				</Box>
				<RedarChart emptyState={emptyState} messageByDate={messageByDate}/>
			</Box>
		</Box>
	);
}
