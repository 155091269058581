import { Typography, useTheme } from "@mui/material";
import React from "react";

export default function TableHeading({ children, ...props }) {
    const theme=useTheme()
    const {mode}=theme.palette
    const lightMode=mode=='light'?true:false
	return (
		<Typography
			fontSize={12}
			fontWeight={500}
			lineHeight={"normal"}
			sx={{
				opacity: "0.5",
			}}
			{...props}
			color={lightMode ? "#000D31" : "#FFF"}
		>
			{children}
		</Typography>
	);
}
