export const formatValue=(value,type)=>{
    if(type=='amount'){
        return `$${value}`
    }
    else if(type=='percentage'){
        return `${value}%`
    }
    else if(type=='number'){
        return value
    }
    else if(type=='times'){
        return `${value}x`
    }
    else{
        return value
    }
}