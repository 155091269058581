import React from "react";
import {
	MenuItem,
	MenuList,
	ListItemIcon,
	ListItemText,
	Box,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIntercom } from 'react-use-intercom';
import { Mixpanel } from "utils/mixpanel";
import { PAGES, BUTTONS } from "constants/mixPanelEvents";

export default function HelpMenu({ mode, onClose }) {
	const navigate = useNavigate();
	const { showNewMessage } = useIntercom();
	const { showMessages } = useIntercom();

	const helpMenuMock = [
		{
			img: "/icons/help-menu/help-circle.svg",
			title: "Help Center",
			buttonName: BUTTONS.HELP_CENTRE,
			action: "",
			link: "https://www.help.txtcartapp.com/en/",
			isExternalLink: true,
		},
		{
			img: "/icons/help-menu/Product.svg",
			title: "Product Updates",
			buttonName: BUTTONS.PRODUCT_UPDATES,
			action: "",
			link: "https://txtcart.canny.io/changelog",
			isExternalLink: true,
		},
		{
			img: "/icons/help-menu/Feature.svg",
			title: "Feature Requests",
			buttonName: BUTTONS.FEATURE_REQUESTS,
			action: "",
			link: "https://txtcart.canny.io/feature-requests",
			isExternalLink: true,
		},
		{
			img: "/icons/help-menu/Turtorial.svg",
			title: "Tutorials",
			buttonName: BUTTONS.TUTORIALS,
			action: "",
			link: "/tutorial",
		},
		{
			img: "/icons/help-menu/Contact.svg",
			title: "Contact Support",
			buttonName: BUTTONS.CONTACT_SUPPORT,
			action: "contact",
		},
	];
	const handleClick = (value) => {
		Mixpanel.track_button_action(PAGES.GLOBAL, value?.buttonName);
		if (value?.link) {
			onClose();
			if (value?.isExternalLink) {
				window.open(value?.link, "_blank");
			} else {
				navigate(value?.link);
			}
		}
		else if(value.action === "contact"){
			showMessages();
			showNewMessage();
		}
	};

	return (
		<Box
			bgcolor={mode === "light" ? "#FFF" : "#0F111B"}
			border={mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D"}
			borderRadius={" 0px 0px 8px 8px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				{helpMenuMock?.map((item, index) => {
					return (
						<MenuItem key={index} onClick={() => handleClick(item)}>
							<ListItemIcon>
								<img src={item?.img} />
							</ListItemIcon>
							<ListItemText>
								<Typography fontSize={"14px"} color={"#808698"}>
									{item.title}
								</Typography>
							</ListItemText>
						</MenuItem>
					);
				})}
			</MenuList>
		</Box>
	);
}
