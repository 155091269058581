import React from "react";
import { Helmet } from "react-helmet";
import IntegationLockedTemplate from "../../templates/LockedPages/Integration";

export default function IntegrationLocked() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | Integration</title>
			</Helmet>
			<IntegationLockedTemplate />
		</div>
	);
}
