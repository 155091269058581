import SecondaryButton from "components/Buttons/SecondaryButton";
import EmptyComponentTable from "components/Table/EmptyComponentTable";
import React from "react";
import { CartSVG } from "resources/Icons/Table";

export default function EmptyTableData({handleOpenModal}) {
	return (
		<EmptyComponentTable
			icon={<CartSVG />}
			title="You have no lists yet"
			desc="Upload your subscriber CSV from Shopify or another SMS app"
			button={<SecondaryButton onClick={handleOpenModal} >Import a List</SecondaryButton>}
		/>
	);
}
