import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React from "react";
import { CustomSubHeading } from "./EINTab";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function SixthTab({handleNext, header,maxTexts, rate}) {
	const theme = useTheme();

	return (
		<Box>
			<FixContainer mb={2}>
				<CustomSubHeading theme={theme} fontWeight={600} fontSize={16}>
					{header}
				</CustomSubHeading>
			</FixContainer>
			<FixContainer mb={2}>
				<Typography fontSize={"14px"} fontWeight={"400"} color={"#696E7E"} mb={1}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
					/>
					Increased delivery rates
				</Typography>
				<Typography fontSize={"14px"} fontWeight={"400"} color={"#696E7E"} mb={1}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
					/>
					{maxTexts}
				</Typography>
				<Typography fontSize={"14px"} fontWeight={"400"} color={"#696E7E"}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
					/>
					{rate}
				</Typography>

				
			</FixContainer>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton
					onClick={() => {
						handleNext()
					}}
				>
					Next
				</PrimaryButton>
			</Box>
			
		</Box>
	);
}

export const FixContainer = ({ children, ...props }) => {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Box
			width={belowTablet ? "100%" : "100%"}
			minWidth={belowTablet ? "100%" : "100%"}
			maxWidth={belowTablet ? "100%" : "100%"}
			{...props}
		>
			{children}
		</Box>
	);
};