import { styled } from "@mui/material";
import { Select } from "antd";

const CustomSelect = ({ children, ...props }) => {
	return <StyledSelect {...props}>{children}</StyledSelect>;
};

const StyledSelect = styled(Select)(({ theme }) => ({
	height: "40px",
	width: "100%",
	color: theme.palette?.pageHeading,

	"& .ant-select-selector": {
		backgroundColor: "transparent !important",
		border: `1px solid ${theme.palette?.card?.border} !important`,
	},
	"& .ant-select-selection-placeholder": {
		color: "#808698 !important",
		fontSize: "14px",
		fontWeight: "500",
	},
	"& .ant-select-selection-item": {
		color: theme.palette?.pageHeading,
		fontSize: "14px",
		fontWeight: "500",
	},
	"& .ant-select-item-option-grouped":{
		'.ant-select-item-option-grouped':'0px !important'
	}
}));

export default CustomSelect;
