import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { checkEmptyState } from "resources/helpers/checkEmptyState";

export default function InsightPieChart({ title, data, heading }) {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const adjustedLeftValue = belowTablet
		? parseInt("31px") + 10
		: parseInt("70px") - 24;
	const leftValue = `${adjustedLeftValue}px`;
	const [emptyState, setEmptyState] = useState(false);
	const [graphData, setGraphData] = useState([]);
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			setGraphData(data);
			setEmptyState(true);
		} else {
			setGraphData(data);
		}
	}, []);


	return (
		<Box
			width="100%"
			// height={"226px"}
			bgcolor={theme?.palette?.card?.background}
			border={`1px solid ${theme?.palette?.card?.border}`}
			borderRadius={"12px"}
			p={"20px"}
			className={"nine2"}
		>
			<Typography
				color={theme?.palette?.pageHeading}
				fontSize={20}
				fontWeight={600}
			>
				{title}
			</Typography>
			<Box position={"relative"}>
				<Box
					textAlign={"center"}
					position={"absolute"}
					width={"65px"}
					top={"80px"}
					left={leftValue}
				>
					<Typography fontSize={20} fontWeight={600} lineHeight={"normal"}>
						{emptyState ? "0" : heading?.value}
					</Typography>
					<Typography
						fontSize={12}
						fontWeight={500}
						color={"#84848B"}
						lineHeight={"normal"}
					>
						{heading?.title}
					</Typography>
				</Box>
			</Box>
			<Box display={"flex"} alignItems={"center"} marginLeft={"-24px"}>
				<PieChart width={200} height={200}>
					<Pie
						// activeIndex={this.state.activeIndex}
						activeShape={renderActiveShape}
						data={data}
						cx="50%"
						cy="50%"
						innerRadius={60}
						outerRadius={80}
						fill="rgba(255, 0, 76, 0.25)"
						stroke="#0081FF"
						dataKey="count"
						// onMouseEnter={this.onPieEnter}
					>
						{graphData?.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={emptyState ? "rgba(232, 235, 243, 0.50)" : entry.fill}
								stroke={emptyState ? "rgba(232, 235, 243, 0.50)" : entry.stroke}
							/>
						))}
					</Pie>
				</PieChart>
				<Box
					width={"160px"}
					display={"flex"}
					flexDirection={"column"}
					gap={"16px"}
				>
					{data?.map((item, index) => {
						return (
							<Box
								key={index}
								display={"flex"}
								justifyContent={"space-between"}
							>
								<Box display={"flex"} alignItems={"center"}>
									<span
										style={{
											width: "10px",
											height: "10px",
											background: item?.fill,
											border: `1px solid ${item?.stroke}`,
											borderRadius: "100px",
											marginRight: "12px",
										}}
									>
										&nbsp;
									</span>
									<Typography fontSize={16} fontWeight={500}>
										{item?.name}
									</Typography>
								</Box>
								<Box>
									<Typography fontSize={16} fontWeight={600}>
										{emptyState ? "0" : item?.count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
									</Typography>
								</Box>
							</Box>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
}

const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? "start" : "end";

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
				{payload.name}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={fill}
				fill="none"
			/>
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill="#333"
			>{`PV ${value}`}</text>
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill="#999"
			>
				{`(Rate ${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};


