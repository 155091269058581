/* eslint-disable no-unused-vars */
import { Box, Popover, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import TotalSalesChart from "components/Chart/TotalSalesChart";
import React, { useEffect, useState } from "react";
import { DownArrow } from "resources/Icons/Arrows";
import { checkEmptyState, updateDataIfEmpty } from "resources/helpers/checkEmptyState";

export default function TotalSales({data}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const [anchorEl, setAnchorEl] = useState(null);
	const [showBarChart, setShowBarChart] = useState(true);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<Box
			bgcolor={theme?.palette?.card?.background}
			border={`1px solid ${theme?.palette?.card?.border}`}
			borderRadius={"12px"}
			p={"20px"}
		>
			<Box
				display={"flex"}
				mb={"34px"}
				alignItems={isMobile ? "baseline" : "unset"}
			>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={isMobile ? "column" : "row"}
					gap={isMobile && "14px"}
					width={"100%"}
				>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={20}
						fontWeight={600}
						lineHeight={"normal"}
						mb={isMobile && "14px"}
					>
						Total Sales Generated
					</Typography>
					<Box
						display={"flex"}
						className="analytics-ellipse "
						gap={"12px"}
						justifyContent={isMobile ? "flex-start" : "space-between"}
					>
						<Box display={"flex"} alignItems={"center"} gap={"7px"}>
							<Box className="analytics-ellipse cart-Recovery-ellipse">
								&nbsp;
							</Box>
							<Typography
								color={theme?.palette?.pageHeading}
								fontSize={isMobile ? 12 : 14}
								fontWeight={500}
								lineHeight={"normal"}
							>
								Cart Recovery
							</Typography>
						</Box>
						<Box display={"flex"} alignItems={"center"} gap={"7px"}>
							<Box className="analytics-ellipse campaigns-ellipse"> &nbsp;</Box>
							<Typography
								color={theme?.palette?.pageHeading}
								fontSize={isMobile ? 12 : 14}
								fontWeight={500}
								lineHeight={"normal"}
							>
								Campaigns
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box ml={"20px"}>
					<Box
						display={"flex"}
						justifyContent={"space-between"}
						height={"40px"}
						width={"65px"}
						mb={isMobile ? "10px" : "0px"}
						mt={isMobile ? "0px" : "0"}
						id="pointerCursor"
						sx={{
							borderRadius: "5px",
							padding: "11px",
							border:
								mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
							background: theme.palette?.card?.background,
							position: "relative",
						}}
						onClick={handleClick}
					>
						<Box paddingRight={"5px"}>
							{showBarChart ? (
								<img
									src={`/img/analytics/${
										mode == "light" ? "bar-chart" : "bar-chart-dark"
									}.svg`}
									width={"19px"}
								/>
							) : (
								<img
									src={`/img/analytics/${
										mode == "light" ? "line-chart" : "line-chart-dark"
									}.svg`}
									width={"80%"}
								/>
							)}
						</Box>

						<Box
							display={"flex"}
							alignItems={"center"}
							sx={{
								// position: "absolute",
								top: "14px",
								right: "14px",
							}}
						>
							<DownArrow />
							{/* <img src="/topbar/down.svg" width={"100%"} /> */}
						</Box>
					</Box>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
					>
						<Box
							width={"65px"}
							sx={{
								borderRadius: "5px",
								border:
									mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
								background: theme.palette?.card?.background,
								color: "#808698",
								fontSize: "12px",
							}}
						>
							<Typography
								sx={{
									padding: "8px 15px",
									":hover": {
										background: "#cccaca3b",
									},
								}}
								fontSize={"14px"}
								id="pointerCursor"
								onClick={() => {
									handleClose()
									setShowBarChart(!showBarChart)}
								}
							>
								{!showBarChart ? (
									<img
										src={`/img/analytics/${
											mode == "light" ? "bar-chart" : "bar-chart-dark"
										}.svg`}
										width={"19px"}
									/>
								) : (
									<img
										src={`/img/analytics/${
											mode == "light" ? "line-chart" : "line-chart-dark"
										}.svg`}
										width={"19px"}
									/>
								)}
								{/* {item} */}
							</Typography>
						</Box>
					</Popover>
				</Box>
			</Box>

			<Box>
				<TotalSalesChart showBarChart={showBarChart} graphData={data} />
			</Box>
		</Box>
	);
}
