/* eslint-disable no-unused-vars */
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { DecreaseTag, NeutralTag, IncreaseTag } from "./common";
import Chart from "./Chart";

export default function Graph({
	name,
	value,
	isIncrease,
	byValue,
	data,
	emptyState,
	dataKey
}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Box
			bgcolor={theme?.palette?.card?.background}
			border={`1px solid ${theme?.palette?.card?.border}`}
			borderRadius={"12px"}
			width={"100%"}
			p={"20px"}
		>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				mb={"5px"}
			>
				<Typography
					fontSize={16}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
					lineHeight={"normal"}
					sx={{
						opacity: "0.5",
					}}
				>
					{name}
				</Typography>
				{byValue == 0 ? (<NeutralTag/>)
				: isIncrease ? (
					<IncreaseTag byValue={byValue} />
				) : (
					<DecreaseTag byValue={byValue} />
				)}
			</Box>
			<Box mb={"32px"}>
				<Typography
					fontSize={30}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
					lineHeight={"normal"}
				>
					{value}
				</Typography>
			</Box>
			<Box>
				<Chart data={data} mode={mode} tooltipName={name} dataKey={dataKey}/>
			</Box>
		</Box>
	);
}
