import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Box } from "@mui/material";

export default function Layout({children}) {
  
	return (
		<Box display={"flex"} mt={'112px'}>
			<Sidebar  />
			<Box width={"100%"}>
				<Header  flowName={'Recovery Flow'}/>
        {children}
			</Box>
		</Box>
	);
}
