import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function InvalidTab() {
	const theme = useTheme();
	return (
		<Box>
			<CustomSubHeading theme={theme}>
				Based on the information provided, your business is not eligible for 10
				Digit Long Code messaging. If you believe this is a mistake, please
				contact support.
			</CustomSubHeading>
		</Box>
	);
}

export const CustomSubHeading = ({ children, theme, ...props }) => {
	return (
		<Typography
			color={theme.palette?.pageHeading}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			mb={1}
			sx={{
				opacity: "0.5",
			}}
			{...props}
		>
			{children}
		</Typography>
	);
};