import { Box, Grid, Skeleton, useTheme } from "@mui/material";
import React from "react";

export default function MetricsCard({mode}) {
    const getMode = useTheme();
	return (
		<Grid
			container
			id="metricCard"
			item
			xs={12}
			sm={12}
			lg={2.24}
			md={5.8}
			justifyContent={"space-between"}
			sx={{
				padding: "10px",
				borderRadius: "12px",
				border: mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
				background: getMode.palette?.card?.background,
			}}
		>
			<Box sx={{ width: '100%' }}>
				<Skeleton animation="wave" />
				<Skeleton variant="rectangular" animation="wave" height={40} />
			</Box>
		</Grid>
	);
}
