import React from "react";
import { Modal, Box, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function CaptureModal({ mode, open, handleClose }) {
	const lightMode = mode == "light" ? true : false;
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Modal
			open={open}
			// onClose={handleClose}
			id="CaptureModal"
			aria-labelledby="CaptureModal"
			aria-describedby="CaptureModal"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					mb={3}
				>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"20px"}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Successfully Ran Auto-Capture
					</Typography>
					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={handleClose}
						id="pointerCursor"
						style={{
							marginTop: "-10px",
						}}
					/>
				</Box>
				<Box
					textAlign={"center"}
					bgcolor={lightMode ? "#DBEDFF" : "rgba(12, 132, 254, 0.10)"}
					padding={"29px 0px"}
					borderRadius={"8px"}
					display={"flex"}
					justifyContent={"center"}
				>
					<Typography
						color={lightMode ? "#0081FF" : "#0081FF"}
						fontSize={"20px"}
						fontWeight={600}
						lineHeight={"normal"}
						mb={"6px"}
						maxWidth={"414px"}
					>
						Your websites FAQ page was successfully captured for new answers to
						customer questions
					</Typography>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "500px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "20px 20px 25px 20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};
