import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

export default function PageHeading({ children, ...props }) {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Typography
			lineHeight={"normal"}
			fontSize={belowTablet ? "20px" : "24px"}
			fontWeight={600}
			color={theme.palette?.card?.color}
			{...props}
		>
			{children}
		</Typography>
	);
}
