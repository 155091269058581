import { Box, Modal, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import EINTab from "./Tabs/EINTab";
import { useTheme } from "@emotion/react";
import { txtCartFetch } from "utilifyFunctions";

import SMSCapabaleTab from "./Tabs/SMSCapabaleTab";
import VolumeTab from "./Tabs/VolumeTab";
import CompanyInformationTab from "./Tabs/CompanyInformationTab";
import ContactInformationTab from "./Tabs/ContactInformationTab";
import PoliciesTab from "./Tabs/PoliciesTab";
import DataReviewTab from "./Tabs/DataReviewTab";
import RateInfoTab from "./Tabs/RateInfo";
import InvalidTab from "./Tabs/InvalidTab";
import VerificationTab from "./Tabs/VerificationTab";
import SubmittedTab from "./Tabs/SubmittedTab";

export default function MessagingModal({ open, handleClose, settings }) {
	const [tab, setTab] = useState("EINTab");
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [formData, setFormData] = useState({});
	const [submissionMessage, setSubmissionMessage] = useState("");

	const handleFormData = (name, value) => {
		setFormData({ ...formData, [name]: value });
	};
	useEffect(() => {
		console.log(formData);
	}, [formData]);

	const submitSoleProp = async function(){
		console.log("submit sole prop");
		let body = {
			type : "Sole Prop",
			tos: formData.TOSUrl,
			privacy: formData.PrivacyPolicyUrl
		};
		
		try {
			const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/a2p/submit", body, true);
			
			if (response && response.success){
				setSubmissionMessage("Your request has been submitted. You will receive a verification message to your SMS Capable phone.");
			} else {
				console.log(response);
				const errorMessage = "There was an issue submitting your request. Please review your data or contact support.";
				setSubmissionMessage(errorMessage);
			}
		} catch (error) {
			setSubmissionMessage("There was a problem connecting to the server. Please try again later.");
			console.error("Submission error:", error);
		}
		setTab("SubmittedTab");
	};
	
	const submitBrand = async function(){
		formData.type = formData.segmentsCount === "6,000 to 220,000" ? "Standard" : "Low Volume";
		
		try {
			const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/a2p/submit", formData, true);
	
			if (response && response.success){
				setSubmissionMessage("Your request has been submitted. You will receive a verification message to your SMS Capable phone.");
			} else {
				console.log(response);
				const errorMessage = "There was an issue submitting your request. Please review your data or contact support.";
				setSubmissionMessage(errorMessage);
			}
		} catch (error) {
			setSubmissionMessage("There was a problem connecting to the server. Please try again later.");
			console.error("Submission error:", error);
		}
		setTab("SubmittedTab");
	};

	const hanldeSubmit = function(){
		if(formData.hasEIN === "No"){
			submitSoleProp();
		} 
		else{
			submitBrand();
		}
	}

	return (
		<Modal
			open={open}
			id="messaging"
			aria-labelledby="messaging-modal"
			aria-describedby="messaging-modal"
		>
			<MainContainer belowSmall={belowSmall} lightMode={lightMode}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					mb={"24px"}
				>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"22px"}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Enable 10 Digit Long Code
					</Typography>

					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={() => {
							setTab("EINTab");
							setFormData({});
							handleClose();
						}}
						id="pointerCursor"
						style={{
							marginTop: "-10px",
						}}
					/>
				</Box>
				{tab === "EINTab" && (
					<EINTab formData={formData} handleFormData={handleFormData} setTab={setTab} settings={settings}/>
				)}
				{tab === "SMSCapableTab" && (
					<SMSCapabaleTab formData={formData} handleFormData={handleFormData} setTab={setTab}/>
				)}
				{tab === "VolumeTab" && (
					<VolumeTab formData={formData} handleFormData={handleFormData} setTab={setTab} />
				)}
				{tab === "CompanyInformationTab" && <CompanyInformationTab setTab={setTab} handleFormData={handleFormData} formData={formData}/>}
				{tab === "ContactInformationTab" && <ContactInformationTab setTab={setTab} handleFormData={handleFormData} formData={formData}/>}
				{tab === "PoliciesTab" && <PoliciesTab setTab={setTab} formData={formData} handleFormData={handleFormData} settings={settings}/>}
				{tab === "RateInfoSolePropTab" && 
					<RateInfoTab 
					handleNext={()=> setTab("DataReviewTab")} 
					handleClose={handleClose} 
					header="Based on the information provided, you are eligible for the following:" 
					maxTexts="Up to 3,000 Messages per day"
					rate="A message rate of one message per second"/>}
				{tab === "RateInfoLowVolumeTab" &&
					<RateInfoTab 
					handleNext={() => setTab("DataReviewTab")} 
					handleClose={handleClose} 
					header="Based on the information provided, you are eligible for the following:" 
					maxTexts="Up to 6,000 Messages per day"
					rate="A message rate of 3.75 messages per second"/>}
				{tab === "RateInfoStandardTab" &&
					<RateInfoTab 
					handleNext={() => setTab("DataReviewTab")} 
					handleClose={handleClose} 
					header="Based on the information provided, you are eligible for the following:" 
					maxTexts="Up to 200,000 Messages per day"
					rate="A message rate of up to 225 messages per second"/>}
				{tab === "DataReviewTab" && <DataReviewTab setTab={setTab} settings={settings} formData={formData}/>}
				{tab === "VerificationTab" && <VerificationTab setTab={setTab} settings={settings} formData={formData} handleSubmit={hanldeSubmit}/>}
				{tab === "InvalidTab" && <InvalidTab />}
				{tab === "SubmittedTab" && <SubmittedTab message={submissionMessage}/>}
				
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowSmall }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowSmall ? "90%" : "700px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "20px 20px 25px 20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
				maxHeight: "90vh",
			}}
		>
			{children}
		</Box>
	);
};