/* eslint-disable react/no-unknown-property */
import React from "react";

export default function LastArrow() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="7"
			height="12"
			viewBox="0 0 7 12"
			fill="none"
		>
			<path
				d="M6 11L1 6L6 1"
				stroke="#888D9F"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
