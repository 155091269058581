import React from "react";

export default function ClickedLink() {
	return (
		<svg width="25" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.11488 8.68024C3.26786 8.67878 3.41704 8.63244 3.54392 8.54696C3.67081 8.46148 3.7698 8.34063 3.82862 8.1994C3.88744 8.05818 3.9035 7.90278 3.87481 7.75251C3.84612 7.60224 3.77394 7.46369 3.66722 7.35407L1.32347 5.01032C1.17612 4.86801 0.978778 4.78927 0.773937 4.79105C0.569095 4.79283 0.373148 4.87499 0.228298 5.01984C0.0834477 5.16469 0.00128388 5.36064 -0.000496149 5.56548C-0.00227618 5.77032 0.0764694 5.96766 0.218781 6.11501L2.56253 8.45876C2.71011 8.60273 2.90871 8.68237 3.11488 8.68024Z"
				fill="#0081FF"
			/>
			<path
				d="M0.827188 12.5938H2.33398C2.54118 12.5938 2.7399 12.5114 2.88641 12.3649C3.03292 12.2184 3.11523 12.0197 3.11523 11.8125C3.11523 11.6053 3.03292 11.4066 2.88641 11.2601C2.7399 11.1136 2.54118 11.0312 2.33398 11.0312H0.827188C0.619988 11.0312 0.421273 11.1136 0.27476 11.2601C0.128247 11.4066 0.045938 11.6053 0.045938 11.8125C0.045938 12.0197 0.128247 12.2184 0.27476 12.3649C0.421273 12.5114 0.619988 12.5938 0.827188 12.5938Z"
				fill="#0081FF"
			/>
			<path
				d="M7.02148 7.90621C7.22868 7.90621 7.4274 7.8239 7.57391 7.67739C7.72042 7.53088 7.80273 7.33216 7.80273 7.12496V5.61816C7.80273 5.41096 7.72042 5.21225 7.57391 5.06574C7.4274 4.91922 7.22868 4.83691 7.02148 4.83691C6.81428 4.83691 6.61557 4.91922 6.46906 5.06574C6.32254 5.21225 6.24023 5.41096 6.24023 5.61816V7.12496C6.24023 7.33216 6.32254 7.53088 6.46906 7.67739C6.61557 7.8239 6.81428 7.90621 7.02148 7.90621Z"
				fill="#0081FF"
			/>
			<path
				d="M0.771318 18.8384C0.873557 18.8394 0.974973 18.8201 1.06975 18.7818C1.16454 18.7434 1.25082 18.6868 1.32366 18.615L3.66741 16.2713C3.80972 16.1239 3.88847 15.9266 3.88669 15.7217C3.88491 15.5169 3.80275 15.3209 3.6579 15.1761C3.51305 15.0312 3.3171 14.9491 3.11226 14.9473C2.90742 14.9455 2.71007 15.0243 2.56272 15.1666L0.218974 17.5103C0.113168 17.6204 0.041795 17.759 0.0136049 17.9091C-0.0145853 18.0592 0.00163293 18.2142 0.0602722 18.3552C0.118912 18.4962 0.217422 18.6171 0.343725 18.7029C0.470028 18.7887 0.618633 18.8358 0.771318 18.8384Z"
				fill="#0081FF"
			/>
			<path
				d="M10.9276 8.68306C11.0299 8.6839 11.1313 8.66452 11.2261 8.62603C11.3209 8.58753 11.4072 8.53069 11.4799 8.45876L13.8237 6.11501C13.966 5.96766 14.0447 5.77032 14.0429 5.56548C14.0412 5.36064 13.959 5.16469 13.8141 5.01984C13.6693 4.87499 13.4733 4.79283 13.2685 4.79105C13.0637 4.78927 12.8663 4.86801 12.719 5.01032L10.3752 7.35407C10.2693 7.4642 10.1978 7.60286 10.1695 7.75304C10.1412 7.90321 10.1574 8.05838 10.2161 8.19949C10.2747 8.3406 10.3733 8.46153 10.4997 8.54743C10.6261 8.63333 10.7748 8.68046 10.9276 8.68306Z"
				fill="#0081FF"
			/>
			<path
				d="M5.95331 10.7454C6.18189 10.5136 6.47335 10.3539 6.7917 10.2858C7.11006 10.2178 7.44136 10.2445 7.74472 10.3626L22.3428 15.9235C22.6716 16.0487 22.953 16.2737 23.1474 16.567C23.3418 16.8603 23.4394 17.2071 23.4266 17.5587C23.4138 17.9104 23.2912 18.2492 23.076 18.5276C22.8609 18.806 22.5639 19.01 22.2268 19.111L16.2064 20.9172C16.1872 20.9229 16.1696 20.9333 16.1553 20.9475C16.1411 20.9617 16.1306 20.9792 16.1248 20.9985L14.3189 27.0189C14.2207 27.3573 14.0174 27.6558 13.7385 27.8713C13.4596 28.0868 13.1195 28.2081 12.7671 28.2178C12.4157 28.2296 12.0693 28.1316 11.7762 27.9374C11.483 27.7433 11.2576 27.4626 11.1314 27.1344L5.57034 12.536C5.45209 12.2328 5.42534 11.9015 5.49341 11.5833C5.56148 11.265 5.72138 10.9737 5.95331 10.7454Z"
				fill="#0081FF"
			/>
		</svg>
	);
}
