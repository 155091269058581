import {
	Box,
	MenuItem,
	Select,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function EINTab({ formData, handleFormData,  setTab, settings }) {
	const theme = useTheme();
	const [hasEIN, setHasEIN] = useState(false);
	const [hasEINSelected, setHasEINSelected] = useState(false)

	const hanldeChange = function(e){
		setHasEINSelected(true);
		setHasEIN(e.target?.value);
		handleFormData(e.target?.name, e.target?.value)
	}

	const handleNext = function(e){
		if(hasEIN === "Yes"){
			setTab("VolumeTab");
		} 
		else if(settings.country === "United States" || settings.county === "Canada"){
			setTab("SMSCapableTab");
		} else{
			setTab("InvalidTab");
		}
	}
	return (
		<Box>
			<FixContainer>
				<CustomTypography theme={theme}>
					Does the business you&apos;re registering have a tax ID (Ex. US EIN)?
				</CustomTypography>
				<CustomSubHeading theme={theme}>
					You can find your US EIN on your W2 or W9.
				</CustomSubHeading>
				<CustomSelect
					value={formData?.taxID}
					name="hasEIN"
					onChange={(e) => hanldeChange(e)}
				>
					<CustomMenuItem value="Yes">Yes</CustomMenuItem>
					<CustomMenuItem value="No">No</CustomMenuItem>
				</CustomSelect>
			</FixContainer>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton disabled={!hasEINSelected}
					onClick={() => handleNext()}
				>
					Next
				</PrimaryButton>
			</Box>
		</Box>
	);
}

export const FixContainer = ({ children, ...props }) => {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Box
			width={belowTablet ? "100%" : "70%"}
			minWidth={belowTablet ? "100%" : "70%"}
			maxWidth={belowTablet ? "100%" : "70%"}
			{...props}
		>
			{children}
		</Box>
	);
};

export const CustomTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			color={theme.palette?.pageHeading}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			mb={1}
			{...props}
		>
			{children}
		</Typography>
	);
};
export const CustomSubHeading = ({ children, theme, ...props }) => {
	return (
		<Typography
			color={theme.palette?.pageHeading}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			mb={1}
			sx={{
				opacity: "0.5",
			}}
			{...props}
		>
			{children}
		</Typography>
	);
};

export const CustomMenuItem = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<MenuItem
			sx={{
				background: theme?.palette?.card?.background,
				color: "#808698",
				fontWeight: 400,
				fontSize: "14px",
			}}
			{...props}
		>
			{children}
		</MenuItem>
	);
};

export const CustomSelect = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<Select
			sx={{
				backgroundColor: theme?.palette?.card?.background,
				"& .MuiSvgIcon-root": { color: "#C1C1C1" },
				"&:focus": {
					backgroundColor: "transparent",
				},
				fontSize: "14px",
				fontWeight: "500",
				lineHeight: "normal",
			}}
			IconComponent={ExpandMoreIcon}
			style={{ width: "100%", height: "40px" }}
			{...props}
			MenuProps={{
				PaperProps: {
					style: {
						maxHeight: 200,
						maxWidth: 300,
						marginTop: 3,
						borderRadius: 8,
						border: `1px solid ${theme?.palette?.card?.border}`,
					},
				},
			}}
		>
			{children}
		</Select>
	);
};