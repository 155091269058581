import React from "react";

export default function Down() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="8"
			viewBox="0 0 12 8"
			fill="none"
		>
			<path
				d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z"
				fill="#808698"
			/>
		</svg>
	);
}
