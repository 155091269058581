import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
	CustomMenuItem,
	CustomSelect,
	CustomSubHeading,
	CustomTypography,
	FixContainer,
} from "./EINTab";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function SMSCapableTab({ formData, handleFormData, setTab }) {
	const theme = useTheme();
	const [smsCapableSet, setSmsCapableSet] = useState(false);

	const handleChange = function(e){
		setSmsCapableSet(true);
		handleFormData(e.target?.name, e.target?.value)
	}
	
	return (
		<Box>
			<React.Fragment>
				<CustomSubHeading theme={theme} fontWeight={600} fontSize={16}>
					To confirm your brand, you will receive an SMS message to your
					phone. If this message cannot be received, verification will be
					rejected.
				</CustomSubHeading>
				<FixContainer>
					<CustomTypography theme={theme}>
						Do you have an SMS Capable phone?
					</CustomTypography>
					<CustomSelect
						name="smsCapablePhone"
						value={formData?.smsCapablePhone}
						onChange={(e) => handleChange(e)}
					>
						<CustomMenuItem value="Yes">Yes</CustomMenuItem>
						<CustomMenuItem value="No">No</CustomMenuItem>
					</CustomSelect>
				</FixContainer>
			</React.Fragment>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton
					disabled={!smsCapableSet}
					onClick={() => {
						if (formData?.smsCapablePhone === "No") {
							setTab("InvalidTab");
						} else{
							setTab("PoliciesTab");
						}
					}}
				>
					Next
				</PrimaryButton>
			</Box>
		</Box>
	);
}