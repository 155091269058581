import LockedModal from "components/Modal/LockedPage";
import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import FeaturedApps from "templates/Integration/components/FeaturedApps";
import IntegrationApps from "templates/Integration/components/IntegrationApps";
export default function Integration() {
	const [showVideoModal, setShowVideoModal] = useState(false);
	useEffect(() => {
		document.body.style.overflow = "hidden";
		const elementsToDisableHover = document.querySelectorAll(".disableSection");
		const disableHoverClass = "disable-hover";
		elementsToDisableHover.forEach((element) => {
			element.classList.add(disableHoverClass);
		});
	}, []);
	return (
		<>
			<Box>
				<Box
					sx={{
						position: "relative",
						height: "100vh",
						WebkitFilter: "blur(4px)",
					}}
					className="disableSection"
				>
					<Grid container spacing={2} alignItems={"center"}>
						<Grid item xs={12}>
							<FeaturedApps />
						</Grid>
						<Grid item xs={12}>
							<IntegrationApps />
						</Grid>
					</Grid>
				</Box>
				<LockedModal
						message="Connect your store to other apps and get powerful integrations to
					bolster data and SMS."
					setShowVideoModal={setShowVideoModal}
				/>
			</Box>
		</>
	);
}
