import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	ResponsiveContainer,
	Tooltip,
} from "recharts";
import {
	checkEmptyState,
	updateRedarDataIfEmpty,
} from "resources/helpers/checkEmptyState";

const data = [
	{
		subject: "Mon",
		smsSent: 120,
		smsReceived: 80,
		//fullMark: 150,
	},
	{
		subject: "Tue",
		smsSent: 150,
		smsReceived: 130,
		//fullMark: 150,
	},
	{
		subject: "Wed",
		smsSent: 140,
		smsReceived: 80,
		//fullMark: 150,
	},
	{
		subject: "Thu",
		smsSent: 130,
		smsReceived: 150,
		//fullMark: 150,
	},
	{
		subject: "Fri",
		smsSent: 140,
		smsReceived: 80,
		//fullMark: 150,
	},
	{
		subject: "Sat",
		smsSent: 80,
		smsReceived: 140,
		//fullMark: 150,
	},
	{
		subject: "Sun",
		smsSent: 150,
		smsReceived: 140,
		//fullMark: 150,
	},
];
export default function RedarChart({messageByDate}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const [graphData, setGraphData] = useState([]);
	const CustomYAxisTick = (props) => {
		var { x, y, payload } = props;

		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={0}
					dy={5}
					textAnchor="end"
					// opacity={0.5}
					fontSize={"8px"}
					fontWeight={600}
					fill={mode == "light" ? "#0A0A18" : "#EBECF2"}
					// transform="rotate(-45)"
				>
					{payload.value}
				</text>
			</g>
		);
	};
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			const updatedData = updateRedarDataIfEmpty(data);
			setGraphData(updatedData);
		} else {
			setGraphData(data);
		}
	}, []);

	return (
		<div style={{ width: "100%" }}>
			<ResponsiveContainer width="100%" height={500}>
				<RadarChart cx="50%" cy="50%" outerRadius="80%" data={messageByDate}>
					<PolarGrid stroke={theme?.palette?.card?.border} />
					<PolarAngleAxis dataKey="subject" tick={<CustomYAxisTick />} axisLine={false}/>
					<PolarRadiusAxis cx="50%" cy="50%" tick={false} axisLine={false}/>
					<Tooltip content={<CustomTooltip />} />
					<Radar
						dataKey="smsSent"
						stroke="#0C84FE"
						fill="rgba(12, 132, 254, 0.20)"
						fillOpacity={1}
					/>
					<Radar
						dataKey="smsReceived"
						stroke="#9963FE"
						fill="rgba(153, 99, 254, 0.15)"
						fillOpacity={1}
					/>
				</RadarChart>
			</ResponsiveContainer>
		</div>
	);
}

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		const payloadLabelMap = {
			Sun: "Sunday",
			Mon: "Monday",
			Tue: "Tuesday",
			Wed: "Wednesday",
			Thu: "Thursday",
			Fri: "Friday",
			Sat: "Saturday",
		};
		const nameMap = {
			smsSent: "SMS Sent",
			smsReceived: "SMS Recieved",
		};
		return (
			<Box
				className="redar-tooltip"
				sx={{
					padding: "12px",
					background: "#FFF",
					width: "175px",
					opacity: "0.95",
					borderRadius: "12px",
					boxShadow: "rgb(174, 174, 174) 0px 0px 10px",
				}}
			>
				<Typography
					fontSize={12}
					fontWeight={600}
					mb={"4px"}
					color={"#9095A5"}
					lineHeight={"16px"}
				>
					{payloadLabelMap[label]}
				</Typography>

				{payload.map((item) => (
					<div
						key={item.dataKey}
						style={{
							borderColor: item.color,
							margin: "4px 0px",
						}}
						className="redar-tooltip-text"
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<span
								className={`${item?.dataKey}`}
								style={{ marginRight: "10px ", marginTop: "3px" }}
							>
								&nbsp;
							</span>
							<Typography
								fontSize={14}
								fontWeight={600}
								color={"#9095A5"}
								lineHeight={"16px"}
								textTransform={"capitalize"}
							>
								{nameMap[item.dataKey]}:&nbsp;
							</Typography>
						</Box>
						<Typography
							fontSize={14}
							fontWeight={600}
							color={"#000D31"}
							lineHeight={"16px"}
						>
							{item?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						</Typography>
					</div>
				))}
			</Box>
		);
	}
};
