import { Navigate, useRoutes } from "react-router-dom";
import { useEffect } from "react";
import Login from "./pages/Login";
import DashboardLayout from "./layouts/Dashboard";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings.js";
import Signup from "./pages/Signup";
import Orders from "./pages/CartRecovery-orders";
import Conversations from "./pages/CartRecovery-conversation";
import CartRecovery from "./pages/CartRecovery.js";
import Integration from "./pages/Integration";
import IntegrationLocked from "./pages/LockedPages/Integration";
import CampaignsLocked from "pages/LockedPages/Campaigns";
import Affilate from "./pages/Affilate";
import Tutorials from "./pages/Tutorials";
import Campaigns from "pages/Campaigns";
import CampaignPlanner from "pages/Campaign-Planner";
import Segments from "pages/ComingSoon/Segments";
import Lists from "pages/ComingSoon/Lists";
import CreateCampaign from "pages/Create-Campaign";
import CampaignReview from "pages/Campaign-review";
import CampaignPlannerLocked from "pages/LockedPages/Campaign-Planner";
import TrustHub from "pages/TrustHub";
import KnowledgeBase from "pages/ComingSoon/KnowledgeBase";
import Inbox from "pages/ComingSoon/Inbox";
import Analytics from "pages/Analytics";
import Onboarding from "pages/Onboarding";
import Reports from "pages/ComingSoon/Reports";
import Flows from "pages/ComingSoon/FlowAnalytics";
import Subscribers from "pages/Subscribers";
import Insights from "pages/Insights";
import CampaignsAnalytics from "pages/Campaigns-Analytics";
import Flow from "pages/ComingSoon/Flow";
import CampaignError from "pages/ErrorPage/CampaignError";

const Routes = () => {
	return useRoutes([
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/create-account",
			element: <Signup />,
		},
		{
			path: "/",
			element:<DashboardLayout />,
			children: [
				{ path: "/", element: <Dashboard /> },
				{ path: "/empty", element: <Dashboard /> },
				{ path: "/Cart-Recovery/:empty?", element: <CartRecovery /> },
				{ path: "/onboarding", element: <Onboarding /> },
				{ path: "/Cart-Recovery/orders/:empty?", element: <Orders /> },
				{ path: "/Cart-Recovery/conversations/:empty?", element: <Conversations /> },
				{ path: "/cart-recovery/knowledge-Base", element: <KnowledgeBase /> },

				{ path: "/inbox/:empty?", element: <Inbox /> },
				{ path: "/Campaigns/:empty?", element: <Campaigns /> },
				{ path: "/campaigns/segments/:empty?", element: <Segments /> },
				{ path: "/campaigns/lists/:empty?", element: <Lists /> },
				{ path: "/campaigns/create-campaigns", element: <CreateCampaign /> },
				{ path: "/campaigns/campaigns-overview", element: <CampaignReview /> },
				{ path: "/Campaign-Planner", element: <CampaignPlanner /> },
				{ path: "/Integrations", element: <Integration /> },
				{ path: "/flows", element: <Flow /> },
				{ path: "/affilates/:empty?", element: <Affilate /> },
				{ path: "/analytics/:empty?", element: <Analytics /> },
				{ path: "/analytics/reports/:empty?", element: <Reports /> },
				{ path: "/analytics/flows/:empty?", element: <Flows /> },
				{ path: "/analytics/subscribers/:empty?", element: <Subscribers /> },
				{ path: "/analytics/insights/:empty?", element: <Insights /> },
				{ path: "/analytics/campaigns/:empty?", element: <CampaignsAnalytics /> },
				{ path: "/tutorial", element: <Tutorials /> },
				{ path: "/Integrations-locked", element: <IntegrationLocked /> },
				{ path: "/Campaigns-locked", element: <CampaignsLocked /> },
				{ path: "/Campaigns-error", element: <CampaignError /> },
				{ path: "/Campaign-Planner-locked", element: <CampaignPlannerLocked /> },
				{ path: "/settings", element: <Settings /> },
				{ path: "/trust-hub", element: <TrustHub /> },
			],
		},
		{
			path: "*",
			element: <DashboardLayout />,
		},
	]);
};

export default Routes;

/**
 * @type {import("react-router-dom").RouteObject[]}
 */
