import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { PlusSVG } from "resources/Icons/Inbox/Index";
import QuickReplyModal from "./QuickReplyModal";

export default function QuickReply({ closeDropdown }) {
	const theme = useTheme();
	const [activeTab, setActiveTab] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	return (
		<React.Fragment>
			<Box
				bgcolor={theme?.palette?.card?.background}
				sx={{
					border: `1px solid ${theme?.palette?.card?.border}`,
					borderRadius: "8px",
					width: "548px",
					height: "373px",
					maxHeight: "350px",
					position:'absolute',
					bottom:'8px'
				}}
			>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					p={"20px"}
					borderBottom={"1px solid #DADEEA"}
				>
					<Typography
						fontSize={16}
						fontWeight={600}
						lineHeight={"normal"}
						color={theme?.palette?.pageHeading}
					>
						Quick Replies
					</Typography>
					<Box
						display={"flex"}
						gap={"20px"}
						alignItems={"center"}
						
					>
						<Box
							display={"flex"}
							id="pointerCursor"
							alignItems={"center"}
							gap={"8px"}
							onClick={() => {
								setIsOpen(true);
							}}
						>
							<PlusSVG />
							<Typography
								fontSize={14}
								fontWeight={600}
								lineHeight={"normal"}
								color={"#0C84FE"}
							>
								New Quick Reply
							</Typography>
						</Box>

						<img
							src="/icons/cut.svg"
							alt="cut"
							onClick={closeDropdown}
							id="pointerCursor"
							style={{
								marginleft: "20px",
							}}
						/>
					</Box>
				</Box>
				<Box display={"flex"}>
					<Box
						width={"50%"}
						borderRight={"1px solid #DADEEA"}
						sx={{
							maxHeight: "282px",
							p: "12px",
							overflow: "hidden",
							overflowY: "scroll",
						}}
					>
						{titleMock?.map((item, index) => {
							return (
								<Typography
									id="pointerCursor"
									key={index}
									p={"10px 8px"}
									fontSize={14}
									fontWeight={500}
									lineHeight={"normal"}
									color={theme?.palette?.pageHeading}
									borderRadius={"4px"}
									mb={"5px"}
									sx={{
										background: activeTab == index && "rgb(218, 222, 234,0.4)",
									}}
									onClick={() => setActiveTab(index)}
								>
									{item?.name}
								</Typography>
							);
						})}
					</Box>
					<Box width={"50%"} p={"22px 16px"}>
						<Typography
							fontSize={14}
							fontWeight={700}
							lineHeight={"normal"}
							color={theme?.palette?.pageHeading}
							mb={"6px"}
						>
							Title
						</Typography>
						<Typography
							fontSize={12}
							fontWeight={500}
							lineHeight={"16px"}
							color={theme?.palette?.pageHeading}
							sx={{
								opacity: "0.5",
							}}
						>
							Lorem ipsum dolor sit amet consectetur. Ut faucibus elementum
							faucibus tincidunt pellentesque nisi elementum. Id faucibus
							vulputate urna dictum non ipsum. Viverra tincidunt nec mauris dui
							urna diam. Non pulvinar eu nunc velit laoreet.
						</Typography>
					</Box>
				</Box>
			</Box>
			<QuickReplyModal open={isOpen} handleClose={() => setIsOpen(false)} />
		</React.Fragment>
	);
}

const titleMock = [
	{
		name: "Name of shortcut",
	},
	{
		name: "Name of shortcut",
	},
	{
		name: "Name of shortcut",
	},
	{
		name: "Name of shortcut",
	},
	{
		name: "Name of shortcut",
	},
	{
		name: "Name of shortcut",
	},
	{
		name: "Name of shortcut",
	},
	{
		name: "Name of shortcut",
	},
	{
		name: "Name of shortcut",
	},
	{
		name: "Name of shortcut",
	},
];
