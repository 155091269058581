import React from "react";

export default function Info({ isActive }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M5.29117 18.8242L0 20L1.17581 14.7088C0.42544 13.3056 0 11.7025 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C8.2975 20 6.6944 19.5746 5.29117 18.8242ZM5.58075 16.711L6.23428 17.0605C7.38248 17.6745 8.6655 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 11.3345 2.32549 12.6175 2.93949 13.7657L3.28896 14.4192L2.63416 17.3658L5.58075 16.711Z"
				fill={isActive ? "white" : "#BFC5D4"}
			/>
		</svg>
	);
}
