import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
import Page from "./components/Page";
import DataProvider from "./context/DataContext";

const App = () => {
	return (
		<DataProvider>
			<Page>
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</Page>
		</DataProvider>
	);
};



export default App;
