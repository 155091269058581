/* eslint-disable no-unused-vars */
import {
	Box,
	Typography,
	styled,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import FilterDropDown from "components/Popovers/FilterDropDown";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useIntercom } from 'react-use-intercom';
import { Mixpanel } from "utils/mixpanel";
import { PAGES, BUTTONS } from "constants/mixPanelEvents";

export default function TableHeader({searchValue,setSearchValue}) {
	const theme = useTheme();
	const { showNewMessage } = useIntercom();
	const { showMessages } = useIntercom();
	const [anchorElFilter, setAnchorFilter] = useState(null);
	const handleLastModifiedClick = (event) => {
		setAnchorFilter(event.currentTarget);
	};

	const handleLastModifiedClose = () => {
		setAnchorFilter(null);
	};
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

	const isFilterOpen = Boolean(anchorElFilter);
	const isMedium = useMediaQuery(theme.breakpoints.down("lg"));

	function exportList(){
		Mixpanel.track_button_action(PAGES.SUBSCRIBERS, BUTTONS.EXPORT);
		showMessages();
		showNewMessage("I would like to export my subscribers. Can you help?");
	}

	return (
		<Box
			bgcolor={theme?.palette?.card?.background}
			flexDirection={isMedium ? "column" : "row"}
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				p: "15px 20px",
				borderTopLeftRadius: "12px",
				borderTopRightRadius: "12px",
				borderTop: `1px solid ${theme.palette?.card?.border}`,
				borderLeft: `1px solid ${theme.palette?.card?.border}`,
				borderRight: `1px solid ${theme.palette?.card?.border}`,
			}}
		>
			<Box>
				<Typography
					color={theme?.palette?.PageHeading}
					fontSize={20}
					fontWeight={600}
					lineHeight={"normal"}
				>
					Subscribers
				</Typography>
			</Box>
			<Box
				display={"flex"}
				gap={"8px"}
				flexDirection={isMedium ? "column" : "row"}
				mt={isMedium ? "10px" : "0px"}
				mb={isMedium ? "10px" : "0px"}
			>
				<Box
					sx={{
						width: isSmall ? "100%" : "212px",
					}}
				>
					<Search>
						<SearchIconWrapper>
							<SearchIcon />
						</SearchIconWrapper>
						<StyledInputBase
							placeholder="Search"
							inputProps={{ "aria-label": "search" }}
							value={searchValue}
							onChange={(e)=>setSearchValue(e.target?.value)}
						/>
					</Search>
				</Box>
				{/* <Box
					sx={{
						width: isSmall ? "100%" : "152px",
					}}
				>
					<FilterDropDown
						id={id}
						anchorElStatus={anchorElFilter}
						handleClose={handleLastModifiedClose}
						handleClick={handleLastModifiedClick}
						open={isFilterOpen}
						width={"152px"}
					/>{" "}
				</Box> */}
				<Box>
					<PrimaryButton
					fullWidth={isMedium?true:false}
					onClick={()=>exportList()}
					>Export List</PrimaryButton>
				</Box>
			</Box>
		</Box>
	);
}

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: "inherit",
	"&:hover": {
		backgroundColor: "inherit",
	},
	marginLeft: 0,
	width: "100%",
	[theme.breakpoints.up("sm")]: {
		// marginLeft: theme.spacing(1),
		width: "auto",
	},
}));

const SearchIconWrapper = styled("div")(() => ({
	padding: "0 0 0 8px",
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	color: "#808698",
	justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "#808698",
	"& .MuiInputBase-input": {
		border:
			theme.palette?.mode == "light"
				? "1px solid #E8EBF3"
				: "1px solid #1E202D",
		borderRadius: "5px",
		height: "30px",

		padding: "5px 0px",
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			// width: "100%",
			"&:focus": {
				// width: "100%",
				border: "1px solid #0C84FE",
			},
		},
	},
	"::placeholder": {
		color: "#808698",
		fontSize: "14px",
	},
}));