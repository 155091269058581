/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Typography } from "@mui/material";
import { Popover, createTheme, useMediaQuery } from "@mui/material";

import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { DataContext } from "context/DataContext";
import { CutSVG } from "resources/Icons";

export default function WarningBanner({ handleRemoveBanner, topText, bottomText }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const { setShowBillingModal } = useContext(DataContext);

	return (
		<Box
			bgcolor={"#FE720C"}
			sx={{ display: "flex" }}
			py={"12px"}
			alignItems={"center"}
			width={"100%"}
		>
			{!isSmall && (
				<Box width={"250px"} sx={{ display: { sm: "none", md: "flex" } }}></Box>
			)}
			{!isSmall && (
				<Box px={2} mt={"5px"}>
					<img src="/icons/alert.svg" alt="Lock Icon" width={"75%"} />
				</Box>
			)}
			<Box
				justifyContent={"center"}
				display={"flex"}
				flexDirection={"column"}
				textAlign={isSmall ? "center" : "start"}
				padding={isSmall ? "5px 35px" : "0px"}
			>
				<Typography
					fontSize={"12px"}
					fontWeight={"600"}
					// mb={"4px"}
					color={"#FFF"}
				>
					{topText}
				</Typography>

				<Typography fontSize={"12px"} fontWeight={"400"} color={"#FFF"}>
					{bottomText}
				</Typography>
				{isSmall && (
					<Box
						mt={2}
						display={"flex"}
						justifyContent={"space-evenly"}
						id="pointerCursor"
						onClick={handleRemoveBanner}
					>
						<CutSVG />
					</Box>
				)}
			</Box>
			{!isSmall && ( //button for desktop
				<Box
					id="pointerCursor"
					sx={{
						position: "absolute",
						right: "16px",
						bottom: "16px",
					}}
					onClick={handleRemoveBanner}
				>
					<CutSVG />
				</Box>
			)}
		</Box>
	);
}
