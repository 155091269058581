/* eslint-disable no-unused-vars */
import {
	Box,
	Grid,
	Popover,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import PageHeading from "components/Typography/PageHeading";
import React, { useEffect, useState } from "react";
import RecoveredProducts from "../Analytics/Insights/components/RecoveredProducts";
import AbandonedProducts from "../Analytics/Insights/components/AbandonedProducts";
import GraphFilter from "components/Popovers/GraphFilter";
import OrderOverview from "components/Chart/OrderOverview";
import { DownArrow } from "resources/Icons/Arrows";
import AverageAOV from "components/Chart/AverageAOV";
import { AverageAOVMock, OrderOverviewMock } from "resources/constant";
import InsightPieChart from "components/Chart/InsightPieChart";
import {
	checkEmptyState,
	updateDataIfEmpty,
} from "resources/helpers/checkEmptyState";
import LockedModal from "components/Modal/LockedModal";
import VideoModal from "components/VideoModal";

export default function Insights() {
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const [showVideoModal, setShowVideoModal] = useState(false);
	const { mode } = theme.palette;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const [graphRange, setGraphRange] = useState("Last 30 Days");

	const handleGraphRange = (type) => {
		setGraphRange(type);

		handleClose();
	};
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

    useEffect(() => {
		document.body.style.overflow = "hidden";
		const elementsToDisableHover = document.querySelectorAll(".disableSection");
		const disableHoverClass = "disable-hover";
		elementsToDisableHover.forEach((element) => {
			element.classList.add(disableHoverClass);
		});
	}, []);

	return (
        <Box
        className="disableSection"
        sx={{
            position: "relative",
            height: "100vh",
            WebkitFilter: "blur(4px)",
        }}>
		<Grid container alignItems={"center"}>
			<Grid item xs={12}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={!belowTablet ? "row" : "column"}
				>
					<PageHeading>Insights</PageHeading>
					<GraphFilter
						graphRange={graphRange}
						handleClick={handleClick}
						anchorEl={anchorEl}
						handleClose={handleClose}
						handleGraphRange={handleGraphRange}
					/>
				</Box>
			</Grid>

			<Grid item xs={12} mt={"20px"}>
				<Box
					display={"flex"}
					gap={"22px"}
					flexDirection={!belowTablet ? "row" : "column"}
				>
					<Box width={"100%"}>
						<FirstChart />
					</Box>
					<Box width={"100%"}>
						<SecondChart />
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} mt={"24px"}>
				<Box
					display={"flex"}
					gap={"24px"}
					flexDirection={!belowTablet ? "row" : "column"}
					className={"bine"}
				>
					{pieData?.map((item, index) => {
						return (
							<Box key={index} width={"100%"} className={"vine"}>
								<InsightPieChart
									title={item?.title}
									data={item?.data}
									heading={item?.heading}
								/>
							</Box>
						);
					})}
				</Box>
				{/* <Chart /> */}
			</Grid>
			<Grid item xs={12} mt={"29px"}>
				<Box
					display={"flex"}
					gap={"22px"}
					flexDirection={!belowTablet ? "row" : "column"}
				>
					<Box width={"100%"}>
						<PageHeading mb="16px">Top Recovered Products</PageHeading>
						<RecoveredProducts />
					</Box>
					<Box width={"100%"}>
						<PageHeading mb="16px">Top Abandoned Products</PageHeading>

						<AbandonedProducts />
					</Box>
				</Box>
			</Grid>
		</Grid>
        <LockedModal setShowVideoModal={setShowVideoModal} message="Unlock powerful insights into your SMS data." open={true} mode={mode} showWatchVideo={true}/>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={"https://www.youtube.com/watch?v=qTpcfMBgqF0"}
				/>
        </Box>
	);
}

const FirstChart = () => {
	const [showBarChart, setShowBarChart] = useState(false);
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const open = Boolean(anchorEl);
	const [graphData, setGraphData] = useState([]);
	const id = open ? "simple-popover" : undefined;
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			const updatedGraphData = updateDataIfEmpty(OrderOverviewMock, 0);
			setGraphData(updatedGraphData);
		} else {
			setGraphData(OrderOverviewMock);
		}
	}, []);

	return (
		<Box
			bgcolor={theme?.palette?.card?.background}
			border={`1px solid ${theme?.palette?.card?.border}`}
			borderRadius={"12px"}
			p={"20px"}
		>
			<Box
				display={"flex"}
				mb={"34px"}
				alignItems={isMobile ? "baseline" : "unset"}
			>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={isMobile ? "column" : "row"}
					gap={isMobile && "14px"}
					width={"100%"}
				>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={20}
						fontWeight={600}
						lineHeight={"normal"}
						mb={isMobile && "14px"}
					>
						Order Overview
					</Typography>
					<Box display={"flex"}>
						<GraphLines />

						<Box>
							<DropDown
								handleClick={handleClick}
								handleClose={handleClose}
								showBarChart={showBarChart}
								anchorEl={anchorEl}
								open={open}
								id={id}
								setShowBarChart={setShowBarChart}
							/>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box>
				<OrderOverview showBarChart={showBarChart} data={graphData} />
			</Box>
		</Box>
	);
};
const SecondChart = () => {
	const [showBarChart, setShowBarChart] = useState(true);
const [graphData, setGraphData] = useState([])
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			const updatedGraphData = updateDataIfEmpty(AverageAOVMock, 0);
			setGraphData(updatedGraphData);
		} else {
			setGraphData(AverageAOVMock);
		}
	}, []);
	return (
		<Box
			bgcolor={theme?.palette?.card?.background}
			border={`1px solid ${theme?.palette?.card?.border}`}
			borderRadius={"12px"}
			p={"20px"}
		>
			<Box
				display={"flex"}
				mb={"34px"}
				alignItems={isMobile ? "baseline" : "unset"}
			>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={isMobile ? "column" : "row"}
					gap={isMobile && "14px"}
					width={"100%"}
				>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={20}
						fontWeight={600}
						lineHeight={"normal"}
						mb={isMobile && "14px"}
					>
						Average AOV
					</Typography>
					<Box display={"flex"}>
						<GraphLines />
						<Box>
							<DropDown
								handleClick={handleClick}
								handleClose={handleClose}
								showBarChart={showBarChart}
								anchorEl={anchorEl}
								open={open}
								id={id}
								setShowBarChart={setShowBarChart}
							/>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box>
				<AverageAOV showBarChart={showBarChart} data={graphData} />
			</Box>
		</Box>
	);
};

const GraphTagHeading = ({ children }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Typography
			color={theme?.palette?.pageHeading}
			fontSize={isMobile ? 12 : 14}
			fontWeight={500}
			lineHeight={"normal"}
		>
			{children}
		</Typography>
	);
};

const DropDown = ({
	handleClick,
	handleClose,
	showBarChart,
	anchorEl,
	open,
	id,
	setShowBarChart,
}) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box ml={"20px"}>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				height={"40px"}
				width={"65px"}
				mb={isMobile ? "10px" : "0px"}
				mt={isMobile ? "0px" : "0"}
				id="pointerCursor"
				sx={{
					borderRadius: "5px",
					padding: "11px",
					border: mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
					background: theme.palette?.card?.background,
					position: "relative",
				}}
				onClick={handleClick}
			>
				<Box paddingRight={"5px"}>
					{showBarChart ? (
						<img
							src={`/img/analytics/${
								mode == "light" ? "bar-chart" : "bar-chart-dark"
							}.svg`}
							width={"19px"}
						/>
					) : (
						<img
							src={`/img/analytics/${
								mode == "light" ? "line-chart" : "line-chart-dark"
							}.svg`}
							width={"80%"}
						/>
					)}
				</Box>
				<Box
					display={"flex"}
					alignItems={"center"}
					sx={{
						top: "14px",
						right: "14px",
					}}
				>
					<DownArrow />
				</Box>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box
					width={"65px"}
					sx={{
						borderRadius: "5px",
						border:
							mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
						background: theme.palette?.card?.background,
						color: "#808698",
						fontSize: "12px",
					}}
				>
					<Typography
						sx={{
							padding: "8px 15px",
							":hover": {
								background: "#cccaca3b",
							},
						}}
						fontSize={"14px"}
						id="pointerCursor"
						onClick={() => {
							handleClose();
							setShowBarChart(!showBarChart);
						}}
					>
						{!showBarChart ? (
							<img
								src={`/img/analytics/${
									mode == "light" ? "bar-chart" : "bar-chart-dark"
								}.svg`}
								width={"19px"}
							/>
						) : (
							<img
								src={`/img/analytics/${
									mode == "light" ? "line-chart" : "line-chart-dark"
								}.svg`}
								width={"19px"}
							/>
						)}
						{/* {item} */}
					</Typography>
				</Box>
			</Popover>
		</Box>
	);
};

const GraphLines = () => {
	return (
		<Box display={"flex"} gap={"12px"} className="insight-ellipse">
			<Box>
				<Box display={"flex"} alignItems={"center"} gap={"7px"}>
					<Box className="cart-Recovery-ellipse">&nbsp;</Box>
					<GraphTagHeading>Cart Recovery</GraphTagHeading>
				</Box>
				<Box display={"flex"} alignItems={"center"} gap={"7px"}>
					<Box className="automations-ellipse"> &nbsp;</Box>
					<GraphTagHeading>Automations</GraphTagHeading>
				</Box>
			</Box>
			<Box>
				<Box display={"flex"} alignItems={"center"} gap={"7px"}>
					<Box className="campaigns-ellipse"> &nbsp;</Box>
					<GraphTagHeading>Campaigns</GraphTagHeading>
				</Box>
				<Box display={"flex"} alignItems={"center"} gap={"7px"}>
					<Box className="flows-ellipse">&nbsp;</Box>
					<GraphTagHeading>Flows</GraphTagHeading>
				</Box>
			</Box>
		</Box>
	);
};

const pieData = [
	{
		heading: {
			value: "5,210",
			title: "Total Sales",
		},
		title: "Sales By Country",
		data: [
			{
				name: "USA",
				value: 100,
				stroke: "#0081FF",
				fill: "rgba(0, 129, 255, 0.25)",
			},
			{
				name: "Canada",
				value: 10,
				stroke: "#FF004C",
				fill: "rgba(255, 0, 76, 0.25)",
			},
			{
				name: "Uk",
				value: 10,
				stroke: "#0CD16A",
				fill: "rgba(12, 209, 106, 0.25)",
			},
			{
				name: "Australia",
				value: 10,
				stroke: "#FFA800",
				fill: "rgba(255, 168, 0, 0.25)",
			},
		],
	},
	{
		heading: {
			value: "74%",
			title: "Male",
		},
		title: "Sales By Gender",
		data: [
			{
				name: "Male",
				value: 5012,
				stroke: "#0081FF",
				fill: "rgba(0, 129, 255, 0.25)",
			},
			{
				name: "Female",
				value: 4198,
				stroke: "#FF004C",
				fill: "rgba(255, 0, 76, 0.25)",
			},
		],
	},
	{
		heading: {
			value: "21%",
			title: "Tablet",
		},
		title: "Sales By Device",
		data: [
			{
				name: "Desktop",
				value: 400,
				stroke: "#0081FF",
				fill: "rgba(0, 129, 255, 0.25)",
			},
			{
				name: "Tablet",
				value: 300,
				stroke: "#9963FE",
				fill: "rgba(153, 99, 254, 0.25)",
			},
			{
				name: "Mobile",
				value: 100,
				stroke: "#FF5C00",
				fill: "rgba(255, 92, 0, 0.25)",
			},
		],
	},
];
