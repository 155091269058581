/* eslint-disable no-unused-vars */
import { Box, styled, useTheme } from "@mui/material";
import React, { useState, Suspense } from "react";

const OnboardingToolTip = ({ text = " ", lightMode, belowTablet }) => {
	const theme = useTheme();
	const [isActive, updateIsActive] = useState(false);
	const [showImaage, updateShowImage] = useState(false);

	const handleImageLoad = () => {
		updateShowImage(true);
	};

	return (
		<div
			onMouseOver={() => {
				if (text) {
					updateIsActive(true);
					updateShowImage(true);
				}
			}}
			onMouseLeave={() => {
				updateIsActive(false);
			}}
			style={{
				position: "relative",
			}}
		>
			{isActive && (
				<div
					className="tooltip-container"
					style={{
						left: belowTablet ? "-27px" : "-27px",
                        bottom:'15px'
					}}
				>
					<Suspense fallback={null}>
						<Box>
							{showImaage && (
								<CustomBox lightMode={lightMode}>
									{text}
								</CustomBox>
							)}
						</Box>
					</Suspense>
				</div>
			)}
			<Box>
				<img
					src={`/img/onboarding/info.svg`}
					// loading="lazy"
				/>
			</Box>
		</div>
	);
};

export default OnboardingToolTip;
const CustomBox = styled(Box)(({ theme, page, lightMode }) => ({
	color:  "#FFF" ,
	backgroundColor: '#041328',
	position: "absolute",
	fontSize:'12px',
	padding:'14px 10px',
	border: `1px solid #E8EBF3`,
	borderRadius: "12px",
	bottom: "17px",
	"::before": {
		content:`" "`,
		display: "block",
		position: "absolute",
		left: "42.5%",
		top: "100%",
		width: 0,
		height: 0,
		border: "10px solid transparent",
		"border-top-color":'#E8EBF3',
	},
	"::after":{
		content:`" "`,
		display: "block",
		position: "absolute",
		left: "43%",
		top: "99%",
		width: 0,
		height: 0,
		border:" 9px solid transparent",
		"border-top-color":'#041328',
	}
}));
