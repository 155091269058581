import React, {  useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	Select,
	MenuItem,
	Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputModal from "components/Inputs/InputModal";
import ModalSelect from "components/Inputs/ModalSelect";
const options = [
	"Order Revenue",
	"AOV",
	"Blended ROAS",
	"Order Revenue 1",
	"AOV 1",
	"Blended ROAS 1",
];

export default function GenerateReport({ open, handleClose }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [frequency, setFrequency] = useState("Daily");
	const [time, setTime] = useState("09:00 AM");

	const closeModal = () => {
		setSelectedValues([]);
		handleClose();
	};
	const [selectedValues, setSelectedValues] = useState([]);

	const handleChange = (event) => {
		setSelectedValues(event?.target?.value);
	};
	

	return (
		<Modal
			open={open}
			id="generate-report"
			aria-labelledby="generate-report"
			aria-describedby="generate-report"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					borderBottom={`1px solid ${theme?.palette?.card?.border}`}
					p={"20px"}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						mb={"8px"}
					>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"20px"}
							fontWeight={600}
							lineHeight={"normal"}
						>
							New Report
						</Typography>
						<img
							src="/icons/cut.svg"
							alt="cut"
							onClick={closeModal}
							id="pointerCursor"
							style={{
								marginTop: "-10px",
							}}
						/>
					</Box>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"20px"}
						sx={{
							opacity: "0.5",
						}}
					>
						Regular, custom updates direct to your inbox
					</Typography>
				</Box>
				<Box p={"20px"}>
					<Box mb={"16px"}>
						<CustomTypography>Report Name</CustomTypography>
						<InputModal />
					</Box>
					<Box mb={"16px"}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: "20px",
							}}
						>
							<Box display={"flex"} gap={1} alignItems={"center"}>
								<Checkbox
									size="small"
									sx={{ padding: "0", color: theme?.palette?.card?.border }}
								/>
								<CustomTypography theme={theme} color={"#323232"} mb={0}>
									Email
								</CustomTypography>
							</Box>
							<Box display={"flex"} gap={1} alignItems={"center"}>
								<Checkbox
									size="small"
									sx={{ padding: "0", color: theme?.palette?.card?.border }}
								/>
								<CustomTypography theme={theme} color={"#323232"} mb={0}>
									Slack
								</CustomTypography>
							</Box>
							<Box display={"flex"} gap={1} alignItems={"center"}>
								<Checkbox
									size="small"
									sx={{ padding: "0", color: theme?.palette?.card?.border }}
								/>
								<CustomTypography theme={theme} color={"#323232"} mb={0}>
									SMS
								</CustomTypography>
							</Box>
						</Box>
					</Box>
					<Box mb={"16px"}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								flexDirection: belowTablet ? "column" : "row",
								alignItems: "center",
								gap: "16px",
							}}
							mb={2}
						>
							<Box width={"100%"}>
								<CustomTypography theme={theme}>
									Report Frequency
								</CustomTypography>
								<ModalSelect
									onChange={(e) => {
										setFrequency(e?.target?.value);
									}}
									value={frequency}
								>
									<CustomMenuItem value={"Daily"}>Daily</CustomMenuItem>
								</ModalSelect>
							</Box>
							<Box width={"100%"}>
								<CustomTypography theme={theme}>Time of Day</CustomTypography>
								<ModalSelect
									onChange={(e) => {
										setTime(e?.target?.value);
									}}
									value={time}
								>
									<CustomMenuItem value={"09:00 AM"}>09:00 AM</CustomMenuItem>
								</ModalSelect>
							</Box>
						</Box>
					</Box>
					<Box mb={"16px"}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								flexDirection: belowTablet ? "column" : "row",
								alignItems: "center",
								gap: "16px",
							}}
							mb={2}
						>
							<Box width={"100%"}>
								<CustomTypography theme={theme}>
									Email Recipients
								</CustomTypography>
								<InputModal />
							</Box>
							<Box width={"100%"}>
								<CustomTypography theme={theme}>
									SMS Recipients
								</CustomTypography>
								<InputModal />
							</Box>
						</Box>
					</Box>
					<Box mb={"16px"}>
						<CustomTypography theme={theme}>Metrics</CustomTypography>
						<Select
							multiple
							sx={{
								"& .MuiSvgIcon-root": { color: "#C1C1C1" },
								"& .MuiOutlinedInput-notchedOutline": {
									border: `1px solid ${theme?.palette?.card?.border}`,
								},
							}}
							IconComponent={ExpandMoreIcon}
							style={{ width: "100%", height: "40px" }}
							value={selectedValues}
							onChange={handleChange}
							renderValue={(selected) =>
								selected.length === 0 ? "None selected" : selected.join(", ")
							}
						>
							{options.map((option) => (
								<MenuItem
									key={option}
									value={option}
									backgroundColor={theme?.palette?.card?.background}
								>
									<Checkbox checked={selectedValues.includes(option)} />
									<label>{option}</label>
								</MenuItem>
							))}
						</Select>
					</Box>
					<Box display={"flex"} justifyContent={"end"}>
						<Box display={"flex"} gap={"8px"}>
							<Button
								size={"small"}
								sx={{
									border: `1px solid ${theme?.palette?.card?.border}`,
									backgroundColor: "transparent",
									padding: "11px 20px",
									color: mode == "light" ? "#000D31" : "#FFF",
									":hover": {
										color: mode == "light" ? "#000D31" : "#FFF",
									},
								}}
								variant="outlined"
								onClick={() => closeModal()}
							>
								<Typography fontSize={"14px"} fontWeight={"600"}>
									Cancel
								</Typography>
							</Button>
							<Button
								size={"small"}
								sx={{
									backgroundColor: "#0081FF",
									padding: "11px 20px",
									color: "#FFF",
									":hover": {
										color: mode == "light" ? "#0C0D17" : "#FFF",
									},
								}}
								variant="outlined"
							>
								<Typography fontSize={"14px"} fontWeight={"600"}>
									Create
								</Typography>
							</Button>
						</Box>
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}
const CustomMenuItem = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<MenuItem
			sx={{
				background: theme?.palette?.card?.background,
			}}
			{...props}
		>
			{children}
		</MenuItem>
	);
};

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "568px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "0",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
				maxHeight: "90vh",
			}}
		>
			{children}
		</Box>
	);
};

const CustomTypography = ({ children, ...props }) => {
	return (
		<Typography
			color={"#808698"}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			mb={1}
			{...props}
		>
			{children}
		</Typography>
	);
};
