import { Box, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { CustomSelect, TextInput } from "components/Inputs";
import React, { useEffect, useState } from "react";
import { DownArrow } from "resources/Icons/Arrows";
import { Mixpanel } from "../../../utils/mixpanel";
import { PAGES, EVENT_CATEGORIES } from "../../../constants/mixPanelEvents";

export default function Account({settings, setSettings}) {
	const theme = useTheme();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [businessType, setBusinessType] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [country, setCountry] = useState('');

	useEffect(()=>{
		Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
			page: PAGES.ACCOUNT,
		});
	},[])

	useEffect(() => {
		setFirstName(settings.firstName);
		setLastName(settings.lastName);
		setBusinessType(settings.businessType);
		setPhone(settings.phone);
		setEmail(settings.email);
		setCountry(settings.country);
	}, [settings]);

	function customSetFirstName(value){
		setSettings('firstName', value)
		setFirstName(value); 
	}

	function customSetLastName(value){
		setSettings('lastName', value)
		setLastName(value); 
	}

	function customSetBusinessType(value){
		setSettings('businessType', value)
		setBusinessType(value); 
	}

	function customSetPhone(value){
		setSettings('phone', value)
		setPhone(value); 
	}

	function customSetEmail(value){
		setSettings('email', value)
		setEmail(value); 
	}

	function customSetCountry(value){
		setSettings('country', value)
		setCountry(value); 
	}

	return (
		<Box
			p={"20px"}
			sx={{
				borderRadius: "12px",
				border: `1px solid ${theme?.palette?.card?.border}`,
				backgroundColor: theme?.palette?.card?.background,
			}}
		>
			<Typography
				fontSize={"20px"}
				fontWeight={600}
				color={theme?.palette?.pageHeading}
			>
				Details
			</Typography>
			<InputWrapper>
				<Box width={"100%"}>
					<CustomLabel>First Name</CustomLabel>
					<TextInput 
						onChange={e => customSetFirstName(e.target.value)}
						value={firstName}
					/>
				</Box>
				<Box width={"100%"}>
					<CustomLabel>Last Name</CustomLabel>
					<TextInput
						onChange={e => customSetLastName(e.target.value)}
						value={lastName}
					/>
				</Box>
			</InputWrapper>
			<InputWrapper>
				<Box width={"100%"}>
					<CustomLabel>Country</CustomLabel>
					<CustomSelect
						placeholder="Country"
						suffixIcon={<DownArrow />}
						value={country}
						onChange={customSetCountry}
					>
						{countries?.map((item, index) => {
							return (
								<MenuItem key={index} value={item}>
									{item}
								</MenuItem>
							);
						})}
					</CustomSelect>
				</Box>
				<Box width={"100%"}>
					<CustomLabel>Phone Number</CustomLabel>
					<TextInput 
						onChange={e => customSetPhone(e.target.value)}
						value={phone}
					/>
				</Box>
			</InputWrapper>
			<InputWrapper>
				<Box width={"100%"}>
					<CustomLabel>Business Type</CustomLabel>
					<CustomSelect
						placeholder="Business Type"
						suffixIcon={<DownArrow />}
						value={businessType}
						onChange={customSetBusinessType}
					>
						<MenuItem value={"Drop Shopper"}>
							Dropshipping
						</MenuItem>
						<MenuItem value={"Small or Medium Business"}>
							Small or Medium Business
						</MenuItem>
						<MenuItem value={"Mid Market"}>
							Mid-Market
						</MenuItem>
						<MenuItem value={"Enterprise"}>
							Enterprise
						</MenuItem>
						<MenuItem value={"Agency"}>
							Agency
						</MenuItem>
						<MenuItem value={"Other"}>
							Other
						</MenuItem>
					</CustomSelect>
				</Box>
				<Box width={"100%"}>
					<CustomLabel>E-mail</CustomLabel>
						<TextInput 
							value={email}
							onChange={e => customSetEmail(e.target.value)}
						/>
						
				</Box>
			</InputWrapper>
		</Box>
	);
}

const InputWrapper = ({ children }) => {
    const theme=useTheme()
    const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box display={"Flex"} gap={"12px"} mt={"20px"} flexDirection={belowSmall?'column':'row'}>
			{children}
		</Box>
	);
};
const CustomLabel = ({ children }) => {
	return (
		<Typography fontSize={14} fontWeight={500} color={"#808698"} mb={"8px"}>
			{children}
		</Typography>
	);
};
const countries = ['Afghanistan',
'Albania',
'Algeria',
'Andorra',
'Angola',
'Antigua and Barbuda',
'Argentina',
'Armenia',
'Australia',
'Austria',
'Azerbaijan',
'The Bahamas',
'Bahrain',
'Bangladesh',
'Barbados',
'Belarus',
'Belgium',
'Belize',
'Benin',
'Bhutan',
'Bolivia',
'Bosnia and Herzegovina',
'Botswana',
'Brazil',
'Brunei',
'Bulgaria',
'Burkina Faso',
'Burundi',
'Cabo Verde',
'Cambodia',
'Cameroon',
'Canada',
'Central African Republic',
'Chad',
'Chile',
'China',
'Colombia',
'Comoros',
'Congo, Democratic Republic of the',
'Congo, Republic of the',
'Costa Rica',
'Côte d’Ivoire',
'Croatia',
'Cuba',
'Cyprus',
'Czech Republic',
'Denmark',
'Djibouti',
'Dominica',
'Dominican Republic',
'East Timor (Timor-Leste)',
'Ecuador',
'Egypt',
'El Salvador',
'Equatorial Guinea',
'Eritrea',
'Estonia',
'Eswatini',
'Ethiopia',
'Fiji',
'Finland',
'France',
'Gabon',
'The Gambia',
'Georgia',
'Germany',
'Ghana',
'Greece',
'Grenada',
'Guatemala',
'Guinea',
'Guinea-Bissau',
'Guyana',
'Haiti',
'Honduras',
'Hungary',
'Iceland',
'India',
'Indonesia',
'Iran',
'Iraq',
'Ireland',
'Israel',
'Italy',
'Jamaica',
'Japan',
'Jordan',
'Kazakhstan',
'Kenya',
'Kiribati',
'Korea, North',
'Korea, South',
'Kosovo',
'Kuwait',
'Kyrgyzstan',
'Laos',
'Latvia',
'Lebanon',
'Lesotho',
'Liberia',
'Libya',
'Liechtenstein',
'Lithuania',
'Luxembourg',
'Madagascar',
'Malawi',
'Malaysia',
'Maldives',
'Mali',
'Malta',
'Marshall Islands',
'Mauritania',
'Mauritius',
'Mexico',
'Micronesia, Federated States of',
'Moldova',
'Monaco',
'Mongolia',
'Montenegro',
'Morocco',
'Mozambique',
'Myanmar (Burma)',
'Namibia',
'Nauru',
'Nepal',
'Netherlands',
'New Zealand',
'Nicaragua',
'Niger',
'Nigeria',
'North Macedonia',
'Norway',
'Oman',
'Pakistan',
'Palau',
'Panama',
'Papua New Guinea',
'Paraguay',
'Peru',
'Philippines',
'Poland',
'Portugal',
'Qatar',
'Romania',
'Russia',
'Rwanda',
'Saint Kitts and Nevis',
'Saint Lucia',
'Saint Vincent and the Grenadines',
'Samoa',
'San Marino',
'Sao Tome and Principe',
'Saudi Arabia',
'Senegal',
'Serbia',
'Seychelles',
'Sierra Leone',
'Singapore',
'Slovakia',
'Slovenia',
'Solomon Islands',
'Somalia',
'South Africa',
'Spain',
'Sri Lanka',
'Sudan',
'Sudan, South',
'Suriname',
'Sweden',
'Switzerland',
'Syria',
'Taiwan',
'Tajikistan',
'Tanzania',
'Thailand',
'Togo',
'Tonga',
'Trinidad and Tobago',
'Tunisia',
'Turkey',
'Turkmenistan',
'Tuvalu',
'Uganda',
'Ukraine',
'United Arab Emirates',
'United Kingdom',
'United States',
'Uruguay',
'Uzbekistan',
'Vanuatu',
'Vatican City',
'Venezuela',
'Vietnam',
'Yemen',
'Zambia',
'Zimbabwe'];