import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	Divider
} from "@mui/material";

export default function TrustHubModal({
	open,
	handleClose,
	title = "",
	content = "",
	validateCompliance
}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [isHover, setIsHover] = useState(false)
	return (
		<Modal
			open={open}
			id="TrustHubModal"
			aria-labelledby="TrustHubModal"
			aria-describedby="TrustHubModal"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box p={"20px"}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						mb={"8px"}
					>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"20px"}
							fontWeight={600}
							lineHeight={"normal"}
						>
							{title}
						</Typography>
						<img
							src="/icons/cut.svg"
							alt="cut"
							onClick={handleClose}
							id="pointerCursor"
							style={{
								marginTop: "-10px",
							}}
						/>
					</Box>
					<Box>
						<CustomTypography theme={theme}>
							Last Checked: A few seconds ago
						</CustomTypography>
					</Box>
				</Box>

				<Divider />
				<Box p={"22px 20px 20px 20px"}>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={600}
						lineHeight={"normal"}
						mb={"8px"}
					>
						How this rule works
					</Typography>
					<CustomTypography theme={theme} lineHeight="20px">
						{content}
					</CustomTypography>
					{title === "Automated-to-Peer Compliance" && 
						<Box display={"flex"} justifyContent={"end"} mt={"33px"}>
						<Button
							size={"small"}
							sx={{
								backgroundColor: "#0081FF",
								padding: "11px 20px",
								color: "#FFF",
								":hover": {
									color: mode == "light" ? "#0C0D17" : "#FFF",
								},
							}}
							variant="outlined"
							onMouseOver={()=>{
								setIsHover(true)
							}}
							onMouseOut={()=>{
								setIsHover(false)
							}}
							onClick={validateCompliance}
						>
							<Box display={'flex'} justifyContent={'center'} gap={'8px'}>
							<RecheckIcon hover={isHover} mode={mode}/>
							<Typography fontSize={"14px"} fontWeight={"600"}>
								Recheck
							</Typography>
							</Box>
						</Button>
					</Box>
					}
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "642px",
				backgroundColor: theme?.palette?.card?.background,
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};

const CustomTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			color={theme.palette?.pageHeading}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			sx={{
				opacity: "0.5",
			}}
			{...props}
		>
			{children}
		</Typography>
	);
};

const RecheckIcon = ({hover,mode}) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.5407 5.67501L19.432 6.08054C16.553 2.45679 11.3788 1.38918 7.25011 3.77288C4.5146 5.35222 2.86232 8.08739 2.55215 11.0015C2.49369 11.5506 2.8915 12.0432 3.44068 12.1017C3.98987 12.1601 4.48245 11.7623 4.54091 11.2131C4.78634 8.90741 6.09163 6.75113 8.25011 5.50493C11.406 3.68289 15.3356 4.41507 17.6452 7.06005L17.3013 6.9679C16.7678 6.82496 16.2195 7.14154 16.0766 7.67501C15.9336 8.20847 16.2502 8.75681 16.7837 8.89975L19.5157 9.6318C20.0492 9.77475 20.5975 9.45816 20.7405 8.9247L21.4725 6.19265C21.6155 5.65918 21.2989 5.11084 20.7654 4.9679C20.2319 4.82496 19.6836 5.14154 19.5407 5.67501Z"
				fill={mode=='dark'?'#FFF':hover?'black':"#FFF"}
			/>
			<path
				d="M20.5594 11.8984C21.1086 11.9569 21.5064 12.4495 21.4479 12.9986C21.1378 15.9127 19.4855 18.6479 16.75 20.2272C12.6212 22.611 7.44688 21.5433 4.56792 17.9194L4.45934 18.3246C4.3164 18.8581 3.76806 19.1746 3.23459 19.0317C2.70113 18.8888 2.38454 18.3404 2.52749 17.807L3.25954 15.0749C3.40248 14.5414 3.95082 14.2249 4.48428 14.3678L7.21633 15.0999C7.7498 15.2428 8.06638 15.7911 7.92344 16.3246C7.7805 16.8581 7.23216 17.1746 6.6987 17.0317L6.35434 16.9394C8.66387 19.5849 12.5939 20.3173 15.75 18.4952C17.9084 17.249 19.2137 15.0927 19.4592 12.787C19.5176 12.2378 20.0102 11.84 20.5594 11.8984Z"
				fill={mode=='dark'?'#FFF':hover?'black':"#FFF"}
			/>
			<path
				d="M13.0001 7.49993C13.0001 6.94765 12.5523 6.49993 12.0001 6.49993C11.4478 6.49993 11.0001 6.94765 11.0001 7.49993V11.9999C11.0001 12.3512 11.1844 12.6767 11.4856 12.8574L13.9856 14.3574C14.4591 14.6416 15.0734 14.488 15.3575 14.0144C15.6417 13.5408 15.4881 12.9266 15.0145 12.6424L13.0001 11.4337V7.49993Z"
				fill={mode=='dark'?'#FFF':hover?'black':"#FFF"}
			/>
		</svg>
	);
};
