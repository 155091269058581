import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
	CalendarSVG,
	LocationSVG,
	MailSVG,
	PhoneSVG,
} from "resources/Icons/Inbox/Index";
import RecoverStatusIcon from "../components/Status";
import TextArea from "antd/es/input/TextArea";

export default function CustomerDetails({activeTab}) {
	const theme = useTheme();
	const [isEditing, setEditing] = useState(false);
	const [editedText, setEditedText] = useState("");
	const [data, setData] = useState({
		name: "NICOLE RIZZO",
		type: "Customer",
		timeSpan: "Customer for about 20 hours",
		email: "brodys79@gmail.com",
		number: "+1 (614) 379-2032",
		location: "Tulsa, OK",
		localeTime: "9:30 AM local time",
		notes: [
			{
				name: "Jason | Feb 10, 2023",
				note: "Lorem ipsum dolor sit amet consectetur. Vel tempus dui viverra et ut facilisis ultricies. Morbi posuere scelerisque dui elementum nunc feugiat. Amet egestas viverra vulputate lacus leo sollicitudin neque neque. Facilisis facilisi tristique bibendum in elit.",
			},
			{
				name: "Mary | April 5, 2023",
				note: "Lorem ipsum dolor sit amet consectetur. Vel tempus dui viverra et ut facilisis ultricies. Morbi posuere scelerisque dui elementum nunc feugiat. Amet egestas viverra vulputate lacus leo sollicitudin neque neque. Facilisis facilisi tristique bibendum in elit.",
			},
		],
	});

	const handleEditClick = () => {
		setEditing(true);
	};

	const handleCancelClick = () => {
		setEditing(false);
		setEditedText("");
	};

	const handleSaveClick = () => {
		setEditing(false);
		setEditedText("");
		if(!editedText){
			return
		}
		let tmp = { ...data };
		tmp?.notes?.unshift({ name: "Jason | Feb 10, 2023", note: editedText });
		setData({ ...tmp });
		// console.log("Saved:", editedText);
	};
	const handleDelete = (index) => {
		let tmp = { ...data };
		tmp?.notes?.splice(index, 1);
		setData({ ...tmp });
	};

	const conversationDetails = {
		topic: "Cart Recovery",
		status: "Recovered",
		assignee: "Devin Henry",
		assigneeImage: "Devin-Henry",
		source: "Online Store",
		created: "September 10th, 2023",
		avgRespTime: "33m",
		numberOfOrders: "7",
		moneySpent: "$867.19",
		numberOfChats: "12",
	};
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Box
			width={isMobile ? "100%" : "25%"}
			bgcolor={theme?.palette?.card?.background}
			sx={{
				overflow: "hidden",
				overflowY: "scroll",
				maxHeight: `calc(100vh - 50px)`,
			}}
			borderLeft={`1px solid ${theme?.palette?.card?.border}`}
			display={isMobile ? (activeTab == "Customer" ? "block" : "none") : "block"}
		>
			<Box
				p={"18px 16px 16px 16px"}
				borderBottom={`1px solid ${theme?.palette?.card?.border}`}
			>
				<PrimaryText fontSize="20px" fontWeight="600">
					Customer Details
				</PrimaryText>
			</Box>
			<Box
				p={"20px 15px"}
				borderBottom={`1px solid ${theme?.palette?.card?.border}`}
			>
				<Box display={"flex"} justifyContent={"space-between"} mb={"4px"}>
					<PrimaryText fontSize="16px" fontWeight="600">
						{data?.name}
					</PrimaryText>
					<SecondaryText color="#0081FF">Manage</SecondaryText>
				</Box>
				<SecondaryText>{data?.type}</SecondaryText>
				<Box display={"flex"} gap={"15px"} flexDirection={"column"} mt={"17px"}>
					<Box display={"flex"} gap={"10px"}>
						<CalendarSVG /> <PrimaryText>{data?.timeSpan}</PrimaryText>
					</Box>
					<Box display={"flex"} gap={"10px"}>
						<MailSVG /> <PrimaryText>{data?.email}</PrimaryText>
						<Box
							bgcolor={"rgba(12, 209, 106, 0.10)"}
							display={"flex"}
							justifyContent={"center"}
							borderRadius={"2px"}
							p={"1px 14px"}
						>
							<Typography
								fontSize={12}
								fontWeight={600}
								lineHeight={"normal"}
								color={"#00BE5B"}
							>
								Subscribed
							</Typography>
						</Box>
					</Box>
					<Box display={"flex"} gap={"10px"}>
						<PhoneSVG /> <PrimaryText>{data?.number}</PrimaryText>
					</Box>
					<Box display={"flex"} gap={"10px"}>
						<LocationSVG /> <PrimaryText>{data?.location}</PrimaryText>
					</Box>
				</Box>
				<SecondaryText
					fontSize="12px"
					fontWeight="400"
					mt="6px"
					ml="31px"
					mb="20px"
				>
					{data?.localeTime}
				</SecondaryText>
				<Box>
					{isEditing ? (
						<div>
							<TextArea
								autoSize={{minRows:3}}
								value={editedText}
								onChange={(e) => setEditedText(e.target.value)}
							/>
							<Box display={"flex"} justifyContent={"end"} mt={"11px"}>
								<Box display={"flex"} gap={"12px"}>
									<Typography
										id="pointerCursor"
										color={"#808698"}
										fontSize={"14px"}
										fontWeight={500}
										onClick={handleCancelClick}
										lineHeight={"normal"}
									>
										Cancel
									</Typography>
									<Typography
										color={"#0081FF"}
										fontSize={"14px"}
										fontWeight={500}
										lineHeight={"normal"}
										id="pointerCursor"
										onClick={handleSaveClick}
									>
										Save Note
									</Typography>
								</Box>
							</Box>
						</div>
					) : (
						<SecondaryText
							color="#0081FF"
							onClick={handleEditClick}
							id="pointerCursor"
						>
							Add Note
						</SecondaryText>
					)}
				</Box>
				<Box>
					{data?.notes?.map((item, index) => {
						return (
							<React.Fragment key={index}>
								<Box
									p={"15px 0px"}
									sx={{
										borderBottom:
											index < data?.notes?.length - 1
												? `1px solid ${theme?.palette?.card?.border}`
												: "",
									}}
								>
									<Box
										display={"flex"}
										justifyContent={"space-between"}
										mb={"8px"}
									>
										<PrimaryText>{item?.name}</PrimaryText>
										<SecondaryText
											color="#FF465C"
											onClick={() => handleDelete(index)}
											id="pointerCursor"
										>
											Delete
										</SecondaryText>
									</Box>
									<SecondaryText fontSize="12px" fontWeight="400">
										{item?.note}
									</SecondaryText>
								</Box>
							</React.Fragment>
						);
					})}
				</Box>
			</Box>
			<Box
				p={"20px 15px"}
				borderBottom={`1px solid ${theme?.palette?.card?.border}`}
			>
				<PrimaryText fontSize="16px" fontWeight="600" mb="18px">
					CONVERSATION DETAILS
				</PrimaryText>
				<ConversationDetails conversationDetails={conversationDetails} />
			</Box>
		</Box>
	);
}

const ConversationDetails = ({ conversationDetails }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const {
		topic,
		assignee,
		source,
		avgRespTime,
		numberOfOrders,
		moneySpent,
		numberOfChats,
	} = conversationDetails;
	return (
		<Box display={"flex"} gap={"21px"} flexDirection={"column"}>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Box width={"50%"}>
					<SecondaryText>Topic</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{topic}</PrimaryText>
				</Box>
			</Box>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Box width={"50%"}>
					<SecondaryText>Status</SecondaryText>
				</Box>
				<Box
					width={"50%"}
					textAlign={"end"}
					display={"flex"}
					justifyContent={"end"}
				>
					<RecoverStatusIcon />
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Source</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{source}</PrimaryText>
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Team Mate Assigned</SecondaryText>
				</Box>
				<Box
					width={"50%"}
					textAlign={"end"}
					display={"flex"}
					gap={"5px"}
					justifyContent={"end"}
				>
					<PrimaryText>{assignee}</PrimaryText>
				</Box>
			</Box>

			{/* <Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Created</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{created}</PrimaryText>
				</Box>
			</Box> */}
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Average Response Time</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{avgRespTime}</PrimaryText>
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Number of Orders</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{numberOfOrders}</PrimaryText>
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Total Money Spent</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{moneySpent}</PrimaryText>
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Box width={"50%"}>
					<SecondaryText>Number of Messages</SecondaryText>
				</Box>
				<Box width={"50%"} textAlign={"end"}>
					<PrimaryText>{numberOfChats}</PrimaryText>
				</Box>
			</Box>
			<Button
				size={"small"}
				fullWidth
				sx={{
					padding: "11px 30px",
					border: mode == "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
					color: mode == "light" ? "#FF465C" : "#FF465C",
				}}
				variant="outlined"
			>
				<Typography fontSize={"14px"} fontWeight={"600"}>
					Unsubscribe
				</Typography>
			</Button>
		</Box>
	);
};
const PrimaryText = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<Typography
			fontWeight={500}
			fontSize={14}
			color={theme?.palette?.pageHeading}
			lineHeight={"normal"}
			{...props}
		>
			{children}
		</Typography>
	);
};
const SecondaryText = ({ children, ...props }) => {
	return (
		<Typography
			fontWeight={500}
			fontSize={14}
			color={"#808698"}
			{...props}
			lineHeight={"normal"}
		>
			{children}
		</Typography>
	);
};
