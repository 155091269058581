export const  segmentCriteria = [
    { name: 'A/B Test Group' },
    { name: 'Abandoned Checkout Not Recovered' },
    { name: 'Abandoned Checkout Recovered' },
    { name: 'Average Order Value' },
    { name: 'Campaigns Clicked' },
    { name: 'Campaigns Received' },
    { name: 'Collection Viewed' },
    { name: 'Customer Created Date' },
    { name: 'Customer ID' },
    { name: 'Customer Phone Country Code' },
    { name: 'Customer Address City' },
    { name: 'Customer Address State' },
    { name: 'Customer Gender' },
    { name: 'Disengaged Customers' },
    { name: 'Email Opt-in Customers' },
    { name: 'Engaged Customers' },
    { name: 'Has been in Flow' },
    { name: 'Last Click Date' },
    { name: 'Last Order Date' },
    { name: 'Last Texted Date' },
    { name: 'Loyal Customers' },
    { name: 'New Customers' },
    { name: 'Number of Orders' },
    { name: 'Number of Replies' },
    { name: 'Number of Texts Sent' },
    { name: 'Order Fulfillment Status' },
    { name: 'Order Payment Status' },
    { name: 'Purchased Product Names' },
    { name: 'Purchased Product Tags' },
    { name: 'Subscribed to Back In Stock' },
    { name: 'Subscriber Created Date' },
    { name: 'Subscriber Has Clicked' },
    { name: 'Subscriber Has Not Ordered Since' },
    { name: 'Subscriber Opt-In Source' },
    { name: 'Subscriber Phone State (US Only)' },
    { name: 'Subscriber is a Customer' },
    { name: 'Subscriber is Tagged With' },
    { name: 'Subscriber is Not Tagged With' },
    { name: 'Total Spent' },
  ];

  export const operatorOptions=[
    { name: 'Equals' },
    { name: 'Not Equals To' },
    { name: 'Less Than' },
    { name: 'Less Than or Equal To' },
    { name: 'Greater Than' },
    { name: 'Greater Than or Equal To' },
  
  ]
  
  

  export const countryCode=[
    {name:'+1 | US/CAD'},
    {name:'+44 | UK'},
    {name:'+61 | AU'},
  ]