import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import PrimaryButton from "components/Buttons/PrimaryButton";
import TableSecton from "./components/TableSecton";
import PageHeading from "components/Typography/PageHeading";
import ImportSubscribers from "components/Modal/ImportSubscribers";

export default function Lists() {
	const [openModal, setOpenModal] = useState(false);

	const theme = useTheme();
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	
	return (
		<Grid container spacing={2} alignItems={"center"}>
			<Grid item xs={12}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<PageHeading mt={belowSmall ? "0px" : "15px"}>Lists</PageHeading>

					<Box>
						{!belowSmall && (
							<PrimaryButton
								style={{ marginRight: "10px" }}
								onClick={() => {
									setOpenModal(true);
								}}
							>
								Import SMS Subscribers
							</PrimaryButton>
						)}
						<PrimaryButton >Export List</PrimaryButton>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<TableSecton handleOpenModal={()=>setOpenModal(true)}/>
			</Grid>
			<ImportSubscribers
				open={openModal}
				handleClose={() => setOpenModal(false)}
			/>
		</Grid>
	);
}
