import { styled } from "@mui/material";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

export default function PhoneInputField({ value, handleChange }) {
	return (
		<StyledPhoneInput
			defaultCountry="us"
			value={value}
			onChange={handleChange}
		/>
	);
}
const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
	width: "100%",
	"& .react-international-phone-input": {
		width: "100%",
		backgroundColor: "transparent !important",
		color: theme.palette?.pageHeading,
		border: `1px solid ${theme.palette?.card?.border} !important`,
	},
	"& .react-international-phone-country-selector-button": {
		backgroundColor: "transparent !important",
		border: `1px solid ${theme.palette?.card?.border} !important`,
	},
	"& .react-international-phone-country-selector-dropdown": {
		backgroundColor: theme.palette?.card?.background,
		color: theme.palette?.pageHeading,
		border: `1px solid ${theme.palette?.card?.border} !important`,
	},
	"& .react-international-phone-country-selector-dropdown__list-item:hover": {
		backgroundColor: theme.palette?.card?.background,
	},
	"& .react-international-phone-country-selector-button__dropdown-arrow ": {
		/* Your custom styles for the arrow */
		"background-image":
			"url('/arrow/down.svg')" /* Example for changing image */,
	},
}));
