import mixpanel from 'mixpanel-browser';
import { EVENT_CATEGORIES, TxtCartMajorVersion } from '../constants/mixPanelEvents';

/**
 * Initializes the Mixpanel client
 */
try{
    console.log('INITIALIZING: ', process.env.REACT_APP_MIXPANEL_TOKEN);
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: process.env.REACT_APP_ENVIRONMENT === 'DEVELOPER',persistence: 'localStorage' });
    const shopId = localStorage.getItem('shopId');
    mixpanel.identify(shopId);
} catch(err){
    console.log('ERROR HERE');
}

/**
 * Adds common attributes to the props that are sent to Mixpanel.
 * @param {Object} props The props to add attributes to.
 * @param {TxtCartMajorVersion} [majorVersion=TxtCartMajorVersion.V3] The current major version of txtCart.
 * @returns {Object} The props with the added attributes.
 */
const addCommonAttributesToMixPanelProps = (props, majorVersion) => {
    props.shopDomain = localStorage.getItem('MyShopifyDomain');
    props.txtCartMajorVersion = majorVersion;
    return props;
}


let actions = {
    /**
     * Send a Mixpanel "identify" event.
     * @param {string} id The distinct_id to associate with the current user.
     * @see {@link https://mixpanel.com/help/reference/javascript#identifying-users}
     */
    identify: (id) => {
        console.log('IDENTIFIED');
        mixpanel.identify(id);
    },

    /**
     * Send a Mixpanel "alias" event.
     * @param {string} id The distinct_id to associate with the current user.
     * @see {@link https://mixpanel.com/help/reference/javascript#aliasing-users}
     */
    alias: (id) => {
        mixpanel.alias(id);
    },

    /**
     * Sends a Mixpanel "track" event.
     * @param {string} name The name of the event.
     * @param {object} props Properties to be sent with the event.
     * @param {TxtCartMajorVersion} [majorVersion=TxtCartMajorVersion.V3] The current major version of txtCart.
     * @see {@link https://mixpanel.com/help/reference/javascript#tracking-events}
     */
    track: (name, props, majorVersion=TxtCartMajorVersion.V3) => {
        props = addCommonAttributesToMixPanelProps(props, majorVersion);
        console.log(props)
        const shopId = localStorage.getItem('shopId');
        try {
            //Only track identified actions
            if(shopId){
                mixpanel.track(name, props);
            }
        } catch (error) {
            console.error('Mixpanel tracking failed:', error);
        }
    },
    people: {
        /**
         * Sets properties on a user profile in Mixpanel People.
         * @param {object} props Properties to be set on the user profile.
         * @see {@link https://mixpanel.com/help/reference/javascript#setting-user-profiles}
         */
        set: (props) => {
            mixpanel.people.set(props);
        },
    },
    /**
     * Send a tracking event for a button click to Mixpanel.
     * @param {string} page The page where the button was clicked.
     * @param {string} buttonClicked The name of the button clicked.
     * @see {@link https://mixpanel.com/help/reference/javascript#tracking-events}
     */
    track_button_action: (page, buttonClicked, props={}) => {
        actions.track(EVENT_CATEGORIES.BUTTON_CLICK, { buttonClicked, page, ...props });
    }
};

export let Mixpanel = actions;
