import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import TrustHubModal from "components/Modal/TrustHubModal";
import PageHeading from "components/Typography/PageHeading";
import React, { useState, useEffect } from "react";
import { txtCartFetch } from "utilifyFunctions";

export default function TrustHub() {
	const theme = useTheme();
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [openModal, setOpenModal] = useState(false);
	const [activeItem, setActiveItem] = useState({});
	const [consentEnabled, setConseentEnabled] = useState(false);

	async function validateShopifySettings(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/validateShopifySettings");
		console.log("consent response");
		console.log(response.shop.marketing_sms_consent_enabled_at_checkout);
		setConseentEnabled(response.shop.marketing_sms_consent_enabled_at_checkout);
		setOpenModal(false);
	}

	useEffect(() => {
		validateShopifySettings();
	}, []);

	return (
		<Grid container spacing={2} alignItems={"center"}>
			<Grid item xs={12}>
				<PageHeading>Trust Hub</PageHeading>
			</Grid>
			<Grid item xs={12}>
				<Box
					display={"flex"}
					gap={"24px"}
					flexDirection={belowSmall ? "column" : "row"}
				>
					<Box
						width={belowSmall ? "100%" : "75%"}
						display={"flex"}
						gap={"12px"}
						flexDirection={"column"}
					>
						{dataMock?.map((item, index) => {
							return (
								<Box
									id="pointerCursor"
									key={index}
									p={"20px"}
									sx={{
										background: theme?.palette?.card?.background,
										borderRadius: "12px",
										border: `1px solid ${theme?.palette?.card?.border}`,
										display: "flex",
										alignItems: "center",
										gap: "23px",
									}}
									onClick={() => {
										setActiveItem(item);
										setOpenModal(true);
									}}
								>
									<Box>
										<img
											src={
												(item?.title != 'Automated-to-Peer Compliance' || consentEnabled)
													? "/img/active-trust-hub.svg"
													: "/img/not-active-trust-hub.svg"
											}
											style={{
												marginTop:'10px'
											}}
										/>
									</Box>
									<Box>
										<Typography
											color={theme?.palette?.PageHeading}
											fontWeight={600}
											fontSize={16}
											lineHeight={"normal"}
											mb={"12px"}
										>
											{item.title}
										</Typography>
										<Typography
											color={"#808698"}
											fontWeight={400}
											fontSize={14}
											lineHeight={"normal"}
										>
											{item.desc}
										</Typography>
									</Box>
								</Box>
							);
						})}
					</Box>
					<Box width={belowSmall ? "100%" : "25%"}>
						<Box
							p={"20px"}
							sx={{
								background: theme?.palette?.card?.background,
								borderRadius: "12px",
								border: `1px solid ${theme?.palette?.card?.border}`,
							}}
						>
							<Box
								display={"flex"}
								gap={"10px"}
								alignItems={"center"}
								mb={"12px"}
							>
								<img src="/img/what's-new.svg" />
								<Typography
									color={theme?.palette?.PageHeading}
									fontWeight={600}
									fontSize={16}
									lineHeight={"normal"}
								>
									What’s Trust Hub?
								</Typography>
							</Box>
							<Typography
								color={"#808698"}
								fontWeight={400}
								fontSize={14}
								lineHeight={"20px"}
							>
								With our Trust Hub, we highlight the definitions and requirements for SMS compliance. This is used to help ensure you are fully compliant with rules and regulations that govern SMS Marketing.
							</Typography>
						</Box>
					</Box>
				</Box>
			</Grid>
			<TrustHubModal
				open={openModal}
				handleClose={() => setOpenModal(false)}
				title={activeItem?.title}
				content={activeItem?.content}
				validateCompliance={validateShopifySettings}
			/>
		</Grid>
	);
}

const dataMock = [
	{
		title: "Peer-to-Peer Compliance",
		desc: "Compliantly collect and send SMS messages written by TxtCart agents",
		content:
			"In order to send P2P messages compliantly, your checkout needs to be P2P or A2P compliant. If you choose P2P, your customers need to see language regarding opting into SMS in your checkout form AND that they can optionally consent to A2P marketing messages. Failure to provide this information could result in legal consequences. Unlike automated messages, a checkbox is not required.",
		IsTrue: true,
	},
	{
		title: "Automated-to-Peer Compliance",
		desc: "Compliantly collect and send automated SMS messages with TxtCart",
		IsTrue: false,
		content:
			"In order to send A2P messages compliantly, your checkout needs to be A2P compliant. For compliant A2P messages, your customers need to actively opt-in to your SMS marketing messages through one of multiple ways- landing page pop-ups, mobile two tap, checkout checkbox, etc. With TxtCart, this is done exclusively through the checkout checkbox provided by Shopify which informs them they will receive messages from your brand. Failure to prove this information could result in legal consequences.",
	},
	{
		title: "Keyword Message Filtering",
		desc: "Required to filter any messages with banned keywords",
		IsTrue: true,
		content:
			"Various carriers require us to filter words that they do not approve of. This includes any words related to carrier “S.H.A.F.T” rules: Sex, Hate, Alcohol, Firearms, or Tabacco ( including anything marijuana related ).",
	},
	{
		title: "High Opt-out Rate Warning",
		desc: "Carriers will begin blocking messages with a high opt-out rate",
		IsTrue: true,
		content:
			"The carriers monitor the rate at which subscribers are opt-ing out of your messages. The carriers have internal metrics that show if there is a high opt-out rate, the messages being sent are likely spam or collected non-compliantly. If it passes their opt-out threshold, they will filter or, in some cases, completely block messages being sent.",
	},
	{
		title: "US Quiet Hours",
		desc: "Federal law prohibits messages being sent between 9PM and 8AM in the users respective timezone",
		IsTrue: true,
		content:
			"Federal law prohibits our platform from sending any messages outside of US Quiet Hours. Our platform will queue up any messages captured during quiet hours, to send within the next set of compliant messaging hours.",
	},
	{
		title: "Washington, Florida & Oklahoma Quiet Hours",
		desc: "These state laws prohibits messages being sent between 8PM and 8AM",
		IsTrue: true,
		content:
			"To identify Washington, Florida and Oklahoma subscribers, we use their mobile number’s area code, as well as their order information if it is available to comply with local state laws. Our platform will queue up any messages captured during these regions quiet hours, to send within the next set of compliant messaging hours.",
	},
	{
		title: "UK Quiet Hours",
		desc: "UK law prohibits messages being sent between 8PM and 9AM",
		IsTrue: true,
		content:
			"To identify UK subscribers, we use their mobile number’s country code, as well as their order information if it is available to comply with international laws. Our platform will queue up any messages captured during these regions quiet hours, to send within the next set of compliant messaging hours.",
	},
	{
		title: "AUS Quiet Hours",
		desc: "ACMA law prohibits messages being sent between 8PM and 9AM",
		IsTrue: true,
		content:
			"To identify AUS subscribers, we use their mobile number’s country code, as well as their order information if it is available to comply with international laws. Our platform will queue up any messages captured during these regions quiet hours, to send within the next set of compliant messaging hours.",
	},
];

