import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import { light, dark } from "./palette";

const getTheme = (mode, themeToggler) =>
	responsiveFontSizes(
		createTheme({
			palette: mode === "light" ? light : dark,
			shadows: shadows(mode),
			typography: {
				fontFamily: '"Inter", sans-serif',
				button: {
					textTransform: "none",
					fontWeight: "medium",
				},
			},
			zIndex: {
				appBar: 1200,
				drawer: 1300,
			},

			themeToggler,
		})
	);

export default getTheme;
