import React from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function ComingSoon() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const viewRoadMapAction = () => {
		window.open("https://txtcart.canny.io/", "_blank");
	};
	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
			<Box display={"flex"} flexDirection={"column"}>
				<Box display={"flex"} justifyContent={"center"}>
					<Box
						sx={{
							background: lightMode ? "#E6F3FF" : "rgba(230, 243, 255, 0.10)",
							width: "80px",
							height: "80px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							borderRadius: "100px",
						}}
						mb={2}
					>
						<img src="/icons/modal/tool.svg" alt="Icon" />
					</Box>
				</Box>

				<Heading lightMode={lightMode}>Coming Soon...</Heading>
				<DescriptionText lightMode={lightMode}>
					Don’t worry, we are actively building out new features and are super
					excited to share the upcoming releases very soon.
				</DescriptionText>
				<Box
					display={"flex"}
					justifyContent={"space-around"}
					flexDirection={belowTablet ? "column" : "row"}
					alignItems={"center"}
				>
					<Button
						size={"small"}
						sx={{
							width: "200px",
							height: "40px",
							padding: "5px 15px",
							backgroundColor: "#0081FF",
							color: "#FFF",
							":hover": {
								color: lightMode ? "#0081FF" : "#FFF",
							},
						}}
						variant="outlined"
						onClick={viewRoadMapAction}
					>
						<Typography fontSize={"14px"} fontWeight={600}>
							View our Roadmap
						</Typography>
					</Button>
				</Box>
			</Box>
		</MainContainer>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: belowTablet
					? "translate(-50%, -50%)"
					: "translate(-21%, -50%)",
				width: belowTablet ? "90%" : "465px",
				backgroundColor: lightMode ? "#FFF" : "#0C0D17",
				padding: belowTablet ? "30px 20px" : "40px 64px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
			}}
		>
			{children}
		</Box>
	);
};

const Heading = ({ children, lightMode, ...props }) => {
	return (
		<Typography
			color={lightMode ? "#000D31" : "#FFF"}
			fontSize={"24px"}
			fontWeight={600}
			mb={"12px"}
			textAlign={"center"}
			{...props}
		>
			{children}
		</Typography>
	);
};

const DescriptionText = ({ children, lightMode, ...props }) => {
	return (
		<Typography
			color={lightMode ? "#000D31" : "#FFF"}
			fontSize={"14px"}
			fontWeight={500}
			mb={3}
			sx={{
				opacity: "0.5",
				textAlign: "center",
			}}
			{...props}
		>
			{children}
		</Typography>
	);
};
