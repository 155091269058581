/* eslint-disable no-unused-vars */
import React from "react";
import {
	Typography,
	Box,
	useTheme,
	Popover,
	useMediaQuery,
} from "@mui/material";
import Option from "./Option/Option.js";

export default function Select({options,handleSelectChange,defaultValue}) {
	const getMode = useTheme();
	const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [option, setOption] = React.useState(defaultValue);
    const mode = getMode?.palette?.mode;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

    const handleOptionChange = (label, value) => {
        setOption(label)
        handleClose();
        handleSelectChange(label,value);
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

    const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

    return (
        <>
            {!isSmall && (
                <Box
                    display={"flex"}
                    height={"40px"}
                    min-width={"150px"}
                    id="pointerCursor"
                    sx={{
                        borderRadius: "5px",
                        padding: "10px",
                        border:
                            mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
                        background: getMode.palette?.card?.background,
                    }}
                    onClick={handleClick}
                >
                    <Box paddingRight={"5px"}>
                        <img src="/icons/calender.svg" width={"80%"} />
                    </Box>
                    <Typography
                        fontWeight={"500"}
                        fontSize={"12px"}
                        paddingRight={"10px"}
                    >
                        {option}
                    </Typography>
                    <Box display={"flex"} justifyContent={"end"} ml={"5px"}>
                        <img src="/topbar/down.svg" width={"50%"} />
                    </Box>
                </Box>
            )}
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
				>
                <Box
                    min-width={"150px"}
                    sx={{
                        borderRadius: "5px",
                        border:
                            mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
                        background: getMode.palette?.card?.background,
                        color: "#808698",
                        fontSize: "12px",
                    }}
                >
                    {options?.map((item, index) => {
                        return (
                            <Option 
                                index={index} 
                                label={item.label}
                                value={item.value}
                                handleOptionSelect={handleOptionChange}>
                            </Option>
                        );
                    })}
                </Box>
            </Popover>
        </>
    )
}