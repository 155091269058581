import {
	Box,
	Checkbox,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomSubHeading, CustomTypography } from "./EINTab";
import { Input } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function PoliciesTab({setTab, formData, handleFormData}) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [privacySet, setPrivacySet] = useState(false);
	const [termsSet, setTermsSet] = useState(false);
	const [nextEnabled, setNextEnabled] = useState(false);
	const [policiesConfirmed, setPoliciesConfirmed] = useState(false);

	const handlePrivacyChange = function(e){
		handleFormData(e.target?.name, e.target?.value);
		if(e.target?.value){
			setPrivacySet(true)
		} else{
			setPrivacySet(false);
		}
		setNextEnabled(privacySet && termsSet && policiesConfirmed);
	}

	const handleTermsChange = function(e){
		handleFormData(e.target?.name, e.target?.value);
		if(e.target?.value){
			setTermsSet(true)
		} else{
			setTermsSet(false);
		}
		setNextEnabled(privacySet && termsSet && policiesConfirmed);
	}

	const handleConfirmCheck = function(e){
		handleFormData(e.target?.name, e.target?.checked);
		setPoliciesConfirmed(e.target?.checked);
		setNextEnabled(privacySet && termsSet && policiesConfirmed);
	}

	const handleNext = function(){
		if(formData.hasEIN === "No"){
			setTab("RateInfoSolePropTab");
		} else if(formData.segmentsCount === "Less than 6,000"){
			setTab("RateInfoLowVolumeTab");
		} else{
			setTab("RateInfoStandardTab");
		}
	}

	useEffect(() => {
		setNextEnabled(privacySet && termsSet && policiesConfirmed);
	}, [privacySet,termsSet,policiesConfirmed]);

	return (
		<Box>
			<FixContainer mb={2}>
				<CustomSubHeading theme={theme} fontWeight={600} fontSize={16}>
					Please provide the url of your Privacy Policy and Terms of Service,
					then confirm you have completed the required changes for approval.
				</CustomSubHeading>
				<CustomTypography theme={theme}>Privacy Policy URL</CustomTypography>

				<CustomInput onChange={(e) => handlePrivacyChange(e)} name="PrivacyPolicyUrl"/>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Terms of Service URL</CustomTypography>
				<CustomInput onChange={(e) => handleTermsChange(e)} name="TOSUrl"/>
			</FixContainer>
			<FixContainer mb={2}>
				<Box display={"flex"} alignItems={"center"} mb={1}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
					/>
					<Typography fontSize={"14px"} fontWeight={"400"} color={"#696E7E"}>
						Privacy Policy Updates:&nbsp;
					</Typography>
					<Typography
						id="pointerCursor"
						color={"#0081FF"}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
						onClick={() => {
							window.open(
								" https://www.help.txtcartapp.com/en/articles/8001566-sms-privacy-policy-template",
								"_blank"
							);
						}}
					>
						Here
					</Typography>
				</Box>

				<Box display={"flex"} alignItems={"center"}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
					/>
					<Typography fontSize={"14px"} fontWeight={"400"} color={"#696E7E"}>
                    Terms of Service Upates&nbsp;
					</Typography>
					<Typography
						id="pointerCursor"
						color={"#0081FF"}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
						onClick={() => {
							window.open(
								"https://www.help.txtcartapp.com/en/articles/8001573-sms-terms-of-service-template",
								"_blank"
							);
						}}
					>
						Here
					</Typography>
				</Box>
			</FixContainer>
			<FixContainer mb={2}>
				<Box
					display={"flex"}
					gap="8px"
					alignItems={"flex-start"}
					mb={isMobile && "29px"}
				>
					<Checkbox
						onChange={(e) => {handleConfirmCheck(e)}}
						size="small"
						sx={{
							padding: "0",
							color: "#808698",
							"&.Mui-checked": {
								color: "#0081FF",
							},
						}}
						name="confirmedPolicies"
					/>
					<Box>
						<Typography
							color={"#808698"}
							fontSize={14}
							fontWeight={400}
							lineHeight={"normal"}
						>
							I have completed the steps described above
						</Typography>
					</Box>
				</Box>
			</FixContainer>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton
					onClick={() => {
						handleNext()
					}}
					disabled={!nextEnabled}
				>
					Next
				</PrimaryButton>
			</Box>
		</Box>
	);
}

export const FixContainer = ({ children, ...props }) => {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Box
			width={belowTablet ? "100%" : "100%"}
			minWidth={belowTablet ? "100%" : "100%"}
			maxWidth={belowTablet ? "100%" : "100%"}
			{...props}
		>
			{children}
		</Box>
	);
};

const CustomInput = ({ ...props }) => {
	const theme = useTheme();
	return (
		<Input
			{...props}
			style={{
				width: "100%",
				height: "40px",
				fontSize: "14px",
				fontWeight: "500",
				backgroundColor: theme?.palette?.card?.background,

				color: theme?.palette?.pageHeading,
			}}
		/>
	);
};