/* eslint-disable no-unused-vars */
import {
	Box,
	Checkbox,
	Modal,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import NextButton from "./Components/Buttons/NextButton";
import { planMock } from "resources/constant";
import DowngradeButton from "./Components/Buttons/DowngradeButton";
import UpgradeButton from "./Components/Buttons/UpgradeButton";
import RightArrow from "./Components/RightArrow";
import SelectPlanStep from "./Components/SelectPlanStep";
import ConfirmationStep from "./Components/ConfirmationStep";
import { txtCartFetch } from "utilifyFunctions";

const Billing = ({ open, handleClose, currentPlan, currentCycle}) => {
	const [selected, setSelected] = useState(null);
	const [step, setStep] = useState(1);
	const [period, setPeriod] = useState("Monthly");
	const [skus, setSkus] = useState(null);
	const theme = useTheme();
	const { mode } = theme.palette;

	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: belowTablet ? "90%" : "914px",
		bgcolor: theme?.palette?.card?.background,
		border: mode == "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
		borderRadius: " 12px",
		boxShadow: 24,
		outline: "none",
		overflowY: "auto",
		p: 2,
	};

	React.useEffect(() => {
		getSKUs();
	}, []);

	async function getSKUs(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/sku");
		if(response){
			setSkus(response);
		}
	}

	const setPlanPeriod = (value) => {
		console.log(selected)
		setPeriod(value);
	};
	const periodSelectedStyle = {
		fontSize: "14px",
		fontWeight: "600",
		color: "#0081FF",
	};
	const periodNonSelectedStyle = {
		fontSize: "14px",
		fontWeight: "500",
		color: "#85858C",
	};
	const setPlanType = (value) => {
		console.log('SET PLAN TYPE');
		console.log(value);
		setSelected(value);
	};

	const closeBillingModal = () => {
		console.log(skus);
		handleClose();
		setStep(1);
	};
	const ChangeTab=(value)=>{
		if(!selected || (selected?.skuId === currentPlan?.skuId && currentCycle === period)){
			return 
		}
		setStep(value)
	}

	return (
		<Modal
			open={open}
			onClose={closeBillingModal}
			aria-labelledby="Billing-modal-title"
			aria-describedby="Billing-modal-description"
		>
			{step == 1 ? (
				<SelectPlanStep
					style={style}
					mode={mode}
					closeBillingModal={closeBillingModal}
					belowTablet={belowTablet}
					period={period}
					setPlanPeriod={setPlanPeriod}
					periodSelectedStyle={periodSelectedStyle}
					periodNonSelectedStyle={periodNonSelectedStyle}
					selected={selected ? selected : currentPlan}
					currentPlan={currentPlan}
					setPlanType={setPlanType}
					belowSmall={belowSmall}
					ChangeTab={ChangeTab}
					skus={skus}
					currentCycle={currentCycle}
				/>
			) : (
				<ConfirmationStep
					style={style}
					closeBillingModal={closeBillingModal}
					selected={selected}
					period={period}
					currentPlan={currentPlan}
				/>
			)}
		</Modal>
	);
};

export default Billing;
