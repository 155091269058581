import React from "react";
import { ChildContainer, TextFieldLabel } from "..";
import { Box, Divider } from "@mui/material";

export default function CampaignData({smsCount, smsRecipients, mmsRecipients, totalRecipients, estimatedCost}) {
	return (
		<>
			<ChildContainer>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"500"}>
						Message Per Recipient
					</TextFieldLabel>
				</Box>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"400"}>
						1
					</TextFieldLabel>
				</Box>
			</ChildContainer>
			<Divider />
			<ChildContainer>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"500"}>
						SMS Count
					</TextFieldLabel>
				</Box>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"400"}>
						{smsCount}
					</TextFieldLabel>
				</Box>
			</ChildContainer>
			<Divider />
			<ChildContainer>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"500"}>
						SMS Recipients
					</TextFieldLabel>
				</Box>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"400"}>
						{smsRecipients}
					</TextFieldLabel>
				</Box>
			</ChildContainer>
			<Divider />
			<ChildContainer>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"500"}>
						MMS Recipients
					</TextFieldLabel>
				</Box>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"400"}>
						{mmsRecipients}
					</TextFieldLabel>
				</Box>
			</ChildContainer>
			<Divider />
			<ChildContainer>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"500"}>
						Total Recipients
					</TextFieldLabel>
				</Box>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"400"}>
						{totalRecipients}
					</TextFieldLabel>
				</Box>
			</ChildContainer>
			<Divider />
			<ChildContainer>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"700"}>
						Estimated Cost
					</TextFieldLabel>
				</Box>
				<Box>
					<TextFieldLabel fontSize={"16px"} fontWeight={"700"}>
						${estimatedCost}
					</TextFieldLabel>
				</Box>
			</ChildContainer>
		</>
	);
}
