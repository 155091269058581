import React, { useEffect } from "react";
import { Box } from "@mui/material";
import ComingSoonModal from 'components/Modal/ComingSoon'
import Layout from "templates/Flows/components/Layout/Layout";
export default function FlowComingSoonTemplate() {
	useEffect(() => {
		document.body.style.overflow = "hidden";
		const elementsToDisableHover = document.querySelectorAll(".disableSection");
		const disableHoverClass = "disable-hover";
		elementsToDisableHover.forEach((element) => {
			element.classList.add(disableHoverClass);
		});
	}, []);
	return (
		<>
			<Box mt={'12px'}>
				<Box
					sx={{
						position: "relative",
						height: "100vh",
						WebkitFilter: "blur(4px)",
					}}
					className="disableSection"
				>
          <Layout >
            {/* <Box>
              main
            </Box> */}
          </Layout>
				
				</Box>
				<ComingSoonModal />
			</Box>
		</>
	);
}
