
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Select, useTheme } from "@mui/material";

const ModalSelect = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<Select
			sx={{
				"& .MuiSvgIcon-root": { color: "#C1C1C1" },
				"& .MuiOutlinedInput-notchedOutline": {
					border: `1px solid ${theme?.palette?.card?.border}`,
				},
			}}
			IconComponent={ExpandMoreIcon}
			style={{ width: "100%", height: "40px" }}
			{...props}
		>
			{children}
		</Select>
	);
};

export default ModalSelect
