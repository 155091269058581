import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
	CustomMenuItem,
	CustomSelect,
	CustomSubHeading,
	CustomTypography,
} from "./EINTab";
import { Input } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function CompanyInformationTab({setTab, handleFormData, formData}) {
	const theme = useTheme();
	const [isPublic, setIsPublic] = useState(null);
	const [valid, setValid] = useState(false);



	const handlePublicChange = function(e){
		setIsPublic(e.target?.value);
		handleFormData("public", e.target?.value === "Yes");
	}

	const validateForm = function(){
		if(formData.regionOfOperation != null && formData.brandIdentifier != null && formData.buisnessType && formData.registrationNumber != null){
			if(formData.public){
				if(formData.stockExchange != null && formData.stockTicker){
					setValid(true);
					return;
				}
			} else{
				setValid(true);
				return;
			}
		}
		setValid(false);
	}

	useEffect(() => {
		validateForm();
	}, [formData]);

	return (
		<Box>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Region of Operation:</CustomTypography>
				<CustomSubHeading theme={theme}>
					Your selection must correspond with the business registration
					identifier you select below
				</CustomSubHeading>
				<CustomSelect value={formData.regionOfOperation} onChange={(e) => handleFormData(e.target?.name, e.target?.value)} name="regionOfOperation">
					{regionMock?.map((item, index) => {
						return (
							<CustomMenuItem key={index} value={item?.title}>
								{item?.title}
							</CustomMenuItem>
						);
					})}
				</CustomSelect>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>
					Business Registration Identifier:
				</CustomTypography>
				<CustomSubHeading theme={theme}>
					If your company has an EIN, this should be the selected option.
				</CustomSubHeading>
				<CustomSelect value={formData.brandIdentifier} onChange={(e) => handleFormData(e.target?.name, e.target?.value)} name="brandIdentifier">
					{registrationMock?.map((item, index) => {
						return (
							<CustomMenuItem key={index} value={item?.title}>
								{item?.title}
							</CustomMenuItem>
						);
					})}
				</CustomSelect>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Business Type:</CustomTypography>
				<CustomSelect value={formData.buisnessType} onChange={(e) => handleFormData(e.target?.name, e.target?.value)} name="buisnessType">
					{businessType?.map((item, index) => {
						return (
							<CustomMenuItem key={index} value={item?.title}>
								{item?.title}
							</CustomMenuItem>
						);
					})}
				</CustomSelect>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>
					Business Registration Number
				</CustomTypography>
				<CustomSubHeading theme={theme}>
					{formData.brandIdentifier ==="USA: Employer Identification Number (EIN)" ? ("US EIN: [xx-xxxxxxx] (NUMERICAL)") 
					: formData.brandIdentifier === "USA: DUNS Number (Dun and Bradstreet)" ? ("US DUNS: [xx-xxx-xxxx] (NUMERICAL)") 
					: formData.brandIdentifier === "Canada: Canadian Business Number" ? ("CA CBN: [xxxxxxxxx] (NUMERICAL)")
					: formData.brandIdentifier === "Great Britain: Company Number" ? (<><a href="https://www.informdirect.co.uk/company-records/company-registration-number-crn-what-is-it/" target="_blank">Learn More</a></>)
					: formData.brandIdentifier === "Australia: Company Number from ASIC (ACN)" ? (<><a href="https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/" target="_blank">Learn More</a></>)
					: formData.brandIdentifier === "India: Corporate Identity Number" ? (<><a href="https://cleartax.in/s/cin-corporate-identification-number" target="_blank">Learn More</a></>)
					: formData.brandIdentifier === "Estonia: VAT Number" ? (<><a href="https://www.avalara.com/vatlive/en/country-guides/europe/estonia/estonia-vat-registration.html" target="_blank">Learn More</a></>)
					: formData.brandIdentifier === "Romania: VAT Registration Number" ? (<><a href="https://www.avalara.com/vatlive/en/country-guides/europe/romania/romanian-vat-registration.html" target="_blank">Learn More</a></>)
					: (<></>)}
				</CustomSubHeading>
				<CustomInput value={formData.registrationNumber} onChange={(e) => handleFormData(e.target?.name, e.target?.value)} name="registrationNumber"/>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Public/Private:</CustomTypography>
				<CustomSubHeading theme={theme}>
					Is your company publicly traded?
				</CustomSubHeading>
				<CustomSelect value={isPublic} onChange={(e) => handlePublicChange(e)} name="public">
					<CustomMenuItem value="Yes">
						Yes
					</CustomMenuItem>
					<CustomMenuItem value="No">
						No
					</CustomMenuItem>
				</CustomSelect>
			</FixContainer>
			{isPublic === "Yes" ? (		
				<>
					<FixContainer mb={2}>
						<CustomTypography theme={theme}>Stock Exchange</CustomTypography>
						<CustomInput name="stockExchange" onChange={(e) => handleFormData(e.target?.name, e.target?.value)} value={formData.stockExchange} />
					</FixContainer>
					<FixContainer mb={2}>
						<CustomTypography theme={theme}>Stock Ticker</CustomTypography>
						<CustomInput name="stockTicker" onChange={(e) => handleFormData(e.target?.name, e.target?.value)} value={formData.stockTicker} />
					</FixContainer>
				</>	
			) : (<></>)}
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Social Media URL</CustomTypography>
				<CustomSubHeading theme={theme}>
					Please enter a single social media account, in the format
					@social-media-account. Example: @TxtCart
				</CustomSubHeading>
				<CustomInput name="socialMedia" onChange={(e) => handleFormData(e.target?.name, e.target?.value)} value={formData.socialMedia} />
			</FixContainer>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton
					disabled={!valid}
					onClick={() => {
						setTab("ContactInformationTab");
					}}
				>
					Next
				</PrimaryButton>
			</Box>
		</Box>
	);
}

const regionMock = [
	{ title: "USA/Canada" },
	{ title: "Africa" },
	{ title: "Asia" },
	{ title: "Europe" },
	{ title: "Latin America" },
];
const registrationMock = [
	{ title: "USA: Employer Identification Number (EIN)" },
	{ title: "USA: DUNS Number (Dun and Bradstreet)" },
	{ title: "Canada: Canadian Business Number" },
	{ title: "Great Britain: Company Number" },
	{ title: "Australia: Company Number from ASIC (ACN)" },
	{ title: "India: Corporate Identity Number" },
	{ title: "Estonia: VAT Number" },
	{ title: "Romania: VAT Registration Number" },
	{ title: "Israel: Registration Number" },
	{ title: "Other" },
];

const businessType = [
	{ title: "Partnership" },
	{ title: "Limited Liability Corporation" },
	{ title: "Co-operative" },
	{ title: "Non-profit Corporation" },
	{ title: "Corporation" },
];

export const FixContainer = ({ children, ...props }) => {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Box
			width={belowTablet ? "100%" : "100%"}
			minWidth={belowTablet ? "100%" : "100%"}
			maxWidth={belowTablet ? "100%" : "100%"}
			{...props}
		>
			{children}
		</Box>
	);
};

const CustomInput = ({ ...props }) => {
	const theme = useTheme();
	return (
		<Input
			{...props}
			style={{
				width: "100%",
				height: "40px",
				fontSize: "14px",
				fontWeight: "500",
				backgroundColor: theme?.palette?.card?.background,

				color: theme?.palette?.pageHeading,
			}}
		/>
	);
};