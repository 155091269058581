/* eslint-disable no-unused-vars */
import { Box, styled, useTheme } from "@mui/material";
import React, { useState, Suspense } from "react";

const Tooltip = ({ text = " ", lightMode, belowTablet }) => {
	const theme = useTheme();
	const [isActive, updateIsActive] = useState(false);
	const [showImaage, updateShowImage] = useState(false);

	const handleImageLoad = () => {
		updateShowImage(true);
	};

	return (
		<div
			onMouseOver={() => {
				if (text) {
					updateIsActive(true);
					updateShowImage(true);
				}
			}}
			onMouseLeave={() => {
				updateIsActive(false);
			}}
			style={{
				position: "relative",
			}}
		>
			{isActive && (
				<div
					className="tooltip-container"
					style={{
						left: belowTablet ? "-65px" : "-65px",
					}}
				>
					<Suspense fallback={null}>
						<Box>
							{showImaage && (
								<CustomBox lightMode={lightMode}>
									{text}
								</CustomBox>
							)}
						</Box>
					</Suspense>
				</div>
			)}
			<Box>
				<img
					src={`/icons${lightMode ? "/" : "dark/"}info.svg`}
					loading="lazy"
				/>
			</Box>
		</div>
	);
};

export default Tooltip;
const CustomBox = styled(Box)(({ theme, page, lightMode }) => ({
	color: lightMode ? "#0A0A18" : "#EBECF2",
	backgroundColor: theme.palette?.card?.background,
	position: "absolute",
	fontSize:'12px',
	padding:'14px 10px',
	border: `1px solid ${theme.palette?.card?.border}`,
	borderRadius: "12px",
	bottom: "17px",
	"::before": {
		content:`" "`,
		display: "block",
		position: "absolute",
		left: "42.5%",
		top: "100%",
		width: 0,
		height: 0,
		border: "10px solid transparent",
		"border-top-color":theme.palette?.card?.border,
	},
	"::after":{
		content:`" "`,
		display: "block",
		position: "absolute",
		left: "43%",
		top: "100%",
		width: 0,
		height: 0,
		border:" 9px solid transparent",
		"border-top-color": theme.palette?.card?.background,
	}
}));
