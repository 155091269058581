/* eslint-disable react/no-unescaped-entities */
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { InboxSVG } from "resources/Icons/Inbox/Index";

export default function EmptyState() {
	const theme = useTheme();
	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			height={"500px"}
			textAlign={"center"}
		>
			<Box>
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
					<Box
						sx={{
							width: "88px",
							height: "88px",
							background: theme?.palette?.mode=='light'?"#E6F3FF":'#07192a',
							borderRadius: "100px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						mb={"16px"}
					>
						<InboxSVG />
					</Box>
				</Box>
				<Box mb={"12px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={24}
						fontWeight={600}
						lineHeight={"normal"}
                       
					>
						Inbox is empty
					</Typography>
				</Box>
				<Box mb={"22px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={14}
						fontWeight={500}
						lineHeight={"20px"}
						sx={{
							opacity: 0.5,
						}}
                        maxWidth={'538px'}
					>
						Nothing in your inbox yet, check back once customers reply to our
						texts or you've sent a campaign
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
