import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Card from "../../../components/Card/Analytics";
import CartRecovery from "../../../components/Skeleton/CartRecovery";
import PageHeading from "components/Typography/PageHeading";
import { txtCartFetch } from "utilifyFunctions";
import { parseDateFilter } from "utilifyFunctions";

export default function Analytics() {
	const [aov,setAov] = useState(null);
	const [soldCarts,setSoldCarts] = useState(null);
	const [soldCartsValue,setSoldCartsValue] = useState(null);

	const getMode = useTheme();
	const theme = useTheme();
	const mode = getMode?.palette?.mode;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	const getAOV = async function(dateRange){
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/carts/aov"+dateRange);
		if(response){
			setAov(response.aov);
		}
	}

	const getAbandonedCartsSold = async function(dateRange){
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/carts/count/sold"+dateRange);
		if(response){
			setSoldCarts(response.countText);
		}
	}

	const getAbandonedCartsSoldRevenue = async function(dateRange){
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/carts/sold/sum"+dateRange);
		if(response){
			setSoldCartsValue(response.revenue);
		}
	}

	const loadAllTimeMetrics = async function(filter){
		let dateRange = parseDateFilter(filter)
		let urlDateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		getAOV(urlDateRange);
		getAbandonedCartsSold(urlDateRange);
		getAbandonedCartsSoldRevenue(urlDateRange);
	}

	useEffect(() => {
		loadAllTimeMetrics("all_time");
	}, []);
	
	return (
		<Box>
			<PageHeading mb={2} mt={2}>
				Cart Recovery
			</PageHeading>

			<Box
				sx={{
					borderRadius: "12px",
				}}
			>
				<Box
					display="flex"
					flexDirection={belowTablet ? "column" : "row"}
					justifyContent="space-between"
					gap={"10px"}
				>
					{aov !== null && aov !== undefined ? (<Card
						key={0}
						title={"Average Order Value"}
						value={aov}
						desc={"The all-time average value of a cart we recover through TxtCart."}
						mode={mode}
					/>) :(
						<CartRecovery mode={mode} key={0}/>
					)}
					{soldCarts !== null && soldCarts !== undefined ? (<Card
						key={1}
						title={"Total Recoveries Won"}
						value={soldCarts}
						desc={"The all-time number of successful carts we recover with our texts."}
						mode={mode}
					/>) :(
						<CartRecovery mode={mode} key={1}/>
					)}
					{soldCartsValue !== null && soldCartsValue !== undefined ? (<Card
						key={2}
						title={"Total Recovered Revenue"}
						value={soldCartsValue}
						desc={"All all-time cart recovery revenue attributed to our texts."}
						mode={mode}
					/>) :(
						<CartRecovery mode={mode} key={2}/>
					)}
				</Box>
			</Box>
		</Box>
	);
}
