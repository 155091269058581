import React from "react";
import Picker from "emoji-picker-react";
import { Box } from "@mui/material";
import './style.css'

export default function Emoji({ setChosenEmoji }) {
	const onEmojiClick = (event, emojiObject) => {
		setChosenEmoji(event, emojiObject);
	};

	return (
		<Box className='emoji-class'>
			<Picker onEmojiClick={onEmojiClick} />
		</Box>
	);
}