import React, { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	Box,
	useTheme,
} from "@mui/material";

import MetricCard from "../../../../components/MetricCard";
import Select from "../../../../components/Select/Select.js";
import { TimeSelection } from "../../../../resources/constant.js";
import { txtCartFetch } from "../../../../utilifyFunctions.js";
import { parseDateFilter } from "../../../../utilifyFunctions.js";

export default function Metrics() {
	const [spend, setSpend] = useState(null);
	const [revenue, setRevenue] = useState(null);
	const [roi, setRoi] = useState(null);
	const [ordersCount, setOrdersCount] = useState(null);
	const [shoppersCount, setShoppersCount] = useState(null);

	const handleDateChange = (label, value) => {
		setSpend(null);
		setRevenue(null);
		setRoi(null);
		setOrdersCount(null);
		setShoppersCount(null);
		setTimeout(() => {
			loadMetrics(value);
		}, 500); 
	}

	const getMode = useTheme();
	const mode = getMode?.palette?.mode;

	async function getRevenue(dateRange) {
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/revenue"+dateRange);
		setRevenue(response?.revenueText);
		return response?.revenue;
	}


	async function getSpend(dateRange) {
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/charge/sum"+dateRange);
		setSpend(response?.spendText);
		return response?.spend;
	}

	async function getOrdersCount(dateRange) {
		console.log("GET ORDERS COUNT");
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/orders/count"+dateRange);
		console.log(response);
		setOrdersCount(response?.orders?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
	}

	async function getShoppersCount(dateRange) {
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/count"+dateRange);
		setShoppersCount(response?.shoppers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
	}

	async function loadMetrics(filter){
		let dateRange = parseDateFilter(filter)
		let urlDateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		getOrdersCount(urlDateRange);
		getShoppersCount(urlDateRange);
		let rev = await getRevenue(urlDateRange);
		let spend = await getSpend(urlDateRange);
		calculateROI(rev,spend);
	}


	function calculateROI(revenue, spend){
		if(spend === 0){
			spend = 1; 
		}
		let roi = (revenue / spend).toFixed(2) + "x";
		setRoi(roi);
	}

	useEffect(() => {
		loadMetrics("all_time");
		return;
	}, []);
	return (
		<>
			<Box
				display={"flex"}
				flexDirection={"row"}
				justifyContent={"space-between"}
				alignItems={"baseline"}
			>
				<Box>
					<Typography mb={2} mt={2} fontSize={"18px"} fontWeight={"600"}>
						Key SMS Metrics
					</Typography>
				</Box>
				<Select
					options={TimeSelection}
					handleSelectChange={handleDateChange}
					defaultValue="All Time"
				>
				</Select>
			</Box>

			<Box
				sx={{
					borderRadius: "12px",
				}}
			>
				{ <Grid container gap={2} justifyContent={"space-between"}>
					<MetricCard
						key={1}
						title={"Revenue"}
						value={revenue}
						img={"roi"}
						mode={mode}
					/>
					<MetricCard
						key={2}
						title={"Spend"}
						value={spend}
						img={"wallet"}
						mode={mode}
					/>
					<MetricCard
						key={3}
						title={"ROI"}
						value={roi}
						img={"revenue"}
						mode={mode}
					/>
					<MetricCard
						key={4}
						title={"Orders"}
						value={ordersCount}
						img={"orders"}
						mode={mode}
					/>
					<MetricCard
						key={5}
						title={"SMS Subscribers"}
						value={shoppersCount}
						img={"sms"}
						mode={mode}
					/>
				</Grid>}
			</Box>
		</>
	);
}
