/* eslint-disable react/no-unknown-property */
import React from "react";

export default function Bar() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="35"
			height="35"
			viewBox="0 0 35 35"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M26.2493 4.375C27.0548 4.375 27.7077 5.02792 27.7077 5.83333V29.1667C27.7077 29.9721 27.0548 30.625 26.2493 30.625C25.4439 30.625 24.791 29.9721 24.791 29.1667V5.83333C24.791 5.02792 25.4439 4.375 26.2493 4.375Z"
				fill="#3472FA"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.74935 21.875C9.55476 21.875 10.2077 22.5279 10.2077 23.3333V29.1667C10.2077 29.9721 9.55476 30.625 8.74935 30.625C7.94393 30.625 7.29102 29.9721 7.29102 29.1667V23.3333C7.29102 22.5279 7.94393 21.875 8.74935 21.875Z"
				fill="#3472FA"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M17.4993 13.125C18.3048 13.125 18.9577 13.7779 18.9577 14.5833V29.1667C18.9577 29.9721 18.3048 30.625 17.4993 30.625C16.6939 30.625 16.041 29.9721 16.041 29.1667V14.5833C16.041 13.7779 16.6939 13.125 17.4993 13.125Z"
				fill="#3472FA"
			/>
		</svg>
	);
}
