import { Box, Typography, useTheme, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	DecreaseTag,
	NeutralTag,
	IncreaseTag,
} from "templates/Analytics/components/common";
import ConfettiExplosion from "react-confetti-explosion";
import { formatValue } from "resources/helpers/valueFormat";
import { checkEmptyState } from "resources/helpers/checkEmptyState";

export default function Card({ title, value, isIncrease, byValue, icon, valueType }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const [confetti, setConfetti] = React.useState({
		show: false,
		coordinates: { x: 0, y: 0 },
	});
	const [emptyState, setEmptyState] = useState(false);

	const handleCardClick = (e) => {
		const x = e.clientX;
		const y = e.clientY;

		setConfetti({
			show: true,
			coordinates: { x, y },
		});

		setTimeout(() => {
			setConfetti({
				show: false,
				coordinates: { x: 0, y: 0 },
			});
		}, 1000); // Change the duration as needed
	};
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			setEmptyState(true);
		}
		
	}, []);

	return (
		<Box
			id="pointerCursor"
			bgcolor={theme?.palette?.card?.background}
			border={`1px solid ${theme?.palette?.card?.border}`}
			borderRadius={"12px"}
			width={"100%"}
			p={"20px"}
			sx={{
				":hover": {
					boxShadow: lightMode
						? "5px 6px 5px 0px rgba(0, 0, 0, 0.1)"
						: "5px 6px 5px 0px rgb(40 40 40)",
				},
			}}
			onClick={handleCardClick}
		>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				mb={"16px"}
			>
				<Typography
					fontSize={16}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
					lineHeight={"normal"}
					sx={{
						opacity: "0.5",
					}}
				>
					{title}
				</Typography>
				<Box>{icon}</Box>
			</Box>
			<Box padding={"0 50%"}>
				{confetti.show && (
					<ConfettiExplosion
						particleSize={7}
						particleCount={30}
						force={1}
						width={300}
						height={200}
					/>
				)}
			</Box>
			<Box mb={"10px"}>
				{value !== null && value !== undefined ? (
					<Typography
						fontSize={30}
						fontWeight={600}
						color={theme?.palette?.pageHeading}
						lineHeight={"normal"}
					>
						{emptyState
							? formatValue(0, valueType)
							: formatValue(value, valueType)}
					</Typography>
				) : (
					<Box sx={{ width: '100%' }}>
						<Skeleton variant="rectangular" animation="wave" height={40} />
					</Box>
				)}
			</Box>
			<Box mb={"4px"} display={"flex"} alignItems={"center"} gap={"8px"}>
				{(byValue === null || byValue === undefined) ? (
					<Box sx={{ width: '50%' }}>
						<Skeleton variant="rectangular" animation="wave" height={20} />
					</Box>
				) : byValue === 0 ? (<NeutralTag/>)
				: isIncrease ? (
					<IncreaseTag byValue={byValue} />
				) : (
					<DecreaseTag byValue={byValue} />
				)}
				{ (byValue !== null && byValue !== undefined) ? (
					<Typography
						fontSize={14}
						fontWeight={500}
						color={"#808698"}
						lineHeight={"normal"}
					>
						vs. previous period
					</Typography>
				) : (
					<></>
				)}
			</Box>
		</Box>
	);
}
