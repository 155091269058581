import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import ConnectSlackButton from "components/Buttons/ConnectSlackButton";
import PrimaryButton from "components/Buttons/PrimaryButton";
import PageHeading from "components/Typography/PageHeading";
import React, { useState } from "react";
import TableSecton from "./components/TableSecton";
import GenerateReport from "components/Modal/GenerateReport";

export default function Reports() {
	const theme = useTheme();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Grid container alignItems={"center"}>
			<Grid item xs={12} mb={"21px"}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<PageHeading>Reports</PageHeading>
					<Box>
						{!belowSmall && (
							<ConnectSlackButton
								style={{ marginRight: "10px" }}
								onClick={() => {
									// setOpenModal(true);
								}}
							>
								Connect to Slack
							</ConnectSlackButton>
						)}
						<PrimaryButton onClick={() => setIsModalOpen(true)}>
							Generate a Report
						</PrimaryButton>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<TableSecton setIsModalOpen={setIsModalOpen}/>
			</Grid>
			<GenerateReport open={isModalOpen} handleClose={setIsModalOpen} />
		</Grid>
	);
}

