import React from "react";
import { Helmet } from "react-helmet";
import CampaignsLockedTemplate from "../../templates/LockedPages/Campaigns";

export default function CampaignsLocked() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | Campaigns</title>
			</Helmet>
			<CampaignsLockedTemplate />
		</div>
	);
}
