import {
	Box,
	Button,
	ListItemText,
	MenuItem,
	MenuList,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import Antd from "components/Table/Antd";
import TableHeading from "components/Typography/TableHeading";
import React, { useEffect, useState } from "react";
import SegmentModal from "components/Modal/Segments";
import { segmentsMock } from "resources/constant";
import { Dropdown } from "antd";
import EmptyTableData from "./EmptyComponentTable";
import { checkEmptyState } from "resources/helpers/checkEmptyState";

export default function TableSecton({ handleOpenModal }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const [open, setOpen] = useState(false);
	const [tableData, setTableData] = useState();

	const columns = [
		{
			dataIndex: "name",
			key: "name",
			title: <TableHeading minWidth={"150px"}>Name</TableHeading>,
			sortable: false,
			width: "20%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "type",
			key: "type",
			title: <TableHeading minWidth={"200px"}>Type</TableHeading>,
			sortable: false,
			width: "27%",

			render: () => (
				<Box display={"flex"} alignItems={"center"} gap={"12px"}>
					<span
						style={{
							backgroundColor: lightMode ? "#EEEFF2" : "#1F212D",
							padding: "4px 14px",
							borderRadius: "4px",
							color: theme.palette?.pageHeading,
							fontWeight: "500",
							fontSize: "12px",
							// height:'22px'
						}}
					>
						Segment
					</span>
					<Typography
						id="pointerCursor"
						fontSize={12}
						fontWeight={600}
						color={lightMode ? "#0C84FE" : "#0C84FE"}
						lineHeight={"normal"}
						onClick={() => {
							// console.log("c shd jh");
							setOpen(true);
						}}
					>
						View rules
					</Typography>
				</Box>
			),
		},
		{
			dataIndex: "contact",
			key: "contact",
			title: <TableHeading minWidth={"100px"}>Contacts</TableHeading>,
			sortable: false,
			width: "20%",

			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "lastUpdated",
			key: "lastUpdated",
			title: <TableHeading minWidth={"250px"}>Last Updated</TableHeading>,
			sortable: false,
			width: "20%",

			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			key: "actions",
			dataIndex: "actions",
			title: <TableHeading minWidth={"220px"}>Actions</TableHeading>,
			width: "220px",
			render: () => (
				<Box display={"flex"} gap={"14px"}>
					<Button
						sx={{
							padding: "7px 15px",
							border: "1px solid #0081FF",
							backgroundColor: "transparent",
						}}
						variant="outlined"
					>
						<img
							src="/icons/sendIcon.svg"
							style={{ marginRight: "5px" }}
							id="pointerCursor"
						/>
						<Typography
							fontSize={"14px"}
							fontWeight={"600"}
							color="#0081FF"
							lineHeight={"normal"}
						>
							Send Campaign
						</Typography>
					</Button>
					<Dropdown
						overlay={<ActionMenu />}
						placement="bottomRight"
						trigger={["click"]}
					>
						<img src="/icons/more.svg" id="pointerCursor" />
						{/* <MoreOutlined style={iconStyle} twoToneColor="#eb2f96" /> */}
					</Dropdown>
				</Box>
			),
		},
	];

	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		let data;
		if (!empty) {
			data = [...segmentsMock];
		} else {
			data = [];
		}
		setTimeout(() => {
			setTableData([...data]);
		}, 5000);
	}, []);

	return (
		<Box>
			<Antd
				mode={mode}
				columns={columns}
				rows={tableData}
				loading={tableData ? false : true}
				EmptyComponentTable={
					<EmptyTableData handleOpenModal={handleOpenModal} />
				}
			/>
			<SegmentModal
				open={open}
				mode={mode}
				handleClose={() => setOpen(false)}
			/>
		</Box>
	);
}

const ActionMenu = () => {
	const theme = useTheme();
	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={`1px solid ${theme.palette?.card?.border}`}
			borderRadius={"8px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				<MenuItem key={"1"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme}>Edit</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem key={"3"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme} color="#FF465C">
							Delete
						</MenuTypography>
					</ListItemText>
				</MenuItem>
			</MenuList>
		</Box>
	);
};
const MenuTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			fontSize={"14px"}
			color={theme.palette?.mode == "light" ? "#0A0A18" : "#FFF"}
			lineHeight={"normal"}
			fontWeight={500}
			{...props}
		>
			{children}
		</Typography>
	);
};