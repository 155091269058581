import React from "react";

export default function RightArrow() {
	return (
		<svg
			width="18"
			height="12"
			viewBox="0 0 18 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 5H14.17L10.59 1.41L12 0L18 6L12 12L10.59 10.59L14.17 7H0V5Z"
				fill="#969AA5"
			/>
		</svg>
	);
}
