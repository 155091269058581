import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export default function PrimaryButton({children,...props}) {
	const theme=useTheme()
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Button
			sx={{
				padding: "11px 18px",
				border: "1px solid #0081FF",
				backgroundColor: "transparent",
				height:belowSmall?'34px':'40px'
			}}
			variant="outlined"
            {...props}
		>
			<Typography fontSize={"14px"} fontWeight={"600"} color="#0081FF">
				{children}
			</Typography>
		</Button>
	);
}
