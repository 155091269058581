/* eslint-disable react/no-unknown-property */
import React from "react";

export default function Tag() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2.21115 4.39672C2.69052 3.45591 3.45542 2.69101 4.39623 2.21164C5.02687 1.89032 5.68223 1.77146 6.34114 1.71762C6.96542 1.66662 7.72338 1.66664 8.60196 1.66667L16.2626 1.66659C16.9407 1.66596 17.5386 1.6654 18.1194 1.80482C18.6295 1.92731 19.1173 2.12933 19.5646 2.40347C20.0739 2.71553 20.4963 3.13871 20.9753 3.61869L33.9031 16.5466C34.8518 17.4953 35.6348 18.2782 36.2205 18.9682C36.8288 19.6847 37.3216 20.4098 37.6022 21.2732C38.0372 22.6122 38.0372 24.0545 37.6022 25.3935C37.3216 26.2569 36.8288 26.982 36.2205 27.6985C35.6348 28.3884 34.8519 29.1714 33.9032 30.12L30.1195 33.9037C29.1709 34.8524 28.3879 35.6353 27.698 36.221C26.9815 36.8293 26.2564 37.3221 25.393 37.6026C24.054 38.0377 22.6117 38.0377 21.2727 37.6026C20.4093 37.3221 19.6842 36.8293 18.9677 36.221C18.2778 35.6353 17.4948 34.8523 16.5462 33.9037L3.6182 20.9758C3.13822 20.4967 2.71504 20.0744 2.40298 19.5651C2.12884 19.1178 1.92682 18.63 1.80434 18.1199C1.66491 17.5391 1.66547 16.9412 1.6661 16.263L1.66618 8.60243C1.66615 7.72385 1.66613 6.96591 1.71714 6.34163C1.77097 5.68272 1.88983 5.02735 2.21115 4.39672ZM13.3329 9.99984C11.4919 9.99984 9.99955 11.4922 9.99955 13.3332C9.99955 15.1741 11.4919 16.6665 13.3329 16.6665C15.1738 16.6665 16.6662 15.1741 16.6662 13.3332C16.6662 11.4922 15.1738 9.99984 13.3329 9.99984Z"
				fill="#3471FA"
			/>
		</svg>
	);
}
