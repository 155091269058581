import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
	CustomMenuItem,
	CustomSelect,
	CustomTypography,
	FixContainer,
} from "./EINTab";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function SMSCapableTab({ formData, handleFormData, setTab }) {
	const theme = useTheme();
    const [volumeSet, setVolumeSet] = useState(false);
	console.log(formData);

    const handleVolumeChange = function(e){
        handleFormData(e.target?.name, e.target?.value);
        setVolumeSet(true);
    }
	return (
		<Box>
            <FixContainer>
                <CustomTypography theme={theme}>
                    How many message segments will your business send a day?
                </CustomTypography>
                <CustomSelect
                    name="segmentsCount"
                    value={formData?.segmentsCount}
                    onChange={(e) => handleVolumeChange(e)}
                >
                    <CustomMenuItem value="Less than 6,000">
                        Less than 6,000
                    </CustomMenuItem>
                    <CustomMenuItem value="6,000 to 220,000">
                        6,000 to 220,000
                    </CustomMenuItem>
                </CustomSelect>
            </FixContainer>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton
                    disabled={!volumeSet}
					onClick={() => {
						setTab("CompanyInformationTab")
					}}
				>
					Next
				</PrimaryButton>
			</Box>
		</Box>
	);
}