import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function TabSelection({ tab }) {
	const navigate=useNavigate()
	const tabOptions = [
		{ title: "Account", link: "account" },
		{ title: "Company", link: "company" },
		{ title: "Messaging", link: "messaging" },
		{ title: "Discounts", link: "discounts" },
		//{ title: "Users", link: "users" },
		{ title: "Billing", link: "billing" },
	];
	
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightmode = mode == "light" ? true : false;
	return (
		<Box
			className="setting-tab-selection"
			sx={{
				padding: "0 0 0 11px",
				display: "flex",
				gap: "24px",
				overflow: "hidden",
				overflowX: "scroll",
			}}
		>
			{tabOptions?.map((item, index) => {
				return (
					<Box
						key={index}
						onClick={() => 
						navigate(`/settings?tab=${item?.link}`)
						}
						id={"pointerCursor"}
						pb={"9px"}
						sx={{
							borderBottom: tab == item?.link ? "2px solid #0081FF" : "none",
						}}
					>
						<Typography
							sx={{
								color:
									tab == index ? (lightmode ? "#0A0A18" : "#FFF") : "#808698",
								fontSize: "16px",
								fontWeight: 500,
								lineHeight: "20px",
							}}
						>
							{item.title}
						</Typography>
					</Box>
				);
			})}
		</Box>
	);
}
