import { Box, Modal, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function MessagingModal({ open, handleClose, message, title, handleSubmit }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Modal
			open={open}
			id="messaging"
			aria-labelledby="messaging-modal"
			aria-describedby="messaging-modal"
		>
			<MainContainer belowSmall={belowSmall} lightMode={lightMode}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					mb={"24px"}
				>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"22px"}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{title}
					</Typography>

					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={() => {
							handleClose();
						}}
						id="pointerCursor"
						style={{
							marginTop: "-10px",
						}}
					/>
				</Box>
                <Typography
						color={theme.palette?.pageHeading}
						fontSize={"16px"}
						lineHeight={"normal"}
						paddingBottom={"20px"}
					>
						{message}
					</Typography>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton onClick={() => handleSubmit()}>
					Submit
				</PrimaryButton>
			</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowSmall }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowSmall ? "90%" : "700px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "20px 20px 25px 20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
				maxHeight: "90vh",
			}}
		>
			{children}
		</Box>
	);
};