/* eslint-disable no-unused-vars */
import { Box, Typography, useTheme } from "@mui/material";
import { Input } from "antd";
import React from "react";
import { FilterSVG, SearchSVG } from "resources/Icons";
export default function Sidebar() {
	const theme = useTheme();
	return (
		<Box
			sx={{
				background: theme?.palette?.card?.background,
				border: `1px solid ${theme?.palette?.card?.border}`,
				height: "100vh",
				width: "30%",
				p: "0 16px",
			}}
		>
			<Box mb={"20px"} mt={"13px"}>
				<Input
					placeholder="Search Triggers"
					prefix={<SearchSVG />}
					style={
						{
							// backgroundColor:theme?.palette?.card?.background
						}
					}
				/>
			</Box>
			<Typography
				color={theme?.palette?.pageHeading}
				fontSize={20}
				fontWeight={600}
				mb={"16px"}
			>
				Order
			</Typography>
			<Box display={"flex"} gap={"12px"} flexDirection={"column"}>
				{sidebarOrderMock?.map((item, index) => {
					return (
						<FlowCard
							key={index}
							title={item?.title}
							desc={item.desc}
							isSelected={index == 0}
						/>
					);
				})}
			</Box>
			<Typography
				color={theme?.palette?.pageHeading}
				fontSize={20}
				fontWeight={600}
				mb={"16px"}
			>
				Order
			</Typography>
			<Box display={"flex"} gap={"12px"} flexDirection={"column"}>
				{sidebarShippingrMock?.map((item, index) => {
					return (
						<FlowCard
							key={index}
							title={item?.title}
							desc={item.desc}
							isSelected={index == 0}
						/>
					);
				})}
			</Box>
		</Box>
	);
}

const sidebarOrderMock = [
	{
		title: "Abandoned Checkout",
		desc: "Triggered when a customer abandons an order at the checkout.",
	},
	{
		title: "Order Created",
		desc: "Triggered after the customer places an order.",
	},
	{
		title: "Cross-sell",
		desc: "Recommend a product to the customer based on what they purchased",
	},
	{
		title: "Post-Purchase Upsell",
		desc: "Sent to the customer when they place an order. Use it to market other products and give discounts, it will be sent only to sms subscribers. We recommend setting a delay.",
	},
	{
		title: "Order Paid",
		desc: "Triggered after the order payment is confirmed.",
	},
	{
		title: "Order Cancelled",
		desc: "Trigger if the order is cancelled.",
	},
];

const sidebarShippingrMock = [
	{
		title: "Order Fulfillment",
		desc: "Sent to the customer when their order is fulfilled. Includes shipping details.",
	},
	{
		title: "Shipping Update",
		desc: "Sent automatically to the client when there is a shipping update to an order Includes tracking details.",
	},
];

const FlowCard = ({ isSelected, title, desc }) => {
	const theme = useTheme();
	return (
		<Box
			display={"flex"}
			sx={{
				background: theme?.palette?.card?.background,
				border: `1px solid ${theme?.palette?.card?.border}`,
				borderRadius: "12px 12px 12px 12px",
				borderLeft: isSelected
					? "5px solid #3472FA"
					: `1px solid ${theme?.palette?.card?.border}`,
				p: "16px",
			}}
		>
			{/* <Box
				sx={{
					width: "5px",
					background: "#3472FA",
					borderRadius: " 12px 0px 0px 12px ",
				}}
			>
				&nbsp;
			</Box> */}
			<Box>
				<Typography
					mb={1}
					color={theme?.palette?.pageHeading}
					fontSize={14}
					fontWeight={600}
				>
					{title}
				</Typography>
				<Typography color={"#808698"} fontSize={12} fontWeight={400}>
					{desc}
				</Typography>
			</Box>
		</Box>
	);
};
