/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CampaignsCard from "../../../../components/Card/Campaigns";
import LineChartUp from "resources/Icons/Campaigns/LineChartUp";
import {
	TagSVG,
	AlertSVG,
	NotificationSVG,
	SendSVG,
	LineChartUpSVG,
	RocketSVG,
} from "resources/Icons/Campaigns";
import Skeleton from "components/Skeleton/CartRecovery";
import { txtCartFetch } from "utilifyFunctions";
import { parseDateFilter } from "utilifyFunctions";
import { addDays } from "utilifyFunctions";

export default function Analytics({dateRange}) {
	const getMode = useTheme();
	const theme = useTheme();
	const mode = getMode?.palette?.mode;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [acq, setAcq] = useState(null);
	const [ltv, setLtv] = useState(null);
	const [rpm, setRpm] = useState(null);
	const [mps, setMps] = useState(null); 
	const [rr, setRr] = useState(null);
	const [oor, setOor] = useState(null);

	const getAcquisition = async function(){
		let day = addDays(new Date(),-90);
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/acquisition?start="+day.getTime());
		setAcq(response?.rate);	
	}

	const getSubscriberLTV = async function(filter){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/ltv"+filter);
		setLtv(response?.LTV);
	}

	const getRevenueMetrics = async function(filter){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/campaign/revenuePerMessage"+filter);
		setRpm(response?.revenuePerMessage);
	}

	const getMessagesPerSubscriber = async function(filter){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/campaign/messagesPerSubscriber"+filter);
		setMps(response?.messagesPerSubsciber);
	}

	const getReplyRate = async function(filter){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/campaign/replyRate"+filter);
		setRr(response?.replyRate);
	}

	const getOptOut = async function(filter){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/campaign/campaignOptOut"+filter);
		setOor(response?.optOutRate);
	}

	useEffect(() => {	
		setLtv(null);
		setRpm(null);
		setMps(null);
		setRr(null);
		setOor(null);

		let localAcq = acq; 
		setAcq(null);

		setTimeout(() => {
			setAcq(localAcq);
			let range = parseDateFilter(dateRange)
			let urlDateRange = (range ? "?start="+range.start+"&end="+range.end : "");
			getSubscriberLTV(urlDateRange);
			getRevenueMetrics(urlDateRange);
			getMessagesPerSubscriber(urlDateRange);
			getReplyRate(urlDateRange);
			getOptOut(urlDateRange);
		}, 500); 
	}, [dateRange]);

	useEffect(() => {
		getAcquisition();
	}, []);
	return (
		<Box
			sx={{
				borderRadius: "12px",
			}}
			mt={"8px"}
		>
			<Box
				display="flex"
				flexDirection={belowTablet ? "column" : "row"}
				justifyContent="space-between"
				gap={"10px"}
			>
				{
					ltv || ltv === 0 ? (				
						<CampaignsCard
							key="LTV"
							title="Subscriber LTV"
							value={ltv?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							desc= "The lifetime value of a subscriber on average"
							mode={mode}
							icon={<LineChartUp />}
							valueType="amount"
						/>) : 
						(<Skeleton mode={mode} key="LTV"/>)
				}
				{
					acq || acq === 0 ? (				
						<CampaignsCard
						key="AQR"
						title="Acquisition Rate"
						value={acq}
						desc="The opt-in rate of your SMS subscribers. This value is calculated based on the last 90 days of data."
						mode={mode}
						icon={<RocketSVG />}
						valueType= "percentage"
					/>) : (<Skeleton mode={mode} key="AQR"/>)
				}
				{
					rpm || rpm === 0 ? (
						<CampaignsCard
						key="RPM"
						title="Revenue per Message"
						value={rpm?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						desc=  "The total sales per text calculated across all campaigns"
						mode={mode}
						icon={ <TagSVG />}
						valueType=  "amount"
					/>
					) :
					(<Skeleton mode={mode} key="RPM"/>)
				}
			</Box>
			<Box
				display="flex"
				flexDirection={belowTablet ? "column" : "row"}
				justifyContent="space-between"
				gap={"10px"}
				mt={"24px"}
			>
				{
					mps || mps === 0 ? (
						<CampaignsCard
						key="MPS"
						title="Messages per Subscriber"
						value={mps}
						desc= "The average number of messages in a campaign conversation"
						mode={mode}
						icon={<SendSVG />}
						valueType= "number"
					/>
					) :
					(<Skeleton mode={mode} key="RPM"/>)
				}
				{
					rr || rr === 0 ? (
						<CampaignsCard
						key="RR"
						title="Reply Rate"
						value={rr}
						desc= "The rate of response to your campaign texts"
						mode={mode}
						icon={ <NotificationSVG />}
						valueType= "percentage"
					/>
					) :
					(<Skeleton mode={mode} key="RR"/>)
				}
				{
					oor || oor === 0 ? (
						<CampaignsCard
						key="OOR"
						title="Opt-Out Rate"
						value={oor}
						desc= "The rate that people unsubscribe and reply STOP"
						mode={mode}
						icon={ <AlertSVG />}
						valueType= "percentage"
					/>
					) :
					(<Skeleton mode={mode} key="OOR"/>)
				}
			
			</Box>
		</Box>
	);
}
