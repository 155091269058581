import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	Divider,
	Grid,
} from "@mui/material";

export default function MessageTemplates({ open,setOpen, handleClose, templates,setCampaignTemplate }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [selected, setSelected] = useState("");
	const [displayedTemplates, setDisplayedTemplates] = useState([]);

	const handleTab = function(tab){
		console.log(tab);
		setSelected(tab);
		let result; 
		if(tab === "All"){
			setDisplayedTemplates([...templates]);
			return;
		}
		result = templates?.filter((item) => {
			return item?.category === tab;
		});

		setDisplayedTemplates([...result]);
	}

	useEffect(() => {
		setDisplayedTemplates(templates);
	}, [templates]);
	return (
		<Modal
			open={open}
			id="MessageTemplates"
			aria-labelledby="MessageTemplates"
			aria-describedby="MessageTemplates-segments"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					p={"20px"}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					mb={"12px"}
				>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"20px"}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Message Templates
					</Typography>
					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={handleClose}
						id="pointerCursor"
						style={{
							marginTop: "-10px",
						}}
					/>
				</Box>
				<Divider />
				<Box p={"20px"}>
					<Box
						display={"flex"}
						gap={"12px"}
						flexWrap={'wrap'}
						mb={"20px"}
					>
						{TabOptions?.map((item, index) => {
							return (
								<Box
									id="pointerCursor"
									key={index}
									sx={{
										padding: "12px 18px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										border: `1px solid ${
											item == selected ? mode=='light'?"#0A0A18":'#FFF' : theme?.palette?.card?.border
										}`,
									}}
									onClick={() => handleTab(item)}
								>
									<Typography
										color={theme?.palette?.pageHeading}
										fontSize={14}
										fontWeight={600}
										lineHeight={"normal"}
										sx={{
											opacity: item == selected ? "1" : "0.3",
										}}
									>
										{item}
									</Typography>
								</Box>
							);
						})}
					</Box>
					<Box>
						<Grid container spacing={3}>
							{displayedTemplates?.map((item,index) => (
								<Grid item key={index} xs={12} sm={6} md={4}>
										<TamplateCard 
											title={item?.title} 
											previewText={item?.text} 
											character={item?.text.length} 
											template={item} 
											setCampaignTemplate={setCampaignTemplate}
											setOpen={setOpen}/>
								</Grid>
							))}
						</Grid>
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "1000px",
				backgroundColor: theme?.palette?.card?.background,
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				borderRadius: "12px",
				outline: "none",
				":focus-visible": {
					border: "none",
				},
				boxShadow: 24,
				overflowY: "auto",
                maxHeight:'90vh'
                // maxHeight:{"80%"}
			}}
		>
			{children}
		</Box>
	);
};

const TabOptions = ["All","Holiday Events", "Sales","Back in Stock","Product Highlight","Product Launch"];

const TamplateCard = ({ title, previewText,character, setCampaignTemplate, template, setOpen }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	return (
		<Box
			p={"20px"}
            border={`1px solid ${theme?.palette?.card?.border}`}
            borderRadius={'12px'}
		>
			<Box mb={"14px"} display={'flex'} justifyContent={'space-between'} alignItems={'end'}>
				<Typography
					color={theme?.palette?.pageHeading}
					fontSize={16}
					fontWeight={600}
					lineHeight={"normal"}
				>
					{title}
				</Typography>
				<Typography
					color={theme?.palette?.pageHeading}
					fontSize={12}
					fontWeight={500}
					sx={{
						opacity: "0.3",
					}}
					lineHeight={"normal"}
				>
					{character} characters
				</Typography>
			</Box>
			<Box p={'12px'} bgcolor="rgba(12, 132, 254, 0.15);" borderRadius="4px" mb={'12px'} minHeight={"275px"} whiteSpace={"pre-wrap"}>
				{previewText}
			</Box>
			<Box>
				<Button
					size={"small"}
                    fullWidth
					sx={{
						backgroundColor: "#0081FF",
						padding: "11px 30px",
						color: "#FFF",
						":hover": {
							color: mode == "light" ? "#0C0D17" : "#FFF",
						},
					}}
					variant="outlined"
					onClick={() => {setCampaignTemplate(template);setOpen(false)}}
				>
					<Typography fontSize={"14px"} fontWeight={"600"} >
                    Load Template
					</Typography>
				</Button>
			</Box>
		</Box>
	);
};
