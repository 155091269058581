/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
} from "@mui/material";
import { planMock } from "resources/constant";
import OnboardingToolTip from "components/Tooltip/OnboardingToolTip";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";

export default function SelectPlanV2() {
	const navigate = useNavigate();
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const planData = [
		{
			name: "Basic",
			desc: "For new stores looking for a risk-free option to SMS",
			priceMonthly: "$0",
			priceAnnually: "$0",
			discount: "$290 billed Annually",
			features: {
				heading: "EVERYTHING IN BASIC PLUS",
				keyFeatures: ["SMS Cart Recovery", "Customer Chat Support"],
			},
		},
		{
			...planMock[0],
		},
	];
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [period, setPlanPeriod] = useState("Monthly");
	const [recommended, setRecommended] = useState("");
	const [showLoading, setShowLoading] = useState(true);

	const Icons = {
		Starter: "💰",
		Basic: "💡",
	};
	const mapDescription = {
		Starter: "+15% of cart recovery",
		Basic: "+20% of cart recovery",
	};
	const mapPlanDesc = {
		Starter: "Free Plan Includes",
		Basic: "Free Plan Includes:",
		Pro: "Everything in Growth plus:",
	};
	const [showCardForMobile, setShowCardForMobile] = useState("Starter");
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	useState(() => {
		setTimeout(() => {
			setShowLoading(false);
		}, 3000);
		setTimeout(() => {
			setRecommended("Starter");
		}, 3000);
		// setTimeout(() => {
		// 	setShowLoading(false);
		// }, 3000);
		// setTimeout(() => {
		// 	setRecommended("Starter");
		// }, 3000);
	}, []);

	return (
		<MainContainer
			lightMode={lightMode}
			belowTablet={belowTablet}
			isMobile={isMobile}
		>
			<Box>
				{showLoading && <ProgressBar />}
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					mb={"10px"}
					mt={"14px"}
				>
					<img src="/img/onboarding/emoji.svg" />
				</Box>
				<Box
					textAlign={"center"}
					mb={"16px"}
					display={"flex"}
					justifyContent={"center"}
				>
					<Box>
						<Typography
							fontSize={20}
							fontWeight={600}
							lineHeight={"26px"}
							color={"#FFF"}
							mb={"11px"}
						>
							You’re Almost Ready to Go!
						</Typography>
						<Typography
							fontSize={16}
							fontWeight={400}
							lineHeight={"20px"}
							color={"#969AA5"}
							maxWidth={"425px"}
						>
							Based on your order volume, we recommend{" "}
							<span style={{ color: "#FFF", fontWeight: "600" }}>Starter</span>.
							You will be billed
							<span style={{ color: "#FFF", fontWeight: "600" }}>
								$29/mo + 15%
							</span>{" "}
							of cart recovery revenue generated by TxtCart.
						</Typography>
					</Box>
				</Box>
				{isMobile && (
					<PlanSelectorMobile
						setShowCardForMobile={setShowCardForMobile}
						showCardForMobile={showCardForMobile}
					/>
				)}

				<Box
					// display={"flex"}
					display={isMobile ? "block" : "flex"}
					gap={"24px"}
					mb={"24px"}
					justifyContent={"center"}
				>
					{planData?.map((item, index) => {
						return (
							<Box
								width={isMobile ? "100%" : "332px"}
								key={index}
								display={
									isMobile
										? showCardForMobile == item?.name
											? "block"
											: "none"
										: "block"
								}
								sx={{
									background: "#FFF",
									border: `1px solid ${
										recommended == item?.name ? "#7CFAB8" : "#E8EBF3"
									}`,
									borderRadius: "12px",
								}}
							>
								<Box
									sx={{
										padding: "12px",
										borderBottom: `1px solid #EBEBEB`,
									}}
								>
									<Box
										display={"flex"}
										gap={"9px"}
										alignItems={"center"}
										mb={"5px"}
									>
										<Typography
											fontSize={20}
											fontWeight={600}
											lineHeight={"normal"}
											color="#0A0A18"
										>
											{item?.name} {Icons[item?.name]}
										</Typography>
										{recommended == item?.name && <RecommendedTag />}
									</Box>
									<Box height={"40px"}>
										<Typography
											fontSize={14}
											fontWeight={500}
											lineHeight={"normal"}
											color="#0A0A18"
											sx={{
												opacity: "0.5",
											}}
											mb={"8px"}
										>
											{item?.desc}
										</Typography>
									</Box>

									<Box display={"flex"} alignItems={"baseline"} mb={"7px"}>
										<Typography
											fontSize={"32px"}
											fontWeight={"700"}
											color={"#18113C"}
											lineHeight={"normal"}
										>
											{period == "Monthly"
												? item?.priceMonthly
												: item?.priceAnnually}
										</Typography>
										<Typography
											ml={1}
											fontSize={"14px"}
											color={"#696E7E"}
											fontWeight={"400"}
											lineHeight={"22px"}
										>
											/ month
										</Typography>
									</Box>
									<Box mb={"17px"}>
										<Typography
											fontSize={"14px"}
											fontWeight={500}
											lineHeight={"normal"}
											color={"#0081FF"}
										>
											{mapDescription[item?.name]}
										</Typography>
									</Box>
									<Box>
										<Button
											fullWidth
											sx={{
												padding: "11px 18px",
												border: "1px solid #0081FF",
												backgroundColor:
													recommended == item?.name ? "#0081FF" : "transparent",
												height: "40px",
												color: recommended == item?.name ? "#FFF" : "#0081FF",
												":hover": {
													color: "#0081FF",
												},
											}}
											onClick={() => navigate("/onboarding/step-store-info")}
											variant="outlined"
										>
											<Typography fontSize={"14px"} fontWeight={"600"}>
												Start Free Trial
											</Typography>
										</Button>
									</Box>
								</Box>
								<Box p={"20px"}>
									<Typography
										color={"#18113C"}
										fontSize={14}
										fontWeight={600}
										lineHeight={"normal"}
										mb={"20px"}
									>
										{mapPlanDesc[item?.name]}
									</Typography>
									<Box>
										{item?.features?.keyFeatures?.map((item, index) => {
											return (
												<Typography
													key={index}
													fontSize={"14px"}
													fontWeight={"400"}
													color={"#696E7E"}
													mb={2}
												>
													<img
														src="/arrow/tick.svg"
														style={{
															marginRight: "10px",
														}}
													/>
													{item}
												</Typography>
											);
										})}
									</Box>
								</Box>
							</Box>
						);
					})}
				</Box>
				<Box display={"flex"} justifyContent={"center"}>
					<Typography
						fontSize={16}
						fontWeight={400}
						lineHeight={"20px"}
						color={"#969AA5"}
					>
						By selecting these plans, you agree to our{" "}
						<span style={{ color: "#FFF" }} onClick={() => {
							window.open(
								"https://www.help.txtcartapp.com/en/articles/8001573-sms-terms-of-service-template",
								"_blank"
							);
						}}>Terms of Service.</span>
					</Typography>
				</Box>
			</Box>
		</MainContainer>
	);
}

const MainContainer = ({ children, isMobile, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				padding: isMobile ? "20px 15px" : "20px 35px",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "1134px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				"::-webkit-scrollbar": {
					display: "none",
				},
				// height: "935px",
			}}
		>
			{children}
		</Box>
	);
};

const RecommendedTag = () => {
	return (
		<Box
			sx={{
				background: "#7CFAB8",
				width: "159px",
				height: "21px",
				borderRadius: "100px",
			}}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			gap={"7px"}
		>
			<Typography
				fontSize={"12px"}
				fontWeight={600}
				lineHeight={"normal"}
				color={"#18113C"}
				mt={"-2px"}
			>
				Recommended for you
			</Typography>
			<OnboardingToolTip text="" />
		</Box>
	);
};
const PlanSelectorMobile = ({ showCardForMobile, setShowCardForMobile }) => {
	const cardSelectedStyle = {
		fontSize: "14px",
		fontWeight: "600",
		color: "#FFF",
	};
	const cardNonSelectedStyle = {
		fontSize: "14px",
		fontWeight: "500",
		color: "#85858C",
	};
	return (
		<Box
			// width={"320px"}
			sx={{
				display: "flex",
				borderRadius: "4px",
				gap: "2px",
				border: "1px solid rgba(235, 235, 235, 0.15);",
				padding: "4px",
			}}
			position={"relative"}
			mb={"16px"}
		>
			{planName?.map((item, index) => {
				return (
					<Box
						key={index}
						width={"100%"}
						id="pointerCursor"
						sx={{
							background:
								showCardForMobile == item
									? "#0081FF"
									: "rgb(255, 255, 255,0.1)",
							borderRadius: "4px",
							padding: "6px 0",
							display: "flex",
							justifyContent: "center",
						}}
						onClick={() => {
							setShowCardForMobile(item);
						}}
					>
						<Typography
							style={
								showCardForMobile == item
									? cardSelectedStyle
									: cardNonSelectedStyle
							}
						>
							{item}
						</Typography>
					</Box>
				);
			})}
		</Box>
	);
};

const planName = ["Basic", "Starter"];
