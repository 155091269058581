import React from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	Checkbox,
} from "@mui/material";
import { TextInput } from "components/Inputs";

export default function AddUserModal({ open, handleClose }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Modal
			open={open}
			id="add-user"
			aria-labelledby="add-user"
			aria-describedby="add-user"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					mb={"16px"}
				>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"20px"}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Add User
					</Typography>
					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={handleClose}
						id="pointerCursor"
						style={{
							marginTop: "-10px",
						}}
					/>
				</Box>

				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						flexDirection: belowTablet ? "column" : "row",
						alignItems: "center",
						gap: "12px",
					}}
					mb={"12px"}
				>
					<Box width={"100%"}>
						<CustomTypography theme={theme}>First Name</CustomTypography>
						<TextInput placeholder="" />
					</Box>
					<Box width={"100%"}>
						<CustomTypography theme={theme}>Last Name</CustomTypography>
						<TextInput placeholder="" />
					</Box>
				</Box>
				<Box mb={"16px"}>
					<CustomTypography theme={theme}>Email</CustomTypography>
					<TextInput placeholder="" />
				</Box>
				<Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
					<Box>
						<Checkbox size="small" sx={{ padding: "0",marginTop:'-7px' }} />
					</Box>
					<Box>
						<Typography
							color={theme?.palette?.pageHeading}
							fontSize="14px"
							mb="7px"
							fontWeight={500}
							lineHeight={"normal"}
						>
							Admin
						</Typography>
						<Typography
							fontSize="12px"
							lineHeight="16px"
							color={theme?.palette?.pageHeading}
							mb="12px"
							sx={{
								opacity: "0.5",
							}}
							fontWeight={500}
						>
							Admins can add and delete users, as we ll as make other users
							admins. Admins have access to your entire account.
						</Typography>
					</Box>
				</Box>
				<Box display={"flex"} alignItems={"flex-start"} gap={"8px"} mb={'16px'}>
					<Box>
						<Checkbox size="small" sx={{ padding: "0",marginTop:'-7px' }} />
					</Box>
					<Box>
						<Typography
							color={theme?.palette?.pageHeading}
							fontSize="14px"
							mb="7px"
							fontWeight={500}
							lineHeight={"normal"}
						>
							Marketer
						</Typography>
						<Typography
							fontSize="12px"
							lineHeight="16px"
							color={theme?.palette?.pageHeading}
							sx={{
								opacity: "0.5",
							}}
							fontWeight={500}
						>
							Access to SMS Marketing tools - including campaigns, keywords,
							billing details, and more
						</Typography>
					</Box>
				</Box>

				<Box display={"flex"} justifyContent={"end"}>
					<Button
						sx={{
							padding: "11px 20px",
							border: `1px solid ${theme.palette?.card?.border}`,
							backgroundColor: "transparent",
							marginRight: "20px",
						}}
						variant="outlined"
					>
						<Typography
							fontSize={"14px"}
							fontWeight={"600"}
							color={theme.palette?.pageHeading}
							lineHeight={"normal"}
						>
							Back
						</Typography>
					</Button>
					<Button
						size={"small"}
						sx={{
							backgroundColor: "#0081FF",
							padding: "11px 20px",
							color: "#FFF",
							":hover": {
								color: mode == "light" ? "#0C0D17" : "#FFF",
							},
						}}
						variant="outlined"
					>
						<Typography fontSize={"14px"} fontWeight={"600"}>
							Save
						</Typography>
					</Button>
				</Box>
			</MainContainer>
		</Modal>
	);
}


const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "520px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
                maxHeight:'90vh',
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};

const CustomTypography = ({ children }) => {
	return (
		<Typography
			color={"#808698"}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			mb={1}
		>
			{children}
		</Typography>
	);
};
