import SecondaryButton from "components/Buttons/SecondaryButton";
import EmptyComponentTable from "components/Table/EmptyComponentTable";
import React from "react";

export default function EmptyTableData() {
	return (
		<EmptyComponentTable
			title="You have no referrals yet"
			desc="Build recurring revenue as a TxtCart Affiliate and earn 20%"
			button={<SecondaryButton>Refer a friend</SecondaryButton>}
		/>
	);
}
