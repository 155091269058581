/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import Videos from "../../components/Carousel/Videos";
import VideoModal from "components/VideoModal";

export default function Tutorials() {
	const theme = useTheme();
	const { pageHeading } = theme.palette;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [showVideoModal, setShowVideoModal] = useState(false);
	const [videoUrl, setVideoUrl] = useState("");
	return (
		<Grid container spacing={2} alignItems={"center"}>
			<Grid item xs={12} mb={"26px"}>
				<CustomHeading
					belowTablet={belowTablet}
					pageHeading={pageHeading}
					mt={2} 
				>
					How To
				</CustomHeading>
				<Box p={0}>
					<Videos setVideoUrl={setVideoUrl} setShowVideoModal={setShowVideoModal}/>
				</Box>
			</Grid>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={videoUrl}
				/>
		</Grid>
	);
}

const CustomHeading = ({ children, pageHeading, belowTablet, ...props }) => {
	return (
		<Typography
			mb={"32px"}
			fontSize={belowTablet ? "20px" : "24px"}
			fontWeight={"600"}
			color={pageHeading}
			{...props}
		>
			{children}
		</Typography>
	);
};
