import EmptyComponentTable from "components/Table/EmptyComponentTable";
import React from "react";
import { CartSVG } from "resources/Icons/Table";

export default function EmptyTableData() {
	return (
		<EmptyComponentTable
			icon={<CartSVG />}
			title="No orders yet"
			desc="But not for long, we're working to generate your first sale!"
		/>
	);
}
