/* eslint-disable no-unused-vars */
import {
	Box,
	Grid,
	useTheme,
	Typography,
	useMediaQuery,
	Button,
} from "@mui/material";
import { DataContext } from "context/DataContext";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { txtCartFetch } from "utilifyFunctions";

export default function Templates() {
	const [templates, setTemplates] = useState([]);

	const loadTemplates = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/campaign/template/recommended");
		setTemplates(response?.templates);
		console.log('templates',response);
	}

	useEffect(() => {
		loadTemplates();
	}, []);

	const theme = useTheme();

	return (
		<Box
			sx={{
				borderRadius: "12px",
			}}
		>
			<Grid container justifyContent={"space-between"}>
				{templates?.map((item, index) => {
					return (
						<>
							<Card
								key={index}
								title={item?.title}
								description={item?.description}
								img={item?.recommendedImage}
								date={item?.date}
								theme={theme}
								previewText={item?.text}
								id={item?._id}
							/>
						</>
					);
				})}
			</Grid>
		</Box>
	);
}

// const Card = ({ theme, img, title, date, description }) => {
// 	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

// 	return (
// 		<Grid
// 			// onClick={handleCardClick}
// 			container
// 			id="pointerCursor "
// 			item
// 			xs={12}
// 			sm={12}
// 			lg={2.24}
// 			md={5.8}
// 			justifyContent={"space-between"}
// 			sx={{
// 				borderRadius: "12px",
// 			}}
// 			mb={isSmall?'15px':'0px'}
// 		>
// 			<Box display={"flex"} flexDirection={"column"}>
// 				<img
// 					src={img}
// 					alt="SVG"
// 					width={"100%"}
// 					style={{
// 						borderTopLeftRadius: "12px",
// 						borderTopRightRadius: "12px",
// 					}}
// 				/>
// 				<Box
// 					sx={{
// 						background: theme.palette?.card?.background,
// 						padding: "20px",
// 						borderEndEndRadius: "12px",
// 						borderEndStartRadius: "12px",
// 						border: `1px solid ${theme.palette?.card?.border}`,
// 					}}
// 				>
// 					<Box display={"flex"} alignItems={"center"} mb={"8px"}>
// 						<img src="/img/campaigns/calender.svg" />
// 						<Typography
// 							fontSize={14}
// 							fontWeight={500}
// 							color={"#0C84FE"}
// 							lineHeight={"normal"}
// 							ml={"8px"}
// 						>
// 							{date}
// 						</Typography>
// 					</Box>
// 					<Typography
// 						fontSize={16}
// 						fontWeight={600}
// 						color={theme.palette?.pageHeading}
// 						mb={"8px"}
// 					>
// 						{title}
// 					</Typography>
// 					<Typography
// 						fontSize={14}
// 						fontWeight={500}
// 						sx={{
// 							opacity: "0.5",
// 						}}
// 						color={theme.palette?.pageHeading}
// 					>
// 						{description}
// 					</Typography>
// 				</Box>
// 			</Box>
// 		</Grid>
// 	);
// };
const Card = ({
	item,
	theme,
	img,
	title,
	date,
	description,
	category,
	previewText,
	id
}) => {
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [showPreview, setShowPreview] = useState(false);
	const navigate = useNavigate();

	const handleClick = function(id){
		navigate("/campaigns/create-campaigns?tid="+id);
	}

	return (
		<Grid
			// onClick={handleCardClick}
			container
			id="pointerCursor"
			item
			xs={12}
			sm={12}
			lg={2.24}
			md={5.8}
			justifyContent={"space-between"}
			sx={{
				borderRadius: "12px",
			}}
			mb={3}
			position={"relative"}
		>
			<Box
				display={"flex"}
				flexDirection={"column"}
				width={"100%"}
				overflow={"hidden"}
				className="campaign-planner-container campaign-page-card"
				onMouseOver={() => {
					setShowPreview(true);
				}}
				onMouseOut={() => setShowPreview(false)}
				sx={{
					background: theme.palette?.card?.background,
					// padding: "20px",
					borderRadius: "12px",
					height:'390px',
					border: `1px solid ${theme.palette?.card?.border}`,
				}}
			>
				<div className="newtest">
					<img
						src={img}
						// alt="SVG"
						width={"100%"}
						height={"auto"}
						style={{
							borderTopLeftRadius: "12px",
							borderTopRightRadius: "12px",
						}}
						className="campaign-planner-image"
					/>

					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}
						className={"campaigns-planner-tag"}
						// mb={"12px"}
						padding={"17px 20px 0px 20px"}
					>
						<Box className="tag-name">
							<Box display={"flex"} alignItems={"center"} mb={"8px"}>
								<img src="/img/campaigns/calender.svg" />
								<Typography
									fontSize={14}
									fontWeight={500}
									color={"#0C84FE"}
									lineHeight={"normal"}
									ml={"8px"}
								>
									{date}
								</Typography>
							</Box>
						</Box>
					</Box>

					<Typography
						fontSize={16}
						fontWeight={600}
						color={theme.palette?.pageHeading}
						mb={"10px"}
						padding={"0 20px"}
						className="campaign-planner-heading"
					>
						{title}
					</Typography>
					<Typography
						fontSize={14}
						fontWeight={500}
						mb={"20px"}
						sx={{
							opacity: "0.5",
							padding: "0 20px",
						}}
						className="campaign-planner-description"
						color={theme.palette?.pageHeading}
					>
						{description}
					</Typography>
				</div>

				<AnimatedBox title={title} previewText={previewText} />

				<Box
					className="here"
					padding={"0 20px 20px 20px"}
					sx={{
						position: "absolute",
						bottom: 0,
						width: "100%",
					}}
				>
					<CustomButtom
						showPreview={showPreview}
						onClick={() => {
							handleClick(id)
						}}
					>
						Customize & Schedule
					</CustomButtom>
				</Box>
			</Box>
		</Grid>
	);
};

function CustomButtom({ mode, children, showPreview, ...props }) {
	return (
		<Button
			// size={"small"}
			fullWidth
			sx={{
				padding: "11px 0px",
				border: mode == "light" ? "1px solid #0081FF" : "1px solid #0081FF",
				marginRight: "10px",
				backgroundColor: showPreview ? "#0081FF" : "transparent",
				":hover": {
					color: "#0081FF",
				},
				color: showPreview ? "#FFF" : "#0081FF",
			}}
			variant="outlined"
			{...props}
		>
			<Typography fontSize={"14px"} fontWeight={"600"}>
				{children}
			</Typography>
		</Button>
	);
}

const AnimatedBox = ({ title = "", previewText = "" }) => {
	const theme = useTheme();
	return (
		<Box className="animatedBox">
			<Typography
				fontSize={16}
				fontWeight={600}
				color={theme.palette?.pageHeading}
				mb={"10px"}
				padding={"0 20px"}
				className="campaign-planner-heading"
			>
				{title}
			</Typography>
			<Typography
				fontSize={16}
				fontWeight={600}
				color={theme.palette?.pageHeading}
				padding={"0 20px"}
				sx={{
					opacity: "0.5",
				}}
			>
				Template preview
			</Typography>
			<Box
				padding={"12px"}
				margin={"10px 20px 20px 20px"}
				bgcolor={theme.palette?.mode == "light" ? "#DBEDFF" : "#0A1E39"}
				sx={{
					borderRadius: "4px",
				}}
			>
				<Typography
					fontSize={14}
					fontWeight={400}
					lineHeight={"20px"}
					color={theme.palette?.pageHeading}
					sx={{
						opacity: 0.7,
					}}
				>
					{previewText}
				</Typography>
			</Box>
		</Box>
	);
};
