import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "utils/mixpanel";
import { PAGES } from "constants/mixPanelEvents";

export default function Card({ title, mode, description, img, action,link, isExternal, buttonName=null }) {
	const navigate=useNavigate()
	const lightMode=mode==='light'?true:false
	const handleClick=(link)=>{
		if(buttonName){
			Mixpanel.track_button_action(PAGES.HOME, buttonName);
		}
		if(link){
			if(isExternal){
				window.open(link, "_blank");
			} else{
				navigate(link)
			}
		}
	}

	return (
		<>
				<Grid item xs={12}>
					<Typography
						mb={"2px"}
						fontSize={"18px"}
						color={mode === "light" ? "#000D31" : "#EBECF2"}
						fontWeight={600}
					>
						{title}
					</Typography>
					<Typography
						fontSize={"12px"}
						mb={"0.5rem"}
						color={mode === "light" ? "#000D31" : "#EBECF2"}
						fontWeight={500}
						sx={{
							opacity: "0.5",
						}}
					>
						{description}
					</Typography>
				</Grid>
				
				<Grid
					item
					container
					borderRadius={"4px"}
					padding={"6px 0"}
					alignItems={"center"}
					id="pointerCursor"
				>
					<Button
						size="small"
						variant="outlined"
						sx={{
							padding: "7px 0px",
							width:'160px',
							border: "1px solid #0C84FE",
							color: lightMode ? "#0C84FE" : "#EBECF2",
						}}
						onClick={()=>handleClick(link)}
					>
						<Typography fontSize={"14px"} fontWeight={"600"}>
							{action}
						</Typography>
					</Button>
				</Grid>

			{img && (
				<Grid item xs={1} container alignItems={"start"} justifyContent={"end"}>
					<img src={`/features/${img}.svg`} alt={title} />
				</Grid>
			)}
		</>
	);
}
