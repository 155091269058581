import React, { useEffect, useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import PageHeading from "components/Typography/PageHeading";
import Card from "../Analytics/components/Card";
import Graph from "../Analytics/components/Graph";
import Banner from "../Analytics/components/Banner";
import TotalSales from "../Analytics/components/TotalSales";
import TraficSection from "../Analytics/components/TraficSection";
import { checkEmptyState } from "resources/helpers/checkEmptyState";
import LockedModal from "components/Modal/LockedModal";
import VideoModal from "components/VideoModal";

export default function AnalyticsLockedTemplate() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [showVideoModal, setShowVideoModal] = useState(false);
    useEffect(() => {
		document.body.style.overflow = "hidden";
		const elementsToDisableHover = document.querySelectorAll(".disableSection");
		const disableHoverClass = "disable-hover";
		elementsToDisableHover.forEach((element) => {
			element.classList.add(disableHoverClass);
		});
	}, []);

    function updateDataIfEmpty(arr) {
        if (arr.length === 0) {
            return [];
        }
        return arr.map(item => ({
            ...item,
            value: "$0", // Resetting the value to $0
            data: item.data.map(dataItem => ({ date: dataItem.date })) // Keeping the data unchanged
        }));
    }
	const [showTutorialBanner, setShowTutorialBanner] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const [graphRange, setGraphRange] = useState("Last 7 Days");
	const [emptyState, setEmptyState] = useState(false)
	const [graphData, setGraphData] = useState([
		{
			name: "Revenue Per Recipient",
			value: "$0.08",
			isIncrease: false,
			desc: "The average revenue generated by SMS per subscriber",
			byValue: "3%",
			data: [
				{
					date: "Nov 21",
					value: "5",
				},
				{
					date: "Nov 22",
					value: "10",
				},
				{
					date: "Nov 28",
					value: "15",
				},
				{
					date: "Nov 29",
					value: "7",
				},
				{
					date: "Dec 05",
					value: "25",
				},
				{
					date: "Dec 06",
					value: "35",
				},
				{
					date: "Dec 08",
					value: "32",
				},
			],
		},
		{
			name: "Cart Recovery",
			value: "$142,499.25",
			isIncrease: true,
			desc: "The average revenue generated by SMS per subscriber",
			byValue: "3%",
			data: [
				{
					date: "Nov 21",
					value: "5",
				},
				{
					date: "Nov 22",
					value: "10",
				},
				{
					date: "Nov 28",
					value: "15",
				},
				{
					date: "Nov 29",
					value: "7",
				},
				{
					date: "Dec 05",
					value: "25",
				},
				{
					date: "Dec 06",
					value: "35",
				},
				{
					date: "Dec 08",
					value: "32",
				},
			],
		},
		{
			name: "Campaigns",
			value: "$29,732.13",
			isIncrease: true,
			byValue: "3%",
			desc: "The SMS revenue generated by one-time send campaigns",
			data: [
				{
					date: "Nov 21",
					value: "10",
				},
				{
					date: "Nov 22",
					value: "5",
				},
				{
					date: "Nov 28",
					value: "20",
				},
				{
					date: "Nov 29",
					value: "15",
				},
				{
					date: "Dec 05",
					value: "5",
				},
				{
					date: "Dec 06",
					value: "10",
				},
				{
					date: "Dec 08",
					value: "20",
				},
			],
		},
		// {
		// 	name: "Flows",
		// 	value: "$112,767.12",
		// 	isIncrease: true,
		// 	byValue: "3%",
		// 	desc: "The SMS revenue generated by custom flows",
		// 	data: [
		// 		{
		// 			date: "Nov 21",
		// 			value: "3",
		// 		},
		// 		{
		// 			date: "Nov 22",
		// 			value: "6",
		// 		},
		// 		{
		// 			date: "Nov 28",
		// 			value: "14",
		// 		},
		// 		{
		// 			date: "Nov 29",
		// 			value: "10",
		// 		},
		// 		{
		// 			date: "Dec 05",
		// 			value: "23",
		// 		},
		// 		{
		// 			date: "Dec 06",
		// 			value: "30",
		// 		},
		// 		{
		// 			date: "Dec 08",
		// 			value: "40",
		// 		},
		// 	],
		// },
	]);
	const [graphDataRow2, setGraphDataRow2] = useState([
		// {
		// 	name: "Pop Ups",
		// 	value: "$15,679.46",
		// 	isIncrease: false,
		// 	byValue: "3%",
		// 	desc: "The SMS revenue generated by one-time send campaigns",
		// 	data: [
		// 		{
		// 			date: "Nov 21",
		// 			value: "5",
		// 		},
		// 		{
		// 			date: "Nov 22",
		// 			value: "10",
		// 		},
		// 		{
		// 			date: "Nov 28",
		// 			value: "15",
		// 		},
		// 		{
		// 			date: "Nov 29",
		// 			value: "7",
		// 		},
		// 		{
		// 			date: "Dec 05",
		// 			value: "25",
		// 		},
		// 		{
		// 			date: "Dec 06",
		// 			value: "35",
		// 		},
		// 		{
		// 			date: "Dec 08",
		// 			value: "32",
		// 		},
		// 	],
		// },
		// {
		// 	name: "Automations",
		// 	value: "$30.33",
		// 	isIncrease: true,
		// 	byValue: "3%",
		// 	desc: "The SMS revenue generated by custom flows",
		// 	data: [
		// 		{
		// 			date: "Nov 21",
		// 			value: "10",
		// 		},
		// 		{
		// 			date: "Nov 22",
		// 			value: "5",
		// 		},
		// 		{
		// 			date: "Nov 28",
		// 			value: "20",
		// 		},
		// 		{
		// 			date: "Nov 29",
		// 			value: "15",
		// 		},
		// 		{
		// 			date: "Dec 05",
		// 			value: "5",
		// 		},
		// 		{
		// 			date: "Dec 06",
		// 			value: "10",
		// 		},
		// 		{
		// 			date: "Dec 08",
		// 			value: "20",
		// 		},
		// 	],
		// },
	]);

	const firstRowMock = [
		{
			title: "Total Revenue",
			value: "595,852.56",
			isIncrease: true,
			byValue: "3%",
			valueType:'amount',
			desc: "The amount of revenue your brand has generated for the period across all sales channels",
		},
		{
			title: "Attributed Revenue",
			value: "142,499.25",
			valueType:'amount',
			isIncrease: false,
			byValue: "3%",
			desc: "The amount of revenue generated from SMS",
		},
	];

	const secondRowMock = [
		{
			title: "SMS Sent",
			value: "115,896",
			isIncrease: true,
			byValue: "3%",
			valueType:'number',
			desc: "The number of text messages sent to subscribers",
		},
		{
			title: "SMS Received",
			value: "18,298",
			valueType:'number',
			isIncrease: false,
			byValue: "3%",
			desc: "The number of text messages received by subscribers",
		},
		{
			title: "SMS Total",
			value: "133,194",
			valueType:'number',
			isIncrease: true,
			byValue: "3%",
			desc: "The total number of SMS sent and received",
		},
	];

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	console.log(handleClick);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleGraphRange = (type) => {
		setGraphRange(type);
		handleClose();
	};
	console.log(handleGraphRange);
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			const updatedGraphData = updateDataIfEmpty(graphData, 0);
			const updatedGraphDataRow2 = updateDataIfEmpty(graphDataRow2, 0);
			setGraphData(updatedGraphData);
			setGraphDataRow2(updatedGraphDataRow2);
			setEmptyState(true)
		}
	}, []);

	return (
        <Box 
            className="disableSection"
            sx={{
                position: "relative",
                height: "100vh",
                WebkitFilter: "blur(4px)",
            }}>
            <Grid container alignItems={"center"}>
                {showTutorialBanner && 
                    <Grid item xs={12}>
                        <Banner mode={mode} closeTab={setShowTutorialBanner} />
                    </Grid>
                }
                <Grid item xs={12} mt={"32px"} mb={'18px'}>
                    <PageHeading>Analytics</PageHeading>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "24px",
                            flexDirection: isMobile ? "column" : "row",
                        }}
                        mb={"24px"}
                    >
                        {firstRowMock.map((item, index) => {
                            return <Card cardData={item} key={index} />;
                        })}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "24px",
                            flexDirection: isMobile ? "column" : "row",
                        }}
                        mb={"24px"}
                    >
                        {secondRowMock.map((item, index) => {
                            return <Card key={index} cardData={item} />;
                        })}
                    </Box>
                    <Box mb={"24px"}>
                        <TotalSales />
                    </Box>
                    <Box mb={"24px"}>
                        <TraficSection />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "24px",
                            flexDirection: isMobile ? "column" : "row",
                        }}
                        mb={"24px"}
                    >
                        {graphData.map((item, index) => (
                            <Graph
                                key={index}
                                name={item?.name}
                                value={item?.value}
                                isIncrease={item?.isIncrease}
                                byValue={item?.byValue}
                                data={item?.data}
                                emptyState={emptyState}
                            />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "24px",
                            flexDirection: isMobile ? "column" : "row",
                        }}
                    >
                        {graphDataRow2.map((item, index) => (
                            <Graph
                                key={index}
                                name={item?.name}
                                value={item?.value}
                                isIncrease={item?.isIncrease}
                                byValue={item?.byValue}
                                data={item?.data}
                                emptyState={emptyState}
                            />
                        ))}
                    </Box>
                </Grid>
            </Grid>
            <LockedModal setShowVideoModal={setShowVideoModal} message="Unlock powerful insights into your SMS data." open={true}  mode={mode} showWatchVideo={true}/>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={"https://www.youtube.com/watch?v=qTpcfMBgqF0"}
				/>
        </Box>
	);
}
