import Switch from "react-switch";
import React from "react";

export default function ThemeSwitch({ mode, themeToggler }) {
	return (
		<Switch
			className={mode=='light'?"handle-light":'handle-dark'}
			id="newcheck"
			checked={mode == "light" ? false : true}
			onChange={themeToggler}
			checkedIcon={
				<img
					src="/topbar/icon1.svg"
					width={"60%"}
					className={`switch-icon ${mode === "light" ? "checked" : "unchecked"}`}
					
					style={{
						paddingLeft: "4px",
						paddingTop: "1px",
					}}
				/>
			}
			
			onColor="#0C84FE"
			offColor="#85868B"
			uncheckedIcon={
				<img
					src="/topbar/icon2.svg"
					width={"80%"}
					className={`switch-icon ${mode === "light" ? "unchecked" : "checked"}`}
					style={{
						paddingLeft: "10px",
						paddingTop: "3px",
					}}
				/>
			}
		/>
	);
}
