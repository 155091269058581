import React, { Component } from "react";
import { Helmet } from "react-helmet";
import OnboardingTemplate from 'templates/Onboarding'
import { DataContext } from "context/DataContext";
import { Mixpanel } from "../utils/mixpanel";
import { PAGES, EVENT_CATEGORIES } from "../constants/mixPanelEvents";

class Onboarding extends Component {
	static contextType = DataContext;

	componentDidMount() {
		Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
			page: PAGES.ONBOARDING,
		});
	}


	render() {
		return (
			<div>
				<Helmet>
					<title>TxtCart® | Onboarding</title>
				</Helmet>
				<OnboardingTemplate />
			</div>
		);
	}
}

export default Onboarding;
