import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function EmptyComponentTable({
	icon = <SVG />,
	title = "No Data",
	desc = "",
	button,
}) {
	const theme = useTheme();
	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			height={"400px"}
			textAlign={"center"}
		>
			<Box>
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
					<Box
						sx={{
							width: "88px",
							height: "88px",
							background: theme?.palette?.mode=='light'?"#E6F3FF":'#07192a',
							borderRadius: "100px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						mb={"16px"}
					>
						{icon}
					</Box>
				</Box>

				<Box mb={"12px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={24}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{title}
					</Typography>
				</Box>
				<Box mb={"22px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={14}
						fontWeight={500}
						lineHeight={"20px"}
                        sx={{
                            opacity:0.5
                        }}
					>
						{desc}
					</Typography>
				</Box>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} >
                    {button}
                </Box>
			</Box>
		</Box>
	);
}

const SVG = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="31"
			height="31"
			viewBox="0 0 31 31"
			fill="none"
		>
			<path
				d="M29.6379 1.33135C31.4538 3.10649 31.4538 5.98456 29.6379 7.7597C27.822 9.53483 24.8778 9.53483 23.0618 7.7597C21.2459 5.98456 21.2459 3.10649 23.0618 1.33135C24.8778 -0.443784 27.822 -0.443784 29.6379 1.33135Z"
				fill="#0081FF"
			/>
			<path
				d="M31 13.0352C31 12.2431 31 11.8471 30.8783 11.6607C30.7532 11.4689 30.6393 11.3887 30.4135 11.3334C30.1941 11.2796 29.7329 11.4301 28.8103 11.7312C26.1126 12.6117 23.0177 12.0021 20.8698 9.90248C18.722 7.80291 18.0984 4.77758 18.999 2.14045C19.307 1.23866 19.461 0.787773 19.406 0.573321C19.3494 0.352632 19.2673 0.241294 19.0711 0.118918C18.8804 6.3218e-07 18.4753 6.24163e-07 17.6651 6.24163e-07H8.92609C7.67841 -1.81182e-05 6.64863 -3.35846e-05 5.80979 0.066963C4.93854 0.136548 4.13766 0.28589 3.38539 0.660579C2.21879 1.24164 1.27032 2.16881 0.675906 3.3092C0.292607 4.04457 0.139833 4.82746 0.0686493 5.67914C0.000112729 6.49915 0.000128987 7.50577 0.000148758 8.72544L7.31855e-05 18.3906C-0.000594401 19.5957 -0.00109014 20.4907 0.211408 21.2659C0.784702 23.3574 2.45589 24.991 4.59545 25.5515C5.07202 25.6763 5.59281 25.7259 6.20012 25.7455L6.20011 27.8352C6.20004 28.1917 6.19997 28.555 6.22764 28.8527C6.2541 29.1375 6.32416 29.675 6.70803 30.1451C7.14975 30.686 7.81956 31.0007 8.52762 31C9.14294 30.9993 9.61606 30.7171 9.86007 30.5594C10.1152 30.3945 10.4054 30.1674 10.6901 29.9447L14.4304 27.0197C15.2342 26.3911 15.4729 26.2136 15.7209 26.0897C15.9696 25.9655 16.2344 25.8747 16.508 25.8198C16.7807 25.7651 17.0805 25.758 18.1099 25.758H22.0741C23.3218 25.758 24.3515 25.758 25.1904 25.691C26.0616 25.6214 26.8625 25.4721 27.6148 25.0974C28.7814 24.5163 29.7298 23.5892 30.3242 22.4488C30.7075 21.7134 30.8603 20.9305 30.9315 20.0788C31 19.2588 31 18.2522 31 17.0326V13.0352Z"
				fill="#0081FF"
			/>
		</svg>
	);
};
