import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { DataContext } from "context/DataContext";
import CartRecoveryTemplate from "../templates/Cart-Recovery";
import { Mixpanel } from "../utils/mixpanel";
import { PAGES, EVENT_CATEGORIES } from "../constants/mixPanelEvents";

class CartRecovery extends Component {
	static contextType = DataContext;

	componentDidMount() {
		Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
			page: PAGES.CART_RECOVERY,
		});
		this.fetchData();
	}

	fetchData = async () => {
		const { setShowLoading } = this.context;

		// Show the loader
		const isOnboarding = window.location.pathname.includes("/onboarding/");
		if (isOnboarding) {
			return;
		}
		setShowLoading(true);

		try {
			await new Promise((resolve) => setTimeout(resolve, 2000));

			setShowLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setShowLoading(false);
		}
	};

	render() {
		return (
			<div>
				<Helmet>
					<title>TxtCart® | Cart Recovery</title>
				</Helmet>
				<CartRecoveryTemplate />
			</div>
		);
	}
}

export default CartRecovery;
