import React, { useState } from "react";

import { Dropdown } from "antd";

import InputEmoji from "react-input-emoji";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { LightningSVG } from "resources/Icons/Inbox/Index";
import QuickReply from "./QuickReply";
export default function Text({ text, setText }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const textAreaHeight = 200;

	const [visible, setVisible] = useState(false);

	const handleVisibleChange = (flag) => {
		setVisible(flag);
	};
	const closeDropdown = () => {
		setVisible(false);
	};
	const handleChange = (str) => {
		setText(str);
	};

	return (
		<Box
			className="inbox-reply-box"
			width={"100%"}
			position={"relative"}
			borderRadius={isMobile ? "0px" : "12px"}
			border={`1px solid ${theme?.palette?.card?.border}`}
			bgcolor={"#FFF"}
			padding={"0px 0px 35px 0px"}
		>
			<InputEmoji
				borderColor="#FFF"
				borderRadius={isMobile ? "0px" : "12px"}
				fontSize={"14px"}
				height={textAreaHeight}
				backgroundColor={theme?.palette?.card?.background}
				value={text}
				onChange={handleChange}
				theme="auto"
				shouldReturn={(e) => e.key === 'Enter'}
				placeholder="Type your message..."
			/>

			<Box
				position={"absolute"}
				sx={{
					bottom: "8px",
					left: "45px",
				}}
			>
				<Dropdown
					overlay={<QuickReply closeDropdown={closeDropdown} />}
					visible={visible}
					onVisibleChange={handleVisibleChange}
					placement="topLeft"
					trigger={["click"]}
				>
					<Box id="pointerCursor">
						<LightningSVG />
					</Box>
				</Dropdown>
			</Box>
			<Box
				position={"absolute"}
				sx={{
					bottom: "12px",
					right: "8px",
				}}
			>
				<Button
					size={"small"}
					sx={{
						height: "40px",
						backgroundColor: "#0081FF",
						padding: "11px 18px",
						color: mode == "light" ? "#FFF" : "#FFF",
						":hover": {
							color: mode == "light" ? "#FFF" : "#FFF",
							backgroundColor: "#0081FF",
						},
						fontSize: "14px",
						fontWeight: "600",
						gap: "6px",
					}}
					variant="outlined"
				>
					{/* <SendSVG /> */}
					<img src="/icons/send-01.svg" />
					Send
				</Button>
			</Box>
		</Box>
	);
}
