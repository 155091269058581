import { Box, Grid, Typography, useTheme } from "@mui/material";

import React, { useEffect, useState } from "react";
import ShareLinkSection from "./components/ShareLinkSection";
import Analytics from "./components/Analytics";
import TableHeading from "components/Typography/TableHeading";
import Antd from "components/Table/Antd";
import TableHeader from "./components/TableHeader";
import PageHeading from "components/Typography/PageHeading";
import { affilateTableMock } from "resources/constant";
import { checkEmptyState } from "resources/helpers/checkEmptyState";
import EmptyTableData from "./components/EmptyComponentTable";

export default function Dashboard() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const [tableData, setTableData] = useState([])

	

	const columns = [
		{
			dataIndex: "number",
			key: "number",
			title: <TableHeading minWidth={"100px"}>#</TableHeading>,
			sortable: false,
			width: "25%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "storeName",
			key: "storeName",
			title: <TableHeading minWidth={"150px"}>Store Name</TableHeading>,
			sortable: false,
			width: "33%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "registerDate",
			key: "registerDate",
			title: <TableHeading minWidth={"150px"}>Date Registered</TableHeading>,
			sortable: false,
			flex: 1,
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},

		{
			key: "plan",
			dataIndex: "plan",
			title: <TableHeading minWidth={"100px"}>TxtCart Plan</TableHeading>,
			width: "150px",
			render: (params) =>
				params?.id == 0 ? (
					<Typography
						fontSize={14}
						fontWeight={500}
						color={lightMode ? "#000D31" : "#FFF"}
						maxWidth={"180px"}
					>
						{params}
					</Typography>
				) : (
					<>{showStatus(params)}</>
				),
		},
	];

	const showStatus = (value) => {
		return mapStatus[value];
	};

	const mapStatus = {
		Free: (
			<span
				className={`affiliate_plan ${
					lightMode ? "free_plan" : "free_plan_dark"
				}`}
			>
				Free
			</span>
		),
		Starter: (
			<span
				className={`affiliate_plan ${
					lightMode ? "starter_plan" : "starter_plan_dark"
				}`}
			>
				Starter
			</span>
		),
		Growth: (
			<span
				className={`affiliate_plan ${
					lightMode ? "growth_plan" : "growth_plan_dark"
				}`}
			>
				Growth
			</span>
		),
		Pro: (
			<span
				className={`affiliate_plan ${lightMode ? "pro_plan" : "pro_plan_dark"}`}
			>
				Pro
			</span>
		),
	};
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (!empty) {
			setTableData([...affilateTableMock])
		}
		
	}, []);

	return (
		<Grid container alignItems={"center"}>
			<Grid item xs={12}>
				<PageHeading mb={"19px"}>Affiliate</PageHeading>
				<ShareLinkSection />
			</Grid>
			<Grid item xs={12}>
				<Analytics />
			</Grid>
			<Grid item xs={12}>
				<Box
					id="affilate_table"
					sx={{
						borderBottom: `1px solid ${theme.palette?.card?.border}`,
						borderBottomLeftRadius: "12px",
						borderBottomRightRadius: "12px",
					}}
				>
					<Antd
						columns={columns}
						rows={tableData}
						mode={mode}
						TableHead={<TableHeader />}
						EmptyComponentTable={<EmptyTableData/>}
					/>
				</Box>
			</Grid>
		</Grid>
	);
}
