import React from "react";
import { Helmet } from "react-helmet";
import SegmentsComingSoonTemplate from "templates/ComingSoon/SegmentsComingSoonTemplate";

export default function Flow() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | Segment</title>
			</Helmet>
			<SegmentsComingSoonTemplate />
		</div>
	);
}
