import React from "react";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function ConversationModal({
	openModal,
	handleCloseModal,
	lightMode,
	isSmall,
    modalContent,
	recipient
}) {
	const CustomMessageBox = ({ text = "" }) => {
		return (
			<Box mb={2}>
				<Typography
					className="custom-message message"
					// width={"200px"}
					width={isSmall ? "80%" : "212px"}
					padding={"8px 15px"}
					sx={{
						wordWrap: "break-word",
						"::after": {
							backgroundColor: lightMode ? "#f9fbff" : "#2f3035",
						},
						"::before": {
							backgroundColor: lightMode ? "rgb(227 238 255)" : "#2e3748",
						},
					}}
					color={lightMode ? "#000D31" : "#FFF"}
					fontSize={isSmall ? "10px" : "14px"}
				>
					{text}
				</Typography>
			</Box>
		);
	};

	const CustomReplyBox = ({ text = "" }) => {
		return (
			<Box mb={2}>
				<Typography
					className="custom-message reply"
					width={isSmall ? "80%" : "218px"}
					color={lightMode ? "#000D31" : "#FFF"}
					padding={"8px 15px"}
					sx={{
						"::after": {
							backgroundColor: lightMode ? "#f9fbff" : "#2f3035",
						},
						"::before": {
							backgroundColor: lightMode ? "#c9ced9" : "#262834",
						},
					}}
					fontSize={isSmall ? "10px" : "14px"}
				>
					{text}
				</Typography>
			</Box>
		);
	};
	return (
		<Dialog
			open={openModal}
			onClose={handleCloseModal}
			maxWidth={462}
			className="hereknow"
			sx={{
				"& .MuiDialog-paperWidth462": {
					width: "462px",
					borderRadius: "12px",
					border: lightMode ? "1px solid #E8EBF3" : "1px solid #1E202D",
				},
			}}
		>
			<DialogTitle
				sx={{
					paddingBottom: "0",
				}}
			>
				<Typography
					fontSize={isSmall ? "14px" : "20px"}
					fontWeight={600}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					Conversation - {recipient}
				</Typography>
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleCloseModal}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<Box
					mb={2}
					sx={{
						position: "relative",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<img
							src="/iphone.svg"
							style={{
								width: isSmall && "85%",
								// zIndex:'9999'
							}}
						/>
					</Box>
					<Box display={"flex"} justifyContent={"center"}>
						<Box
							sx={{
								overflow: "hidden",
								overflowY: "scroll",
								position: "absolute",
								height: "87%",
								top: isSmall ? "30px" : "40px",
								marginLeft: isSmall && "12%",
								padding: isSmall ? "10px 71px" : "10px 90px",
								scrollbarWidth: "none",
								"&::-webkit-scrollbar": {
								display: "none",
								},
								"-webkit-overflow-scrolling": "touch",
							}}
						>
							{modalContent.map(function(message, i){
								return message.txtCartSent ? (
									<CustomMessageBox text={message.message} />
								) : (
									<CustomReplyBox text={message.message} />
								)
							})}
						</Box>
					</Box>
				</Box>
				{/* <Box display={"flex"} justifyContent={"center"}>
					<Button
						size="small"
						variant="outline"
						maxWidth="296px"
						sx={{
							border: "1px solid #EBEBEB;",
							padding: "10px 30px",
							width: isSmall ? "205px" : "296px",
							maxWidth: isSmall ? "205px" : "296px",
						}}
					>
						<img src="/icons/send.svg" alt="send" width={"15px"} />
						<Typography
							fontSize={isSmall ? "10px" : "14px"}
							ml={2}
							fontWeight={600}
						>
							{" "}
							Reply to message
						</Typography>
					</Button>
				</Box> */}
			</DialogContent>
		</Dialog>
	);
}