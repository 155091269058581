import React from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { SidebarNav } from "./components";
import { useTheme } from "@mui/material/styles";

const Sidebar = ({ pages, open, variant, onClose,isMd,handleOpen, plan }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	return (
		<Drawer
			anchor="left"
			onClose={() => onClose()}
			open={open}
			variant={variant}
			sx={{
				"& .MuiPaper-root": {
					width: "100%",
					maxWidth: "250px",
					height: "100%",
					overflow:'hidden',
					borderRight:mode=='light'&&'none'
				},
			}}
		>
			<Box
				position={"absolute"}
				height={'100vh'}
				sx={{
					paddingTop: { xs: 1, sm: 1 },
				}}
				bgcolor={theme.palette?.sidebar}
				color={mode === "light" ? "#18213c" : "#222B45"}
			>
				<SidebarNav pages={pages} onClose={onClose}  isMd={isMd} handleOpen={handleOpen}/>
			</Box>
		</Drawer>
	);
};

Sidebar.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
	variant: PropTypes.string.isRequired,
	pages: PropTypes.array.isRequired,
};

export default Sidebar;
