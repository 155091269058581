/* eslint-disable no-unused-vars */
import {
	Box,
	Drawer,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
// import { Grid } from "antd";
import React, { useEffect, useState } from "react";
import Contacts from "./Sections/Contacts";
import ChatSection from "./Sections/ChatSection";
import CustomerDetails from "./Sections/CustomerDetails";
import { CustomerDetailsSVG, InfoChatSVG } from "resources/Icons/Inbox/Index";
import { checkEmptyState } from "resources/helpers/checkEmptyState";
import EmptyState from "./components/EmptyState";

export default function Inbox() {
	const theme = useTheme();
	const [open, setOpen] = React.useState(true);
	const handleOpen = () => setOpen(true);
	const handleSidebarClose = () => {
		setOpenSidebar(false);
	};
	const isMd = useMediaQuery(theme.breakpoints.up("md"), {
		defaultMatches: true,
	});
	const [openSidebar, setOpenSidebar] = useState(false);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [activeTab, setActiveTab] = useState("Chat");
	const [emptyState, setEmptyState] = useState(false);

	useEffect(() => {
		document.body.style.overflow = "hidden";
		document.body.classList.add("hide-scrollbar");
		return () => {
			document.body.classList.remove("hide-scrollbar");
		};
	}, []);
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			setEmptyState(true);
		}
	}, []);
	return emptyState ? (
		<EmptyState />
	) : (
		<React.Fragment>
			{isMobile && (
				<TabSelector activeTab={activeTab} setActiveTab={setActiveTab} />
			)}
			<Box display={"flex"} overflow={"hidden"}>
				<Contacts activeTab={activeTab} />
				<ChatSection activeTab={activeTab} />
				<CustomerDetails activeTab={activeTab} />
			</Box>
		</React.Fragment>
	);
}

const TabSelector = ({ activeTab, setActiveTab }) => {
	const theme = useTheme();
	return (
		<Box
			display={"flex"}
			bgcolor={theme?.palette?.card?.background}
			// position={'fixed'}
			// border={`1px solid ${theme?.palette?.card?.border}`}
		>
			<Box
				width={"50%"}
				p={"15px 0px"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				bgcolor={activeTab == "Info" && "#0081FF"}
				border={`1px solid ${
					activeTab == "Info" ? "#0081FF" : theme?.palette?.card?.border
				}`}
				onClick={() => {
					if (activeTab == "Info") {
						setActiveTab("Chat");
					} else {
						setActiveTab("Info");
					}
				}}
			>
				<Box display={"flex"} alignItems={"center"} gap={"5px"}>
					<InfoChatSVG isActive={activeTab == "Info" ? true : false} />
					<Typography
						color={activeTab == "Info" ? "#FFF" : theme?.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Info Chat
					</Typography>
				</Box>
			</Box>
			<Box
				width={"50%"}
				p={"15px 0px"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				bgcolor={activeTab == "Customer" && "#0081FF"}
				borderLeft={"none"}
				border={`1px solid ${
					activeTab == "Customer" ? "#0081FF" : theme?.palette?.card?.border
				}`}
				onClick={() => {
					if (activeTab == "Customer") {
						setActiveTab("Chat");
					} else {
						setActiveTab("Customer");
					}
				}}
			>
				<Box display={"flex"} alignItems={"center"} gap={"5px"}>
					<CustomerDetailsSVG
						isActive={activeTab == "Customer" ? true : false}
					/>
					<Typography
						color={
							activeTab == "Customer" ? "#FFF" : theme?.palette?.pageHeading
						}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Customer Details
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
