import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import CancelPlan from "components/Modal/Settings/CancelPlan";
import ManagePlanModal from "components/Modal/Settings/ManagePlanModal";
import MessageReatesModal from "components/Modal/Settings/MessageReatesModal";
import SettingsTable from "components/Table/SettingsTable";
import TableHeading from "components/Typography/TableHeading";
import React, { useState, useEffect } from "react";
import { Mixpanel } from "../../../utils/mixpanel";
import { PAGES, BUTTONS, EVENT_CATEGORIES } from "../../../constants/mixPanelEvents";

export default function Billing({ settings, period, skus, currentSKU }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const [cancelPlanModal, setCancelPlanModal] = useState(false);
	const [showMessageRates, setShowMessageRates] = useState(false);
	const [email, setEmail] = useState('');
	const [currentPlan, setCurrentPlan] = useState('');
	const [currentRecurring, setCurrentRecurring] = useState('');
	const [commission, setCommission] = useState('');

	useEffect(() => {
		Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
			page: PAGES.BILLING,
		});
	}, []);

	useEffect(() => {
		setEmail(settings.email);
		setCurrentPlan(currentSKU.displayName);
		setCurrentRecurring(settings.recurringFee);
		setCommission(settings.commission);
	}, [settings, currentSKU]);

	
const [showManagePlan, setShowManagePlan] = useState(false)
	const columns = [
		{
			dataIndex: "billingPeriod",
			key: "billingPeriod",
			title: <TableHeading minWidth={"150px"}>Billing Period</TableHeading>,
			sortable: false,
			width: "48%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					lineHeight={"normal"}
				>
					{params}
				</Typography>
			),
		},

		{
			dataIndex: "plan",
			key: "plan",
			title: <TableHeading minWidth={"150px"}>Plan</TableHeading>,
			sortable: false,
			width: "48%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={600}
					color={"#0C84FE"}
					lineHeight={"normal"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "subscriptionStatus",
			key: "subscriptionStatus",
			title: (
				<TableHeading minWidth={"200px"}>Subscription Status</TableHeading>
			),
			sortable: false,
			width: "48%",
			render: (params) => (
				<span
					className={`subscription-status-${lightMode ? params : params}`}
					style={{
						padding: "4px 14px",
						borderRadius: "4px",
						fontWeight: "550",
						fontSize: "12px",
					}}
				>
					{params}
				</span>
			),
		},
		{
			dataIndex: "type",
			key: "type",
			title: <TableHeading minWidth={"150px"}>Type</TableHeading>,
			sortable: false,
			width: "48%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					lineHeight={"normal"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "amount",
			key: "amount",
			title: <TableHeading minWidth={"150px"}>Amount</TableHeading>,
			sortable: false,
			width: "48%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					lineHeight={"normal"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "status",
			key: "status",
			title: <TableHeading minWidth={"200px"}>Status</TableHeading>,
			sortable: false,
			width: "48%",
			render: (params) => (
				<span
					className={`billing-status-${lightMode ? params : params + "-dark"}`}
					style={{
						padding: "4px 14px",
						borderRadius: "4px",
						fontWeight: "550",
						fontSize: "12px",
					}}
				>
					{params}
				</span>
			),
		},
	];

	const dataMock = [
		{
			billingPeriod: "Nov 17 - Dec 17, 2023",
			plan: "Starter",
			subscriptionStatus: "Active",
			type: "Recurring",
			amount: "$29.00",
			status: "Paid",
		},
		{
			billingPeriod: "Nov 17 - Dec 17, 2023",
			plan: "Starter",
			subscriptionStatus: "Active",
			type: "Commission",
			amount: "$$36.34",
			status: "Pending",
		},
		{
			billingPeriod: "Nov 17 - Dec 17, 2023",
			plan: "Starter",
			subscriptionStatus: "Cancelled",
			type: "Carrier Fee",
			amount: "$6.00",
			status: "Posted",
		},
	];
	return (
		<React.Fragment>
			<Box
				// p={"20px"}
				sx={{
					borderRadius: "12px",
					border: `1px solid ${theme?.palette?.card?.border}`,
					backgroundColor: theme?.palette?.card?.background,
					flexDirection: { xs: "column", md: "row" },
					display: "flex",
					justifyContent: "space-between",
				}}
				display={"flex"}
				justifyContent={"space-between"}
			>
				<Box
					width={{ xs: "100%", md: "48%" }}
					p={"20px"}
					sx={{
						borderRight: `1px solid ${theme?.palette?.card?.border}`,
					}}
				>
					<Typography
						// p={"20px 0px 0px 20px"}
						fontSize={"16px"}
						fontWeight={600}
						color={theme?.palette?.pageHeading}
						mb={"10px"}
					>
						Billing Email
					</Typography>
					<CustomLabel>{email}</CustomLabel>
				</Box>
				<Box
					display={"flex"}
					flexDirection={{ xs: "column", md: "row" }}
					flexWrap="wrap" 
					p={"20px"}
					justifyContent={"space-between"}
					width={"100%"}
					alignItems={"center"}
				>
					<Box>
						<Typography
							// p={"20px 0px 0px 20px"}
							fontSize={"16px"}
							fontWeight={600}
							color={theme?.palette?.pageHeading}
							mb={"10px"}
						>
							Current Plan: {currentPlan}
						</Typography>
						<CustomLabel>${period === 'monthly' ? parseFloat(currentRecurring).toFixed(2) : parseFloat(currentRecurring * 12).toFixed(2)}{(period === "monthly" ? "/mo" : "/yr")} + {commission}% of Recovered Sales</CustomLabel>
					</Box>
					<Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} flexWrap="wrap">
						<Button
							size={"small"}
							disableRipple
							sx={{
								height: "40px",
								backgroundColor: theme?.palette?.card?.background,
								border: "none",
								padding: "11px 20px",
								// color: mode == "light" ? "#FFF" : "#FFF",
								":hover": {
									// 	color: mode == "light" ? "#0C0D17" : "#FFF",
									background: "transparent",
									border: "none",
								},
								fontSize: "14px",
								fontWeight: "600",
							}}
							variant="outlined"
							onClick={()=>{
								setShowMessageRates(true);
								Mixpanel.track_button_action(PAGES.SETTINGS, BUTTONS.VIEW_MESSAGE_RATES);
							}}
						>

							View Per Message Rates
						</Button>
						<Button
							size={"small"}
							sx={{
								backgroundColor: "#0081FF",
								padding: "11px 20px",
								height: "40px",
								color: "#FFF",
								":hover": {
									color: mode === "light" ? "#0C0D17" : "#FFF",
								},
							}}
							variant="outlined"
							onClick={()=>{
									setShowManagePlan(true);
									Mixpanel.track_button_action(PAGES.SETTINGS, BUTTONS.MANAGE_PLANS);
								}
						}
						>
							<Typography fontSize={"14px"} fontWeight={"600"}>
								Manage Plans
							</Typography>
						</Button>
						<Button
							size={"small"}
							disableRipple
							sx={{
								backgroundColor: "transparent",
								padding: "11px 20px",
								border: "none",
								height: "40px",
								color: "#B7B7B7",
								":hover": {
									// color: mode == "light" ? "#0C0D17" : "#FFF",
									background: "transparent",
									border: "none",
								},
							}}
							variant="outlined"
							onClick={() => {
								setCancelPlanModal(true);
								Mixpanel.track_button_action(PAGES.SETTINGS, BUTTONS.CANCEL_PLAN);
							}}
						>
							<Typography fontSize={"14px"} fontWeight={"600"}>
								Cancel Plan
							</Typography>
						</Button>
					</Box>
				</Box>
			</Box>
			{/* <Box
				mt={"24px"}
				p={"20px"}
				sx={{
					borderRadius: "12px",
					border: `1px solid ${theme?.palette?.card?.border}`,
					backgroundColor: theme?.palette?.card?.background,
				}}
			>
				<Typography
					fontSize={"20px"}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
					mb={"21px"}
				>
					Past Invoices
				</Typography>
				<SettingsTable columns={columns} rows={dataMock} border={true} />
			</Box> */}
			<CancelPlan
				open={cancelPlanModal}
				handleClose={() => setCancelPlanModal(false)}
				settings={settings}
			/>
			<MessageReatesModal
				open={showMessageRates}
				handleClose={() => setShowMessageRates(false)}
			/>
			<ManagePlanModal 
			open={showManagePlan}
			handleClose={()=>{setShowManagePlan(false)}}
			skus={skus}
			currentSKU={currentSKU}
			currentPeriod={period}
			/>
		</React.Fragment>
	);
}

const CustomLabel = ({ children, ...props }) => {
	return (
		<Typography
			fontSize={14}
			fontWeight={500}
			color={"#808698"}
			mb={"8px"}
			{...props}
		>
			{children}
		</Typography>
	);
};
