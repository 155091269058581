export const light = {
	alternate: {
		main: "#f7faff",
		dark: "#edf1f7",
	},
	sidebar: "#041328",
	// cardShadow: "rgba(23, 70, 161, .11)",
	mode: "light",
	primary: {
		main: "#1976d2",
		light: "#467de3",
		dark: "#2f6ad9",
		contrastText: "#fff",
	},
	secondary: {
		light: "#ffb74d",
		main: "#f9b934",
		dark: "#FF9800",
		contrastText: "rgba(0, 0, 0, 0.87)",
	},
	text: {
		primary: "#fffff",
		secondary: "#fffff",
	},
	divider: "rgba(0, 0, 0, 0.12)",
	background: {
		paper: "#F9FBFF",
		default: "#F9FBFF",
		level2: "#f5f5f5",
		level1: "#ffffff",
	},
	card: {
		background: "#fff",
		color: "#000D31",
		border:'#E8EBF3'
	},
	topbar: {
		background: "#ffff",
	},
	pageHeading:'#000D31'
};

export const dark = {
	alternate: {
		main: "#1a2138",
		dark: "#151a30",
	},
	sidebar: "#0F111B",
	cardShadow: "rgba(0, 0, 0, .11)",
	common: {
		black: "#000",
		white: "#fff",
	},
	mode: "dark",
	primary: {
		main: "#1976d2",
		light: "#2196f3",
		dark: "#0d47a1",
		contrastText: "#fff",
	},
	secondary: {
		light: "#FFEA41",
		main: "#FFE102",
		dark: "#DBBE01",
		contrastText: "rgba(0, 0, 0, 0.87)",
	},
	text: {
		primary: "#EBECF2",
		secondary: "#AEB0B4",
	},
	divider: "rgba(255, 255, 255, 0.12)",
	background: {
		paper: "#07080F",
		default: "#07080F",
		level2: "#333",
		level1: "#2D3748",
	},
	card: {
		background: "#0F111B",
		color: "#FFF",
		border:'#1E202D'
	},
	topbar: {
		background: "#0C0D17",
	},
	pageHeading:'#FFF'
};
