import React from "react";
import { Helmet } from "react-helmet";
import ListsComingSoonTemplate from "templates/ComingSoon/ListsComingSoonTemplate";

export default function Flow() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | List</title>
			</Helmet>
			<ListsComingSoonTemplate />
		</div>
	);
}
