import React from 'react'
import { Helmet } from 'react-helmet';
import CampaignPlannerLockedTemplate from 'templates/LockedPages/Campaign-Planner'

export default function CampaignPlannerLocked() {
    return (
		<div>
			<Helmet>
				<title>TxtCart® | Campaign Planner</title>
			</Helmet>
			<CampaignPlannerLockedTemplate />
		</div>
	);
}
