/* eslint-disable react/no-unknown-property */
import React from 'react'

export default function Cart() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.50002H4.59082L5.93466 26.0015C5.9451 26.1697 5.95771 26.3726 5.98321 26.5513C6.013 26.7599 6.07416 27.0538 6.24636 27.3581C6.47091 27.7549 6.81074 28.0742 7.22083 28.2736C7.53529 28.4264 7.83237 28.4692 8.04246 28.4859C8.2224 28.5002 8.42574 28.5001 8.59428 28.5L28.5 28.5C29.3284 28.5 30 27.8284 30 27C30 26.1716 29.3284 25.5 28.5 25.5H8.90918L8.72168 22.5H27.2577C27.9712 22.5 28.5907 22.5001 29.1048 22.4601C29.6503 22.4177 30.192 22.3241 30.7236 22.0751C31.5227 21.7009 32.194 21.1 32.6543 20.3471C32.9604 19.8463 33.1132 19.3182 33.2156 18.7807C33.3121 18.2743 33.3805 17.6587 33.4593 16.9497L34.3469 8.96082C34.3689 8.76394 34.3946 8.53322 34.3998 8.32997C34.4058 8.09815 34.3928 7.75487 34.2398 7.38674C34.0444 6.91647 33.6953 6.52639 33.2495 6.28026C32.9005 6.08759 32.5608 6.03677 32.3297 6.0171C32.1271 5.99985 31.895 5.99994 31.6969 6.00001L7.69043 6.00002L7.56535 3.99857C7.5549 3.83037 7.54229 3.62742 7.51679 3.44874C7.48701 3.2401 7.42584 2.94626 7.25365 2.64195C7.02909 2.2451 6.68926 1.92584 6.27917 1.72648C5.96471 1.5736 5.66763 1.53088 5.45754 1.51417C5.27761 1.49986 5.07429 1.49994 4.90576 1.50001L3 1.50002C2.17157 1.50002 1.5 2.17159 1.5 3.00002C1.5 3.82844 2.17157 4.50002 3 4.50002Z" fill="#3472FA"/>
  <path d="M11.25 30C10.0074 30 9 31.0074 9 32.25C9 33.4926 10.0074 34.5 11.25 34.5C12.4926 34.5 13.5 33.4926 13.5 32.25C13.5 31.0074 12.4926 30 11.25 30Z" fill="#3472FA"/>
  <path d="M24.75 30C23.5074 30 22.5 31.0074 22.5 32.25C22.5 33.4926 23.5074 34.5 24.75 34.5C25.9926 34.5 27 33.4926 27 32.25C27 31.0074 25.9926 30 24.75 30Z" fill="#3472FA"/>
</svg>
  )
}
