import SecondaryButton from "components/Buttons/SecondaryButton";
import EmptyComponentTable from "components/Table/EmptyComponentTable";
import React from "react";
import { BarSVG } from "resources/Icons/Table";

export default function EmptyTableData() {
	return (
		<EmptyComponentTable
			icon={<BarSVG />}
			title="You have no flows yet"
			desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
			button={<SecondaryButton>Lorem ipsum</SecondaryButton>}
		/>
	);
}
