import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import Antd from "components/Table/Antd";
import TableHeading from "components/Typography/TableHeading";
import React, { useState } from "react";
import { EmptyTableDataProduct } from "./RecoveredProducts";

export default function AbandonedProducts({data}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;

	const columns = [
		{
			dataIndex: "sn",
			key: "sn",
			title: (
				<TableHeading minWidth={"10px"} maxWidth={"10px"}>
					#
				</TableHeading>
			),
			sortable: false,
			width: "1%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "title",
			key: "title",
			title: <TableHeading minWidth={"120px"}>Product</TableHeading>,
			sortable: false,
			width: "25%",
			render: (params,row) => (
				<Box display={"flex"} gap={"10px"} alignItems={"center"}>
					<img style={{height: "40px", width:"40px"}} src={row.image ? row.image : "/img/analytics/product-image.svg"} />
					<Typography
						fontSize={14}
						fontWeight={500}
						color={lightMode ? "#000D31" : "#FFF"}
					>
						{params}
					</Typography>
				</Box>
			),
		},
		{
			dataIndex: "count",
			key: "count",
			title: <TableHeading minWidth={"120px"}>Abandonments</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.recoveries)- parseInt(b.recoveries),
			width: "20%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "percent",
			key: "percent",
			title: <TableHeading minWidth={"60px"}>% &nbsp;Abandoned</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.recovered)- parseInt(b.recovered),
			width: "7%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
	];

	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={`1px solid ${theme.palette?.card?.border}`}
			borderRadius={"12px"}
			width={"100%"}
		>
			<Antd
				mode={mode}
				columns={columns}
				rows={data}
				EmptyComponentTable={<EmptyTableDataProduct />}
				page="cart-recovery"
			/>
		</Box>
	);
}
