/* eslint-disable react/no-unknown-property */
import React from "react";

export default function Error() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="43"
			viewBox="0 0 48 43"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M25.7315 0.370841C24.6292 -0.123614 23.3708 -0.123614 22.2685 0.370841C21.4172 0.752714 20.8642 1.42535 20.4794 1.97343C20.101 2.51231 19.6959 3.21827 19.2504 3.99467L1.63483 34.6895C1.18755 35.4688 0.781095 36.1769 0.505496 36.7778C0.225404 37.3886 -0.0784569 38.2096 0.0183855 39.1467C0.143699 40.3592 0.773436 41.4611 1.75086 42.178C2.50622 42.7321 3.36279 42.8781 4.02709 42.9396C4.68071 43.0001 5.49181 43.0001 6.38437 43H41.6155C42.5081 43.0001 43.3193 43.0001 43.9729 42.9396C44.6372 42.8781 45.4938 42.7321 46.2491 42.178C47.2266 41.4611 47.8563 40.3592 47.9816 39.1467C48.0785 38.2096 47.7746 37.3886 47.4945 36.7778C47.2189 36.1769 46.8125 35.4689 46.3653 34.6897L28.7495 3.9946C28.304 3.21824 27.899 2.51228 27.5206 1.97343C27.1358 1.42535 26.5828 0.752714 25.7315 0.370841ZM26.1309 16.1306C26.1309 14.9434 25.1769 13.981 24.0001 13.981C22.8233 13.981 21.8693 14.9434 21.8693 16.1306V24.7288C21.8693 25.916 22.8233 26.8784 24.0001 26.8784C25.1769 26.8784 26.1309 25.916 26.1309 24.7288V16.1306ZM24.0001 31.1775C22.8233 31.1775 21.8693 32.1399 21.8693 33.327C21.8693 34.5142 22.8233 35.4766 24.0001 35.4766H24.0214C25.1982 35.4766 26.1522 34.5142 26.1522 33.327C26.1522 32.1399 25.1982 31.1775 24.0214 31.1775H24.0001Z"
				fill="#FE720C"
			/>
		</svg>
	);
}
