/* eslint-disable react/no-unknown-property */
import React from "react";

export default function AbandonedCheckout() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<g clip-path="url(#clip0_5049_13269)">
				<path
					d="M1 1C0.447715 1 0 1.44772 0 2C0 2.55229 0.447715 3 1 3H2.7793C2.92489 3 3.0668 3.04581 3.18491 3.13095C3.30302 3.21608 3.39135 3.33622 3.43738 3.47435L6.73438 13.3672C6.88079 13.8064 6.8994 14.2793 6.78711 14.7285L6.51562 15.8164C6.11728 17.4098 7.35758 19 9 19H21C21.5523 19 22 18.5523 22 18C22 17.4477 21.5523 17 21 17H9C8.61061 17 8.36064 16.6805 8.45508 16.3027L8.65981 15.485C8.6945 15.3465 8.7745 15.2235 8.88709 15.1356C8.99969 15.0477 9.13842 15 9.28125 15H20C20.4306 15.0002 20.813 14.7248 20.9492 14.3164L23.6152 6.31641C23.8312 5.66862 23.3489 4.99969 22.666 5H6.55469C6.40909 5 6.26718 4.95419 6.14907 4.86906C6.03095 4.78392 5.94262 4.66379 5.89657 4.52566L4.94922 1.68359C4.81295 1.27515 4.43057 0.999775 4 1H1ZM8 20C6.89543 20 6 20.8954 6 22C6 23.1046 6.89543 24 8 24C9.10457 24 10 23.1046 10 22C10 20.8954 9.10457 20 8 20ZM20 20C18.8954 20 18 20.8954 18 22C18 23.1046 18.8954 24 20 24C21.1046 24 22 23.1046 22 22C22 20.8954 21.1046 20 20 20Z"
					fill="#FF465C"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5049_13269">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
