import React from "react";
import { Box } from "@mui/material";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
export default function CircularProgress({mode,isLargeScreen, value, text}) {
	let textColor=mode=='light'?"#0C84FE":'#EBECF2'
	return (
		<Box padding={isLargeScreen?"10px 20%":"10px 30%"} display={"flex"} justifyContent={"center"}>
			<CircularProgressbar
				styles={buildStyles({
					strokeLinecap: "butt",
					textSize:'20px',
					textColor:textColor,
					pathTransitionDuration: 0.5,
					pathColor: `#0C84FE`,
					trailColor: "##1E202D",
				})}
				className="custom-progress-bar"
				value={value}
				text={text}
				strokeWidth={8}
			/>
		</Box>
	);
}
