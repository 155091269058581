import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { CustomSubHeading } from "./EINTab";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function VerificationTab({ setTab, handleSubmit, formData }) {
	const theme = useTheme();

	return (
		<Box>
			<FixContainer mb={4}>
				<CustomSubHeading theme={theme}>
					To begin verification, select Submit. Please note the following:
				</CustomSubHeading>
			</FixContainer>
			<FixContainer mb={2}>
				<Box display={"flex"} alignItems={"center"} mb={2} mt={2}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
					/>
					<CustomSubHeading theme={theme} mb={0}>
						{formData.segmentsCount === "6,000 to 220,000" ? ("You will receive a one time charge of $59 for verification")
							: ("You will receive a one time charge of $19 for verification")
						}
					</CustomSubHeading>
				</Box>

				{
					formData.hasEIN === "Yes" ? (
						<Box display={"flex"} alignItems={"center"} mb={2} mt={2}>
						<img
							src="/arrow/tick.svg"
							style={{
								marginRight: "10px",
							}}
						/>
						<CustomSubHeading theme={theme} mb={0}>
							Due to a high demand for verification, approval may be delayed.
							During this time your previous phone number will be used to continue
							messaging.
						</CustomSubHeading>
					</Box>
					) : (
						<>
							<Box display={"flex"} alignItems={"center"} mb={2} mt={2}>
							<img
								src="/arrow/tick.svg"
								style={{
									marginRight: "10px",
								}}
							/>
							<CustomSubHeading theme={theme} mb={0}>
							You will receive a confirmation message to your SMS capable phone
							</CustomSubHeading>
						</Box>
							<Box display={"flex"} alignItems={"center"} mb={2} mt={2}>
							<img
								src="/arrow/tick.svg"
								style={{
									marginRight: "10px",
								}}
							/>
							<CustomSubHeading theme={theme} mb={0}>
							You will have 24 hours to respond to the confirmation message
							</CustomSubHeading>
						</Box>
						</>
					)
				}
			</FixContainer>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton
					onClick={() => {
						handleSubmit();
					}}
				>
					Submit
				</PrimaryButton>
			</Box>
		</Box>
	);
}

export const FixContainer = ({ children, ...props }) => {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Box
			width={belowTablet ? "100%" : "100%"}
			minWidth={belowTablet ? "100%" : "100%"}
			maxWidth={belowTablet ? "100%" : "100%"}
			{...props}
		>
			{children}
		</Box>
	);
};