import React from "react";
import { Helmet } from "react-helmet";
import FlowComingSoonTemplate from "templates/ComingSoon/FlowComingSoonTemplate";

export default function Flow() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | Flow</title>
			</Helmet>
			<FlowComingSoonTemplate />
		</div>
	);
}
