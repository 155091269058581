import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

export default function TableHeader() {
	const theme = useTheme();
	return (
		<Box
			sx={{
				borderRadius: "12px 12px 0px 0px",
				borderTop: `1px solid ${theme.palette?.card?.border}`,
				borderRight: `1px solid ${theme.palette?.card?.border}`,
				borderLeft: `1px solid ${theme.palette?.card?.border}`,
				borderBottom: `none`,
				padding: "20px 0px 0px 0px",
				// border: `1px solid ${theme.palette?.card?.border}`,
				background: theme.palette?.card?.background,
			}}
			pb={"24px"}
		>
			<Typography
				lineHeight={"normal"}
				fontSize={"20px"}
				pl={"25px"}
				fontWeight={600}
				color={theme.palette?.card?.color}
				pb={"19px"}
			>
				Referred Stores
			</Typography>
		</Box>
	);
}
