import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
	CustomMenuItem,
	CustomSelect,
	CustomSubHeading,
	CustomTypography,
} from "./EINTab";
import { Input } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function ContactInformationTab({ setTab, handleFormData, formData }) {
	const theme = useTheme();
	const [valid, setValid] = useState(false);

	const validateForm = function(){
		if(formData.contactBusinessTitle != null && formData.contactJobPosition != null && formData.contactPhone && formData.contactPersonalEmail != null
				&& formData.contactFirstName != null && formData.contactLastName != null){
			setValid(true);
		} else{
			setValid(false);
		}
	}

	useEffect(() => {
		validateForm();
	}, [formData]);
	return (
		<Box>
			<FixContainer mb={2}>
				<CustomSubHeading theme={theme}>
					Please populate your individual contact information.
				</CustomSubHeading>
				<CustomTypography theme={theme}>Business Title:</CustomTypography>
				<CustomSubHeading theme={theme}>
					Your personal job title.
				</CustomSubHeading>
				<CustomInput name="contactBusinessTitle" onChange={e=>handleFormData(e.target?.name, e.target?.value)} value={formData?.contactBusinessTitle}/>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Job Position</CustomTypography>
				<CustomSelect  name="contactJobPosition" onChange={e=>handleFormData(e.target?.name, e.target?.value)} value={formData?.contactJobPosition}>
					{jobPostionMock?.map((item, index) => {
						return (
							<CustomMenuItem key={index} value={item?.title}>
								{item?.title}
							</CustomMenuItem>
						);
					})}
				</CustomSelect>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Phone Number:</CustomTypography>
				<CustomSubHeading theme={theme}>
					A phone number you can be contacted at if necessary. Ensure this is a
					properly formatted number. US Example: +12223334444
				</CustomSubHeading>
				<CustomInput name="contactPhone" onChange={e=>handleFormData(e.target?.name, e.target?.value)} value={formData?.contactPhone}/>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Contact Email:</CustomTypography>
				<CustomSubHeading theme={theme}>
					An email you can be contacted it. This may be different than your
					company&apos;s email.
				</CustomSubHeading>
				<CustomInput name="contactPersonalEmail" onChange={e=>handleFormData(e.target?.name, e.target?.value)} value={formData?.contactPersonalEmail}/>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Contact First Name:</CustomTypography>
				<CustomInput  name="contactFirstName" onChange={e=>handleFormData(e.target?.name, e.target?.value)} value={formData?.contactFirstName}/>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomTypography theme={theme}>Contact Last Name:</CustomTypography>

				<CustomInput name="contactLastName" onChange={e=>handleFormData(e.target?.name, e.target?.value)} value={formData?.contactLastName}/>
			</FixContainer>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton
					disabled={!valid}
					onClick={() => {
						setTab("PoliciesTab");
					}}
				>
					Next
				</PrimaryButton>
			</Box>
		</Box>
	);
}

const jobPostionMock = [
	{ title: "Director" },
	{ title: "GM" },
	{ title: "VP" },
	{ title: "CEO" },
	{ title: "General Counsel" },
	{ title: "Other" },
];

export const FixContainer = ({ children, ...props }) => {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Box
			width={belowTablet ? "100%" : "100%"}
			minWidth={belowTablet ? "100%" : "100%"}
			maxWidth={belowTablet ? "100%" : "100%"}
			{...props}
		>
			{children}
		</Box>
	);
};

const CustomInput = ({ ...props }) => {
	const theme = useTheme();
	return (
		<Input
			{...props}
			style={{
				width: "100%",
				height: "40px",
				fontSize: "14px",
				fontWeight: "500",
				backgroundColor: theme?.palette?.card?.background,

				color: theme?.palette?.pageHeading,
			}}
		/>
	);
};