/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
	Modal,
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Select,
	Button,
	RadioGroup,
} from "@mui/material";
import CustomRadioButton from "components/Inputs/RadioButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { segmentCriteria } from "../Segments/mock";
import { CustomMenuItem } from "../ImportSubscribers/Common";
import SecondaryButton from "components/Buttons/SecondaryButton";
import { Input } from "antd";
export default function AddDiscountCode({ 
	mode, 
	open, 
	handleClose, 
	priceRules,
	setPriceRule,
	priceRule, 
	codeType, 
	setCodeType, 
	discountType, 
	setDiscountType,
	setDiscountValue,
	discountValue,
	discountUrl,
	setDiscountUrl,
	discountPath,
	setDiscountPath,
	isEmbedLink,
	setIsEmbedLink
}) {

	const lightMode = mode == "light" ? true : false;
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [addEnabled, setAddEnabled] = useState(false);

	useEffect(() => {
		if(!priceRule && codeType === "same"){
			setAddEnabled(false);
		}
		else if((!discountType || !discountValue) && codeType==="unique"){
			setAddEnabled(false);
		} else{
			setAddEnabled(true);
		}
	}, [priceRule,codeType,discountType,discountValue]);

	return (
		<Modal
			open={open}
			id="view-rules"
			aria-labelledby="view-rules-modal"
			aria-describedby="view-rules-modal"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					sx={{
						padding: belowSmall ? "8px" : "20px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						mb={1}
					>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"20px"}
							fontWeight={600}
							lineHeight={"normal"}
						>
							Add discount code
						</Typography>
						<img
							src="/icons/cut.svg"
							alt="cut"
							onClick={() => handleClose(false)}
							id="pointerCursor"
							style={{
								marginTop: "-10px",
							}}
						/>
					</Box>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"22px"}
						sx={{
							opacity: "0.5",
						}}
					>
						Add discount code to your campaign
					</Typography>
				</Box>

				<Box
					sx={{
						padding: belowSmall ? "8px" : "20px",
					}}
					borderRadius={"8px"}
				>
					<RadioGroup
						aria-labelledby="code type"
						defaultValue={codeType}
						name="codeType"
						onClick={(e) => {
							if (e?.target?.value) {
								console.log(e?.target?.value);
								setCodeType(e?.target?.value);
							}
						}}
					>
						<Box mb={"15px"} display={"flex"} gap={"4px"} alignItems={"center"}>
							<CustomRadioButton value={"unique"} />
							<Typography
								color={theme.palette?.pageHeading}
								fontSize={"14px"}
								fontWeight={500}
								lineHeight={"normal"}
							>
								Unique code per client (Recommended)
							</Typography>
						</Box>
						<Box mb={"26px"} display={"flex"} gap={"4px"} alignItems={"center"}>
							<CustomRadioButton value={"same"} />
							<Typography
								color={theme.palette?.pageHeading}
								fontSize={"14px"}
								fontWeight={500}
								lineHeight={"normal"}
							>
								Same discount for all client
							</Typography>
						</Box>
					</RadioGroup>

					{codeType == "same" && (
						<Box mb={"24px"}>
							<Typography
								color={theme.palette?.pageHeading}
								fontSize={"14px"}
								fontWeight={500}
								mb={"8px"}
								lineHeight={"normal"}
							>
								Discount Code Configration
							</Typography>
							<CustomSelect
							onChange={e=>setPriceRule(e.target.value)} value={priceRule}>
								{priceRules.map((item, index) => {
									return (
										<CustomMenuItem
										key={index}
										value={item?.id}
									>
										{item?.title + " " + (item?.value_type === "fixed_amount" ?  "{$" : "{") + item?.value.replace("-","") + (item?.value_type === "percentage" ? "%}" : "}")}
									</CustomMenuItem>
									);
								})}
							</CustomSelect>
						</Box>
					)}
					{codeType == "unique" && (
						<Box
							mb={"24px"}
							display={"flex"}
							gap={"12px"}
							flexDirection={belowSmall ? "column" : "row"}
						>
							<Box width={"100%"}>
								<Typography
									color={theme.palette?.pageHeading}
									fontSize={"14px"}
									fontWeight={500}
									mb={"8px"}
									lineHeight={"normal"}
								>
									Discount Type
								</Typography>
								<CustomSelect onChange={e=>{setDiscountType(e.target.value)}} default={discountType} value={discountType}>
									<CustomMenuItem value={"fixed_amount"}>
										Fixed amount
									</CustomMenuItem>
									<CustomMenuItem value={"percentage"}>
										Percentage amount
									</CustomMenuItem>
								</CustomSelect>
							</Box>
							<Box width={"100%"}>
								<Typography
									color={theme.palette?.pageHeading}
									fontSize={"14px"}
									fontWeight={500}
									mb={"8px"}
									lineHeight={"normal"}
								>
									Discount value
								</Typography>
								<CustomInput onChange={e => setDiscountValue(e.target.value)} value={discountValue} type="number"/>
							</Box>
						</Box>
					)}
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"16px"}
						fontWeight={600}
						mb={"21px"}
						lineHeight={"normal"}
					>
						{codeType == "same"
							? "The selected discount code will apply for all recipients in the current campaign"
							: "Each recipient in the current campaign will get a personalized code."}
					</Typography>
					<Box mb={8}>
						<Box display={"flex"} gap={"4px"} alignItems={"center"}>
							<CustomRadioButton
								checked={isEmbedLink}
								onClick={() => setIsEmbedLink((prevState) => !prevState)}
							/>
							<Typography
								color={theme.palette?.pageHeading}
								fontSize={"14px"}
								fontWeight={500}
								lineHeight={"normal"}
							>
								Embed a discount code in a link
							</Typography>
						</Box>
						{isEmbedLink&&<Box
							mt={1}
							display={"flex"}
							gap={"12px"}
							flexDirection={belowSmall ? "column" : "row"}
						>
							<CustomInput value={discountUrl} onChange={(e)=>setDiscountUrl(e.target?.value)} name='link'/>
							<CustomInput placeHolder="Leave empty to redirect to your homepage" value={discountPath} onChange={(e)=>setDiscountPath(e.target?.value)}/>
						</Box>}
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "end",
						}}
					>
						<Box>
							<Button
								size={"small"}
								sx={{
									padding: "11px 30px",
									height: "40px",
									border: "1px solid #E8EBF3",
									color: theme.palette?.pageHeading,
									marginRight: "10px",
									backgroundColor: theme.palette?.card?.background,
								}}
								variant="outlined"
								onClick={() => handleClose(false)}
							>
								<Typography fontSize={"14px"} fontWeight={"600"}>
									Cancel
								</Typography>
							</Button>
						</Box>

						<Box>
							<SecondaryButton onClick={() => handleClose(true)} disabled={!addEnabled}>
								Add Discount
							</SecondaryButton>
						</Box>
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "677px",
				backgroundColor: theme?.palette?.card?.background,
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				maxHeight: "90vh",
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};

const CustomSelect = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<Select
			sx={{
				backgroundColor: theme?.palette?.card?.background,
				"& .MuiSvgIcon-root": { color: "#C1C1C1" },
				"&:focus": {
					backgroundColor: "transparent",
				},
				fontSize: "14px",
				fontWeight: "500",
				lineHeight: "normal",
			}}
			IconComponent={ExpandMoreIcon}
			style={{ width: "100%", height: "40px" }}
			{...props}
			MenuProps={{
				PaperProps: {
					style: {
						maxHeight: 200,
						maxWidth: 300,
						marginTop: 3,
						borderRadius: 8,
						border: `1px solid ${theme?.palette?.card?.border}`,
					},
				},
			}}
		>
			{children}
		</Select>
	);
};

const CustomInput = ({ ...props }) => {
	const theme = useTheme();
	return (
		<Input
			{...props}
			style={{
				width: "100%",
				height: "40px",
				fontSize: "14px",
				fontWeight: "500",
				backgroundColor: theme?.palette?.card?.background,

				color: theme?.palette?.pageHeading,
			}}
		/>
	);
};