import React from "react";
import {
	Modal,
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	MenuItem,
	Select,
	Button,
} from "@mui/material";
import { TextInput } from "components/Inputs";
import TextArea from "antd/es/input/TextArea";
import SecondaryButton from "components/Buttons/SecondaryButton";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

export default function QuickReplyModal({ mode, open, handleClose }) {
	const lightMode = mode == "light" ? true : false;
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Modal
			open={open}
			id="create-reply"
			aria-labelledby="create-reply"
			aria-describedby="create-reply"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					mb={3}
				>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"20px"}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Create a new quick reply
					</Typography>

					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={handleClose}
						id="pointerCursor"
						style={{
							marginTop: "-10px",
						}}
					/>
				</Box>
				<Box>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						mb={"8px"}
					>
						Reply Name
					</Typography>
					<TextInput />
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						mb={"8px"}
						mt={"16px"}
					>
						Add message
					</Typography>
					<TextArea
						// value={value}
						// onChange={(e) => {
						// 	console.log(e.target?.value);
						// 	// setValue(e.target.value);
						// }}
						// placeholder="Type your message..."
						autoSize={{ minRows: 5, maxRows: 5 }}
					/>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						mb={"8px"}
						mt={"16px"}
					>
						Available for
					</Typography>
					<Select
						sx={{ "& .MuiSvgIcon-root": { color: "#C1C1C1" } }}
						IconComponent={GridExpandMoreIcon}
						style={{ width: "100%", height: "40px" }}
						onChange={(e) => {
							console.info(e.target.value);
						}}
					>
						<CustomMenuItem value={10}>Everyone</CustomMenuItem>
						<CustomMenuItem value={20}>Everyone</CustomMenuItem>
						<CustomMenuItem value={30}>Everyone</CustomMenuItem>
					</Select>
				</Box>
				<Box display={"flex"} justifyContent={"end"} mt={"16px"}>
					<Box>
						<Button
							size={"small"}
							sx={{
								padding: "11px 30px",
								height: "40px",
								border: `1px solid  ${theme?.palette?.card?.border}`,
								color: theme.palette?.pageHeading,
								marginRight: "10px",
								backgroundColor: theme.palette?.card?.background,
							}}
							variant="outlined"
						>
							<Typography fontSize={"14px"} fontWeight={"600"}>
								Cancel
							</Typography>
						</Button>
						<SecondaryButton>Save</SecondaryButton>
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "535px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "20px 20px 25px 20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};
const CustomMenuItem = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<MenuItem
			sx={{
				background: theme?.palette?.card?.background,
			}}
			{...props}
		>
			{children}
		</MenuItem>
	);
};
