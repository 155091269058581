import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import React, { useRef, useState } from "react";
import Status from "../components/Status";
import Text from "../components/TextArea";
import { useEffect } from "react";
import {
	AbandonedCheckoutSVG,
	RecoveredSaleSVG,
	ClickedLinkSVG,
} from "resources/Icons/Inbox/Index";

export default function ChatSection({ activeTab }) {
	const messages = [
		{
			type: "action",
			action: "Abandoned Checkout",
		},

		{
			type: "message",
			content:
				"Hey Nicole, this is Lindsey from The Test Shoppe. I saw you were checking out our Test Product in Test Color. Awesome choice! Want me to check if I can get you a discount?",
			sentAt: "Dec 12, 2023 11:53am",
			sentBy: "txtCart",
		},
		{
			type: "message",
			content: "Sure! That would be absolutely great...",
			sentAt: "Just Now",
			sentBy: "user",
		},
		{
			type: "message",
			content:
				"Awesome! I just made you a discount code for 25% OFF, it’s txtcrt-8923. Just make sure you apply it at checkout to get your discount! Here is a link back to your cart: https://txtcrt.io/fklsdjfsdlk",
			sentAt: "Dec 12, 2023 11:56am",
			sentBy: "txtCart",
		},
		{
			type: "action",
			action: "Clicked Link",
		},
		{
			type: "message",
			content:
				"Thank you so much. It’s worked I am going to tell all my friends about this company. So amazing...",
			sentAt: "Dec 11, 2023 11:53am",
			sentBy: "user",
		},
		{
			type: "action",
			action: "Recovered Sale",
		},
		{
			type: "message",
			content:
				"Awesome! I just made you a discount code for 25% OFF, it’s txtcrt-8923. Just make sure you apply it at checkout to get your discount! Here is a link back to your cart: https://txtcrt.io/fklsdjfsdlk",
			sentAt: "Dec 12, 2023 11:56am",
			sentBy: "txtCart",
		},
	];
	const [inputHeight, setInputHeight] = useState(0);
	const theme = useTheme();
	const userName = "Nicole Rizzo";
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [text, setText] = useState("");
	const ref = useRef(null);
	const chatContainerRef = useRef(null);
	useEffect(() => {
		// console.log(ref,'test')
		if (ref?.current?.clientHeight) {
			setInputHeight(ref.current.clientHeight - 110);
			// console.log(ref.current.clientHeight, "test"); // Get height without border/padding
			if (chatContainerRef.current) {
				chatContainerRef.current.scrollTop =
					chatContainerRef.current.scrollHeight;
			}
		}
	}, [text]);

	return (
		<Box
			width={isMobile ? "100%" : "50%"}
			position={"relative"}
			overflow={"hidden"}
			display={isMobile ? (activeTab == "Chat" ? "flex" : "none") : "flex"}
			flexDirection={"column"}
		>
			{!isMobile && <Header name={userName} />}
			<Box
				ref={chatContainerRef}
				flex={"1"}
				width={"100%"}
				p={"0 24px"}
				sx={{
					// display:'flex',
					// flexDirection:'column',
					// justifyContent:'end',
					overflow: "hidden",
					overflowY: "scroll",
					maxHeight: `calc(100vh - ${
						isMobile ? "200px" : "240px"
					} - ${inputHeight}px)`,
					minHeight: `calc(100vh - ${
						isMobile ? "200px" : "240px"
					} - ${inputHeight}px)`,
				}}
			>
				<Box display={"flex"} flexDirection={"column"}>
					{messages?.map((item, index) => {
						return item?.type == "action" ? (
							<ActionCard
								nextAction={
									messages[index + 1]?.type == "message" &&
									messages[index + 1]?.sentBy == "user"
										? true
										: false
								}
								action={item?.action}
							/>
						) : item?.sentBy == "user" ? (
							<MessageBox
								number={index}
								content={item?.content}
								sentAt={item?.sentAt}
								key={index}
								isLast={messages[index + 1]?.sentBy == "txtCart" ? true : false}
								showUserLogo={
									messages[index + 1]?.sentBy == "user" ? false : true
								}
								userName={userName}
							/>
						) : (
							<ReplyBox
								content={item?.content}
								sentAt={item?.sentAt}
								key={index}
								isLast={messages[index + 1]?.sentBy == "user" ? true : false}
								showUserLogo={
									messages[index + 1]?.sentBy == "txtCart" ? false : true
								}
							/>
						);
					})}
				</Box>
			</Box>
			<Box
				id="input-box"
				bottom={"20px"}
				width={isMobile ? "100%" : "94%"}
				m={isMobile ? "0" : "0 24px"}
				position={isMobile ? "static" : "absolute"}
				ref={ref}
			>
				<Text text={text} setText={setText} />
			</Box>
		</Box>
	);
}
const Header = ({ name }) => {
	const theme = useTheme();
	return (
		<Box
			bgcolor={theme?.palette?.card?.background}
			p={"9px"}
			display={"flex"}
			justifyContent={"space-between"}
			borderBottom={`1px solid ${theme?.palette?.card?.border}`}
			alignItems={"center"}
		>
			<Box display={"flex"} gap={"16px"}>
				<Typography
					fontWeight={600}
					fontSize={20}
					color={theme?.palette?.pageHeading}
					lineHeight={"normal"}
					ml={"7px"}
				>
					{name}
				</Typography>
				<Status />
			</Box>
			<Box>
				<PrimaryButton>Close Conversation</PrimaryButton>
			</Box>
		</Box>
	);
};

const MessageBox = ({
	content,
	sentAt,
	isLast,
	showUserLogo,
	userName,
	number,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	// console.log(content, isLast, "m");
	return (
		<Box
			display={"flex"}
			gap={"8px"}
			mb={isLast ? "24px" : "6px"}
			mt={number == 0 && "20px"}
		>
			<Box display={"flex"} alignItems={"end"}>
				<Box
					sx={{
						background: "#E7F3FF",
						borderRadius: "50%",
						width: "32px",
						height: "32px",
						border: " 1px solid rgba(0, 129, 255, 0.15)",
						visibility: showUserLogo ? "visible" : "hidden",
					}}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Typography color={"#0081FF"} fontSize={14} fontWeight={600}>
						{userName[0]}
					</Typography>
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"flex-start"}>
				<Box
					p={"16px"}
					sx={{
						background: lightMode ? "rgba(0, 13, 49, 0.05)" : "#1E202D",
						borderRadius: "12px 12px 12px 0px",
					}}
					maxWidth={isMobile ? "100%" : "411px"}
					minWidth={isMobile ? "100%" : "411px"}
				>
					<Box mb={"4px"}>
						<Typography
							color={theme?.pageHeading?.pageHeading}
							fontSize={14}
							fontWeight={400}
							lineHeight={"20px"}
						>
							{content}
						</Typography>
					</Box>
					<Box display={"flex"} justifyContent={"end"}>
						<Typography
							color={"#808698"}
							fontSize={14}
							fontWeight={500}
							lineHeight={"16px"}
						>
							{sentAt}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
const ReplyBox = ({ content, sentAt, isLast, showUserLogo }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	return (
		<Box
			display={"flex"}
			gap={"8px"}
			mb={isLast ? "24px" : "6px"}
			justifyContent={"end"}
		>
			<Box display={"flex"} justifyContent={"end"}>
				<Box
					p={"16px"}
					sx={{
						background: lightMode
							? "rgba(12, 132, 254, 0.10)"
							: "rgba(12, 132, 254, 0.10)",
						borderRadius: "12px 12px 0px 12px",
					}}
					maxWidth={"383px"}
				>
					<Box mb={"4px"}>
						<Typography
							color={theme?.pageHeading?.pageHeading}
							fontSize={14}
							fontWeight={400}
							lineHeight={"20px"}
						>
							{content}
						</Typography>
					</Box>
					<Box display={"flex"} justifyContent={"end"}>
						<Typography
							color={"#808698"}
							fontSize={14}
							fontWeight={500}
							lineHeight={"16px"}
						>
							Sent: {sentAt}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box display={"flex"} alignItems={"end"}>
				<Box
					sx={{
						width: "32px",
						height: "32px",
						visibility: showUserLogo ? "visible" : "hidden",
					}}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<img src="/icons/txt-cart-chat-icon.svg" />
				</Box>
			</Box>
		</Box>
	);
};

const ActionCard = ({ action, nextAction }) => {
	const imgMap = {
		"Abandoned Checkout": (
			<Box mr={"14px"}>
				{" "}
				<AbandonedCheckoutSVG />
			</Box>
		),
		"Clicked Link": (
			<Box mr={"11px"}>
				<ClickedLinkSVG />
			</Box>
		),
		"Recovered Sale": (
			<Box mr={"8px"}>
				<RecoveredSaleSVG />
			</Box>
		),
	};
	const titleColorMap = {
		"Abandoned Checkout": "#FF465C",
		"Clicked Link": "#0081FF",
		"Recovered Sale": "#00BE5B",
	};

	return (
		<Box
			display={"flex"}
			// gap={"14px"}
			alignItems={"end"}
			mb={nextAction ? "14px":'8px'}
			ml={"40px"}
			mt="23px"
		>
			{imgMap[action]}
			<Box>
				<Typography
					fontSize={14}
					fontWeight={600}
					lineHeight={"normal"}
					mb={"4px"}
					color={titleColorMap[action]}
				>
					{action}
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					lineHeight={"16px"}
					color={"#808698"}
				>
					Dec 11, 2023 11:53am
				</Typography>
			</Box>
		</Box>
	);
};
