import React, { useEffect } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Templates from "../Campaigns/components/Templates";
import GraphSection from "../Campaigns/components/GraphSection";
import Analytics from "../Campaigns/components/Analytics";
import CampaignsTable from "../../components/Table/CampaignsTable";
import { campaignsDataMock } from "resources/constant";
import ErrorModal from "components/Modal/ErrorPage";

export default function CampaignsErrorTemplate() {
	const theme = useTheme();
	const { mode } = theme.palette;

	useEffect(() => {
		document.body.style.overflow = "hidden";
		const elementsToDisableHover = document.querySelectorAll(".disableSection");
		const disableHoverClass = "disable-hover";
		elementsToDisableHover.forEach((element) => {
			element.classList.add(disableHoverClass);
		});
	}, []);
	return (
		<Box>
			<Box
				className="disableSection"
				sx={{
					position: "relative",
					height: "100vh",
					WebkitFilter: "blur(4px)",
				}}
			>
				<Grid container spacing={2} alignItems={"center"}>
					
					<Grid item xs={12}>
						<Typography
							fontWeight={"600"}
							fontSize={"20px"}
							lineHeight={"normal"}
							mb={"24px"}
							mt={"8px"}
							color={theme.palette?.pageHeading}
						>
							Recommended Templates
						</Typography>
						<Templates />
					</Grid>
					<Grid item xs={12}>
						<GraphSection />
					</Grid>
					<Grid item xs={12}>
						<Analytics />
					</Grid>
					<Grid item xs={12}>
						<CampaignsTable rows={campaignsDataMock} mode={mode} />
					</Grid>
				</Grid>
			</Box>
			<ErrorModal  />
		</Box>
	);
}
