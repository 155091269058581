import React from "react";

export default function Lightning() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M11.6593 1.76999C11.7052 1.40291 11.5036 1.04952 11.1642 0.902214C10.8249 0.754907 10.429 0.84895 10.1922 1.13314L2.751 10.0626C2.6224 10.2169 2.49215 10.3731 2.39732 10.5104C2.30763 10.6403 2.14387 10.8956 2.13881 11.2311C2.13302 11.6153 2.3042 11.9807 2.60301 12.2222C2.86402 12.4331 3.16499 12.4708 3.32217 12.485C3.48835 12.5 3.69176 12.5 3.89258 12.5L9.05509 12.5L8.33884 18.2299C8.29295 18.597 8.49456 18.9504 8.83391 19.0977C9.17326 19.245 9.56909 19.151 9.80592 18.8668L17.2471 9.93733C17.3757 9.78307 17.506 9.62683 17.6008 9.48952C17.6905 9.35967 17.8543 9.10436 17.8593 8.76881C17.8651 8.38467 17.6939 8.0192 17.3951 7.77773C17.1341 7.56681 16.8331 7.52916 16.676 7.51493C16.5098 7.49989 16.3064 7.49992 16.1055 7.49996L10.9431 7.49997L11.6593 1.76999Z"
				fill="#808698"
			/>
		</svg>
	);
}
