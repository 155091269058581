import {
	Box,
	Button,
	Modal,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { txtCartFetch } from "utilifyFunctions";

export default function ManagePlanModal({ open, handleClose, skus, currentSKU,currentPeriod }) {
	
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const periodSelectedStyle = {
		fontSize: "14px",
		fontWeight: "600",
		color: "#0081FF",
	};
	// const currentPlan = {
	// 	name: "Pro",
	// 	period: "Monthly",
	// };
	const periodNonSelectedStyle = {
		fontSize: "14px",
		fontWeight: "500",
		color: "#85858C",
	};
	const Icons = {
		Basic: "💡",
		Starter: "💰",
		Growth: "🚀",
		Pro: "🏆",
	};
	// const mapDescription = {
	// 	Basic: "+20% of cart recovery",
	// 	Starter: "+15% of cart recovery",
	// 	Growth: "+7.5% of cart recovery",
	// 	Pro: "+2.5% of cart recovery",
	// };

	const [period, setPlanPeriod] = useState(currentPeriod);

	
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [showCardForMobile, setShowCardForMobile] = useState("Pro");
	// const planMock = [
	// 	{
	// 		id: 0,
	// 		name: "Basic",
	// 		desc: "For new stores looking for a risk-free option to SMS",
	// 		priceMonthly: "$0",
	// 		priceAnnually: "$0",
	// 		discount: "$0 billed Annually",
	// 		features: {
	// 			heading: "Free Plan Includes",
	// 			keyFeatures: ["SMS Cart Recovery", "Customer Chat Support"],
	// 		},
	// 	},
	// 	...planMockData,
	// ];


	useEffect(() => {
		setPlanPeriod(currentPeriod);
	}, [currentPeriod]);

	const handleSelect = async function(skuId){
		console.log(skuId);
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/billing/change?skuId="+skuId + "&cycle="+period);
			console.log(response);
			if(response){
				window.location.replace(response.redirect_url);
			}
	}
	return (
		<Modal
			open={open}
			onClose={handleClose}
			id="manage-plan-modal"
			aria-labelledby="manage-plan-modal"
			aria-describedby="manage-plan-modal"
		>
			<MainContainer
				lightMode={lightMode}
				belowTablet={belowTablet}
				isMobile={isMobile}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "end",
					}}
				>
					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={handleClose}
						id="pointerCursor"
						style={{
							marginTop: isMobile ? "-10px" : "-50px",
							marginRight: isMobile ? "0" : "-20px",
						}}
					/>
				</Box>
				<Box>
					<Box textAlign={"center"} mb={"24px"}>
						<Typography
							fontSize={30}
							fontWeight={600}
							lineHeight={"normal"}
							color={theme?.palette?.pageHeading}
						>
							Manage Your Plan Billing
						</Typography>
					</Box>

					<Box display={"flex"} justifyContent={"center"} mb={"10px"}>
						<Box
							width={"210px"}
							sx={{
								display: "flex",
								borderRadius: "4px",
								background: mode == "light" ? "#F6F6F9" : "#141414",
								gap: "5px",
								border: "1px solid rgba(235, 235, 235, 0.15);",
								padding: "4px",
							}}
							position={"relative"}
						>
							<Box
								id="pointerCursor"
								sx={{
									background:
										period == "monthly"
											? "rgb(0, 129, 255,0.2)"
											: "transparent",
									borderRadius: "4px",
									padding: "6px 20px",
								}}
								onClick={() => {
									setPlanPeriod("monthly");
								}}
							>
								<Typography
									style={
										period == "monthly"
											? periodSelectedStyle
											: periodNonSelectedStyle
									}
								>
									Monthly
								</Typography>
							</Box>
							<Box
								id="pointerCursor"
								sx={{
									borderRadius: "4px",
									padding: "6px 20px",
									background:
										period == "Annually"
											? "rgb(0, 129, 255,0.2)"
											: "transparent",
								}}
								onClick={() => {
									setPlanPeriod("Annually");
								}}
							>
								<Typography
									style={
										period == "Annually"
											? periodSelectedStyle
											: periodNonSelectedStyle
									}
								>
									Annually
								</Typography>
							</Box>
							<Box
								position={"absolute"}
								sx={{
									bottom: "-28px",
									right: "-24px",
								}}
							>
								<img src="/img/onboarding/arrow.svg" />
							</Box>
						</Box>
					</Box>
					<Box mb={"15px"} display={"flex"} justifyContent={"center"}>
						<Typography
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"normal"}
							color={"#0081FF"}
							ml={"104px"}
						>
							2 months free!
						</Typography>
					</Box>
					{isMobile && (
						<PlanSelectorMobile
							setShowCardForMobile={setShowCardForMobile}
							showCardForMobile={showCardForMobile}
						/>
					)}

					<Box display={isMobile ? "block" : "flex"} gap={"24px"}>
						{skus?.slice(0, 4)?.map((item, index) => {
							return (
								<Box
									width={"100%"}
									display={
										isMobile
											? showCardForMobile == item?.displayName
												? "block"
												: "none"
											: "block"
									}
									key={index}
									sx={{
										background: theme?.palette?.card?.background,
										border: `1px solid ${
											currentSKU?.skuId === item?.skuId &&
											currentPeriod === period
												? "#7CFAB8"
												: theme?.palette?.card?.border
										}`,
										borderRadius: "12px",
									}}
								>
									<Box
										sx={{
											padding: "12px",
											borderBottom: `1px solid ${
												mode == "light" ? "#EBEBEB" : "#1E202D"
											}`,
										}}
									>
										<Box
											display={"flex"}
											gap={"9px"}
											alignItems={"center"}
											mb={"4px"}
										>
											<Typography
												fontSize={20}
												fontWeight={600}
												lineHeight={"normal"}
												color={mode == "light" ? "#0A0A18" : "#FFF"}
											>
												{item?.displayName} {Icons[item?.displayName]}
											</Typography>
											{currentSKU?.skuId === item?.skuId &&
												currentPeriod === period && <CurrentPlanTag />}
										</Box>

										<Typography
											fontSize={14}
											fontWeight={500}
											lineHeight={"normal"}
											color={mode == "light" ? "#0A0A18" : "#FFF"}
											sx={{
												opacity: "0.5",
												height: "30px",
											}}
											mb={"4px"}
										>
											{item?.internalDescription}
										</Typography>
										<Box display={"flex"} alignItems={"baseline"} mb={"7px"}>
											<Typography
												fontSize={"32px"}
												fontWeight={"700"}
												// color={"#18113C"}
												color={mode == "light" ? "#18113C" : "#FFF"}
												lineHeight={"normal"}
											>
												{period == "monthly"
													? "$"+item?.recurringFee
													: item.allowAnnual ? "$"+(item?.annualRecurring / 12).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
												: "Ineligible"}
											</Typography>
											<Typography
												ml={1}
												fontSize={"14px"}
												color={"#696E7E"}
												fontWeight={"400"}
												lineHeight={"22px"}
											>
												{item.allowAnnual ? "/ month" : ""}
											</Typography>
										</Box> 
										<Box>
											<Typography
												fontSize={14}
												fontWeight={500}
												lineHeight={"normal"}
												color={mode == "light" ? "#0A0A18" : "#FFF"}
												sx={{
													opacity: "0.5",
												}}
												mb={"8px"}
											>
												{(period === "Annually" && item?.allowAnnual) ?  ("$"+(item?.annualRecurring) + " billed annually") : <>&nbsp;</>}
											</Typography>
										</Box>
										<Box mb={"17px"}>
											<Typography
												fontSize={"14px"}
												fontWeight={500}
												lineHeight={"normal"}
												color={"#0081FF"}
											>
												{(period === "monthly" || item?.allowAnnual) ? ("+" + (item?.commissionPercent * 100).toFixed(1).toString() + "% of cart recovery") : <>&nbsp;</>}
											</Typography>
										</Box>
										<Box>
											<Button
												fullWidth
												disabled={
													(currentSKU?.skuId === item?.skuId &&
													currentPeriod === period) ||
													(period === 'Annually' && item?.allowAnnual === false)
												}
												onClick={() => {handleSelect(item?.skuId)}}
												sx={{
													padding: "11px 18px",
													border: "1px solid #0081FF",
													backgroundColor:
													currentSKU?.skuId === item?.skuId &&
														currentPeriod === period
															? "#0081FF"
															: "transparent",
													height: "40px",

													":hover": {
														background:
														currentSKU?.skuId == item?.skuId &&
															period === currentPeriod
																? "#0081FF"
																: "transparent",
														color:
														currentSKU?.skuId === item?.skuId &&
															period === currentPeriod
																? "#FFF"
																: "#0081FF",
													},
												}}
												variant="outlined"
											>
												<Typography
													fontSize={"14px"}
													fontWeight={"600"}
													sx={{
														color:
														currentSKU?.skuId === item?.skuId &&
															currentPeriod === period
																? "#FFF"
																: "#0081FF",
													}}
												>
													{item?.skuId == currentSKU?.skuId &&
													period == currentPeriod
														? "Current Plan"
														: item?.tier >= currentSKU.tier
														? `Upgrade to ${item?.displayName}`
														: `Downgrade to ${item?.displayName}`}
												</Typography>
											</Button>
										</Box>
									</Box>
									<Box p={"20px"}>
										<Typography
											color={mode == "light" ? "#18113C" : "#FFF"}
											fontSize={14}
											fontWeight={600}
											lineHeight={"normal"}
											mb={"20px"}
										>
											{item?.displayHeading}
										</Typography>
										<Box>
											{item?.keyFeatures?.slice(1,item?.keyFeatures?.length)?.map((item, index) => {
												return (
													<Typography
														key={index}
														fontSize={"14px"}
														fontWeight={"400"}
														color={"#696E7E"}
														mb={2}
													>
														<img
															src="/arrow/tick.svg"
															style={{
																marginRight: "10px",
															}}
														/>
														{item}
													</Typography>
												);
											})}
										</Box>
									</Box>
								</Box>
							);
						})}
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, isMobile, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			id="manage-billing"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				padding: isMobile ? "20px 15px" : "58px",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "1523px",
				backgroundColor: theme?.palette?.card?.background,
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxWidth: "90vw",
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				"::-webkit-scrollbar": {
					display: "none",
				},
				// height: "935px",
			}}
		>
			{children}
		</Box>
	);
};

const PlanSelectorMobile = ({ showCardForMobile, setShowCardForMobile }) => {
	const cardSelectedStyle = {
		fontSize: "14px",
		fontWeight: "600",
		color: "#FFF",
	};
	const cardNonSelectedStyle = {
		fontSize: "14px",
		fontWeight: "500",
		color: "#85858C",
	};
	return (
		<Box
			// width={"320px"}
			sx={{
				display: "flex",
				borderRadius: "4px",
				gap: "2px",
				border: "1px solid rgba(235, 235, 235, 0.15);",
				padding: "4px",
			}}
			position={"relative"}
			mb={"16px"}
		>
			{planName?.map((item, index) => {
				return (
					<Box
						key={index}
						width={"100%"}
						id="pointerCursor"
						sx={{
							background:
								showCardForMobile == item
									? "#0081FF"
									: "rgb(255, 255, 255,0.1)",
							borderRadius: "4px",
							padding: "6px 0",
							display: "flex",
							justifyContent: "center",
						}}
						onClick={() => {
							setShowCardForMobile(item);
						}}
					>
						<Typography
							style={
								showCardForMobile == item
									? cardSelectedStyle
									: cardNonSelectedStyle
							}
						>
							{item}
						</Typography>
					</Box>
				);
			})}
		</Box>
	);
};

const CurrentPlanTag = () => {
	return (
		<Box
			sx={{
				background: "#0081FF",
				width: "92px",
				height: "22px",
				borderRadius: "100px",
			}}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
		>
			<Typography
				fontSize={"12px"}
				fontWeight={600}
				lineHeight={"normal"}
				color={"#FFF"}
			>
				Current Plan
			</Typography>
		</Box>
	);
};


const planName = ["Starter", "Growth", "Pro"];
