import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import { CustomDesc, CustomTypography } from "./Common";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "PNG", "GIF"];
const Upload = () => {
	const theme = useTheme();
	const { mode } = theme.palette;
	// eslint-disable-next-line no-unused-vars
	const [file, setFile] = useState(null);
	const handleChange = (file) => {
		setFile(file);
	};

	return (
		<Box>
			<Box mb="24px">
				<Box mb={"8px"}>
					<CustomTypography>Prepare your file and upload</CustomTypography>
				</Box>
				<CustomDesc theme={theme} mb="24px">
					Add your susbcribers to the CSV template and upload it here.
				</CustomDesc>
				<Box maxWidth={'454px'}>
					<FileUploader
						handleChange={handleChange}
						name="file"
						types={fileTypes}
					>
						<Box
							padding={"20px"}
							display={"flex"}
							justifyContent={"center"}
							alignContent={"center"}
							textAlign={"center"}
							sx={{
								backgroundColor:mode=='light'?'#FFF': "rgba(255, 255, 255, 0.05)",
								borderRadius:'4px'
							}}
							border=" 1px dashed rgba(0, 129, 255, 0.40);"
						>
							<Box>
								<Box>
									<img
										src="/img/file.svg"
										style={{
											width: "32px",
											height: "32px",
										}}
									/>
								</Box>
								<Typography
									mb={"9px"}
									width={"276px"}
									color={theme?.palette?.pageHeding}
									fontSize={14}
									fontWeight={500}
								>
									Drop your CSV here or{" "}
									<span style={{ color: "#0081FF" }}>
										select a file from your computer
									</span>
								</Typography>
								<CustomDesc theme={theme}>
									Note: Maximum file size is 10 MB
								</CustomDesc>
							</Box>
						</Box>
					</FileUploader>
					<Box display={"flex"} alignItems={"flex-start"} mt={"18px"}>
						<Checkbox
							size="small"
							defaultChecked
							sx={{ padding: "0", marginRight: "8px" }}
						/>
						<CustomDesc theme={theme} fontSize="12px">
							I certify that these subscribers were collected through a
							compliant opt-in mechanism in accordance with applicable laws and
							regulations, and that all sunscribseribers (opt0out) have been
							removed.
						</CustomDesc>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
export default Upload;
