import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

export default function FeaturedAppsCard({
	name,
	description,
	icon,
	mode,
	isConnected,
	url
}) {
	const lightMode = mode == "light" ? true : false;
	return (
		<Grid item xs={12}>
			<Box display={"flex"} justifyContent={"space-between"} mb={3}>
				<Box>
					<img
						src={icon}
						alt="Icon"
						style={{
							borderRadius: "12px",
							border:
								mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
						}}
						width={"72px"}
						height={"72px"}
					/>
				</Box>
				<Box>
					<Button
						size={"small"}
						sx={{
							backgroundColor: "transparent",
							padding: "12px 24px",
							color: lightMode ? "#FFF" : "#0C0D17",
							":hover": {
								color: lightMode ? "#0C0D17" : "#FFF",
							},
							border: `1px solid ${lightMode ? "#EBEBEB" : "#1E202D"}`,
						}}
						variant="outlined"
						onClick={() =>{window.open(url, '_blank').focus();}}
					>
						<Typography
							fontSize={14}
							fontWeight={600}
							color={isConnected ? "#FF465C" : lightMode ? "#0A0A18" : "#FFF"}
						>
							{!isConnected ? "Connect" : "Disconnect"}
						</Typography>
					</Button>
				</Box>
			</Box>
			<Box>
				<Typography
					fontSize={20}
					fontWeight={600}
					mb={"4px"}
					color={lightMode ? "#0A0A18" : "#FFF"}
				>
					{name}
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					sx={{
						opacity: "0.5",
						lineHeight: "22px",
					}}
				>
					{description}
				</Typography>
			</Box>
		</Grid>
	);
}
