import { Box, Typography } from "@mui/material";
import React from "react";
import {
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  AreaChart,
  CartesianGrid,
} from "recharts";

export default function Chart({ mode, data, tooltipName = "", dataKey }) {
  const CustomTick = (props) => {
    const { x, y, payload } = props;

    let getX = (value, x) => {
      return x-20;
    };

    return (
      <g>
        <g>
          <text
            x={getX(payload?.value, x)}
            y={y + 10}
            fontSize={"14px"}
            opacity={0.5}
            fill={mode == "light" ? "#0A0A18" : "#EBECF2"}
          >
            {payload.value}
          </text>
        </g>
      </g>
    );
  };

  const CustomYTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g>
        <g>
          <text
            x={x-40}
            y={y}
            fontSize={"14px"}
            opacity={0.5}
            fill={mode == "light" ? "#0A0A18" : "#EBECF2"}
          >
            ${payload.value}
          </text>
        </g>
      </g>
    );
  };
  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={350}>
        <AreaChart
          height={100}
          data={data}
          margin={{
            top: 10,
            right: 19,
            left: -13,
            bottom: 0,
          }}
          // isAnimationActive={true}
        >
          <XAxis
            dataKey="date"
            tickLine={false}
            scale={"auto"}
            tickMargin={10}
            tick={<CustomTick />}
            width={5}
          />
          <YAxis
            tickCount={10}
            axisLine={false}
            tickLine={false}
            tick={<CustomYTick/>}
            type="number"
          />
          <Tooltip content={<CustomTooltip tooltipName={tooltipName} />} />
          <Tooltip />
          <CartesianGrid
            strokeDasharray="1 0"
            vertical={false}
            color={mode == "light" ? "#EBEBEB" : "#303343"}
            opacity={mode == "light" ? 0.5 : 0.2}
          />
          <defs>
            <linearGradient
              id="conversations-gradient"
              x1="0"
              y1="1"
              x2="0"
              y2="0"
            >
              <stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
              <stop offset="100%" stopColor="#0C84FE" />
            </linearGradient>
          </defs>
          <Area
            dataKey={dataKey}
            key="value"
            fill="url(#conversations-gradient)"
            stroke="#0C84FE"
            opacity={1}
            fillOpacity={0.2}
            baseLine={8}
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

const CustomTooltip = ({ active, payload, label, tooltipName }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          padding: "12px",
          background: "#FFF",
          width: "175px",
          opacity: "0.95",
          borderRadius: "12px",
          boxShadow: "rgb(174, 174, 174) 0px 0px 10px",
        }}
      >
        <Typography
          fontSize={12}
          fontWeight={600}
          mb={"4px"}
          color={"#9095A5"}
          lineHeight={"16px"}
        >
          {label}
        </Typography>

        {payload.map((item) => (
          <div
            key={item.campaigns}
            style={{
              display: "flex",
              alignItems: "center", // Align items vertically
              justifyContent: "space-between", // Space evenly between items
              marginBottom: "4px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  marginRight: "10px",
                  marginTop: "3px",
                  backgroundColor: "#0081ff",
                  width: "8px",
                  height: "8px",
                  borderRadius: "100px",
                }}
              />
              <Typography
                fontSize={14}
                fontWeight={600}
                color={"#9095A5"}
                lineHeight={"16px"}
                textTransform={"capitalize"}
              >
                {tooltipName}:
              </Typography>
            </div>
            <Typography
              fontSize={14}
              fontWeight={600}
              color={"#000D31"}
              lineHeight={"16px"}
            >
              ${item?.value?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </div>
        ))}
      </Box>
    );
  }
};
