import { useTheme } from "@emotion/react";
import { Box, Modal, useMediaQuery } from "@mui/material";
import React from "react";
import ReactPlayer from 'react-player'

export default function VideoModal({ isOpen, onClose, url }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const style = {};
	const isMd = useMediaQuery(theme.breakpoints.down("md"));
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="video-modal-title"
			aria-describedby="video-modal-description"
		>
			<Box
				style={style}
				sx={{
					backgroundColor: mode == "light" ? "#fff" : "#0C0D17",
					border: mode == "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
					borderRadius: " 12px",
					outline: "none",
					overflowY: "auto",
					p: '16px 16px 16px 16px',
					position: "relative",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: isMd ? "80%" : "691px",
					boxShadow: 24,
				}}
			>
				<Box mb={3} display={"flex"} justifyContent={"end"}>
					<img
						src="/img/cart-recovery/cut.svg"
						alt="close"
						onClick={onClose}
						id="pointerCursor"
					/>
				</Box>
				<ReactPlayer url={url} width={"100%"}/>
			</Box>
		</Modal>
	);
}
