import React, { useState } from "react";
import {
	Modal,
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
} from "@mui/material";
import SecondaryButton from "components/Buttons/SecondaryButton";
import { TextInput } from "components/Inputs";
import { useIntercom } from 'react-use-intercom';

export default function SendMessageConfirmation({ mode, open, handleClose,sendTest, testPhone }) {
	const lightMode = mode == "light" ? true : false;
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const { showNewMessage } = useIntercom();
	const { showMessages } = useIntercom();

	function changeTestNumber(){
		console.log("on click");
		showMessages();
		showNewMessage("I would like to change the phone number my test campaigns are sent to.");
	}

	return (
		<Modal
			open={open}
			id="send-message"
			aria-labelledby="send-message"
			aria-describedby="send-message"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					sx={{
						padding: belowSmall ? "8px" : "20px",
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						mb={1}
					>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"20px"}
							fontWeight={600}
							lineHeight={"normal"}
						>
							Send Test Message?
						</Typography>
						<img
							src="/icons/cut.svg"
							alt="cut"
							onClick={() => handleClose(false)}
							id="pointerCursor"
							style={{
								marginTop: "-10px",
							}}
						/>
					</Box>
				</Box>
				<Box p={"20px"}>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"16px"}
						fontWeight={600}
						lineHeight={"normal"}
						mb={"12px"}
					>
						Send test message to
					</Typography>
					<TextInput
						value={testPhone}
						disabled={true}
					/>
					<Typography
						color={"#0081FF"}
						fontSize={"14px"}
						fontWeight={600}
						lineHeight={"20px"}
						mb={"9px"}
						mt={"12px"}
						id='pointerCursor'
						onClick={() => changeTestNumber()}
					>
						Change my test number
					</Typography>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"20px"}
						sx={{
							opacity: 0.5,
						}}
					>
						Test messages do not include working shortcodes or discount URLs.
						Images and GIFs only appear for US recipients.
					</Typography>
				</Box>

				<Box
					sx={{
						padding: belowSmall ? "0px 8px 8px 8px" : " 0px 20px 20px 20px",
					}}
					borderRadius={"8px"}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "end",
						}}
					>
						<Box>
							<Button
								size={"small"}
								sx={{
									padding: "11px 30px",
									height: "40px",
									border: "1px solid #E8EBF3",
									color: theme.palette?.pageHeading,
									marginRight: "10px",
									backgroundColor: theme.palette?.card?.background,
								}}
								variant="outlined"
								onClick={() => handleClose(false)}
							>
								<Typography fontSize={"14px"} fontWeight={"600"}>
									Cancel
								</Typography>
							</Button>
						</Box>

						<Box>
							<SecondaryButton onClick={() => sendTest()}>
								Send Message
							</SecondaryButton>
						</Box>
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "451px",
				backgroundColor: theme?.palette?.card?.background,
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				maxHeight: "90vh",
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};