/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
} from "@mui/material";
import { useIntercom } from 'react-use-intercom';

export default function Example({ handleClose, message, recipientPhone }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [showConfetii, setShowConfetii] = useState(true);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { showNewMessage } = useIntercom();
	const { showMessages } = useIntercom();

	useEffect(() => {
		setTimeout(() => {
			setShowConfetii(false);
		}, 1300);
	}, [message]);
	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
			<Box>
				<Box textAlign={"center"} mb={"26px"} mt={"26px"}>
					<Typography
						fontSize={24}
						fontWeight={600}
						lineHeight={"normal"}
						color={"#FFF"}
					>
						We just sent a test cart recovery message to
					</Typography>
					<Typography
						fontSize={24}
						fontWeight={700}
						lineHeight={"normal"}
						color={"#0081FF"}
					>
						{recipientPhone}
					</Typography>
				</Box>

				<Box display={"flex"} justifyContent={"center"} mb={"54px"} className='text-example'>
					<Box width={"218px"} position={"relative"}>
						<Box height={"238px"} overflow={"hidden"}>
							<img src="/img/onboarding/example-1.svg" />
						</Box>
						<Box
							position={"absolute"}
							zIndex={9999}
							display={"flex"}
							gap={"8px"}
							sx={{
								top: "50px",
								left: "26px",
								overflowY: "scroll",
							}}
							maxHeight={"191px"}
							overflow={"hidden"}
						>
							<Box
								bgcolor={"#E6F3FF"}
								width={"129px"}
								sx={{
									borderRadius: "4px",
								}}
								p={'6px'}
							>
								<Typography fontSize={10} style={{ wordWrap: "break-word", whiteSpace: 'pre-line' }}>
									{message}
								</Typography>
								<Typography
									textAlign={"end"}
									color={"#808698"}
									fontSize={10}
									fontWeight={500}
									lineHeight={"16px"}
									mt={1}
								>
									Delivered · 1m
								</Typography>
							</Box>
							<Box display={"flex"} alignItems={"end"}>
								<Box
									sx={{
										width: "32px",
										height: "32px",
									}}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
								>
									<img src="/icons/txt-cart-chat-icon.svg" />
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>

				<Box display={"flex"} justifyContent={isMobile ? "center" : "end"}>
					<Box
						display={"flex"}
						gap={"8px"}
						flexDirection={isMobile ? "column" : "row"}
					>
						<Button
							size={"small"}
							sx={{
								height: "40px",
								backgroundColor: "transparent",
								padding: isMobile ? "11px 15px" : "11px 31px",
								border: "1px solid #818993",
								gap: "8px",
								color: "#FFF",
								":hover": {
									color: mode == "light" ? "#FFF" : "#FFF",
									border: "1px solid #818993",
								},
								fontSize: "14px",
								fontWeight: "400",
							}}
							variant="outlined"
							onClick={() => {
								showMessages();
								showNewMessage("I am currently onboarding and did not receive the test message.");
							}}
						>
							<img src="/img/onboarding/message-icon.svg" />I didn’t receive the
							message
						</Button>
						<Button
							size={"small"}
							sx={{
								height: "40px",
								backgroundColor: "#0081FF",
								padding: "11px 31px",
								color: mode == "light" ? "#FFF" : "#FFF",
								":hover": {
									color: mode == "light" ? "#FFF" : "#FFF",
								},
								fontSize: "14px",
								fontWeight: "600",
							}}
							variant="outlined"
							onClick={() => {
								handleClose();
								// setOnboarding(false)
								// navigate("/onboading-step-terms");
							}}
						>
							Take me to Dashboard
						</Button>
					</Box>
				</Box>
			</Box>
		</MainContainer>
	);
}
const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				padding: "20px",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "724px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				// backgroundColor: lightMode ? "#cacae2" : "#cacae2",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				"::-webkit-scrollbar": {
					display: "none",
				},
				// height: "935px",
			}}
		>
			{children}
		</Box>
	);
};
