/* eslint-disable react/no-unknown-property */
import React from "react";

export default function Emoji() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M7.87187 13.0019C7.67368 12.8393 7.51612 12.6689 7.39556 12.5002H12.6038C12.4832 12.6689 12.3257 12.8393 12.1275 13.0019C11.5919 13.4414 10.8392 13.7502 9.99967 13.7502C9.16019 13.7502 8.40749 13.4414 7.87187 13.0019Z"
				fill="#808698"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0.833008 10.0002C0.833008 4.93755 4.93706 0.833496 9.99967 0.833496C15.0623 0.833496 19.1663 4.93755 19.1663 10.0002C19.1663 15.0628 15.0623 19.1668 9.99967 19.1668C4.93706 19.1668 0.833008 15.0628 0.833008 10.0002ZM7.49968 6.25016C6.80932 6.25016 6.24968 6.80981 6.24968 7.50016C6.24968 8.19052 6.80932 8.75016 7.49968 8.75016C8.19004 8.75016 8.74968 8.19052 8.74968 7.50016C8.74968 6.80981 8.19004 6.25016 7.49968 6.25016ZM12.4997 6.25016C11.8093 6.25016 11.2497 6.80981 11.2497 7.50016C11.2497 8.19052 11.8093 8.75016 12.4997 8.75016C13.19 8.75016 13.7497 8.19052 13.7497 7.50016C13.7497 6.80981 13.19 6.25016 12.4997 6.25016ZM6.24967 10.8335C5.78944 10.8335 5.41634 11.2066 5.41634 11.6668C5.41634 12.6872 6.01264 13.6322 6.81467 14.2903C7.63311 14.9619 8.75541 15.4168 9.99967 15.4168C11.2439 15.4168 12.3662 14.9619 13.1847 14.2903C13.9867 13.6322 14.583 12.6872 14.583 11.6668C14.583 11.2066 14.2099 10.8335 13.7497 10.8335H6.24967Z"
				fill="#808698"
			/>
		</svg>
	);
}
