import SecondaryButton from "components/Buttons/SecondaryButton";
import EmptyComponentTable from "components/Table/EmptyComponentTable";
import React from "react";
import { useNavigate } from "react-router-dom";



export default function EmptyTableData() {
    const navigate = useNavigate();
	return (
		<EmptyComponentTable
			title="You have no campaigns yet"
			desc="Create your first SMS campaign to get in touch with your customers"
			button={<SecondaryButton
                onClick={() => navigate("/campaigns/create-campaigns")}
            >Create a Campaign</SecondaryButton>}
		/>
	);
}