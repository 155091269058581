import { useTheme } from "@emotion/react";
import { Box, Checkbox, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import CartRecoveryChart from "../../../components/Chart/Cart-recovery";
import { parseDateFilter } from "utilifyFunctions";
import { txtCartFetch } from "utilifyFunctions";
import { getDateChartTick } from "utilifyFunctions";
import { addDays } from "utilifyFunctions";
export default function ChartSection({ mode, filter }) {
	const [showConversationsGraph, setShowConversationsGraph] = useState(true);
	const [showRepliesGraph, setShowRepliesGraph] = useState(true);
	const [showOrdersGraph, setShowOrdersGraph] = useState(true)
	const [insights, setInsights] = useState([]);
	const [max, setMax] = useState(0);
	const theme = useTheme();
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const lightMode = mode == "light" ? true : false;

	const getInsights = async function(dateRange,filter){
		let URL = process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/carts/insights"+dateRange;
		if(dateRange){
			URL += "&dateFilter="+filter;
		}
		const response = await txtCartFetch("GET",URL);
		if(response){
			setMax(response.max);
			for(let i = 0; i < response.dataPoints.length; i++){
				response.dataPoints[i].date = getDateChartTick(response.dataPoints[i].date,filter);
			}
			setInsights(response.dataPoints)
		}
	}

	const loadInsights = async function(filter){
		let dateRange;

		if(filter === "all_time"){
			let oldestCart = await getOldestCart();
			filter = checkAllTimeRange(oldestCart);
			console.log('AFTER CHECK', filter);
			dateRange = parseDateFilter(filter, oldestCart);
		} else{
			dateRange = parseDateFilter(filter);
		}

		let urlDateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		console.log(urlDateRange);
		getInsights(urlDateRange,filter);
	}

	const getOldestCart = async function(){
		console.log('GETTING OLDEST CART');
		let oldestCart = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/cart/oldest");
		console.log(oldestCart);
		return oldestCart;
	}

	const checkAllTimeRange = function(oldestCart){
		console.log('ALL TIME RANGE CHECK');
		let MS_IN_HOUR = 60 * 60 * 1000; 
		let MS_IN_DAY = 24 * MS_IN_HOUR;
		console.log(oldestCart);
		if(oldestCart){
			let today = new Date();
			let yesterday = addDays(today,-1);
			let oldestDate = new Date(oldestCart.lastMessage);

			let currentTime = Date.now();
			let diff = currentTime - oldestCart.lastMessage;
			console.log(diff);

			console.log(today);
			console.log(oldestDate);
			if(today.getDate() === oldestDate.getDate() && today.getMonth() === oldestDate.getMonth() && today.getFullYear() === oldestDate.getFullYear()){
				return "today";
			}
			else if(yesterday.getDate() === oldestDate.getDate() && yesterday.getMonth() === oldestDate.getMonth() && yesterday.getFullYear() === oldestDate.getFullYear()){
				return "yesterday";
			}
			else if(diff < (7 * MS_IN_DAY)){
				return "last_days_7";
			}
			else if(diff < (30 * MS_IN_DAY)){
				return "last_days_30";
			}
			else{
				return "full";
			}
		} else{
			return("today");
		}
	}
	
	useEffect(() => {
		loadInsights(filter);
	}, [filter]);

	return (
		<div>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				mb={"15px"}
				mr={"20px"}
			>
				<Box>
					<Typography
						fontSize={"20px"}
						fontWeight={600}
						color={lightMode ? "#0A0A18" : "#EBECF2"}
						ml={"19px"}
						lineHeight={"normal"}
					>
						Insights
					</Typography>
				</Box>
				
				{!belowSmall && (
					<Box display={"flex"} alignItems="center">
						<CustomCheckbox
							color={"#0C84FE"}
							onClick={() => setShowConversationsGraph(!showConversationsGraph)}
							checked={showConversationsGraph}
						/>
						<CustomTypography lightMode={lightMode}>
							Conversations
						</CustomTypography>

						<CustomCheckbox
							color={"#9963FE"}
							onClick={() => setShowRepliesGraph(!showRepliesGraph)}
							checked={showRepliesGraph}
						/>
						<CustomTypography lightMode={lightMode}>Replies</CustomTypography>

						<CustomCheckbox
							color={"#0CD16A"}
							onClick={() => setShowOrdersGraph(!showOrdersGraph)}
							checked={showOrdersGraph}
						/>
						<CustomTypography lightMode={lightMode}>Orders</CustomTypography>
					</Box>
				)}
				<Box
				sx={{
					width:'10%'
				}}
				>
					<>&nbsp;</>
				</Box>
			</Box>
			{belowSmall && (
				<Box display={"flex"} justifyContent={"end"} mb={2}>
					<Box
						display={"flex"}
						justifyContent={"space-between"}
						alignItems={"center"}
						pr={"10px"}
					>
						<span className="cart-recorvery-Ellipse conversation-Ellipse"></span>
						<CustomTypographyMobile lightMode={lightMode}>
							Conversations
						</CustomTypographyMobile>
						<span className="cart-recorvery-Ellipse replies-Ellipse"></span>

						<CustomTypographyMobile lightMode={lightMode}>
							Replies
						</CustomTypographyMobile>
						<span className="cart-recorvery-Ellipse orders-Ellipse"></span>

						<CustomTypographyMobile lightMode={lightMode}>
							Orders
						</CustomTypographyMobile>
					</Box>
				</Box>
			)}
			<CartRecoveryChart
				mode={mode}
				showConversationsGraph={showConversationsGraph}
				showOrdersGraph={showOrdersGraph}
				showRepliesGraph={showRepliesGraph}
				data = {insights}
				max= {max}
			/>
		</div>
	);
}

const CustomTypography = ({ children, lightMode, ...props }) => {
	const textColor = lightMode ? "#0A0A18" : "#FFF";

	return (
		<Typography
			fontSize={14}
			fontWeight={500}
			color={textColor}
			{...props}
			lineHeight={"normal"}
		>
			{children}
		</Typography>
	);
};
const CustomCheckbox = ({ color, ...props }) => {
	return (
		<Checkbox
			{...props}
			sx={{
				color: color,
				"&.Mui-checked": {
					color: color,
				},
			}}
		/>
	);
};
const CustomTypographyMobile = ({ children, lightMode, ...props }) => {
	const textColor = lightMode ? "#0A0A18" : "#FFF";

	return (
		<Typography
			fontSize={12}
			fontWeight={500}
			mr={"12px"}
			color={textColor}
			{...props}
		>
			{children}
		</Typography>
	);
};
