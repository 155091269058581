import { Button, Typography, useTheme } from "@mui/material";
import React from "react";

export default function DowngradeButton({ children, ...props }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	return (
		<Button
			size={"small"}
			sx={{
				padding: "11px 30px",
				border: mode == "light" ? "1px solid #FF004C" : "1px solid #FF004C",
				color:"#FFF",
				backgroundColor: "#FF004C",
				"&:hover": {
					color: mode == "light" ? "#FF004C" : "#FF004C",
				},
			}}
			{...props}
		>
			<Typography
				fontSize={"14px"}
				fontWeight={"600"}
				
				lineHeight={"normal"}
				
			>
				{children}
			</Typography>
		</Button>
	);
}
