import React from "react";
import { Grid, Typography, Box, useTheme, Tooltip} from "@mui/material";
import ConfettiExplosion from "react-confetti-explosion";
import MetricsCardSkelton from "../Skeleton/MetricsCard"

export default function MetricCard({ key, mode, img, title, value }) {
	let showSkeleton = (value === undefined || value === null);

	const lightMode=mode=='light'?true:false

	const getMode = useTheme();
	const [confetti, setConfetti] = React.useState({
		show: false,
		coordinates: { x: 0, y: 0 },
	});
	const handleCardClick = (e) => {
		const x = e.clientX;
		const y = e.clientY;

		setConfetti({
			show: true,
			coordinates: { x, y },
		});

		setTimeout(() => {
			setConfetti({
				show: false,
				coordinates: { x: 0, y: 0 },
			});
		}, 1000); // Change the duration as needed
	};
	return !showSkeleton ? (
		<Grid
			onClick={handleCardClick}
			container
			id="pointerCursor"
			item
			xs={12}
			sm={12}
			lg={2.24}
			md={5.8}
			justifyContent={"space-between"}
			sx={{
				padding: "15px", 
				borderRadius: "12px",
				border: mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
				background: getMode.palette?.card?.background,
				":hover": {
					boxShadow:
						lightMode
							? "5px 6px 5px 0px rgba(0, 0, 0, 0.1)"
							: "5px 6px 5px 0px rgb(40 40 40)",
				},
			}}
		>
			<Grid item container xs={12}>
				<Grid item xs={12}
				sx={{
					marginBottom:'11px'
				}}
				>
					<Typography
						fontSize={"16px"}
						color={mode === "light" ? "#000D31" : "#EBECF2"}
						fontWeight={600}
						lineHeight={'normal'}
						sx={{
							opacity: "0.5",
						}}
					>
						{title}
					</Typography>
				</Grid>
				<Box padding={"0 50%"}>
					{confetti.show && (
						<ConfettiExplosion particleSize={7} particleCount={30}  force={1} width={300} height={200}/>
					)}
				</Box><Grid item container xs={12} alignItems="center" style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Tooltip title={value} placement="top">
				<Typography
					style={{
						flex: 1, // Allow text to take as much space as needed
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
					fontSize={"24px"}
					color={mode === "light" ? "#000D31" : "#EBECF2"}
					fontWeight={600}
				>
					{value}
				</Typography>
				</Tooltip>
				<Box style={{ flexShrink: 0, marginLeft: '10px' }}> {/* Prevent the image from shrinking */}
					<img src={`/metrics/${img}.svg`} alt={title} width={"20px"} />
				</Box>
			</Grid>
			</Grid>
		</Grid>

	) : (
		<MetricsCardSkelton mode={mode} key={key} />
	);
}
