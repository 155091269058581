import React, { useState } from "react";
import { Empty, Table } from "antd";
import { Typography, styled, useTheme } from "@mui/material";
import { LastSVG, NextSVG } from "resources/Icons/Table";

const Antd = ({
	mode,
	columns = [],
	rows = [],
	page = "mainPage",
	TableHead,
	EmptyComponentTable,
	loading=false
}) => {
	const lightMode = mode == "light" ? true : false;
	const theme = useTheme();

	const [currentPage, setCurrentPage] = useState(1);
	const itemRender = (_, type, originalElement) => {
		if (type === "prev") {
			return <LastSVG />;
		}
		if (type === "next") {
			return <NextSVG />;
		}
		return originalElement;
	};
	const CustomEmptyComponent = () => <Empty description="No Empty Data" />;
	const customLocale = {
		emptyText: EmptyComponentTable ?? <CustomEmptyComponent />,
	};
	return (
		<>
			<div
				className="conversationTable"
				style={{
					background: theme.palette?.card?.background,

					borderRadius: "12px",
					padding: 0,
					margin: 0,
				}}
			>
				{TableHead && TableHead}
				<CustomTable
					locale={loading==false&&customLocale}
					columns={columns}
					TableHead={TableHead}
					dataSource={rows}
					page={page}
					loading={rows == null}
					pagination={
						page != "cart-recovery" && {
							showQuickJumper: false,
							total: rows?.length,
							current: currentPage,
							defaultCurrent: 1,
							visible: false,
							defaultPageSize: 20,
							showTotal: (total) => (
								<Typography
									color={lightMode ? "#000D31" : "#FFF"}
								>{`${currentPage}/${Math.ceil(total / 20)}`}</Typography>
							),
							onChange: (page) => setCurrentPage(page),
							itemRender: itemRender,
						}
					}
					rowClassName="No-hover"
					className="conversation-table-new"
				/>
			</div>
		</>
	);
};

export default Antd;

const CustomTable = styled(Table)(({ theme, page, TableHead }) => ({
	"& .ant-table-thead .ant-table-cell": {
		backgroundColor: theme.palette?.card?.background,
		padding: "10px  0  10px 20px !important",
		// minWidth: "100px",
	},
	"& .ant-table-thead th:first-child": {
		"border-start-start-radius": TableHead
			? "0px !important"
			: "9px !important",
	},
	"& .ant-table-thead th:last-child": {
		"border-start-end-radius": TableHead ? "0px !important" : "9px !important",
	},
	"& .ant-table-tbody tr:last-child td:first-child": {
		"border-end-start-radius":
			page == "cart-recovery" ? "8px !important" : "0px !important",
	},
	"& .ant-table-tbody tr:last-child  td:last-child": {
		"border-end-end-radius":
			page == "cart-recovery" ? "8px !important" : "0px !important",
	},

	"& .ant-table  .ant-table-content": {
		overflow: "auto",
		height: "auto",
	},
	"& .ant-table  table": {
		width: "100%",
		borderRadius: "0px",
	},
	"& .No-hover .ant-table-cell-row-hover": {
		background:
			theme.palette?.mode == "light" ? "`} !important" : "#0F111B !important",
	},

	"& .ant-table": {
		PointerEvent: "none",
		borderTopRightRadius: TableHead ? "0px !important" : "10px !important",
		borderTopLeftRadius: TableHead ? "0px !important" : "10px !important",
		borderRadius: "0px",

		borderTop: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
		borderBottomRightRadius:
			page == "cart-recovery" ? "8px !important" : "0px !important",
		borderBottomLeftRadius:
			page == "cart-recovery" ? "8px !important" : "0px !important",
		borderRight: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
		borderLeft: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
		borderBottom: "none !important",
	},
	"& .ant-table-thead >tr>th ": {
		borderBottom: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
	},
	"& .ant-table-tbody >tr >td": {
		borderBottom: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
		padding: "15px  0  17px 20px !important",
		backgroundColor: theme.palette?.card?.background,
	},
	"& .ant-table-tbody .ant-table-cell:hover":{
		background:`${theme.palette?.card?.background} !important`
	},
	"& .ant-table-thead >tr>th ::before": {
		backgroundColor: "transparent !important",
	},
	"& .ant-table-thead .ant-table-cell::before": {
		display: "none",
	},
	"& .ant-pagination-item": {
		display: "none !important",
	},
	"& .ant-pagination-total-text": {
		marginTop: "5px",
	},
	"& .ant-pagination": {
		borderLeft: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
		borderRight: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
		borderBottomLeftRadius: "12px !important",
		borderBottomRightRadius: "12px !important",
		borderBottom: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
	},
	"& .ant-pagination-prev": {
		"margin-inline-end": "0px !important",
		borderTopLeftRadius: "8px !important",
		borderTopRightRadius: "0px !important",
		borderBottomLeftRadius: "8px !important",
		borderBottomRightRadius: "0px !important",
		border: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
	},
	"& .ant-pagination-next": {
		borderTopLeftRadius: "0px !important",
		borderTopRightRadius: "8px !important",
		borderBottomLeftRadius: "0px !important",
		borderBottomRightRadius: "8px !important",
		border: `1px solid ${
			theme.palette?.mode == "light" ? "#E8EBF3" : "#1E202D"
		}`,
	},
	"& .ant-pagination-next::hover": {
		background: "tranparent",
	},
	"& .ant-pagination-item-link": {
		color: `${theme.palette?.pageHeading} !important`,
	},
	"& .ant-table-content::-webkit-scrollbar": {
		height: "3px",
		width: "2px" /* Width of the entire scrollbar */,
		background:
			theme.palette?.card?.background /* Background color of the track */,
	},
	
}));
