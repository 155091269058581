import { Button, useTheme } from "@mui/material";
import React from "react";

export default function AlternativeButton({children,...props}) {
    const theme=useTheme()
    const {mode}=theme.palette
	return (
		<Button
        
			size={"small"}
			sx={{
                height:'40px',
				padding: "11px 30px",
				border: mode == "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
				color: theme.palette?.pageHeading,
				// marginRight: "10px",
                fontSize:'14px',
                fontWeight:'600'
			}}
			variant="outlined"
            {...props}
		>
				{children}
		</Button>
	);
}
