import {
	Box,
	Typography,
	useTheme,
} from "@mui/material";
import SettingsTable from "components/Table/SettingsTable";
import TableHeading from "components/Typography/TableHeading";
import React, { useEffect, useState } from "react";
import { Mixpanel } from "../../../utils/mixpanel";
import { PAGES, EVENT_CATEGORIES } from "../../../constants/mixPanelEvents";

export default function Discount({ settings }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const [discounts, setDiscounts] = useState([]);

	useEffect(()=>{
		Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
			page: PAGES.DISCOUNTS,
		});
	},[])

	useEffect(() => {
		let discounts = [
			{
				code: settings.discountCode,
				type: "Percent Off",
				value: settings.discountValue,
			}	
		]
		setDiscounts(discounts);
	}, [settings]);

	const columns = [
		{
			dataIndex: "code",
			key: "code",
			title: <TableHeading minWidth={"150px"}>Code Name</TableHeading>,
			sortable: false,
			width: "40%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					lineHeight={"normal"}
					mb={"10px"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "type",
			key: "type",
			title: <TableHeading minWidth={"150px"}>Discount Type</TableHeading>,
			sortable: false,
			width: "40%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					lineHeight={"normal"}
					mb={"10px"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "value",
			key: "value",
			title: <TableHeading minWidth={"200px"}>Value</TableHeading>,
			sortable: false,
			width: "20%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					lineHeight={"normal"}
					mb={"10px"}
				>
					{params}
				</Typography>
			),
		},
	];

	return (
		<Box
			sx={{
				borderRadius: "12px",
				border: `1px solid ${theme?.palette?.card?.border}`,
				backgroundColor: theme?.palette?.card?.background,
			}}
		>
			<Typography
				p={"20px 0px 0px 20px"}
				fontSize={"16px"}
				fontWeight={600}
				color={theme?.palette?.pageHeading}
			>
				Manage Custom SMS Discount Codes
			</Typography>
			<SettingsTable columns={columns} rows={discounts} />
		</Box>
	);
}

