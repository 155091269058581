import {  Grid } from "@mui/material";
import React from "react";
import FeaturedApps from "./components/FeaturedApps";
import IntegrationApps from "./components/IntegrationApps";
// import Banner from "./components/Banner";

export default function Integation() {
	return (
		<Grid container spacing={2} alignItems={"center"}>
			{/* <Grid item xs={12}>
				<Banner />
			</Grid> */}
			<Grid item xs={12}>
				<FeaturedApps />
			</Grid>
			<Grid item xs={12}>
				<IntegrationApps />
			</Grid>
		</Grid>
	);
}
