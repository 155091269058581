/* eslint-disable no-unused-vars */
import {
	Box,
	Button,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeading from "components/Typography/PageHeading";
import SecondaryButton from "components/Buttons/SecondaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import Overview from "./Components/Overview";
import Conversation from "../components/Conversation";
import CampaignData from "./Components/Campaign-Data";
import { BackArrow } from "resources/Icons/Arrows";
import ConfirmModal from "components/Modal/Confirm";
import { txtCartFetch } from "utilifyFunctions";
import SendMessageConfirmation from 'components/Modal/SendMessageConfirmation'
import { Mixpanel } from "utils/mixpanel";
import { PAGES, BUTTONS } from "constants/mixPanelEvents";

export default function Lists() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [confirmDraft, setConfirmDraft] = useState(false);
	const [confirmSendNow, setConfirmSendNow] = useState(false);
	const [campaignId, setCampaignId] = useState(null);
	const [targetAudience, setTargetAudience] = useState(null);
	const [textValue, setTextValue] = useState("");
	const [name, setName] = useState("");
	const [value, setValue] = useState("");
	const [image, setImage] = useState(null);
	const [smsCount, setSmsCount] = useState(null);
	const [fileName, setFileName] = useState("");
	const [totalRecipients, setTotalRecipients] = useState("Calculating...");
	const [smsRecipients, setSmsRecipients] = useState("Calculating...");
	const [mmsRecipients, setMmsRecipients] = useState("Calculating...");
	const [estimatedCost, setEstimatedCost] = useState("Calculating...");
	const [isScheduled, setIsScheduled] = useState();
	const [costCalculated, setCostCalculated] = useState(false);
	const [sendNowConfirmMessage, setSendNowConfirmMessage] = useState("");
	const [confirmSchedule, setConfirmSchedule] = useState("");
	const [scheduleConfirmMessage, setScheduleConfirmMessage] = useState("");
	const [sendMessageModal, setSendMessageModal] = useState(false)
	const [testPhone, setTestPhone] = useState("");

	const confirmDraftAgree=()=>{
		Mixpanel.track_button_action(PAGES.REVIEW_CAMPAIGN, BUTTONS.SAVE_DRAFT);
		navigate("/campaigns");
    }
    const confirmDraftDisagree=()=>{
        setConfirmDraft(false)
    }

    const confirmScheduleCancel=()=>{
        setConfirmSchedule(false)
    }

	const openSendNowModal = function(){
		console.log("open send now modal");
		if(costCalculated){
			setConfirmSendNow(true);
		} else{
			alert("Cost of campaign is still calculating. Please wait for processing to complete before sending.");
		}
	}

	const openScheduleModal = function(){
		if(costCalculated){
			setConfirmSchedule(true);
		} else{
			alert("Cost of campaign is still calculating. Please wait for processing to complete before sending.");
		}
	}

	const schedule = async function(){
		Mixpanel.track_button_action(PAGES.REVIEW_CAMPAIGN, BUTTONS.SCHEDULE_NOW);
		let body = {id:campaignId, cost:estimatedCost}
		try{
			const result = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/Campaign/Schedule", body);
			if(result){
				navigate("/campaigns");
			}
		} catch(err){
			console.log(err);
			if(err){
				alert("There was an issue sending your campaign. Please try again or contact support");
			}
		}
	}

	const confirmSendNowCancel=() =>{
		setConfirmSendNow(false);
	}
	const sendNow = async function(){
		let body = {id:campaignId,cost:estimatedCost}
		try{
			Mixpanel.track_button_action(PAGES.REVIEW_CAMPAIGN, BUTTONS.SEND_NOW);
			const result = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/Campaign/Send", body);
			if(result){
				navigate("/campaigns");
			}
		} catch(err){
			console.log(err);
			if(err){
				alert("There was an issue sending your campaign. Please try again or contact support");
			}
		}
	}

	const sendTest = async function(){
		Mixpanel.track_button_action(PAGES.CAMPAIGN_REVIEW, BUTTONS.SEND_TEST);
		if(!testPhone){
			alert("No phone number on file. Please contact support to update your phone number.");
		}
		setSendMessageModal(false);
		let body = {
			id: campaignId
		}
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/campaign/send/test",body);
		console.log(response);
		if(response.success){
			alert("Test message sent!")
		} else{
			alert("There was an issue sending your test message. Please try again or contact support");
		}
	}

	const loadPhone = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/owner_phone");
		console.log(response);
		setTestPhone(response);
	}

	const loadCampaign = async function(){
		console.log("GET CID");
		let cid = searchParams.get("cid");
		setCampaignId(cid);
		console.log(cid);
		if(cid){
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/getCampaignData?id="+cid);
			console.log('CAMPAIGN RETRIEVED');
			console.log(response);
			if(!response.htmlMessage){
				alert("The campaign you are attempting to load is not compatible with TxtCart Campaigns 3.0. Please close this alert to start a new campaign.");
				navigate("/campaigns/create-campaigns");
			} else{
				let imageExists = response.imgFileName && response.imgFileName !== "null";
				setName(response.name);
				setTextValue(response.message);
				setValue(response.htmlMessage);
				setSmsCount(response.smsCount);
				setTotalRecipients(response.maxTexts);
				setIsScheduled(response.isScheduled);
				let audience; 
				if(response.filter === 'all'){
					audience = 'All Customers (';
				}
				else if(response.filder === 'new'){
					audience = 'New Customers - Customers with 1 purchase (';
				}
				else if(response.filter === 'loyal'){
					audience = 'Loyal Customers - Total Spend $250+ ('
				}
				else if(response.filter === 'engaged'){
					audience = 'Engaged Customers - Bought in last 30 days ('
				}
				else if(response.filter === 'notRecovered'){
					audience = 'Abandoned Checkout: Not recovered (';
				}
				else if(response.filter === 'recovered'){
					audience = 'Abandoned Checkout: Recovered (';
				}
				else if(response.filter === 'email'){
					audience = 'Email Opt-In Customers (';
				}
				else if(response.filter === 'disengaged'){
					audience = 'Unengaged Customers: Hasn’t purchased last 7 days (';
				}

				audience += response.maxTexts + ')';
				setTargetAudience(audience);

				if(imageExists){
					let imgURL = "https://txtcartcampimagebucket.s3.us-east-2.amazonaws.com/" + response.image;
					setImage(`<img src="${imgURL}" alt="Image" width='200px' />`);
					setFileName(response.imgFileName);
				} else{
					setFileName("No image selected");
				}
				const costResponse = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/getCampaignCost?image="+imageExists+"&length="+response.message.length+"&isGSM="+response.isGSM+"&filter="+response.filter);
				setCostCalculated(true);
				console.log("Cost Response");
				console.log(costResponse);
				setSendNowConfirmMessage("By selecting Send you agree to a $" + costResponse.totalCost + " one time charge. This campaign will begin sending immediately. Messages will not be delivered to recipients outside of quiet hours. If a campaign is not completed before quiet hours begin, the remaining messages will be sent the following day. ")
				setScheduleConfirmMessage("By selecting Schedule this message will be sent on " + response.formattedDate + ". You agree to a one time charge of $" + costResponse.totalCost+ " at the time of sending. Messages will not be delivered to recipients outside of quiet hours. If a campaign is not completed before quiet hours begin, the remaining messages will be sent the following day.")
				setSmsRecipients(costResponse.sms);
				setMmsRecipients(costResponse.mms);
				setEstimatedCost(costResponse.totalCost);
			}
		} 
	}
	
	useEffect(() => {
		loadPhone();
		loadCampaign();
	}, []);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={12} lg={9}>
				<Grid container spacing={2} alignItems={"center"}>
					<Grid item xs={12}>
						<Box
							display={"flex"}
							justifyContent={"space-between"}
							alignItems={"center"}
							position={"relative"}
							ml={isSmall ? "35px" : "0px"}
						>
							<Box
								position={"absolute"}
								id={"pointerCursor"}
								onClick={() => {
									navigate(-1);
									Mixpanel.track_button_action(PAGES.CREATE_SMS_CAMPAIGN, BUTTONS.REVIEW_CAMPAIGN);
								}
								}
								sx={{
									left: "-30px",
								}}
							>
								<BackArrow />
							</Box>
							<PageHeading>Campaign Overview</PageHeading>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<MainContainer>
							<Overview 
								name={name} 
								targetAudience={targetAudience} 
								message={textValue}
								smsCount={smsCount} 
								fileName={fileName}
								cid={campaignId}/>
						</MainContainer>
					</Grid>
					<Grid item xs={12}>
						<PageHeading pt={"21px"} mb="6px">
							Campaign Data
						</PageHeading>
					</Grid>
					<Grid item xs={12}>
						<MainContainer>
							<CampaignData 
								smsCount={smsCount}
								totalRecipients={totalRecipients}
								smsRecipients={smsRecipients}
								mmsRecipients={mmsRecipients}
								estimatedCost={estimatedCost}
							/>
						</MainContainer>
					</Grid>
					<Grid item xs={12}>
						<Box
							display={"flex"}
							justifyContent={isSmall?'space-between':"end"}
							mt={1}
							
						>	
								<Button
									size={"small"}
									sx={{
										padding: "11px 30px",
										height:'40px',
										border:
											mode === "light"
												? "1px solid #E8EBF3"
												: "1px solid #1E202D",
										color: theme.palette?.pageHeading,
										marginRight: "10px",
										backgroundColor: theme.palette?.card?.background,
									}}
									variant="outlined"
									onClick={()=>confirmDraftAgree()}
								>
									<Typography fontSize={"14px"} fontWeight={"600"}>
										Save Draft
									</Typography>
								</Button>
								{ isScheduled ?  (<SecondaryButton onClick={() => schedule()}>Schedule</SecondaryButton>)
									: (<SecondaryButton onClick={() => sendNow()}>Send Now</SecondaryButton>)}
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} lg={3} md={12} mt={"35px"}>
			<Conversation value={value} image={image} setSendMessageModal={setSendMessageModal} />
			</Grid>
			<ConfirmModal open={confirmSendNow} text={sendNowConfirmMessage} confirmAgree={sendNow} confirmDisagree={confirmSendNowCancel} confirmButtonText="Send" cancelButtonText="Cancel" width="600px"/>
			<ConfirmModal open={confirmSchedule} text={scheduleConfirmMessage} confirmAgree={schedule} confirmDisagree={confirmScheduleCancel} confirmButtonText="Schedule" cancelButtonText="Cancel" width="600px"/>
			<ConfirmModal open={confirmDraft} text="Save Draft?" confirmAgree={confirmDraftAgree} confirmDisagree={confirmDraftDisagree} confirmButtonText="Save" cancelButtonText="Cancel"/>
			<SendMessageConfirmation  open={sendMessageModal} handleClose={()=>setSendMessageModal(false)} sendTest={sendTest} testPhone={testPhone}/>
		</Grid>
	);
}

const MainContainer = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				borderRadius: "12px",
				border: `1px solid ${theme?.palette?.card?.border}`,
				backgroundColor: theme?.palette?.card?.background,
			}}
			{...props}
		>
			{children}
		</Box>
	);
};
export const ChildContainer = ({ children, ...props }) => {
	return (
		<Box
			p={"20px"}
			display={"flex"}
			justifyContent={"space-between"}
			alignItems={"center"}
			{...props}
		>
			{children}
		</Box>
	);
};

export const TextFieldLabel = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<Typography
			fontSize={20}
			fontWeight={600}
			lineHeight={"normal"}
			color={theme.palette?.PageHeading}
			{...props}
		>
			{children}
		</Typography>
	);
};

export const ValueTypography = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<Typography
			fontSize={14}
			fontWeight={500}
			lineHeight={"normal"}
			color={"#808698"}
			{...props}
		>
			{children}
		</Typography>
	);
};
export const ShortCode = ({ children, ...props }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	return (
		<span
			style={{
				backgroundColor: mode == "light" ? "#D9ECFF" : "#1F212D",
				color: "#0081FF",
				padding: "3px 7px",
				borderRadius: "4px",
				lineHeight: "normal",
				marginRight: "5px",
				wordBreak: "",
			}}
		>
			{children}{" "}
			{/* <img src="/icons/short-code-cut.svg" style={{ marginLeft: "10px" }} /> */}
		</span>
	);
};
