import { Box, RadioGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
	CustomMenuItem,
	CustomSelect,
	CustomTypography,
	demoOption,
} from "./Common";
import CustomRadioButton from "components/Inputs/RadioButton";
import { TextInput } from "components/Inputs";

const SelectList = ({ listType, setListType }) => {
	const theme = useTheme();
	// console.log(listType);
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box>
			<Box mb={"21px"}>
				<CustomTypography>
					Import subscribers into a new list or select an existing list
				</CustomTypography>
			</Box>
			<Box mb={"26px"}>
				<RadioGroup
					value={listType}
					onChange={(e) => {
						setListType(e?.target?.value);
					}}
				>
					<Box mb={"13px"} display={"flex"} alignItems={"center"}>
						<CustomRadioButton value={"New List"} />
						<Typography
							color={theme?.palette?.pageHeading}
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"normal"}
						>
							New List
						</Typography>
					</Box>
					<Box display={"flex"} alignItems={"center"}>
						<CustomRadioButton value={"Existing List"} />
						<Typography
							color={theme?.palette?.pageHeading}
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"normal"}
						>
							Existing List
						</Typography>
					</Box>
				</RadioGroup>
			</Box>
			<Box mb={"62px"} width={belowSmall?'100%':"40%"}>
				<Typography
					color={theme?.palette?.pageHeading}
					fontSize={"14px"}
					fontWeight={500}
					lineHeight={"normal"}
					mb={"8px"}
				>
					List Name
				</Typography>
				{listType == "Existing List" ? (
					<CustomSelect placeholder="Select a list">
						{demoOption.slice(0, 2)?.map((item, index) => {
							return (
								<CustomMenuItem value={item} key={index}>
									{item}
								</CustomMenuItem>
							);
						})}
					</CustomSelect>
				) : (
					<TextInput placeholder="Enter a list name"/>
				)}
			</Box>
		</Box>
	);
};

export default SelectList;
