import { Box, Typography, useTheme } from "@mui/material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import React  from "react";

export default function Header({ flowName, handleSave }) {
	const theme = useTheme();
	return (
		<Box
			sx={{
				background: theme?.palette?.card?.background,
				border: `1px solid ${theme?.palette?.card?.border}`,
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				p: "9px 17px",
			}}
		>
			<Box>
				<Typography
					color={theme?.palette?.pageHeading}
					fontSize={20}
					fontWeight={600}
					lineHeight={"normal"}
				>
					{flowName}
				</Typography>
			</Box>
			<Box>
				<PrimaryButton onClick={handleSave}>Save</PrimaryButton>
			</Box>
		</Box>
	);
}
