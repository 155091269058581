import { Box, Grid, Typography, useTheme } from "@mui/material";
import LockedModal from "components/Modal/LockedPage";
import React, { useEffect, useState } from "react";
import CampaignsSection from "templates/Campaign-Planner/components/CampaignsSection";
import VideoModal from "components/VideoModal";

export default function CampaignPlannerLocked() {
	const theme = useTheme();
	const [showVideoModal, setShowVideoModal] = useState(false);

	useEffect(() => {
		document.body.style.overflow = "hidden";
		const elementsToDisableHover = document.querySelectorAll(".disableSection");
		const disableHoverClass = "disable-hover";
		elementsToDisableHover.forEach((element) => {
			element.classList.add(disableHoverClass);
		});
	}, []);
	return (
		<Box>
			<Box
				sx={{
					position: "relative",
					height: "100vh",
					WebkitFilter: "blur(4px)",
				}}
				className="disableSection"
			>
				<Grid container spacing={2} alignItems={"center"}>
					<Grid item xs={12}>
						<Typography
							lineHeight={"normal"}
							fontSize={"24px"}
							fontWeight={600}
							color={theme.palette?.card?.color}
							mb={"8px"}
						>
							Campaign Planner
						</Typography>
						<Typography
							lineHeight={"20px"}
							fontSize={"14px"}
							fontWeight={500}
							color={theme.palette?.card?.color}
							mb={"24px"}
							sx={{
								opacity: "0.5",
							}}
						>
							Use the upcoming events to engage with your subscribers and send
							high-converting SMS campaigns
						</Typography>
						<CampaignsSection />
					</Grid>
				</Grid>
			</Box>
			<LockedModal setShowVideoModal={setShowVideoModal} message="Send customized one-time SMS/MMS blasts to your subscriber list and drive more revenue with SMS Campaigns" showWatchVideo={true}/>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={"https://www.youtube.com/watch?v=sYmTnWyolWU"}
				/>
		</Box>
	);
}
