/* eslint-disable no-unused-vars */
import React from "react";
import {
	Typography,
	useTheme
} from "@mui/material";

export default function Option({index,label,value,handleOptionSelect}) {
    const handleClick = () => {
        handleOptionSelect(label,value);
	}

    return (
        <>
            <Typography
                key={index}
                sx={{
                    padding: "8px 15px",
                    ":hover": {
                        background: "#cccaca3b",
                    },
                }}
                fontSize={"14px"}
                id="pointerCursor"
                onClick={handleClick}
            >
                {label}
            </Typography>
        </>
    )
}