import React from "react";
import {
	Box,
	useTheme,
	useMediaQuery,
    Skeleton,
} from "@mui/material";
export default function Card({ mode, id}) {
	const getMode = useTheme();
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Box
			id={id}
			sx={{
				width: belowTablet ? "100%" : "32%",
				display: "flex",
				flexDirection: "column",
				padding: "20px",
				borderRadius: "12px",
				border: mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
				background: getMode.palette?.card?.background,
			}}
		>
			<Box sx={{ width: '100%' }}>
				<Skeleton animation="wave" />
				<Skeleton variant="rectangular" animation="wave" height={40} />
			</Box>
		</Box>
	);
}
