/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { CustomHeading, CustomSubHeading } from "..";
import { CustomSelect } from "components/Inputs";
import { Add, DownArrow } from "resources/Icons/Arrows";
import {
	Box,
	Button,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { DeleteSVG } from "resources/Icons";
import { Select } from "antd";
import { txtCartFetch } from "utilifyFunctions";

const { Option, OptGroup } = Select;

export default function TargetAudience({
	setExcludeAudience,
	excludeAudience,
	setTargetAudience,
	setMaxTexts,
	value,
	segments
}) {
	const theme = useTheme();
	const { mode } = theme.palette;

	const lightMode = mode == "light" ? true : false;
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [allCustomersDisplay, setAllCustomersDisplay] = useState('All Customers (-)');
	const [newCustomersDisplay, setNewCustomersDisplay] = useState('New Customers - Customers with 1 purchase (-)');
	const [loyalCustomerDisplay, setLoyalCustomerDisplay] = useState('Loyal Customers - Total Spend $250+ (-)');
	const [engagedCustomerDisplay, setEngagedCustomerDisplay] = useState('Engaged Customers - Bought in last 30 days (-)');
	const [abandonedNotRecoveredDisplay, setAbandonedNotRecoveredDisplay] = useState('Abandoned Checkout: Not Recovered (-)');
	const [abandonedRecoveredDisplay, setAbandonedRecoveredDisplay] = useState('Abandoned Checkout: Recovered (-)');
	const [emailOptInDisplay, setEmailOptInDisplay] = useState('Email Opt-In Customers (-)');
	const [disengagedCustomersDisplay, setDisengagedCustomerDisplay] = useState('Disengaged Customers: No purhcase in 7 days (-)');
	const [segmentSizes, setSegmentSizes] = useState({});

	useEffect(() => {
		if(segments){
			setSegmentSizes(segments);
			setAllCustomersDisplay('All Customers (' + segments.all + ')');
			setNewCustomersDisplay('New Customers - Customers with 1 purchase (' + segments.new + ')');
			setLoyalCustomerDisplay('Loyal Customers - Total Spend $250+ (' + segments.loyal + ')');
			setEngagedCustomerDisplay('Engaged Customers - Bought in last 30 days (' + segments.engaged + ')');
			setAbandonedNotRecoveredDisplay('Abandoned Checkout: Not recovered (' + segments.notRecovered + ')');
			setAbandonedRecoveredDisplay('Abandoned Checkout: Recovered (' + segments.recovered + ')');
			setEmailOptInDisplay('Email Opt-In Customers (' + segments.email + ')');
			setDisengagedCustomerDisplay('Unengaged Customers: Hasn’t purchased last 7 days (' + segments.disengaged + ')');
			console.log('USING EFFECT');
		}
	}, [segments]);

	const handleChange = function(e){
		console.log('handle change');
		console.log(e);
		setTargetAudience(e);
		setMaxTexts(segmentSizes[e]);
	}
	return (
		<React.Fragment>
			<CustomHeading theme={theme} mb={2}>
				Target Audience / Segment
			</CustomHeading>
			<CustomSubHeading theme={theme} mb={1}>
				Include audiences
			</CustomSubHeading>
			<CustomSelect
				placeholder="Choose audiences for your campaign"
				suffixIcon={<DownArrow />}
				onChange={handleChange}
				prefix={<DownArrow />}
				value={value}
			>
				<Option value='all'>{allCustomersDisplay}</Option>
				<OptGroup label={"SEGMENTS"}>
					<Option value='new'>{newCustomersDisplay}</Option>
					<Option value='loyal'>{loyalCustomerDisplay}</Option>
					<Option value='engaged'>{engagedCustomerDisplay}</Option>
					<Option value='notRecovered'>{abandonedNotRecoveredDisplay}</Option>
					<Option value='recovered'>{abandonedRecoveredDisplay}</Option>
					<Option value='email'>{emailOptInDisplay}</Option>
					<Option value='disengaged'>{disengagedCustomersDisplay}</Option>
				</OptGroup>
			</CustomSelect>
			{/* {excludeAudience ? (
				<Box>
					<Box
						bgcolor={"rgba(0, 129, 255, 0.15);"}
						p={"6px 12px"}
						borderRadius={"4px"}
						width={"55px"}
						id="pointerCursor"
						m={"12px 0px"}
					>
						<Typography
							fontSize={"14px"}
							fontWeight={600}
							color={"#0081FF"}
							lineHeight={"normal"}
						>
							AND
						</Typography>
					</Box>
					<CustomSubHeading theme={theme} mb={1}>
						Exclude audiences
					</CustomSubHeading>
					<Box display={"flex"} flexDirection={isSmall ? "column" : "row"}>
						<CustomSelect
							placeholder="Choose audiences for your campaign"
							suffixIcon={<DownArrow />}
							width="90%"
							prefix={<DownArrow />}
						>
							<Option value={"All Customers - (8239)"}>
								All Customers - (8239)
							</Option>
							<Option value={"All US/CAD Customers - (122)"}>
								All US/CAD Customers - (122)
							</Option>
							<OptGroup label={"SEGMENTS"}>
								{targetAudienceOptionMock?.map((item, index) => {
									return (
										<Option key={index} value={item?.value}>
											{item?.value}
										</Option>
									);
								})}
							</OptGroup>
						</CustomSelect>
						<Button
							sx={{
								padding: "6px",
								border: `1px solid ${theme.palette?.card?.border}`,
								backgroundColor: lightMode
									? "#FFF"
									: " rgba(255, 255, 255, 0.10);",
								lineHeight: "normal",
								width: "32px",
								minWidth: "32px",
								height: "40px",
								marginLeft: isSmall ? "0px" : "12px",
								marginTop: isSmall ? "12px" : "0px",
							}}
							variant="outlined"
							onClick={() => setExcludeAudience(false)}
						>
							<DeleteSVG />
						</Button>
					</Box>
				</Box>
			) : (
				<Button
					sx={{
						padding: "11px 20px",
						border: `1px solid ${theme.palette?.card?.border}`,
						backgroundColor: "transparent",
						marginRight: "20px",
						marginTop: "12px",
					}}
					variant="outlined"
					onClick={() => setExcludeAudience(true)}
				>
					<Add />
					<CustomSubHeading theme={theme} ml={"10px"}>
						Exclude audiences (optional)
					</CustomSubHeading>
				</Button>
			)} */}
		</React.Fragment>
	);
}

const targetAudienceOptionMock = [
	{ group: "SEGMENTS", value: "All UK/AUS Customers - (438)" },
	{ group: "SEGMENTS", value: "Abandoned Checkout Not Recovered - (3489)" },
	{ group: "SEGMENTS", value: "30+ days No Contact - (383)" },
	{ group: "SEGMENTS", value: "Email Opt In Customers - (7837)" },
	{ group: "SEGMENTS", value: "Customers Texted More than Once - (988)" },
	{ group: "SEGMENTS", value: "Customers with One Purchase - (219)" },
	{ group: "SEGMENTS", value: "Customers with 2+ Purchases - (87)" },
	{ group: "SEGMENTS", value: "Purchased in Last 30 Days - (99)" },
	{ group: "SEGMENTS", value: "No Purchase in Last 7 Days - (3737)" },
	{ group: "SEGMENTS", value: "Total Spend Under $100 - (200)" },
	{ group: "SEGMENTS", value: "Total Spend Between $101-499 - (438)" },
	{ group: "SEGMENTS", value: "Total Spend Over $500 - (73)" },
];

const OptionTest = () => {
	return (
		<>
			<Option value={"All Customers - (8239)"}>All Customers - (8239)</Option>
			<Option value={"All US/CAD Customers - (122)"}>
				All US/CAD Customers - (122)
			</Option>
			<OptGroup label={"SEGMENTS"}>
				<Option value={"Total Spend Over $500 - (73)"}>
					Total Spend Over $500 - (73)
				</Option>
				<Option value={"Total Spend Between $101-499 - (438)"}>
					Total Spend Between $101-499 - (438)
				</Option>
			</OptGroup>
		</>
	);
};

const test = [
	{
		// label: "",
		options: [
			{ label: "All Customers - (8239)", value: "All Customers - (8239)" },
			{
				label: "All US/CAD Customers - (122)",
				value: "All US/CAD Customers - (122)",
			},
		],
	},
	{
		label: "SEGMENTS",
		options: [
			{
				label: "All UK/AUS Customers - (438)",
				value: "All UK/AUS Customers - (438)",
			},
			{
				label: "Abandoned Checkout Not Recovered - (3489)",
				value: "Abandoned Checkout Not Recovered - (3489)",
			},
			{
				label: "30+ days No Contact - (383)",
				value: "30+ days No Contact - (383)",
			},
			{
				label: "Email Opt In Customers - (7837)",
				value: "Email Opt In Customers - (7837)",
			},
			{
				label: "Customers Texted More than Once - (988)",
				value: "Customers Texted More than Once - (988)",
			},
			{
				label: "Customers with One Purchase - (219)",
				value: "Customers with One Purchase - (219)",
			},
			{
				label: "Customers with 2+ Purchases - (87)",
				value: "Customers with 2+ Purchases - (87)",
			},
			{
				label: "Purchased in Last 30 Days - (99)",
				value: "Purchased in Last 30 Days - (99)",
			},
			{
				label: "No Purchase in Last 7 Days - (3737)",
				value: "No Purchase in Last 7 Days - (3737)",
			},
			{
				label: "Total Spend Under $100 - (200)",
				value: "Total Spend Under $100 - (200)",
			},
			{
				label: "Total Spend Between $101-499 - (438)",
				value: "Total Spend Between $101-499 - (438)",
			},
			{
				label: "Total Spend Over $500 - (73)",
				value: "Total Spend Over $500 - (73)",
			},
		],
	},
];
