import {
	Box,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import Antd from "components/Table/Antd";
import TableHeading from "components/Typography/TableHeading";
import React, { useEffect, useState } from "react";
import TableHeader from "./TableHeader";
import { compareDateStringsUsingMomemt } from "resources/utils/sorter";
import EmptyTableData from "./EmptyTableData";

export default function TableSecton({data}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const [tableData, setTableData] = useState();
	const [mainData, setMainData] = useState();

	const columns = [
		{
			dataIndex: "name",
			key: "name",
			title: <TableHeading minWidth={"120px"}>Name</TableHeading>,
			sortable: false,
			width: "20%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "subscriberDate",
			key: "subscriberDate",
			title: <TableHeading minWidth={"120px"}>Subscriber Date</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) =>
				compareDateStringsUsingMomemt(a?.subscriberDate, b?.subscriberDate),
			width: "20%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "source",
			key: "source",
			title: <TableHeading minWidth={"120px"}>Source</TableHeading>,
			sortable: false,
			width: "15%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					Online Store
				</Typography>
			),
		},
		{
			dataIndex: "country",
			key: "country",
			title: <TableHeading minWidth={"120px"}>Country</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => a?.country.localeCompare(b?.country),
			width: "20%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "numSales",
			key: "numSales",
			title: <TableHeading minWidth={"60px"}>Orders</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a?.numSales) - parseInt(b?.numSales),
			width: "15%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "totalSpent",
			key: "totalSpent",
			title: <TableHeading minWidth={"30px"}>Spend</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a?.spend) - parseInt(b?.spend),
			width: "10%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					${params?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				</Typography>
			),
		}
	];
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		setMainData([...data]);
	}, [data]);

	useEffect(() => {
		console.log(searchValue, 'searchValue');
		const filteredData = mainData?.filter((subscriber) => {
			return searchValue
				? subscriber.name.toLowerCase().includes(searchValue.toLowerCase())
				: true;
		});
		setTableData(filteredData || mainData);
	}, [searchValue, mainData]);
	return (
		<Box
			sx={{
				borderBottom: `1px solid ${theme.palette?.card?.border}`,
				borderBottomLeftRadius: "12px",
				borderBottomRightRadius: "12px",
			}}
		>
			<Antd
				mode={mode}
				rows={tableData}
				columns={columns}
				EmptyComponentTable={EmptyTableData}
				loading={mainData ? false : true}
				TableHead={
					<TableHeader
						mode={mode}
						theme={theme}
						handleFilterTableData={null}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
					/>
				}
			/>
		</Box>
	);
}