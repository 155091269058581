import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { DataContext } from "context/DataContext";
import CampaignsAnalyticsTemplate from "templates/Analytics/Campaigns";
import { txtCartFetch } from "utilifyFunctions";
import CampaignsAnalyticsLocked from '../templates/LockedPages/Campaign-Analytics';
import { Mixpanel } from "../utils/mixpanel";
import { PAGES, EVENT_CATEGORIES } from "../constants/mixPanelEvents";

class CampaignsAnalytics extends Component {
	static contextType = DataContext;

	constructor(props) {
		super(props);
		this.state = {
		  verified: true
		};
	  }
		componentDidMount() {
			Mixpanel.track(EVENT_CATEGORIES.PAGE_VIEW, {
				page: PAGES.CAMPAIGN_ANALYTICS,				
			});
			this.load();
		}
	
		load = async function(){        
			const { setShowLoading } = this.context;
			setShowLoading(true);
	
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/verify?id=Business");
			this.setState({verified: response});
			try {
				await new Promise((resolve) => setTimeout(resolve, 500));
	
				setShowLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setShowLoading(false);
			}
		}

	render() {
		const { verified } = this.state 
		if(verified){
			return (
				<div>
					<Helmet>
						<title>TxtCart® | Campaigns</title>
					</Helmet>
					<CampaignsAnalyticsTemplate />
				</div>
			);
		} else{
			return (
				<div>
					<Helmet>
						<title>TxtCart® | Campaigns</title>
					</Helmet>
					<CampaignsAnalyticsLocked />
				</div>
			);
		}
	}
}

export default CampaignsAnalytics;
