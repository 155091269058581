import { styled, useTheme } from "@mui/material";
import React from "react";
import DatePicker from "react-datepicker";
import "./datePicker.css";
import "react-datepicker/dist/react-datepicker.css";

export default function CustomDatePicker({ children, ...props }) {
	const theme = useTheme();
	
	return (
		<CustomDate theme={theme} {...props} >
			{children}
		</CustomDate>
	);
}
const CustomDate = styled(DatePicker)(({ theme }) => ({
	width: "100%",
	height: "40px",
	padding: "0 11px",
	border: `1px solid ${theme.palette?.card?.border} !important`,
	color: theme.palette?.pageHeading,
	backgroundColor: theme.palette?.card?.background,
	fontSize: "14px",
	fontWeight: "500",
	borderRadius: "4px",
	"& .react-datepicker-wrapper": {
		width: "100% !important",
	},
    '& .react-datepicker-popper':{
        paddingTop:'0px '
    },
	"& .react-datepicker__input-container": {
		width: "100% !important",
	},
	"& .react-datepicker": {
		backgroundColor: `${theme.palette?.card?.background} !important`,
	},
	"& .react-datepicker__month-container": {
		backgroundColor: `${theme.palette?.card?.background} !important`,
		border: `1px solid ${theme.palette?.card?.border} !important`,
	},
	"& .react-datepicker__header ": {
		backgroundColor: `${theme.palette?.card?.background} !important`,
		border: "none !important",
	},
}));