import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Card from "../../../components/Card/Analytics";

export default function Analytics() {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	const { mode } = theme.palette;
	const dataMock = [
		{
			title: "Current Active Promo",
			value: "None",
			desc: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
			valueType: "number",
		},
		{
			title: "Referrals",
			value: "17",
			desc: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
			valueType: "number",
		},
		{
			title: "Earnings",
			value: "351.98",
			desc: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
			valueType: "amount",
		},
	];

	return (
		<Box>
			<Box
				display="flex"
				flexDirection={belowTablet ? "column" : "row"}
				justifyContent="space-between"
				gap={"10px"}
				mb={"24px"}
			>
				{dataMock?.map((item, index) => {
					return (
						<>
							<Card
								key={index}
								title={item?.title}
								value={item?.value}
								desc={item?.desc}
								mode={mode}
								valueType={item?.valueType}
							/>
						</>
					);
				})}
			</Box>
		</Box>
	);
}
