import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
	Paper,
	Button,
	Typography,
	ThemeProvider,
	createTheme,
	Box,
	Link,
} from "@mui/material";

function Register() {
	const navigate = useNavigate();
	const getMode = useTheme();
	const mode = getMode?.palette?.mode;
	const lightMode = mode == "light" ? true : false;

	const theme = createTheme({
		palette: {
			mode: mode,
		},
	});
	const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

	const handleSubmit = (event) => {
		event.preventDefault();
		navigate("/login");
	};

	return (
		<ThemeProvider theme={theme}>
			<Paper sx={{ padding: 0, margin: 0 }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						height: "auto",
					}}
				>
					<Box
						display={"flex"}
						flexDirection={"inherit"}
						justifyContent={"center"}
						sx={{
							width: isLargeScreen ? "50%" : "100%",
							padding: isLargeScreen ? "8% 8rem" : "70px 1rem",
							height: "100vh",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								width: isLargeScreen ? "500px" : "100%",
							}}
						>
							<img
								src={lightMode ? "/logos/logo2-light.svg" : "/logos/logo2.svg"}
								alt="Logo"
								style={{ width: "150px", height: "auto" }}
							/>

							<form onSubmit={handleSubmit}>
								<Typography
									fontWeight={"700"}
									fontSize={"34px"}
									marginTop={"10px"}
								>
									Connect Your Store
								</Typography>
								<p style={{ fontSize: "13px", fontWeight: "500" }}>
									Enter your Shopify domain
								</p>
								<form>
									<input
										type="text"
										name="domain"
										className="domain-placeholder"
										style={{ margin: "0" }}
									></input>
									<br />
								</form>

								<Box
									marginTop={"14px"}
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										gap: "10%",
										justifyContent: "space-between",
									}}
								></Box>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{
										mt: 2,
										backgroundColor: "#0081FF",
										boxShadow: "none",
										fontSize: "12px",
										fontWeight: "600",
										padding: "10px",
									}}
								>
									Sign Up
								</Button>
								{/* <GoogleLogin lightMode={lightMode} /> */}
								<Box
									sx={{
										mt: 1,
										display: "flex",
										justifyContent: "center",
									}}
								>
									<Typography color={"#969AA5"} fontSize={"12px"}>
										Already have an account?
										<Link
											href="/login"
											variant="body2"
											style={{
												textDecoration: "none",
												paddingLeft: "5px",
												fontWeight: "600",
												fontSize: "12px",
											}}
										>
											Login Here
										</Link>
									</Typography>
								</Box>
							</form>
						</Box>
					</Box>
					{isLargeScreen && (
						<Box
							sx={{
								width: "50%",
								position: "relative",
								maxHeight: "100vh", // Set a maximum height
								overflow: "hidden", // Hide overflow content
							}}
						>
							<img
								src="/logos/Section.svg"
								alt="Section"
								style={{
									width: "100%",
									height: "96vh",
									maxWidth: "100%",
									marginLeft: "2em",
								}} // Ensure the image is responsive
							/>
							<Typography
								fontSize={"16px"}
								lineHeight={"20px"}
								sx={{
									position: "absolute",
									bottom: "23%",
									left: "50%",
									transform: "translateX(-50%)",
									color: "#969AA5",
									padding: "10px",
									width: "100%",
									textAlign: "center",
								}}
							>
								Trusted by brands including:
							</Typography>
						</Box>
					)}
				</Box>
			</Paper>
		</ThemeProvider>
	);
}

export default Register;

