import { Box, Typography } from "@mui/material";
import React from "react";
import {
	XAxis,
	YAxis,
	CartesianGrid,
	ResponsiveContainer,
	Area,
	AreaChart,
	Tooltip,
} from "recharts";

export default function Subscriber({ mode, data = [], graphRange }) {
	const CustomTick = (props) => {
		const { x, y, payload } = props;
		let getX = (value, x) => {
			return x -20; 
		};
		let formattedValue;
		let mappedValue = mapXAxisValue(graphRange);
		if (mappedValue) {
			formattedValue = mappedValue[payload.value];
		} else {
			formattedValue = payload.value;
		}

		return (
			<g>
				<g>
					<text
						x={getX(payload.index, x)}
						y={y + 10}
						fontSize={"14px"}
						opacity={0.5}
						fill={mode == "light" ? "#0A0A18" : "#EBECF2"}
					>
						{formattedValue}
					</text>
				</g>
			</g>
		);
	};
	const CustomYAxisTick = (props) => {
		var { x, y, payload } = props;
		if (payload.value == "4.0") {
			y = y - 7;
		}

		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={0}
					dy={10}
					textAnchor="end"
					opacity={0.5}
					fontSize={"14px"}
					fill={mode == "light" ? "#0A0A18" : "#EBECF2"}
				>
					{payload.value}
				</text>
			</g>
		);
	};

	return (
		<div style={{ width: "100%" }}>
			<ResponsiveContainer width="100%" height={500}>
				<AreaChart
					height={100}
					data={data}
					margin={{
						top: 10,
						right: 19,
						left: 5,
						bottom: 0,
					}}
				>
					<XAxis
						dataKey="date"
						tickLine={false}
						scale={"auto"}
						tickMargin={10}
						tick={<CustomTick />}
						width={5}
					/>
					<YAxis
						tickCount={10}
						axisLine={false}
						tickLine={false}
						tickMargin={20}
						tick={<CustomYAxisTick />}
						type="number"
						allowDecimals={false}
					/>
					<Tooltip content={<CustomTooltip />} />
					<CartesianGrid
						strokeDasharray="1 0"
						vertical={false}
						color={mode == "light" ? "#EBEBEB" : "#303343"}
						opacity={mode == "light" ? 0.5 : 0.2}
					/>
					<defs>
						<linearGradient
							id="subscriber-gradient"
							x1="0"
							y1="1"
							x2="0"
							y2="0"
						>
							<stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
							<stop offset="100%" stopColor="#0C84FE" />
						</linearGradient>
					</defs>
					<Area
						dataKey="count"
						fill="url(#subscriber-gradient)"
						stroke="#0C84FE"
						opacity={1}
						fillOpacity={0.2}
						baseLine={8}
						strokeWidth={2}
						connectNulls={true}
						isAnimationActive={false}
					/>
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
}

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<Box
				className="tooltip"
				sx={{
					padding: "12px 12px",
					background: "#FFF",
					width: "200px",
					opacity: "0.95",
					borderRadius: "3px",
					boxShadow: "rgb(174, 174, 174) 0px 0px 10px",
				}}
			>
				<Typography
					fontSize={12}
					fontWeight={600}
					mb={1}
					color={"#7C7C85"}
					lineHeight={"normal"}
				>
					{label}
				</Typography>

				{payload.map((item) => (
					<div
						key={item.dataKey}
						style={{
							borderColor: item.color,
							margin:
								item?.dataKey == "orders" ? "0px 0px 0px 0px" : "12px 0px",
						}}
						className="tooltip-text"
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								// alignItems:'center'
							}}
						>
							<span
								className={`${item?.dataKey}`}
								style={{ marginRight: "10px ", marginTop: "3px" }}
							>
								&nbsp;
							</span>
							<Typography
								fontSize={12}
								color={"#7C7C85"}
								lineHeight={"normal"}
								textTransform={"capitalize"}
							>
								{item.dataKey} :
							</Typography>
						</Box>

						<Typography fontSize={12} color={"#7C7C85"} lineHeight={"normal"}>
							{new Intl.NumberFormat("en-US").format(item?.value)}
						</Typography>
					</div>
				))}
			</Box>
		);
	}
};

function mapXAxisValue(type) {
	if (type == "This Year" || type == "Last Year") {
		return {
			1: "Jan",
			2: "Feb",
			3: "Mar",
			4: "Apr",
			5: "May",
			6: "Jun",
			7: "Jul",
			8: "Aug",
			9: "Sep",
			10: "Oct",
			11: "Nov",
			12: "Dec",
		};
	} else if (type == "Last 7 Days") {
		return {
			1: "Sun",
			2: "Mon",
			3: "Tue",
			4: "Wed",
			5: "Thu",
			6: "Fri",
			7: "Sat",
		};
	} else {
		return false;
	}
	// const mapWeek = {
	// 	1: "Sunday",
	// 	2: "Monday",
	// 	3: "Tuesday",
	// 	4: "Wednesday",
	// 	5: "Thursday",
	// 	6: "Friday",
	// 	7: "Saturday",
	// };
}
