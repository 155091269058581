/* eslint-disable react/no-unknown-property */
import React from "react";

export default function Currency({ isActive }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.0007 0.833496C4.93804 0.833496 0.833984 4.93755 0.833984 10.0002C0.833984 15.0628 4.93804 19.1668 10.0007 19.1668C15.0633 19.1668 19.1673 15.0628 19.1673 10.0002C19.1673 4.93755 15.0633 0.833496 10.0007 0.833496ZM10.834 4.5835C10.834 4.12326 10.4609 3.75016 10.0007 3.75016C9.54041 3.75016 9.16732 4.12326 9.16732 4.5835V5.00016C7.55649 5.00016 6.25065 6.306 6.25065 7.91683C6.25065 9.52766 7.55649 10.8335 9.16732 10.8335H10.834C11.5243 10.8335 12.084 11.3931 12.084 12.0835C12.084 12.7739 11.5243 13.3335 10.834 13.3335H9.02843C8.41478 13.3335 7.91732 12.836 7.91732 12.2224C7.91732 11.7621 7.54422 11.3891 7.08398 11.3891C6.62375 11.3891 6.25065 11.7621 6.25065 12.2224C6.25065 13.7565 7.4943 15.0002 9.02843 15.0002H9.16732V15.4168C9.16732 15.8771 9.54041 16.2502 10.0007 16.2502C10.4609 16.2502 10.834 15.8771 10.834 15.4168V15.0002C12.4448 15.0002 13.7507 13.6943 13.7507 12.0835C13.7507 10.4727 12.4448 9.16683 10.834 9.16683H9.16732C8.47696 9.16683 7.91732 8.60718 7.91732 7.91683C7.91732 7.22647 8.47696 6.66683 9.16732 6.66683H10.9729C11.5865 6.66683 12.084 7.16429 12.084 7.77794C12.084 8.23818 12.4571 8.61127 12.9173 8.61127C13.3776 8.61127 13.7507 8.23818 13.7507 7.77794C13.7507 6.24382 12.507 5.00016 10.9729 5.00016H10.834V4.5835Z"
				fill={isActive ? "#00BE5B" : "#808698"}
			/>
		</svg>
	);
}
