import React from "react";

export default function Inbox() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="33"
			height="33"
			viewBox="0 0 33 33"
			fill="none"
		>
			<path
				d="M12.1194 4.12491C10.8438 4.12405 9.88221 4.1234 9.00605 4.4256C8.23537 4.69143 7.53342 5.12526 6.95099 5.6957C6.28886 6.34421 5.85939 7.20461 5.2897 8.34591C4.59061 9.74413 3.45895 12.0117 2.69901 13.5349C2.43644 14.0611 2.30516 14.3243 2.33693 14.5384C2.36467 14.7253 2.46818 14.8927 2.6231 15.001C2.80052 15.125 3.09489 15.125 3.68364 15.125H8.08773C9.5507 15.125 10.8881 15.9515 11.5424 17.2601C11.7308 17.6369 12.116 17.875 12.5373 17.875H20.4627C20.8841 17.875 21.2693 17.6369 21.4577 17.2601C22.1119 15.9515 23.4494 15.125 24.9123 15.125H29.3165C29.9052 15.125 30.1996 15.125 30.377 15.001C30.5319 14.8927 30.6354 14.7253 30.6632 14.5384C30.6949 14.3243 30.5637 14.0611 30.3011 13.5349C29.5412 12.0117 28.4095 9.74414 27.7104 8.34594C27.1407 7.20462 26.7113 6.34421 26.0491 5.6957C25.4667 5.12526 24.7647 4.69143 23.9941 4.4256C23.1179 4.1234 22.1563 4.12405 20.8807 4.12491H12.1194Z"
				fill="#3472FA"
			/>
			<path
				d="M31.6251 20.0745C31.6252 19.3048 31.6252 18.92 31.4753 18.6258C31.3436 18.3671 31.1331 18.1567 30.8744 18.0249C30.5803 17.875 30.1954 17.875 29.4256 17.875H24.9123C24.491 17.875 24.1058 18.113 23.9174 18.4899C23.2631 19.7984 21.9257 20.625 20.4627 20.625H12.5373C11.0744 20.625 9.73695 19.7984 9.08269 18.4899C8.89426 18.113 8.50908 17.875 8.08773 17.875H3.57448C2.80469 17.875 2.4198 17.875 2.12566 18.0249C1.86698 18.1567 1.65654 18.3671 1.52475 18.6258C1.3749 18.92 1.37493 19.3048 1.375 20.0744C1.37503 20.3686 1.37505 20.6627 1.37506 20.9568C1.37504 22.0636 1.37502 22.9771 1.43582 23.7213C1.49897 24.4941 1.6345 25.2046 1.97452 25.8719C2.50182 26.9068 3.34322 27.7482 4.37811 28.2755C5.04544 28.6156 5.75591 28.7511 6.52879 28.8142C7.27293 28.875 8.18642 28.875 9.29323 28.875H23.7069C24.8137 28.875 25.7272 28.875 26.4713 28.8142C27.2442 28.7511 27.9547 28.6156 28.622 28.2755C29.6569 27.7482 30.4983 26.9068 31.0256 25.8719C31.3656 25.2046 31.5011 24.4941 31.5643 23.7213C31.6251 22.9771 31.6251 22.0636 31.6251 20.9568C31.6251 20.6627 31.6251 20.3686 31.6251 20.0745Z"
				fill="#3472FA"
			/>
		</svg>
	);
}
