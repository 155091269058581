import EmptyComponentTable from "components/Table/EmptyComponentTable";
import React from "react";



export default function EmptyTableData() {
	return (
		<EmptyComponentTable
			title="You have no conversations yet"
			desc="Check back later once we've had some time to text your abandoned checkouts"
			
		/>
	);
}
