import React from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Divider,
} from "@mui/material";

export default function MessageReatesModal({ open, handleClose }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Modal
			open={open}
			id="message-rates"
			aria-labelledby="message-rates"
			aria-describedby="message-rates"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box p={"20px 20px 14px 20px"}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						mb={"8px"}
					>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"20px"}
							fontWeight={600}
							lineHeight={"normal"}
						>
							Message Rates
						</Typography>
						<img
							src="/icons/cut.svg"
							alt="cut"
							onClick={handleClose}
							id="pointerCursor"
							style={{
								marginTop: "-10px",
							}}
						/>
					</Box>

					<Box>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"20px"}
							mb={"5px"}
							sx={{
								opacity: 0.5,
							}}
						>
							View the per segment cost of your campaigns & flows
						</Typography>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"20px"}
							sx={{
								opacity: 0.5,
							}}
						>
							*Excludes cart recovery texting
						</Typography>
					</Box>
				</Box>

				<Divider />
				<Box p={"20px"}>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={600}
						lineHeight={"normal"}
						mb={"10px"}
					>
						SMS Segments
					</Typography>
					<Divider />
					<Box mb={"10px"} mt={"10px"} display={"flex"}>
						<Typography
							width={"40%"}
							color={theme.palette?.pageHeading}
							fontSize={"12px"}
							fontWeight={600}
							lineHeight={"normal"}
							sx={{
								opacity: 0.5,
							}}
						>
							Country
						</Typography>
						<Typography
							width={"60%"}
							color={theme.palette?.pageHeading}
							fontSize={"12px"}
							fontWeight={600}
							lineHeight={"normal"}
							sx={{
								opacity: 0.5,
							}}
						>
							Rates
						</Typography>
					</Box>
					{SMS_rates?.map((item, index) => {
						return (
							<React.Fragment key={index}>
								<Divider />
								<Box mb={"10px"} mt={"10px"} display={"flex"}>
									<Typography
										width={"40%"}
										color={theme.palette?.pageHeading}
										fontSize={"14px"}
										fontWeight={500}
										lineHeight={"normal"}
									>
										{item?.contry}
									</Typography>
									<Typography
										width={"60%"}
										color={theme.palette?.pageHeading}
										fontSize={"14px"}
										fontWeight={500}
										lineHeight={"normal"}
									>
										{item?.rate}
									</Typography>
								</Box>
							</React.Fragment>
						);
					})}
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={600}
						lineHeight={"normal"}
						mt={"20px"}
						mb={"10px"}
					>
						MMS Segments
					</Typography>
					<Divider />
					<Box mb={"10px"} mt={"10px"} display={"flex"}>
						<Typography
							width={"40%"}
							color={theme.palette?.pageHeading}
							fontSize={"12px"}
							fontWeight={600}
							lineHeight={"normal"}
							sx={{
								opacity: 0.5,
							}}
						>
							Country
						</Typography>
						<Typography
							width={"60%"}
							color={theme.palette?.pageHeading}
							fontSize={"12px"}
							fontWeight={600}
							lineHeight={"normal"}
							sx={{
								opacity: 0.5,
							}}
						>
							Rates
						</Typography>
					</Box>
					{MMS_rates?.map((item, index) => {
						return (
							<React.Fragment key={index}>
								<Divider />
								<Box mb={"10px"} mt={"10px"} display={"flex"}>
									<Typography
										width={"40%"}
										color={theme.palette?.pageHeading}
										fontSize={"14px"}
										fontWeight={500}
										lineHeight={"normal"}
									>
										{item?.contry}
									</Typography>
									{item?.rate ? (
										<Typography
											width={"60%"}
											color={theme.palette?.pageHeading}
											fontSize={"14px"}
											fontWeight={500}
											lineHeight={"normal"}
										>
											{item?.rate}
										</Typography>
									) : (
										<Typography
											width={"60%"}
											color={"#808698"}
											fontSize={"14px"}
											fontWeight={500}
											lineHeight={"normal"}
											fontStyle={"italic"}
										>
											Not Supported
										</Typography>
									)}
								</Box>
							</React.Fragment>
						);
					})}
					<Divider />
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "642px",
				backgroundColor: theme?.palette?.card?.background,
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				maxHeight: "90vh",
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};

const SMS_rates = [
	{
		contry: "US/Canada",
		rate: "$0.0150",
	},
	{
		contry: "UK/AU",
		rate: "$0.1500",
	},
];
const MMS_rates = [
	{
		contry: "US/Canada",
		rate: "$0.0450",
	},
	{
		contry: "AU",
		rate: "$0.4500",
	},
	{
		contry: "UK",
		rate: false,
	},
];
