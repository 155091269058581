import React from "react";
import LoginTemplate from "../templates/Login";
import { Helmet } from "react-helmet";

export default function Login() {
	return (
		<>
			<Helmet>
				<title>TxtCart® | Sign In</title>
			</Helmet>
			<LoginTemplate />
		</>
	);
}
