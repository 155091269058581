import React from "react";
import { Helmet } from "react-helmet";
import InboxComingSoonTemplate from "templates/ComingSoon/InboxComingSoonTemplate";

export default function Flow() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | Inbox</title>
			</Helmet>
			<InboxComingSoonTemplate />
		</div>
	);
}
