/* eslint-disable react/no-unknown-property */
import React from "react";

export default function Calender() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14 0C14.5523 0 15 0.447715 15 1V2.00163C15.4755 2.00489 15.891 2.01472 16.2518 2.04419C16.8139 2.09012 17.3306 2.18868 17.816 2.43597C18.5686 2.81947 19.1805 3.43139 19.564 4.18404C19.8113 4.66937 19.9099 5.18608 19.9558 5.74817C20 6.28936 20 6.95372 20 7.75868V16.2413C20 17.0463 20 17.7106 19.9558 18.2518C19.9099 18.8139 19.8113 19.3306 19.564 19.816C19.1805 20.5686 18.5686 21.1805 17.816 21.564C17.3306 21.8113 16.8139 21.9099 16.2518 21.9558C15.7106 22 15.0463 22 14.2413 22H5.75868C4.95372 22 4.28937 22 3.74818 21.9558C3.18608 21.9099 2.66937 21.8113 2.18404 21.564C1.43139 21.1805 0.819469 20.5686 0.435975 19.816C0.188684 19.3306 0.0901201 18.8139 0.0441949 18.2518C-2.20835e-05 17.7106 -1.19146e-05 17.0463 4.09674e-07 16.2413V7.7587C-1.19146e-05 6.95374 -2.20872e-05 6.28937 0.0441949 5.74817C0.0901201 5.18608 0.188684 4.66937 0.435975 4.18404C0.819469 3.43139 1.43139 2.81947 2.18404 2.43597C2.66937 2.18868 3.18608 2.09012 3.74818 2.04419C4.10898 2.01472 4.52454 2.00489 5 2.00163V1C5 0.447715 5.44772 0 6 0C6.55229 0 7 0.447715 7 1V2H13V1C13 0.447715 13.4477 0 14 0ZM5 4.00176V5C5 5.55228 5.44772 6 6 6C6.55229 6 7 5.55228 7 5V4H13V5C13 5.55228 13.4477 6 14 6C14.5523 6 15 5.55228 15 5V4.00176C15.4455 4.00489 15.7954 4.01357 16.089 4.03755C16.5274 4.07337 16.7516 4.1383 16.908 4.21799C17.2843 4.40973 17.5903 4.7157 17.782 5.09202C17.8617 5.24842 17.9266 5.47262 17.9624 5.91104C17.9992 6.36113 18 6.94342 18 7.8V8H2V7.8C2 6.94342 2.00078 6.36113 2.03755 5.91104C2.07337 5.47262 2.1383 5.24842 2.21799 5.09202C2.40973 4.7157 2.7157 4.40973 3.09202 4.21799C3.24842 4.1383 3.47262 4.07337 3.91104 4.03755C4.20463 4.01357 4.55447 4.00489 5 4.00176Z"
				fill="#C3C7D2"
			/>
		</svg>
	);
}
