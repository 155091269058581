import React from "react";
import SignUpTemplate from "../templates/Signup";
import { Helmet } from "react-helmet";

export default function Signup() {
	return (
		<>
			<Helmet>
				<title>TxtCart® | Sign Up</title>
			</Helmet>
			<SignUpTemplate />
		</>
	);
}
