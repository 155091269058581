import { useTheme } from "@mui/material";
import { Input } from "antd";

const InputModal = ({ ...props }) => {
	const theme = useTheme();
	return (
		<Input
			style={{
				width: "100%",
				height: "40px",
				backgroundColor: theme.palette?.card.background,
				color: theme?.palette?.pageHeading,
				border: `1px solid ${theme?.palette?.card?.border}`,
			}}
			{...props}
		/>
	);
};
export default InputModal