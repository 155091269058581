import SecondaryButton from "components/Buttons/SecondaryButton";
import EmptyComponentTable from "components/Table/EmptyComponentTable";
import React from "react";
import { BarSVG } from "resources/Icons/Table";

export default function EmptyTableData({setIsModalOpen}) {
	return (
		<EmptyComponentTable
			icon={<BarSVG />}
			title="You have no reports yet"
			desc="Pull powerful information about your store and SMS marketing"
			button={<SecondaryButton
			onClick={()=>setIsModalOpen(true)}
			>Generate a Report</SecondaryButton>}
		/>
	);
}
