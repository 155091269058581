import { useTheme } from "@emotion/react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function TableHeader() {
	const theme = useTheme();
	const {mode} =theme.palette

	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const navigate = useNavigate();
	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			flexDirection={belowSmall?'column':'row'}
			padding={"20px  20px "}
			alignItems={"baseline"}
			
			sx={{
				borderTopLeftRadius: "12px",
				borderTopRightRadius: "12px",
				borderTop: mode == "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
				borderLeft: mode == "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
				borderRight:
					mode == "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
			}}
		>
			<Box>
				<Typography fontSize={"20px"} fontWeight={"600"} lineHeight={'normal'}>
					Latest Conversations
				</Typography>
			</Box>
			<Box>
				<Button
					size={"small"}
					onClick={()=>navigate('/cart-recovery/conversations')}
					sx={{
						backgroundColor: mode == "light" ? "#FFF" : "#2A2B37",
						padding: belowSmall?'9px 16px':"11px 31px",
						border: `1px solid ${mode == "light" ? "#E8EBF3" : "transparent"}`,
						color: mode == "light" ? "#000D31" : "#EBECF2",
						":hover": {
							color: mode == "light" ? "#0C0D17" : "#FFF",
						},
						marginTop:belowSmall?'30px':'0'
					}}
					variant="outlined"
				>
					<Typography fontSize={"14px"} fontWeight={"600"}>
						View All Conversations
					</Typography>
				</Button>
			</Box>
		</Box>
	);
}
