import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function SubmittedTab({message}) {
	const theme = useTheme();
	return (
		<Box>
		<Typography
			color={theme.palette?.pageHeading}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			mb={1}
			sx={{
				opacity: "0.5",
			}}
		>
			{message}
		</Typography>
		</Box>
	);
}
