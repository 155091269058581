import React from "react";
import { Helmet } from "react-helmet";
import CampaignsErrorTemplate from "templates/Error/CampaignsErrorTemplate";

export default function CampaignError() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | Campaigns</title>
			</Helmet>
			<CampaignsErrorTemplate />
		</div>
	);
}
