import moment from "moment";
export const compareDateStrings = (a, b) => {
	if (a === b) {
        return 0;
    }
	if (a === null || a === undefined || a === '') {
        return 1;
    }
    if (b === null || b === undefined || b === '') {
        return -1;
    }
	const date1 = new Date(a);
	const date2 = new Date(b);
	return date1 - date2;
};

export const compareDateStringsUsingMomemt=(a,b)=>{
	let d1 = moment(a, "M/DD/YY h:mmA");
	let d2 = moment(b, "M/DD/YY h:mmA");
	const date1 = new Date(d1);
	const date2 = new Date(d2);

	return date1 - date2;
}


