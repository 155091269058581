/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Table } from "antd";
import InputBase from "@mui/material/InputBase";
import {
	Box,
	MenuItem,
	Typography,
	styled,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Status from "components/Popovers/Status";
import LastModified from "components/Popovers/LastModified";
import Select from "components/Select/Select";
import { StatusOptions } from "resources/constant";

export default function TableHeader({ belowSmall, theme,handleFilterTableData,emptyState,searchValue,setSearchValue, allCount, customCount, templateCount, activeTableTab,setActiveTableTab, filterStatus }) {
	const [anchorElLastModified, setAnchorElLastModified] = useState(null);

	// const handleLastModifiedClick = (event) => {
	// 	setAnchorElLastModified(event.currentTarget);
	// };

	// const handleLastModifiedClose = () => {
	// 	setAnchorElLastModified(null);
	// };

	const lastModifiedOpen = Boolean(anchorElLastModified);
	const modifiedId = lastModifiedOpen ? "simple-popover" : undefined;
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedium = useMediaQuery(theme.breakpoints.down("lg"));
	const [selected, setSelected] = useState("Status: All");
	const handleTabChange = (value) => {
		setActiveTableTab(value);
		handleFilterTableData(value)
	};

	const handleStatusChange = function(label,value){
		console.log(label);
		filterStatus(value);
	}
	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			flexDirection={isMedium ? "column" : "row"}
			padding={"20px  20px  0px 20px"}
			// alignItems={"baseline"}
			sx={{
				borderTopLeftRadius: "12px",
				borderTopRightRadius: "12px",
				borderTop: `1px solid ${theme.palette?.card?.border}`,
				borderLeft: `1px solid ${theme.palette?.card?.border}`,
				borderRight: `1px solid ${theme.palette?.card?.border}`,
			}}
		>
			<Box
				display={"flex"}
				gap={"24px"}
				alignItems={"baseline"}
				flexDirection={isSmall ? "column" : "row"}
			>
				<Box
					sx={{
						paddingBottom: "15px",
					}}
				>
					<Typography
						fontSize={"20px"}
						fontWeight={"600"}
						lineHeight={"normal"}
					>
						All SMS Campaigns
					</Typography>
				</Box>
				<Box display={"flex"} gap={"10px"}>
					<Box
						display={"flex"}
						sx={{
							borderBottom:
								activeTableTab == "All" ? " 2px solid #0C84FE" : "none",
							paddingBottom: "20px",
						}}
						onClick={() => handleTabChange("All")}
						id="pointerCursor"
					>
						<Typography
							fontSize={14}
							fontWeight={500}
							color={
								activeTableTab == "All" ? theme.palette?.pageHeading : "#808698"
							}
							lineHeight={"normal"}
						>
							All
						</Typography>
						<ActiveTabCount
							theme={theme}
							value={allCount}
							activeTableTab={activeTableTab}
							name="All"
						/>
					</Box>

					<Box
						display={"flex"}
						sx={{
							borderBottom:
								activeTableTab == "Template" ? " 2px solid #0C84FE" : "none",
							paddingBottom: "20px",
						}}
						onClick={() => handleTabChange("Template")}
						id="pointerCursor"
					>
						<Typography
							fontSize={14}
							lineHeight={"normal"}
							fontWeight={500}
							color={
								activeTableTab == "Template"
									? theme.palette?.pageHeading
									: "#808698"
							}
							// pl={"10px"}
						>
							Template
						</Typography>
						<ActiveTabCount
							theme={theme}
							value={templateCount}
							name="Template"
							activeTableTab={activeTableTab}
						/>
					</Box>
					<Box
						id="pointerCursor"
						display={"flex"}
						sx={{
							borderBottom:
								activeTableTab == "Custom" ? " 2px solid #0C84FE" : "none",
							paddingBottom: "20px",
						}}
						onClick={() => handleTabChange("Custom")}
					>
						<Typography
							fontSize={14}
							lineHeight={"normal"}
							fontWeight={500}
							color={
								activeTableTab == "Custom"
									? theme.palette?.pageHeading
									: "#808698"
							}
							// pl={"10px"}
						>
							Custom
						</Typography>
						<ActiveTabCount
							theme={theme}
							name="Custom"
							value={customCount}
							activeTableTab={activeTableTab}
						/>
					</Box>
				</Box>
			</Box>
			<Box
				display={"flex"}
				gap={"10px"}
				mt={isMedium ? "10px" : "0px"}
				mb={isMedium ? "10px" : "0px"}
				flexDirection={isSmall ? "column" : "row"}
			>
				<Box
					sx={{
						width: isSmall ? "100%" : "170px",
					}}
				>
					<Status
						handleSelectChange={handleStatusChange}
						options={StatusOptions}
						width={"180px"}
						selected={selected}
						setSelected={setSelected}
					/>
				</Box>
				{/* <Box
					sx={{
						width: isSmall ? "100%" : "140px",
					}}
				>
					<LastModified
						id={modifiedId}
						anchorElStatus={anchorElLastModified}
						handleClose={handleLastModifiedClose}
						handleClick={handleLastModifiedClick}
						open={lastModifiedOpen}
						width={"140px"}
					/>
				</Box> */}
				<Box
					sx={{
						width: isSmall ? "100%" : "212px",
					}}
				>
					<Search>
						<SearchIconWrapper>
							<SearchIcon />
						</SearchIconWrapper>
						<StyledInputBase
							placeholder="Search"
							inputProps={{ "aria-label": "search" }}
							value={searchValue}
							onChange={(e)=>setSearchValue(e.target?.value)}
						/>
					</Search>
				</Box>
			</Box>
		</Box>
	);
}
const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: "inherit",
	"&:hover": {
		backgroundColor: "inherit",
	},
	marginLeft: 0,
	width: "100%",
	[theme.breakpoints.up("sm")]: {
		// marginLeft: theme.spacing(1),
		width: "auto",
	},
}));

const SearchIconWrapper = styled("div")(() => ({
	padding: "0 0 0 8px",
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	color: "#808698",
	justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "#808698",
	"& .MuiInputBase-input": {
		border:
			theme.palette?.mode == "light"
				? "1px solid #E8EBF3"
				: "1px solid #1E202D",
		borderRadius: "5px",
		padding: "5px 0px",
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			// width: "100%",
			"&:focus": {
				// width: "100%",
				border: "1px solid #0C84FE",
			},
		},
	},
	"::placeholder": {
		color: "#808698",
		fontSize: "14px",
	},
}));

const ActiveTabCount = ({ value, theme, activeTableTab, name }) => {
	return (
		<span
			style={{
				width: "30px",
				height: "18px",
				// padding: "0px 9px",
				borderRadius: "100px",
				display:'flex',
				justifyContent:'center',
				alignItems:'center',
				marginLeft: "7px",
				background:
					activeTableTab == name
						? theme.palette?.mode == "light"
							? "#DBEDFF"
							: "#0b1f3a"
						: theme.palette?.mode == "light"
						? "#EDEEf1"
						: "#1D1F2A",
			}}
		>
			<Typography
				fontSize={12}
				fontWeight={500}
				color={activeTableTab == name ? "#0C84FE" : "#808698"}
				sx={{
					opacity: "1",
				}}
			>
				{value}
			</Typography>
		</span>
	);
};
