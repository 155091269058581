import React, { createContext, useState } from "react";
import { useEffect } from "react";
export const DataContext = createContext(null);
export default function DataProvider(props) {
	const [showCartRecoveryTutorial, setShowCartRecoveryTutorial] =
		useState(true);
	const [showLoading, setShowLoading] = useState(false);
	const [showVideoModal, setShowVideoModal] = useState(false);

	const [showChildren, setShowChildren] = useState(false);
	const [campaignForEdit, setCampaignForEdit] = useState(false);
	const [showBillingModal, setShowBillingModal] = useState(false);
	const [showFullPageConteffi, setShowFullPageConteffi] = useState(false);
	const [campaignsVerified, setCampaignsVerified] = useState(false);
	const [onboarding, setOnboarding] = useState(false);

	useEffect(() => {
		let value = localStorage.getItem("cart-banner");
		if (value) {
			setShowCartRecoveryTutorial(true);
		}

		setShowChildren(true);
	}, []);
	return (
		<DataContext.Provider
			value={{
				showCartRecoveryTutorial,
				setShowCartRecoveryTutorial,
				showLoading,
				setShowLoading,
				showVideoModal,
				setShowVideoModal,
				onboarding,
				setOnboarding,
				setCampaignForEdit,
				campaignForEdit,
				showBillingModal,
				setShowBillingModal,
				showFullPageConteffi,
				setShowFullPageConteffi,
				campaignsVerified,
				setCampaignsVerified,
			}}
		>
			{showChildren && props.children}
		</DataContext.Provider>
	);
}
