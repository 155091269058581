import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export default function ConnectSlackButton({ children, ...props }) {
	const theme = useTheme();
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Button
			sx={{
				padding: "11px 18px",
				border: "none",
				backgroundColor: "#4A154B",
				height: belowSmall ? "34px" : "40px",
				":hover": {
					background: "#4A154B",
					border: "none",
				},
			}}
			variant="outlined"
			{...props}
		>
			<Typography fontSize={"14px"} fontWeight={"600"} color="#FFF">
				<img
					src="/img/analytics/slack-icon.svg"
					style={{ marginRight: "8px", marginBottom: "-4px" }}
				/>
				{children}
			</Typography>
		</Button>
	);
}
