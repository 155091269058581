import {
	Box,
	Typography,
	MenuItem,
	MenuList,
	ListItemText,
	Button,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import Antd from "components/Table/Antd";
import TableHeading from "components/Typography/TableHeading";
import React, {  useEffect, useState } from "react";
import { Dropdown } from "antd";
import { reportMock } from "resources/constant";
import { compareDateStrings } from "resources/utils/sorter";
import EmptyTableData from "./EmptyTableData";
import { checkEmptyState } from "resources/helpers/checkEmptyState";

export default function TableSecton({ setIsModalOpen }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const [tableData, setTableData] = useState();

	const columns = [
		{
			dataIndex: "reportName",
			key: "reportName",
			title: <TableHeading minWidth={"120px"}>Report Name</TableHeading>,
			sortable: false,
			width: "15%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "channel",
			key: "channel",
			title: <TableHeading minWidth={"110px"}>Channel</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => a?.channel.localeCompare(b?.channel),
			width: "10%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "frequency",
			key: "frequency",
			title: <TableHeading minWidth={"130px"}>Frequency</TableHeading>,
			sortable: false,
			width: "15%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "summary",
			key: "summary",
			title: <TableHeading minWidth={"210px"}>Summary</TableHeading>,
			sortable: false,
			width: "25%",
			render: (params, record) => (
				<Box display={"flex"}>
					<Typography
						fontSize={14}
						fontWeight={500}
						color={lightMode ? "#000D31" : "#FFF"}
					>
						{params},&nbsp;
					</Typography>
					<span style={{ color: "#0081FF" }} id="pointerCursor">
						{/* <Tooltip
							text={record?.more}
							lightMode={lightMode}
							title={record?.more && "3 more"}
						/> */}
						{record?.more && "3 more"}
					</span>
				</Box>
			),
		},
		{
			dataIndex: "createdOn",
			key: "createdOn",
			title: <TableHeading minWidth={"130px"}>Created On</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => compareDateStrings(a.createdOn, b.createdOn),
			width: "15%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "Status",
			key: "Status",
			title: <TableHeading minWidth={"120px"}>Status</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => a?.Status.localeCompare(b?.Status),
			width: "15%",
			render: (params) => (
				<Box className={`status ${lightMode ? params : `${params}-dark`}`}>
					<span>{params}</span>
				</Box>
			),
		},

		{
			key: "actions",
			dataIndex: "actions",
			title: (
				<TableHeading minWidth={"50px"} maxWidth="50px">
					Actions
				</TableHeading>
			),
			width: "50px",
			render: () => (
				<Box display={"flex"} justifyContent={"center"} mr={"10px"}>
					<Dropdown
						overlay={<ActionMenu />}
						placement="bottomRight"
						trigger={["click"]}
					>
						<Button
							height="10px"
							disableRipple
							sx={{
								height: "25px",
								":hover": {
									background: "transparent",
								},
							}}
						>
							<img src="/icons/more.svg" id="pointerCursor" />
						</Button>
					</Dropdown>
				</Box>
			),
		},
	];

	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		let data;
		if (!empty) {
			data = [...reportMock];
		} else {
			data = [];
		}
		setTimeout(() => {
			setTableData([...data]);
		}, 4000);
	}, []);

	return (
		<Box className="reports-analytics">
			<Antd
				mode={mode}
				columns={columns}
				rows={tableData}
				loading={tableData ? false : true}
				EmptyComponentTable={<EmptyTableData setIsModalOpen={setIsModalOpen} />}
			/>
		</Box>
	);
}

const ActionMenu = () => {
	const theme = useTheme();
	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={`1px solid ${theme.palette?.card?.border}`}
			borderRadius={"8px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				<MenuItem key={"1"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme}>Edit</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem key={"3"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme} color="#FF465C">
							Delete
						</MenuTypography>
					</ListItemText>
				</MenuItem>
			</MenuList>
		</Box>
	);
};
const MenuTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			fontSize={"14px"}
			color={theme.palette?.mode == "light" ? "#0A0A18" : "#FFF"}
			lineHeight={"normal"}
			fontWeight={500}
			{...props}
		>
			{children}
		</Typography>
	);
};