import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import TableSecton from "./components/TableSecton";
import PrimaryButton from "components/Buttons/PrimaryButton";
import CreateSegment from "components/Modal/Segments/CreateSegment";

export default function Segments() {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Grid container spacing={2} alignItems={"center"}>
			<Grid item xs={12}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<Typography
						lineHeight={"normal"}
						fontSize={"24px"}
						fontWeight={600}
						color={theme.palette?.card?.color}
						mt={"15px"}
					>
						Segments
					</Typography>
					{!belowSmall &&
					(
						<PrimaryButton onClick={() => setOpen(true)}>
							Create Segment
						</PrimaryButton>
					)}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<TableSecton handleOpenModal={() => setOpen(true)}/>
			</Grid>
			<CreateSegment open={open} handleClose={() => setOpen(false)} />
		</Grid>
	);
}
