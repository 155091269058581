import React from "react";
import { Helmet } from "react-helmet";
import FlowAnalyticsComingSoonTemplate from "templates/ComingSoon/FlowAnalyticsComingSoonTemplate";

export default function FlowAnalytics() {
	return (
		<div>
			<Helmet>
				<title>TxtCart | Integration</title>
			</Helmet>
			<FlowAnalyticsComingSoonTemplate />
		</div>
	);
}